
import { Calendar } from 'primereact/calendar';
import { useState, useEffect } from 'react';
import Moment from 'moment';
import './FieldCommon.scss'

interface IOptions {
    label: string,
    value: string
}

interface ISelect {
    update: (name: string, value: string, displayLabel: string, valueLabels?: any) => void,
    label: string,
    name: string,
    disabled?: boolean,
    error?: boolean,
    minDate?: any,
    maxDate?: any,
    isCompare?: boolean,
    compareWith?: string,
    differenceInDays?: number,
    value: any
}

const Select = ({ update, label, name, disabled, error, minDate, maxDate, isCompare, differenceInDays, compareWith, value }: ISelect) => {
    const [val, setVal] = useState<any>()

    useEffect(() => {
        if (value) {
            setVal(Moment(value).toDate())
        } else {
            setVal(undefined)
        }
    }, [value])

    const change = (e: any) => {
        setVal(e.value)
        const d = Moment(e.value).format('YYYY-MM-DD')
        update(name, d, label)
    }

    const getMinDate = () => {
        return minDate && new Date(minDate)
    }

    const getMaxDate = () => {
        return maxDate && new Date(maxDate)
    }
    return (<Calendar dateFormat="yy-mm-dd" className={"form-control shadow-none max-height-38" + `${error ? ' invalid' : ''}`} value={val} onChange={change} readOnlyInput={true} placeholder={`Select ${label}`}
        disabled={disabled} minDate={getMinDate()} maxDate={getMaxDate()} showMinMaxRange={false} icon="mdi mdi-calendar" showIcon={true} />)
}

export default Select