
import { useEffect } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';


const SmartRouteNavigator = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`/smart${location.pathname}`)
    }, [location]);

    return <div>Redirecting to SMART</div>
}

export default SmartRouteNavigator;