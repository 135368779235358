const actionType = {
      GET_ALL_COMPANY: 'GET_ALL_COMPANY_SOLUTION_MAPPING',
      GET_ALL_COMPANY_SUCCESS: 'GET_ALL_COMPANY_SOLUTION_MAPPING_SUCCESS',
      GET_ALL_COMPANY_FAILURE: 'GET_ALL_COMPANY_SOLUTION_MAPPING_FAILURE',
      GET_ALL_MAPPINGS: 'GET_ALL_MAPPINGS',
      GET_ALL_MAPPINGS_SUCCESS: 'GET_ALL_MAPPINGS_SUCCESS',
      GET_ALL_MAPPINGS_FAILURE: 'GET_ALL_MAPPINGS_FAILURE',
      GET_ALL_SULUTION_MAPPING: 'GET_ALL_SULUTION_MAPPING',
      GET_ALL_SULUTION_MAPPING_SUCCESS: 'GET_ALL_SULUTION_MAPPING_SUCCESS',
      GET_ALL_SULUTION_MAPPING_FAILURE: 'GET_ALL_SULUTION_MAPPING_FAILURE',
      UPDATE_SOLUTION_MAPPINGS: 'UPDATE_SOLUTION_MAPPINGS',
      ADD_SOLUTION_MAPPINGS: 'ADD_SOLUTION_MAPPINGS',
      ADD_SOLUTION_MAPPINGS_SUCCESS: 'ADD_SOLUTION_MAPPINGS_SUCCESS',
      ADD_SOLUTION_MAPPINGS_FAILURE: 'ADD_SOLUTION_MAPPINGS_FAILURE',
      FILTER_SUT_COMPANY: 'FILTER_SUT_COMPANY',
      RESET_ALL_SOLUTION_MAPPINGS: 'RESET_ALL_SOLUTION_MAPPINGS',
      CLEAR_SOLUTION_MAPPING_STATE: 'CLEAR_SOLUTION_MAPPING_STATE',
      CLEAR_FILTER_SUT_COMPANY: 'CLEAT_FILTER_SUT_COMPANY'
}

export default actionType