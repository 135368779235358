const actionTypes = {
    GET_ALL_CUSTOM_SCORE_GROUP : 'GET_ALL_CUSTOM_SCORE_GROUP',
    GET_ALL_CUSTOM_SCORE_GROUP_SUCCESS : 'GET_ALL_CUSTOM_SCORE_GROUP_SUCCESS',
    GET_ALL_CUSTOM_SCORE_GROUP_FAILURE : 'GET_ALL_CUSTOM_SCORE_GROUP_FAILURE',
    GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP:'GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP',
    GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_SUCCESS:'GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_SUCCESS',
    GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_FAILURE:'GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_FAILURE',
    GET_ALL_TC_SCORE_TAG:'GET_ALL_TC_SCORE_TAG',
    GET_ALL_TC_SCORE_TAG_SUCCESS:'GET_ALL_TC_SCORE_TAG_SUCCESS',
    GET_ALL_TC_SCORE_TAG_FAILURE:'GET_ALL_TC_SCORE_TAG_FAILURE',
    GET_ALL_COMPANY:'GET_ALL_COMPANY',
    GET_ALL_COMPANY_SUCCESS:'GET_ALL_COMPANY_SUCCESS',
    GET_ALL_COMPANY_FAILURE:'GET_ALL_COMPANY_FAILURE',
    GET_ALL_CONTENT_PACKAGE:'GET_ALL_CONTENT_PACKAGE',
    GET_ALL_CONTENT_PACKAGE_SUCCESS:'GET_ALL_CONTENT_PACKAGE_SUCCESS',
    GET_ALL_CONTENT_PACKAGE_FAILURE:'GET_ALL_CONTENT_PACKAGE_FAILURE',
    ADD_CUSTOM_SCORE_GROUP:'ADD_CUSTOM_SCORE_GROUP',
    ADD_CUSTOM_SCORE_GROUP_SUCCESS:'ADD_CUSTOM_SCORE_GROUP_SUCCESS',
    ADD_CUSTOM_SCORE_GROUP_FAILURE:'ADD_CUSTOM_SCORE_GROUP_FAILURE',
    DELETE_CUSTOM_SCORE_GROUP:'DELETE_CUSTOM_SCORE_GROUP',
    DELETE_CUSTOM_SCORE_GROUP_SUCCESS:'DELETE_CUSTOM_SCORE_GROUP_SUCCESS',
    DELETE_CUSTOM_SCORE_GROUP_FAILURE:'DELETE_CUSTOM_SCORE_GROUP_FAILURE',
    EDIT_CUSTOM_SCORE_GROUP:'EDIT_CUSTOM_SCORE_GROUP',
    EDIT_CUSTOM_SCORE_GROUP_SUCCESS:'EDIT_CUSTOM_SCORE_GROUP_SUCCESS',
    EDIT_CUSTOM_SCORE_GROUP_FAILURE:'EDIT_CUSTOM_SCORE_GROUP_FAILURE',
    UPDATE_CUSTOM_SCORE_GROUP_INFO:'UPDATE_CUSTOM_SCORE_GROUP_INFO',
    SELECT_ADD_SCORE_GROUP:'SELECT_ADD_SCORE_GROUP',
    SELECT_EDIT_SCORE_GROUP:'SELECT_EDIT_SCORE_GROUP',
    CLEAR_SELECTED_SCORE_GROUP:'CLEAR_SELECTED_SCORE_GROUP',
    UPDATE_SCORE_GROUP_INFO:'UPDATE_SCORE_GROUP_INFO',
    UPDATE_SELECTED_TC_TAGS:'UPDATE_SELECTED_TC_TAGS',
    UPDATE_TC_TAG_LIST:'UPDATE_TC_TAG_LIST',
    UPDATE_ALL_CUSTOM_SCORE_GROUP: 'UPDATE_ALL_CUSTOM_SCORE_GROUP',
    SET_VIEW_ONLY: 'SET_VIEW_ONLY'
}

export default actionTypes;