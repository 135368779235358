import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { updateRequestData, clearRequestData, submitRequest, checkRequestStatus } from '../../../actions/reportRequestAction'
import { InputTextarea } from 'primereact/inputtextarea';
import { IReportRequestData, IReportRequestStatus } from '../../../types/reportRequest'
import { IStoreState } from "../../../types/StoreState";
import { showNotification } from '../../../actions/notificationAction';
import { INotification } from '../../../types/notification';
import ProcessComponent from '../../shared/ProcessComponent/ProcessComponent';
import './ReportRequest.scss'
import { rolesList } from 'src/constants/RoleList';

interface IReportRequestProps {
    data: IReportRequestData,
    status: IReportRequestStatus,
    updateRequestData: (payload: any) => void,
    clearRequestData: () => void,
    submitRequest: (payload: any) => void,
    userInfo: any,
    checkRequestStatus: () => void,
    isPendingRequest: boolean,
    roles: number[],
    rolesLoading: boolean,
    showNotification: (payload: INotification) => void
}

const ReportRequest = ({ rolesLoading, roles, data, status, updateRequestData, clearRequestData, submitRequest, userInfo, isPendingRequest, checkRequestStatus, showNotification }: IReportRequestProps) => {
    const [requestSubmitted, setRequestSubmitted] = useState(false);

    const piRequestStatus = {
        processName: 'PI Data Access Request Status',
        processDesc: 'You have requested access for PI Data. Current status of your request is below.',
        steps: [
            {
                title: 'Request',
                desc: 'A PI request has been submitted by the user.'
            },
            {
                title: 'Pending',
                desc: 'Your request is pending for DPO approval. Please contact dpo@shl.com.'
            },
            {
                title: 'Approve/Reject',
                desc: 'Your request has been approved by DPO.'
            },
            {
                title: 'Access Granted',
                desc: 'You have access to personal information.'
            }
        ]
    }
    useEffect(() => {
        checkRequestStatus()
        return () => {
            clearRequestData();
        }
    }, [])

    useEffect(() => {
        if (status && !status.loading && status.success) {
            setRequestSubmitted(true)
        }
    }, [status])

    const onFieldChange = (event: any) => {
        updateRequestData({ justification: event.currentTarget.value })
    }

    const valid = () => {
        return data && data.justification.trim()
    }

    const submit = () => {
        if (!valid()) {
            showNotification({
                severity: 'error',
                summary: 'Justification is required.',
                detail: '',
                life: 2000
            })
            return
        };
        const payload = {
            name: userInfo && userInfo.data && `${userInfo.data.fname} ${userInfo.data.lname}`,
            ...data
        }

        submitRequest(payload)
    }


    return (<div className="h-100 p-4 container mx-auto l3-font-size">
        {requestSubmitted || isPendingRequest ?
            <div className="mx-auto mb-5 shadow process-wrapper"> <ProcessComponent {...piRequestStatus} processIndex={1} status={1}  ></ProcessComponent> </div>
            : rolesLoading ? <div>Checking..</div> : roles.some((item) => item === rolesList.PIDATA) ? <div className="mx-auto mb-5 shadow process-wrapper"> <ProcessComponent {...piRequestStatus} processIndex={1} status={4}  ></ProcessComponent> </div> : <>
                <h4>You don't have access to Personal Information Data, Please request access if you want to view PI Data.</h4>
                <h6>This is a one time request. Access will be granted after DPO approval.</h6>
                <div className="w-100 mt-3">
                    <InputTextarea placeholder='Please Enter Justification' rows={4} value={data.justification} onChange={onFieldChange} autoResize className="form-control p-1 w-100 border border-info"></InputTextarea>
                </div>
                <div className="mt-3">
                    <button className="btn btn-success" onClick={submit} disabled={status && status.loading}>Submit</button>
                </div>
            </>}
    </div>)
}

const mapStateToProps = (state: IStoreState) => ({
    data: state.reportRequest.reportData,
    status: state.reportRequest.requestStatus,
    userInfo: state.login.userInfo,
    isPendingRequest: state.reportRequest.isRequestPending.data,
    roles: state.login.userRoles.roles,
    rolesLoading: state.login.userRoles.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    updateRequestData: (payload: any) => {
        dispatch(updateRequestData(payload))
    },
    clearRequestData: () => {
        dispatch(clearRequestData())
    },
    submitRequest: (payload: any) => {
        dispatch(submitRequest(payload))
    },
    checkRequestStatus: () => {
        dispatch(checkRequestStatus())
    },
    showNotification: (payload: INotification) => {
        dispatch(showNotification(payload))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportRequest);