import axios from "axios";
import { config } from "../../../api/config";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { connect } from 'react-redux'
import { clearBreadCrumb, updateBreadCrumb } from "../../../actions/BreadCrumbAction";
import { deleteToken, logHistory, updateToken } from "../../../actions/tableauReportAction";
import tokenService from "../../../api/token.service";
import { ICaReport, ICategoryReports } from "../../../types/CaReport";
import { IStoreState } from "../../../types/StoreState";
import TableauReport from "../../shared/Tableau/TableauReport";
import './CaReport.scss'
import report from "../../Smart/report/report";
import { clearCaReportState } from "../../../actions/CaReportAction";

interface IProps {
    currentCategory: ICategoryReports,
    currentCaReport: ICaReport,
    clearCaReportState: () => void,
    token: any,
    userInfo: any,
    updateToken: (token: any) => void,
    deleteToken: () => void,
    updateBreadCrumb: (breadcrumb: any) => void,
    clearBreadCrumb: () => void,
    logHistory: (payload: any) => void,
    deleteTokenStatus: {
        loading: boolean,
        success: boolean,
        error: boolean
    }
}


const CaReport = ({ currentCaReport, currentCategory, updateBreadCrumb, updateToken, clearBreadCrumb, logHistory, token, deleteToken, clearCaReportState, userInfo, deleteTokenStatus }: IProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [waitingForName, setWaitingForName] = useState<boolean>(false);
    const reportRef = useRef<any>()
    const [intialLoad, setIntialLoad] = useState<boolean>(false);
    const [waitingTokenDelete, setWaitingTokenDelete] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            checkTokenStatus().then((data) => {
                if (data)
                    deleteToken();
            })
            clearBreadCrumb();
            clearCaReportState();
        }

    }, [])


    useEffect(() => {
        if (waitingForName && userInfo && userInfo.fname && userInfo.lname) {
            const payload = {
                reportId: params.reportId,
                name: `${userInfo.fname} ${userInfo.lname}`,
                applicationName: 'client-analytics'
            }
            logHistory(payload)
            setWaitingForName(false)
        }

    }, [userInfo])

    useEffect(() => {
        if (params && params.kpiId && params.reportId) {
            setIntialLoad(true);
            reportRef && reportRef.current && reportRef.current.dispose()
            const breadcrumb = [{ label: 'Home', url: '/clientAnalytics' }];
            const userInfo = tokenService.getUserInfo();
            if (userInfo && userInfo.lname && userInfo.fname) {
                const payload = {
                    reportId: params.reportId,
                    name: `${userInfo.fname} ${userInfo.lname}`,
                    applicationName: 'client-analytics'
                }
                logHistory(payload)
            }
            else {
                setWaitingForName(true)
            }
            if (!currentCaReport || !currentCategory) {
                navigate('/404')
                return
            }
            breadcrumb.push({ label: `${currentCategory.categoryName}`, url: `/clientAnalytics` })
            breadcrumb.push({ label: `${currentCaReport.name}`, url: `report/${currentCaReport.id}` })
            updateBreadCrumb(breadcrumb)
            if (token) {
                deleteToken();
                setWaitingTokenDelete(true)
            }
            reportRef && reportRef.current && reportRef.current.initViz({});
        }

    }, [currentCaReport, currentCategory])

    useEffect(() => {
        if (waitingTokenDelete && deleteTokenStatus && !deleteTokenStatus.loading && deleteTokenStatus.success) {
            reportRef && reportRef.current && reportRef.current.initViz({});
        }
    }, [deleteTokenStatus])

    const checkTokenStatus = async () => {
        if (!tokenService.getTableauToken) return true
        const isActive = await axios.get(config.baseUrl + config.apiUrl.isSessionActive + '?token=' + tokenService.getTableauToken());
        if (isActive && isActive.status === 200 && isActive.data && isActive.data.state === 'OK') {
            if (isActive.data.tokenStatus.toUpperCase() !== 'ACTIVE') {
                tokenService.deleteTableauToken();
                return false
            }
        }
        return true
    }

    const updateIntialLoad = (status: boolean) => {
        setIntialLoad(status)
    }

    const download = () => {
        reportRef && reportRef.current && reportRef.current.download()
    }



    return (
        <div className="parent-container p-2">
            <div className="mt-1 report-container">
                <TableauReport updateIntialLoad={updateIntialLoad} token={token} updateToken={updateToken} ref={reportRef} report={currentCaReport} styles={{}} hideToolBar={true} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: IStoreState) => ({
    currentCategory: state.caReport.currentCategory,
    currentCaReport: state.caReport.currentCaReport,
    token: state.tableauReport.token,
    userInfo: state.login.userInfo.data,
    deleteTokenStatus: state.tableauReport.deleteTokenStatus
})

const mapDispatchToProps = (dispatch: any) => ({
    deleteToken: () => {
        dispatch(deleteToken())
    },
    updateToken: (token: any) => {
        dispatch(updateToken(token))
    },
    updateBreadCrumb: (payload: any) => {
        dispatch(updateBreadCrumb(payload))
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    logHistory: (payload: any) => {
        dispatch(logHistory(payload))
    },
    clearCaReportState: () => {
        dispatch(clearCaReportState())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CaReport);