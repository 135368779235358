import { useState, useEffect, useRef } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import './FieldCommon.scss'

interface IOptions {
    label: string,
    value: string,
    disabled?: boolean
}
interface IGroupOptions {
    label: string,
    items: IOptions[]
}


interface IGroupedMultiSelect {
    update: (name: string, value: any) => void,
    selectOptions?: IGroupOptions[],
    label: string,
    name: string,
    disabled?: boolean,
    error?: boolean,
    noJoin?: boolean,
    value?: any,
    maxSelected?: number,
    onFilter?: (event: any) => void,
    onShow?: () => void,
    onHide?: () => void,
    panelClass?: string,
    noApply?: boolean,
    onBlur?: (e: any) => void,
    valueSelectedAll?: any,
    optionLabelField?: string,
    optionValueField?: string
}

const GroupedMultiSelect = ({ update, selectOptions, label, name, disabled, error, noJoin, value, maxSelected, onFilter, panelClass, onShow, onHide, noApply, onBlur, valueSelectedAll, optionLabelField, optionValueField }: IGroupedMultiSelect) => {
    const [innerValue, setValue] = useState<any[]>([])
    const [list, setList] = useState<any[]>([])

    useEffect(() => {
        if (value) {
            if (!noJoin) {
                setValue(value.split(','))
                updateList(value.split(','))
            } else {
                setValue(value)
                updateList(value)
            }
        } else {
            setValue([])
        }
    }, [value])

    useEffect(() => {
        if (selectOptions) {
            setList(selectOptions)
            updateList(value)
        }
    }, [selectOptions]);

    const updateList = (value: any) => {
        if (!selectOptions) return;
        if (value.includes(valueSelectedAll)) {
            let l = selectOptions.map((item: any) => {
                item.items.map((i: any) => {
                    if (i.value !== 'allUser') {
                        i['disabled'] = true
                    }
                    return i;
                })
                return item
            })
            setList(l)
        } else if (!value) {
            let l = selectOptions.map((item: any) => {
                item.items.map((i: any) => {
                    i['disabled'] = false
                    return i;
                })
                return item
            })
            setList(l)
        }
    }


    const groupUpdate = (name: string, value: any) => {
        if (value.includes(valueSelectedAll)) {
            let l = list.map((item: any) => {
                item.items.map((i: any) => {
                    if (i.value !== 'allUser') {
                        i['disabled'] = true
                    }
                    return i;
                })
                return item
            })
            update(name, valueSelectedAll);
            setList(l)
        } else if (!value) {
            update(name, '');
            let l = list.map((item: any) => {
                item.items.map((i: any) => {
                    i['disabled'] = false
                    return i;
                })
                return item
            })
            setList(l)
        } else {
            update(name, value)
        }
    }

    const panelFooterTemplate = (props: any, hide: any) => {

        const apply = () => {
            if (noJoin) {
                if (valueSelectedAll) {
                    groupUpdate(name, innerValue)
                } else {
                    update(name, innerValue)
                }
                hide()
                return
            }
            if (valueSelectedAll) {
                groupUpdate(name, innerValue.join(','))
            } else {
                update(name, innerValue.join(','))
            }
            hide();
        }

        return <div className='p-1'>
            <hr className='m-2' />
            <button className='me-4 text-end btn btn-primary mb-1 shadow-none' onClick={apply} >Apply</button>
        </div>
    }



    const change = (e: any) => {
        setValue(e.value)
        if (noApply) {
            if (noJoin) {
                if (valueSelectedAll) {
                    groupUpdate(name, e.value)
                } else {
                    update(name, e.value)
                }
                return
            }
            if (valueSelectedAll) {
                groupUpdate(name, e.value.join(','))
            } else {
                update(name, e.value.join(','))
            }
        }
    }

    const checkFilter = (event: any) => {
        onFilter && onFilter(event)
    }

    const blurred = (e: any) => {
        onBlur && onBlur(e)
    }

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option.label}</div>
            </div>
        );
    };
    return (
        <MultiSelect optionLabel="label" onShow={onShow} onHide={onHide} panelClassName={panelClass} panelFooterTemplate={!noApply && panelFooterTemplate} className={"form-control shadow-none max-height-38" + `${error ? ' invalid' : ''}`} resetFilterOnHide={true} optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} value={innerValue} options={selectOptions} onChange={change} placeholder={`Select ${label}`} filter disabled={disabled} selectionLimit={maxSelected} onFilter={checkFilter} onBlur={blurred} />
    )
}

export default GroupedMultiSelect