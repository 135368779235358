class TokenService {
    storage = process.env.REACT_APP_ENVIRONMENT === 'Local' ? localStorage : sessionStorage;
    getLocalRefreshToken() {
        const userString = this.storage.getItem("user")
        if (userString) {
            const user = JSON.parse(userString);
            return user?.refreshToken;
        } return null
    }

    getLocalAccessToken() {
        const userString = this.storage.getItem("user")
        if (userString) {
            const user = JSON.parse(userString);
            return user?.accessToken;
        } return null
    }

    updateLocalAccessToken(token: any) {
        const userString = this.storage.getItem("user")
        if (userString) {
            const user = JSON.parse(userString);
            user.accessToken = token;
            this.storage.setItem("user", JSON.stringify(user));
        }
    }

    getUser() {
        const userString = this.storage.getItem("user")
        if (userString) {
            return JSON.parse(userString);
        }
        return null
    }

    setUser(user: any) {
        this.storage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        this.storage.removeItem("user");
    }

    getUserInfo() {
        const userString = this.storage.getItem("userInfo")
        if (userString) {
            return JSON.parse(userString);
        }
        return null
    }

    setUserInfo(user: any) {
        this.storage.setItem("userInfo", JSON.stringify(user));
    }

    getTableauToken() {
        const token = sessionStorage.getItem("tableauToken")
        if (token) {
            return JSON.parse(token);
        }
        return null
    }

    setTableauToken(token: string) {
        sessionStorage.setItem("tableauToken", JSON.stringify(token));
    }

    deleteTableauToken() {
        sessionStorage.removeItem('tableauToken')
    }

    setTokenInterval(id: any) {
        sessionStorage.setItem("tokenInterval", JSON.stringify(id));
    }

    getTokenInterval() {
        const token = sessionStorage.getItem("tokenInterval")
        if (token) {
            return JSON.parse(token);
        }
        return null
    }
    deleteTokenInterval() {
        sessionStorage.removeItem('tokenInterval')
    }

    setReadNotification(notificationId: any) {
        let notification = [...this.getReadNotification(), notificationId]
        localStorage.setItem("readNotifications", notification.join(','))
    }

    getReadNotification() {
        if (localStorage.getItem("readNotifications"))
            return localStorage.getItem("readNotifications")?.split(',') || []
        else
            return []
    }

    setNotificationClosed() {
        sessionStorage.setItem('notification_closed', 'closed');
    }

    getNotificationClosed() {
        return sessionStorage.getItem('notification_closed');
    }

    getUserRoles() {
        let r = localStorage.getItem("dca58335a7fbd0d43e4146885e53d736");
        if (r) {
            return r.split(',').map((i: string) => parseInt(i));
        } else {
            return []
        }
    }

    setUserRoles(roles: number[]) {
        localStorage.setItem("dca58335a7fbd0d43e4146885e53d736", roles.join(','));
    }

    getUserReportsList() {
        let r = localStorage.getItem("reportslist");
        if (r) {
            return JSON.parse(r);
        } else {
            return []
        }
    }

    setUserReportsList(reportList: any) {
        localStorage.setItem("reportslist", JSON.stringify(reportList));
    }
}

export default new TokenService();