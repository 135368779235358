import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class TableauTokenApi {

    deleteToken(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteTableauToken, {}, {}, requestAction, requestSuccessAction, requestErrorAction);
    }
    logViewHistory(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.logHistory, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
    logFilter(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.logFilter, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    downloadReport(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.downloadReport, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    seacuredownloadRequest(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.requestSecureExtract, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    getTokenStatus(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.logFilter, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}