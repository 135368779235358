import { error } from "console";
import actionType from "src/actions/SecureExtractActionType";
import { ISecureExtract } from "src/types/secureExtract";

const initialState: ISecureExtract = {
    approvedRequest: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    pendingRequest: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    approvedRequestStatus: {
        loading: false,
        success: false,
        error: false
    },
    rejectRequestStatus: {
        loading: false,
        success: false,
        error: false
    }
}


const secureExtractReducer = (state = initialState, action: any): ISecureExtract => {
    switch (action.type) {
        case actionType.GET_PENDING_SECURE_REQUEST: {
            return {
                ...state,
                pendingRequest: Object.assign({}, state.pendingRequest, { loading: true })
            }
        }
        case actionType.GET_PENDING_SECURE_REQUEST_SUCCESS: {
            return {
                ...state,
                pendingRequest: Object.assign({}, state.pendingRequest, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_PENDING_SECURE_REQUEST_ERROR: {
            return {
                ...state,
                pendingRequest: Object.assign({}, state.pendingRequest, { loading: false, error: true, success: false })

            }
        }

        case actionType.GET_APPROVED_SECURE_REQUEST: {
            return {
                ...state,
                approvedRequest: Object.assign({}, state.approvedRequest, { loading: true })
            }
        }
        case actionType.GET_APPROVED_SECURE_REQUEST_SUCCESS: {
            return {
                ...state,
                approvedRequest: Object.assign({}, state.approvedRequest, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_APPROVED_SECURE_REQUEST_ERROR: {
            return {
                ...state,
                approvedRequest: Object.assign({}, state.approvedRequest, { loading: false, error: true, success: false })

            }
        }
        case actionType.APPROVE_SECURE_REQUEST_REQUEST: {
            return {
                ...state,
                approvedRequestStatus: Object.assign({}, state.approvedRequestStatus, { loading: true, success: false, error: false })
            }
        }
        case actionType.APPROVE_SECURE_REQUEST_REQUEST_SUCCESS: {
            return {
                ...state,
                approvedRequestStatus: Object.assign({}, state.approvedRequestStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.APPROVE_SECURE_REQUEST_REQUEST_ERROR: {
            return {
                ...state,
                approvedRequestStatus: Object.assign({}, state.approvedRequestStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.REJECT_SECURE_REQUEST_REQUEST: {
            return {
                ...state,
                rejectRequestStatus: Object.assign({}, state.rejectRequestStatus, { loading: true, success: false, error: false })
            }
        }
        case actionType.REJECT_SECURE_REQUEST_REQUEST_SUCCESS: {
            return {
                ...state,
                rejectRequestStatus: Object.assign({}, state.rejectRequestStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.REJECT_SECURE_REQUEST_REQUEST_ERROR: {
            return {
                ...state,
                rejectRequestStatus: Object.assign({}, state.rejectRequestStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.CLEAR_SECURE_REQUEST_ACTION_STATUS: {
            return {
                ...state,
                approvedRequestStatus: { loading: false, success: false, error: false },
                rejectRequestStatus: { loading: false, success: false, error: false }
            }
        }
        default: return state
    }
}

export default secureExtractReducer