import { connect } from 'react-redux'
import Text from '../../shared/Fields/Text';
import { IStoreState } from '../../../types/StoreState';
import { updateFactInfo } from '../../../actions/ManageFactAction';
import { IFact, IFactError } from '../../../types/ManageFact'
import Boolean from '../../shared/Fields/Boolean';
interface IManageFactForm {
  updateFactInfo: (data: any) => void,
  formData: IFact,
  formError?: IFactError
}

const ManageFactForm = ({ updateFactInfo, formData, formError }: IManageFactForm) => {

  const onUpdate = (name: string, value: any) => {
    let newValue: any = {};
    newValue[name] = value
    updateFactInfo(newValue)
  }

  return (
    <form className='ManageFactForm l2-font-size'>
      <div className='row mb-3 '>
        <label className='col-3 '>Fact</label>
        <div className='col-9'>
          <Text maxLimit={200} value={formData.question} update={(name, value) => onUpdate(name, value)} label='fact' name='question' error={formError && formError.questionError} hint={'Use same key here either from value or from query.'}></Text>
          {formError && formError.questionError ? <small className='l1-font-size text-danger'>Please enter fact</small> : null}
        </div>
      </div>

      <div className='row mb-3 '>
        <label className='col-3 '>Is Dynamic</label>
        <div className='col-9'>
          <Boolean value={formData.isDynamic ? 'true' : ''} name='isDynamic' update={(name, value) => onUpdate(name, value)}  ></Boolean>
        </div>
      </div>

      {formData.isDynamic ? <div className='row mb-3 '>
        <label className='col-3 '>Query</label>
        <div className='col-9'>
          <Text value={formData.query} update={(name, value) => onUpdate(name, value)} label='query' name='query' error={formError && formError.queryError} hint={'For multiple query use (;) as seprator. Column name should be in lowercase.'}></Text>
          {formError && formError.queryError ? <small className='l1-font-size text-danger'>Please enter query for dynamic value</small> : null}
        </div>
      </div> : null}

      {!formData.isDynamic ? <div className='row mb-3 '>
        <label className='col-3 '>Value </label>
        <div className='col-9'>
          <Text value={formData.value} update={(name, value) => onUpdate(name, value)} label='value' name='value' hint={'Please add object in key value pair. {count = 100}.'}></Text>
        </div>
      </div> : null}



    </form>
  )

}

const mapStateToProps = (state: IStoreState) => ({
  formData: state.manageFact.currentFact
})


const mapDispatchToProps = (dispatch: any) => ({
  updateFactInfo: (data: any) => {
    dispatch(updateFactInfo(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageFactForm);