import actionType from "src/actions/SmartAlertNotificationActionTypes";
import { ISmartAlertsNotification } from "src/types/Smart/SmartAlertsNotification";

const initialState: ISmartAlertsNotification = {
    userAlertsSubscription: {
        loading: false,
        data: [],
        error: false,
        success: false
    },
    userSubscribeAlertAction: {
        loading: false,
        error: false,
        success: false
    },
    userUnsubscribeAlertAction: {
        loading: false,
        error: false,
        success: false
    },
    createNewAlert: {
        loading: false,
        error: false,
        success: false
    },
    editAlertSubscription: {
        loading: false,
        error: false,
        success: false
    },
    deleteAlertSubscription: {
        loading: false,
        error: false,
        success: false
    },
    dNumberList: {
        loading: false,
        data: [],
        error: false,
        success: false
    },
    alertOnDnumberList: {
        loading: false,
        data: [],
        error: false,
        success: false
    }
};


const smartAlertsNotificationsReducer = (state = initialState, action: any): ISmartAlertsNotification => {

    switch (action.type) {
        case actionType.GET_USER_ALERT_SUBSCRIPTION:
            return {
                ...state,
                userAlertsSubscription: Object.assign({}, state.userAlertsSubscription, { loading: true, success: false, error: false }),

                createNewAlert: {
                    loading: false,
                    error: false,
                    success: false
                },
                editAlertSubscription: {
                    loading: false,
                    error: false,
                    success: false
                },
                deleteAlertSubscription: {
                    loading: false,
                    error: false,
                    success: false
                },
                alertOnDnumberList: {
                    loading: false,
                    data: [],
                    error: false,
                    success: false
                },
                userSubscribeAlertAction: {
                    loading: false,
                    error: false,
                    success: false
                },
                userUnsubscribeAlertAction: {
                    loading: false,
                    error: false,
                    success: false
                }
            }

        case actionType.GET_USER_ALERT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userAlertsSubscription: {
                    loading: false,
                    data: action.payload, success: true, error: false
                }
            }
        case actionType.GET_USER_ALERT_SUBSCRIPTION_ERROR:
            return {
                ...state,
                userAlertsSubscription: {
                    loading: false,
                    data: [], success: false, error: true
                }
            }

        case actionType.UNSUBSCRIBE_ALERT:
            return {
                ...state,
                userUnsubscribeAlertAction: {
                    ...state.userUnsubscribeAlertAction,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.UNSUBSCRIBE_ALERT_SUCCESS:
            return {
                ...state,
                userUnsubscribeAlertAction: {
                    loading: false,
                    success: true,
                    error: false
                }
            };

        case actionType.UNSUBSCRIBE_ALERT_ERROR:
            return {
                ...state,
                userUnsubscribeAlertAction: {
                    loading: false,
                    success: false,
                    error: true
                }
            };

        case actionType.SUBSCRIBE_ALERT:
            return {
                ...state,
                userSubscribeAlertAction: {
                    ...state.userSubscribeAlertAction,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.SUBSCRIBE_ALERT_SUCCESS:
            return {
                ...state,
                userSubscribeAlertAction: {
                    loading: false,
                    success: true,
                    error: false
                }
            };

        case actionType.SUBSCRIBE_ALERT_ERROR:
            return {
                ...state,
                userSubscribeAlertAction: {
                    loading: false,
                    success: false,
                    error: true
                }
            };

        case actionType.CREATE_NEW_ALERT_SUBSCRIPTION:
            return {
                ...state,
                createNewAlert: {
                    ...state.createNewAlert,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.CREATE_NEW_ALERT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                createNewAlert: {
                    loading: false,
                    success: true,
                    error: false
                },
                userAlertsSubscription: {
                    loading: false,
                    data: state.userAlertsSubscription.data,
                    error: false,
                    success: false
                }
            };

        case actionType.CREATE_NEW_ALERT_SUBSCRIPTION_ERROR:
            return {
                ...state,
                createNewAlert: {
                    loading: false,
                    success: false,
                    error: true
                }
            };

        case actionType.EDIT_ALERT_SUBSCRIPTION:
            return {
                ...state,
                editAlertSubscription: {
                    ...state.editAlertSubscription,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.EDIT_ALERT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                editAlertSubscription: {
                    loading: false,
                    success: true,
                    error: false
                }
            };

        case actionType.EDIT_ALERT_SUBSCRIPTION_ERROR:
            return {
                ...state,
                editAlertSubscription: {
                    loading: false,
                    success: false,
                    error: true
                }
            };

        case actionType.DELETE_ALERT_SUBSCRIPTION:
            return {
                ...state,
                deleteAlertSubscription: {
                    ...state.deleteAlertSubscription,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.DELETE_ALERT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                deleteAlertSubscription: {
                    loading: false,
                    success: true,
                    error: false
                }
            };

        case actionType.DELETE_ALERT_SUBSCRIPTION_ERROR:
            return {
                ...state,
                deleteAlertSubscription: {
                    loading: false,
                    success: false,
                    error: true
                }
            };

        case actionType.GET_ALL_DNUMBER:
            return {
                ...state,
                dNumberList: {
                    ...state.dNumberList,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.GET_ALL_DNUMBER_SUCCESS:
            return {
                ...state,
                dNumberList: {
                    loading: false,
                    data: action.payload,
                    success: true,
                    error: false
                }
            };

        case actionType.GET_ALL_DNUMBER_ERROR:
            return {
                ...state,
                dNumberList: {
                    loading: false,
                    data: [],
                    success: false,
                    error: true
                }
            };

        case actionType.GET_ALERTS_ON_DNUMBER_LIST:
            return {
                ...state,
                alertOnDnumberList: {
                    ...state.alertOnDnumberList,
                    loading: true,
                    success: false,
                    error: false
                }
            };

        case actionType.GET_ALERTS_ON_DNUMBER_LIST_SUCCESS:
            return {
                ...state,
                alertOnDnumberList: {
                    loading: false,
                    data: action.payload,
                    success: true,
                    error: false
                }
            };

        case actionType.GET_ALERTS_ON_DNUMBER_LIST_ERROR:
            return {
                ...state,
                alertOnDnumberList: {
                    loading: false,
                    data: [],
                    success: false,
                    error: true
                }
            }

        default: {
            return state;
        }
    }
}

export default smartAlertsNotificationsReducer;