const actionType = {
    GET_PROJECT_LIST: 'GET_PROJECT_LIST',
    GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',
    GET_PROJECT_LIST_ERROR: 'GET_PROJECT_LIST_ERROR',
    ADD_PROJECT: 'ADD_PROJECT',
    ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
    ADD_PROJECT_ERROR: 'ADD_PROJECT_ERROR',
    EDIT_PROJECT: 'EDIT_PROJECT',
    EDIT_PROJECT_SUCCESS: 'EDIT_PROJECT_SUCCESS',
    EDIT_PROJECT_ERROR: 'EDIT_PROJECT_ERROR',
    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',
    SELECT_ADD_PROJECT: 'SELECT_ADD_PROJECT',
    SELECT_EDIT_PROJECT: 'SELECT_EDIT_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    CLEAR_PROJECT: 'CLEAR_MANAGE_PROJECT',
    GET_USER_PROJECT_LIST: 'GET_USER_PROJECT_LIST',
    GET_USER_PROJECT_LIST_SUCCESS: 'GET_USER_PROJECT_LIST_SUCCESS',
    GET_USER_PROJECT_LIST_ERROR: 'GET_USER_PROJECT_LIST_ERROR',
}

export default actionType