const actionType = {
    GET_USER_ALERT_SUBSCRIPTION: 'GET_USER_ALERT_SUBSCRIPTION',
    GET_USER_ALERT_SUBSCRIPTION_SUCCESS: 'GET_USER_ALERT_SUBSCRIPTION_SUCCESS',
    GET_USER_ALERT_SUBSCRIPTION_ERROR: 'GET_USER_ALERT_SUBSCRIPTION_ERROR',

    UNSUBSCRIBE_ALERT: 'UNSUBSCRIBE_ALERT',
    UNSUBSCRIBE_ALERT_SUCCESS: 'UNSUBSCRIBE_ALERT_SUCCESS',
    UNSUBSCRIBE_ALERT_ERROR: 'UNSUBSCRIBE_ALERT_ERROR',

    SUBSCRIBE_ALERT: 'SUBSCRIBE_ALERT',
    SUBSCRIBE_ALERT_SUCCESS: 'SUBSCRIBE_ALERT_SUCCESS',
    SUBSCRIBE_ALERT_ERROR: 'SUBSCRIBE_ALERT_ERROR',

    CREATE_NEW_ALERT_SUBSCRIPTION: 'CREATE_NEW_ALERT_SUBSCRIPTION',
    CREATE_NEW_ALERT_SUBSCRIPTION_SUCCESS: 'CREATE_NEW_ALERT_SUBSCRIPTION_SUCCESS',
    CREATE_NEW_ALERT_SUBSCRIPTION_ERROR: 'CREATE_NEW_ALERT_SUBSCRIPTION_ERROR',

    EDIT_ALERT_SUBSCRIPTION: 'EDIT_ALERT_SUBSCRIPTION',
    EDIT_ALERT_SUBSCRIPTION_SUCCESS: 'EDIT_ALERT_SUBSCRIPTION_SUCCESS',
    EDIT_ALERT_SUBSCRIPTION_ERROR: 'EDIT_ALERT_SUBSCRIPTION_ERROR',

    DELETE_ALERT_SUBSCRIPTION: 'DELETE_ALERT_SUBSCRIPTION',
    DELETE_ALERT_SUBSCRIPTION_SUCCESS: 'DELETE_ALERT_SUBSCRIPTION_SUCCESS',
    DELETE_ALERT_SUBSCRIPTION_ERROR: 'DELETE_ALERT_SUBSCRIPTION_ERROR',

    GET_ALL_DNUMBER: 'GET_ALL_DNUMBER',
    GET_ALL_DNUMBER_SUCCESS: 'GET_ALL_DNUMBER_SUCCESS',
    GET_ALL_DNUMBER_ERROR: 'GET_ALL_DNUMBER_ERROR',

    GET_ALERTS_ON_DNUMBER_LIST: 'GET_ALERTS_ON_DNUMBER_LIST',
    GET_ALERTS_ON_DNUMBER_LIST_SUCCESS: 'GET_ALERTS_ON_DNUMBER_LIST_SUCCESS',
    GET_ALERTS_ON_DNUMBER_LIST_ERROR: 'GET_ALERTS_ON_DNUMBER_LIST_ERROR',
}

export default actionType;