export const data = [
    {
        "tableName": "company_dim",
        description: 'A company configured by ---- team in SHL and is assigned to an Admin to further create project , products and schedule candidates as required.',
        "items": [
            {
                "name": "company_name",
                columnDescription: "What is the name of the company (also called as client)"
            },
            {
                "name": "company_description",
                columnDescription: "Additional details related to the client"
            },
            {
                "name": "created date",
                columnDescription: "Inception date of the company"
            },
            {
                "name": "updated date",
                columnDescription: "Date for last changes made in the company settings"
            },
            {
                "name": "language",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            },
            {
                "name": "ce_language_id",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            },
            {
                "name": "admin_language_id",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            },
            {
                "name": "is_active",
                columnDescription: "Whether the company is active today"
            },
            {
                "name": "disabled",
                columnDescription: "Whether the company is active today"
            },
            {
                "name": "ms_enabled",
                columnDescription: "No data present in this table "
            },
            {
                "name": "d_number",
                columnDescription: "For each of client there is unique account number associated to it coming from SFDC system ,and can be referred also in account_dim table "
            },
            {
                "name": "assessment_invite_template",
                columnDescription: "Which tempplate was used to send the assessment invite and reminder over email ,Template details can further be looked in vr_template_dim"
            },
            {
                "name": "reminder_email_template",
                columnDescription: "Which tempplate was used to send the assessment invite and reminder over email ,Template details can further be looked in vr_template_dim"
            },
            {
                "name": "two_factor_enabled"
            },
            {
                "name": "version",
                columnDescription: "What is the lastest version of the company created ( each update contributing to newer version )"
            },
            {
                "name": "legacy_company_code",
                columnDescription: "What is the code of the client in legacy system "
            },
            {
                "name": "legacy_system",
                columnDescription: "Is the legacy system active for the client"
            },
            {
                "name": "is_partner",
                columnDescription: "Is the client also a partner in the company"
            },
            {
                "name": "primany_country_id",
                columnDescription: "Which country does the company belongs to"
            },
            {
                "name": "billing_type",
                columnDescription: "No data present in this table "
            },
            {
                "name": "time_zone",
                columnDescription: "What is timezone for the client working in "
            },
            {
                "name": "brandname",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "logoname",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "logopath",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "primanycolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "secondarycolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "backgroundcolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "headercolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "menucolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "focuscolor",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "theme",
                columnDescription: "Company url setting details ,In these columns html objects codes are mentioned referecing to each configurations"
            },
            {
                "name": "brandname",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "brand_created_date",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "brand_updated_date",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "brand_is_active",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "brand_version",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "admin_brading",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "ce_branding",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "player_branding",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "vr_branding",
                columnDescription: "Branding details for the client "
            },
            {
                "name": "company_type_id",
                columnDescription: "If the company is Internal , External or Integrated"
            },
            {
                "name": "company_type_description",
                columnDescription: "If the company is Internal , External or Integrated"
            },
            {
                "name": "company_type_code",
                columnDescription: "If the company is Internal , External or Integrated"
            },
            {
                "name": "player_id",
                columnDescription: "Which wrapper is used for the company ,Wrapper of an assessments , for different assessment we have different player. But now only one player is associated to all. Older ones from Soda S2P might still have previous version"
            },
            {
                "name": "player_created_date",
                columnDescription: "Which wrapper is used for the company ,Wrapper of an assessments , for different assessment we have different player. But now only one player is associated to all. Older ones from Soda S2P might still have previous version"
            },
            {
                "name": "player_updated_date",
                columnDescription: "Which wrapper is used for the company ,Wrapper of an assessments , for different assessment we have different player. But now only one player is associated to all. Older ones from Soda S2P might still have previous version"
            },
            {
                "name": "created_by",
                columnDescription: "Admin who has created and updated the details for company , brand "
            },
            {
                "name": "updated_by",
                columnDescription: "Admin who has created and updated the details for company , brand "
            },
            {
                "name": "google",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "facebook",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "linkedin",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "googleactive",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "facebookactive",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "linkedinactive",
                columnDescription: "Social media page details for google , facebook and linkedin. ,Whether they were created and is active today."
            },
            {
                "name": "googlecreated",
                columnDescription: "Social media page created date "
            },
            {
                "name": "facebookcreated",
                columnDescription: "Social media page created date "
            },
            {
                "name": "linkedincreated",
                columnDescription: "Social media page created date "
            },
            {
                "name": "googleupdated",
                columnDescription: "Social media updated date "
            },
            {
                "name": "facebookupdated",
                columnDescription: "Social media updated date "
            },
            {
                "name": "linkedinupdated",
                columnDescription: "Social media updated date "
            },
            {
                "name": "googlecreatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "facebookcreatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "linkedincreatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "googleupdatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "facebookupdatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "linkedinupdatedby",
                columnDescription: "Social media page created and updated by admin detail"
            },
            {
                "name": "is_new_mq",
                columnDescription: "which version product the company has opted for among these "
            },
            {
                "name": "is_new_apta",
                columnDescription: "which version product the company has opted for among these "
            },
            {
                "name": "is_new_mcq",
                columnDescription: "which version product the company has opted for among these "
            }
        ]
    },
    {
        "tableName": "language_dim",
        "items": [
            {
                "name": "language",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            },
            {
                "name": "ce_language_id",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            },
            {
                "name": "admin_language_id",
                columnDescription: "What is the preferred language spoken by the client and the admin of the company"
            }
        ]
    },
    {
        "tableName": "user_admin_dim",
        description: 'For each of the client/company created an Admin is assigned who is responsible for creating project , products and schedulling candidates as required.Each of the candidates are mapped to single/multiple role with respect to the company.',
        "items": [
            {
                "name": "firstname",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "lastname",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "title",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "location",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "gender",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "emailid",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "country_code",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "mobile_number",
                columnDescription: "PII details Admin who manages a client and schedules candidates assessments and manages it "
            },
            {
                "name": "is_active",
                columnDescription: "Whether the account of the admin is active or locked and reasoning for incase of inactive admin profiles"
            },
            {
                "name": "inactive_reason",
                columnDescription: "Whether the account of the admin is active or locked and reasoning for incase of inactive admin profiles"
            },
            {
                "name": "account_locked",
                columnDescription: "Whether the account of the admin is active or locked and reasoning for incase of inactive admin profiles"
            },
            {
                "name": "notification_sent",
                columnDescription: "If the notification has been sent to the user upon creation"
            },
            {
                "name": "candidate",
                columnDescription: "If the admin is also a candidate "
            },
            {
                "name": "created_date",
                columnDescription: "When was the admin created and last updated in the system "
            },
            {
                "name": "updated_date",
                columnDescription: "When was the admin created and last updated in the system "
            },
            {
                "name": "country_id",
                columnDescription: "Which country does the admin belongs to"
            },
            {
                "name": "role_name",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "role_description",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "role_precedence",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "roleversion",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "roleisinternal",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "roleisactive",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "rolecompanyid",
                columnDescription: "What is the role/designation of each of the admin and its details such as if the role still active , description of the role , role version , role company belongs to which is same as to which admin belongs to. ,Ex - Interviewer , HR Advisor , Recuiter etc"
            },
            {
                "name": "group_name",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "group_description",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "usergroupversion",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "usergroupcompany",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "usergroupactive",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "createdby",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "updatedby",
                columnDescription: "Each of the users are associated to a usergroup under a particular user"
            },
            {
                "name": "owner",
                columnDescription: "Who is the admin to have created this project"
            },
            {
                "name": "updated_by",
                columnDescription: "Who is the admin to have updated the project last "
            }
        ]
    },
    {
        "tableName": "project_application_dim",
        description: 'Whenever a client want to assess some candidates against any job requirement , they create a project.',
        "items": [
            {
                "name": "form_name",
                columnDescription: "Which form was submited by admin to create a project within a company/client. ,A project form template has been created which is used for creating/submitting projects."
            },
            {
                "name": "company_id",
                columnDescription: "Which form was submited by admin to create a project within a company/client. ,A project form template has been created which is used for creating/submitting projects."
            },
            {
                "name": "form_description",
                columnDescription: "Which form was submited by admin to create a project within a company/client. ,A project form template has been created which is used for creating/submitting projects."
            },
            {
                "name": "form_created_by_id",
                columnDescription: "Which form was submited by admin to create a project within a company/client. ,A project form template has been created which is used for creating/submitting projects."
            },
            {
                "name": "form_updated_by_id",
                columnDescription: "Which form was submited by admin to create a project within a company/client. ,A project form template has been created which is used for creating/submitting projects."
            },
            {
                "name": "form_created_date",
                columnDescription: "When was the form created and updated"
            },
            {
                "name": "form_updated_date",
                columnDescription: "When was the form created and updated"
            },
            {
                "name": "form_status",
                columnDescription: "what is the current status of the form. If the form has been published/submitted by Admin"
            },
            {
                "name": "form_type_id",
                columnDescription: "Each of the form has been given a unique type code."
            },
            {
                "name": "form_type_description",
                columnDescription: "Each of the form has been given a unique type code."
            },
            {
                "name": "project_id",
                columnDescription: "which project has been created with each from "
            }
        ]
    },
    {
        "tableName": "form_link_dim",
        "items": [
            {
                "name": "link_url",
                columnDescription: "What is the link of the form submitted "
            },
            {
                "name": "language_code",
                columnDescription: "What is language of the form "
            },
            {
                "name": "is_secure",
                columnDescription: "If the form page is secure"
            },
            {
                "name": "status",
                columnDescription: "What is the status of the form "
            },
            {
                "name": "is_active",
                columnDescription: "Is the form is still active"
            }
        ]
    },
    {
        "tableName": "project_dim",
        description: 'Whenever a client want to assess some candidates against any job requirement , they create a project.',
        "items": [
            {
                "name": "name",
                columnDescription: "What is the name and description of the project"
            },
            {
                "name": "description",
                columnDescription: "Additional details pertaining to the project"
            },
            {
                "name": "created_date",
                columnDescription: "When was the project created"
            },
            {
                "name": "deadline_date",
                columnDescription: "By when the project can be appeared by the candidates. It can either get expired on a date or number of days after a date."
            },
            {
                "name": "deadline_type",
                columnDescription: "By when the project can be appeared by the candidates. It can either get expired on a date or number of days after a date."
            },
            {
                "name": "updated_date",
                columnDescription: "When was the project last updated"
            },
            {
                "name": "is_active",
                columnDescription: "Flag for active , and reasoning incase of inactive"
            },
            {
                "name": "inactive_reason",
                columnDescription: "Flag for active , and reasoning incase of inactive"
            },
            {
                "name": "assessment_invite_template",
                columnDescription: "Which template has been used from vr_template_dim table for creating the project"
            },
            {
                "name": "assessment_profileadded_pre_publish",
                columnDescription: "Which template has been used from vr_template_dim table for creating the project"
            },
            {
                "name": "location",
                columnDescription: "In some cases project created specific for a location "
            },
            {
                "name": "locked",
                columnDescription: "Is the project locked for further use in any company"
            },
            {
                "name": "draft",
                columnDescription: "Whether the project draft is present"
            },
            {
                "name": "email_template_id",
                columnDescription: "Which template is chosen for email remiders to be send to the candidates"
            },
            {
                "name": "first_reminder",
                columnDescription: "Which template is chosen for email remiders to be send to the candidates"
            },
            {
                "name": "first_reminder_sent",
                columnDescription: "Which template is chosen for email remiders to be send to the candidates"
            },
            {
                "name": "notify_all_candidate_completion",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "notify_all_candidate_completion_send_to_all",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "notify_candidate_result_received",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "notify_candidate_result_received_send_to_all",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "notify_report_available",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "notify_report_available_send_to_all",
                columnDescription: "If the candidate status,result,report has to be sent over the email"
            },
            {
                "name": "from_integration_api",
                columnDescription: "Is the project an intergrated or non integrated one ,We are following two streams of handelling a project. ,In case of integrated client has their own ATS (Application tracking system) and one candiate is mapped to only one project. For clients who manage the project by ATS and dont want to share the candidate emails they follow the integrated stream. Project name for integrated starts with TMS_<> ,Whereas in case non integrated project there can be multiple candidates appearing for it and it is managed through TC application only."
            },
            {
                "name": "integration_project_email_notification",
                columnDescription: "Email configuration if the email has to be sent to creater of the project , users or any specific email id"
            },
            {
                "name": "bcc_email_to_project_creater",
                columnDescription: "Email configuration if the email has to be sent to creater of the project , users or any specific email id"
            },
            {
                "name": "bcc_email_to_project_user",
                columnDescription: "Email configuration if the email has to be sent to creater of the project , users or any specific email id"
            },
            {
                "name": "bcc_email_to_email",
                columnDescription: "Email configuration if the email has to be sent to creater of the project , users or any specific email id"
            },
            {
                "name": "specified_bcc",
                columnDescription: "Email configuration if the email has to be sent to creater of the project , users or any specific email id"
            },
            {
                "name": "reminder_email_template",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "reminder_flag",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "second_reminder",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "second_reminder_sent",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "third_reminder",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "third_reminder_sent",
                columnDescription: "Option is present to turn on the email first , sencond and third reminder set for candidate ,Whether the option is turned on or not "
            },
            {
                "name": "owner",
                columnDescription: "Who is the admin to have created this project"
            },
            {
                "name": "updated_by",
                columnDescription: "Who is the admin to have updated the project last "
            },
            {
                "name": "application_name",
                columnDescription: "What is the application name for integrated clients "
            },
            {
                "name": "status",
                columnDescription: "Depending upon the deadline date and deadline type provided , what is the current status of the project"
            },
            {
                "name": "has_multiple_stages",
                columnDescription: "Stages are defined on a project which maps different section of a project with each other. ,Stages are used to identify candidates meeting minimun qualification criteria for selection ,Hurdles are present within or externally of a stage to evaluate a candidates performace and taken into consideration for selection. ,Hurdle are present in a project which a candidate has to complete in order to more forward or proceed further in the "
            },
            {
                "name": "stage_enabled",
                columnDescription: "Stages are defined on a project which maps different section of a project with each other. ,Stages are used to identify candidates meeting minimun qualification criteria for selection ,Hurdles are present within or externally of a stage to evaluate a candidates performace and taken into consideration for selection. ,Hurdle are present in a project which a candidate has to complete in order to more forward or proceed further in the "
            },
            {
                "name": "hurdle_enabled",
                columnDescription: "Stages are defined on a project which maps different section of a project with each other. ,Stages are used to identify candidates meeting minimun qualification criteria for selection ,Hurdles are present within or externally of a stage to evaluate a candidates performace and taken into consideration for selection. ,Hurdle are present in a project which a candidate has to complete in order to more forward or proceed further in the "
            },
            {
                "name": "dpn_type",
                columnDescription: "What is the Data protection Notice configured for the project . , Acknowledgment from candidate of how we are storing the data"
            },
            {
                "name": "adm"
            },
            {
                "name": "va_id"
            },
            {
                "name": "enabled_new_datastore"
            },
            {
                "name": "-- ? ajay kumar"
            },
            {
                "name": "adapter_id",
                columnDescription: "Talio , Kanexa . For Integrated Projects "
            },
            {
                "name": "adapter_name",
                columnDescription: "Talio , Kanexa . For Integrated Projects "
            },
            {
                "name": "job_profile_id",
                columnDescription: "What is the job , role and responsibily against which the project is created "
            },
            {
                "name": "is_gdpr"
            },
            {
                "name": "closed_date"
            },
            {
                "name": "data_deletion_date"
            },
            {
                "name": "vr_candidate_friendly_job_name",
                columnDescription: "Friendly name for template being used for creating the project"
            },
            {
                "name": "vr_job_location",
                columnDescription: "Location for which the template was created "
            },
            {
                "name": "vr_template_id",
                columnDescription: "Each of the project created has a template associated to tells us about the layout of how the candidate will travel through the hierarchy"
            },
            {
                "name": "vr_send_weekly_results",
                columnDescription: "Is the template enabled for sending weekly status "
            },
            {
                "name": "vr_enabled_flag",
                columnDescription: "Is the template enabled to be used"
            },
            {
                "name": "vr_project_type",
                columnDescription: "What is the project type for the template it was created for"
            }
        ]
    },
    {
        "tableName": "job_profile_dim",
        "items": [
            {
                "name": "job_profile_id",
                columnDescription: "What is the job , role and responsibily against which the project is created "
            }
        ]
    },
    {
        "tableName": "project_tag_dim",
        "items": [
            {
                "name": "name",
                columnDescription: "Tags refers to any additional information for which a project has been created ,What is tag that has been associated with the project. ,Each project is allowed to be tagged against multiple tags upon creation for better accessbility ,Project tag helps us to track the integrated projects and their KPIs "
            },
            {
                "name": "description",
                columnDescription: "What is description mentioned for the tag by admin while creating the tag comprising of the additional details refering to the same."
            },
            {
                "name": "active",
                columnDescription: "Is the tag active at current date"
            },
            {
                "name": "allow_multiselect",
                columnDescription: "Is the tag allowed to be selected together with other tags "
            },
            {
                "name": "mandatory",
                columnDescription: "Is the mandatory to be selected for a particulat project and company"
            },
            {
                "name": "version",
                columnDescription: "What is the current version of the tag"
            },
            {
                "name": "company",
                columnDescription: "Which company for which was tag has been created"
            },
            {
                "name": "is_integrated",
                columnDescription: "Is the project_tag created is for itegrated or not integrated - flag"
            },
            {
                "name": "created_date",
                columnDescription: "When was the tag created and last updated "
            },
            {
                "name": "updated_date",
                columnDescription: "When was the tag created and last updated "
            },
            {
                "name": "created_by",
                columnDescription: "Who is the admin to have configured the tag and the admin to have last updated the tag details "
            },
            {
                "name": "updated_by",
                columnDescription: "Who is the admin to have configured the tag and the admin to have last updated the tag details "
            },
            {
                "name": "value",
                columnDescription: "Each tag created has "
            },
            {
                "name": "tag_value_created_date",
                columnDescription: "When was the tag value created and last updated "
            },
            {
                "name": "tag_value_updated_date",
                columnDescription: "When was the tag value created and last updated "
            },
            {
                "name": "tag_value_created_by",
                columnDescription: "Who had created and updated the tag value"
            },
            {
                "name": "tag_value_updated_by",
                columnDescription: "Who had created and updated the tag value"
            },
            {
                "name": "tag_value_active",
                columnDescription: "Flag to identify if the tag value is active "
            },
            {
                "name": "enabled",
                columnDescription: "What is the version of the current and historical tag values created"
            },
            {
                "name": "tag_value_version",
                columnDescription: "What is the current version of tag value "
            }
        ]
    },
    {
        "tableName": "vr_template_dim",
        "items": [
            {
                "name": "vr_template_id",
                columnDescription: "Each of the project created has a template associated to tells us about the layout of how the candidate will travel through the hierarchy"
            },
            {
                "name": "name",
                columnDescription: "What is the name of template"
            },
            {
                "name": "version",
                columnDescription: "What is the version of the template"
            },
            {
                "name": "vr_job_role_name",
                columnDescription: "what is the job role for which the template has been created"
            },
            {
                "name": "vr_job_role_description",
                columnDescription: "Additional description pertaining to the job role"
            },
            {
                "name": "created_by_id",
                columnDescription: "who has created the template"
            },
            {
                "name": "created_date",
                columnDescription: "When was the template created and updated "
            },
            {
                "name": "updated_date",
                columnDescription: "When was the template created and updated "
            },
            {
                "name": "status",
                columnDescription: "What is the status of the template created - Draft , published , modified or archived"
            },
            {
                "name": "temp_json",
                columnDescription: "Published Json - ,Layout of the task item details majorly for SHL Experience Projects ,Draft Json - ,Temporary Json which are not published"
            },
            {
                "name": "published_json",
                columnDescription: "Published Json - ,Layout of the task item details majorly for SHL Experience Projects ,Draft Json - ,Temporary Json which are not published"
            },
            {
                "name": "is_active",
                columnDescription: "If the template created is active currently or not"
            },
            {
                "name": "unique_code",
                columnDescription: "DB unique identifier"
            },
            {
                "name": "localization_allowed",
                columnDescription: "Can the template be used in other job profiles"
            },
            {
                "name": "demo_template",
                columnDescription: "Is the template created is a demo one"
            },
            {
                "name": "include_survey_opt_out",
                columnDescription: "Is the op out option has been provided in the template"
            },
            {
                "name": "vr_template_purpose",
                columnDescription: "What is the purpose of the template created"
            },
            {
                "name": "vr_template_description",
                columnDescription: "Additional details for the template"
            },
            {
                "name": "vr_template_type",
                columnDescription: "experience_lite - SHL Experiences ,precisefit - Non SHL expereiences  ,?? ,??"
            }
        ]
    },
    {
        "tableName": "content_package_battery_dim",
        "items": [
            {
                "name": "name",
                columnDescription: "What is the name of product"
            },
            {
                "name": "description",
                columnDescription: "Additional details against the product specifying the capability it is assessing "
            },
            {
                "name": "units",
                columnDescription: "How much does each product idealy charges to"
            },
            {
                "name": "package_type",
                columnDescription: "What is the package type. We have three types of packages - Assessment , Composite and Report. ,Package type is assessment is for an assessment , report is for report page , whereas composite is for the package which consits of both."
            },
            {
                "name": "content_package_version",
                columnDescription: "What is the version of the product created "
            },
            {
                "name": "display_name",
                columnDescription: "What is the name of the product for reporting purpose"
            },
            {
                "name": "active",
                columnDescription: "Is the product active currently"
            },
            {
                "name": "display_collapsed"
            },
            {
                "name": "display_to_user"
            },
            {
                "name": "friendly_name",
                columnDescription: "Maybe - that is visible on the platform website on the front end "
            },
            {
                "name": "sort_weight"
            },
            {
                "name": "language_count",
                columnDescription: "In how many languages the product has been configured"
            },
            {
                "name": "level_count"
            },
            {
                "name": "has_dependencies",
                columnDescription: "If the content package has dependency on any other existing product"
            },
            {
                "name": "is_solution",
                columnDescription: "Is the product a sitting based/solution or standalone assessment. ,Please refer row 122 for more details"
            },
            {
                "name": "non_expandable"
            },
            {
                "name": "is_global",
                columnDescription: "Flag created if the product created for global utilisation"
            },
            {
                "name": "is_current",
                columnDescription: "Flag created if the product is of the most latest version"
            },
            {
                "name": "content_package_tag",
                columnDescription: "Unique tag of the product for representation"
            },
            {
                "name": "rr_eligibal_days",
                columnDescription: "What is the reponse reuse eligibility of the product upon creation. ,Response reuse is to identify the candidate scoring against a client that can be resused againt any other client for n number of days "
            },
            {
                "name": "rr_eligible",
                columnDescription: "What is the reponse reuse eligibility of the product upon creation. ,Response reuse is to identify the candidate scoring against a client that can be resused againt any other client for n number of days "
            },
            {
                "name": "change_for_rr",
                columnDescription: "What is the reponse reuse eligibility of the product upon creation. ,Response reuse is to identify the candidate scoring against a client that can be resused againt any other client for n number of days "
            },
            {
                "name": "player_flag"
            },
            {
                "name": "package_classification_id",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "classification_active",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "classification_code",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "classification_description",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "classification_name",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "package_battery_id"
            },
            {
                "name": "package_battery_deleted"
            },
            {
                "name": "package_battery_description"
            },
            {
                "name": "package_battery_display_name"
            },
            {
                "name": "package_battery_name"
            },
            {
                "name": "battery_version"
            },
            {
                "name": "mcp_identifier",
                columnDescription: "Is the content package a sitting based or non sitting based "
            },
            {
                "name": "component_id",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "componenet_type",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "component_display_name",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "componenet_multi_lingual",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "componenet_name",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "compoenet_report_type",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "component_unique_tag",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "componenet_default_lang",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            },
            {
                "name": "componenet_friendly_name",
                columnDescription: "As each content package can have multiple or single component associated with it as confugured by the client , the mapping of the two can be found in these columns"
            }
        ]
    },
    {
        "tableName": "candidate_content_package_fact",
        "items": [
            {
                "name": "start_date",
                columnDescription: "When was the assessment started for the product , and when was the last assessment completed within the product"
            },
            {
                "name": "end_date",
                columnDescription: "When was the assessment started for the product , and when was the last assessment completed within the product"
            },
            {
                "name": "status",
                columnDescription: "What is the current status/progress of the candidate against a particular company , project and product"
            }
        ]
    },
    {
        "tableName": "candidate_dim",
        description: 'Once a product is created , candidates are scheduled to give assessments',
        "items": [
            {
                "name": "firstname",
                columnDescription: "What is the Candidate PII details "
            },
            {
                "name": "lastname",
                columnDescription: "What is the Candidate PII details "
            },
            {
                "name": "email",
                columnDescription: "What is the Candidate PII details "
            },
            {
                "name": "gender",
                columnDescription: "What is the Candidate PII details "
            },
            {
                "name": "password",
                columnDescription: "Encrypted password for the candidate login"
            },
            {
                "name": "candidate_created_date",
                columnDescription: "When was the candidate created and updated "
            },
            {
                "name": "candidate_updated_date",
                columnDescription: "When was the candidate created and updated "
            },
            {
                "name": "candidate version",
                columnDescription: "What is the latest version of the candidate "
            },
            {
                "name": "is_active",
                columnDescription: "Is the candidate active today , incase not what is the reason for the same. ,Is the candidate able to login in the system"
            },
            {
                "name": "inactive_reason",
                columnDescription: "Is the candidate active today , incase not what is the reason for the same. ,Is the candidate able to login in the system"
            },
            {
                "name": "account_locked",
                columnDescription: "Is the candidate active today , incase not what is the reason for the same. ,Is the candidate able to login in the system"
            },
            {
                "name": "salt"
            },
            {
                "name": "company_id",
                columnDescription: "Which client is the candidate assessed for "
            },
            {
                "name": "country_id",
                columnDescription: "What is the country of the candidate "
            },
            {
                "name": "user_profile_id",
                columnDescription: "As these are derived from user_profile table in TC , it is consisting of candidate/user created and updated date"
            },
            {
                "name": "user_created_date",
                columnDescription: "As these are derived from user_profile table in TC , it is consisting of candidate/user created and updated date"
            },
            {
                "name": "user_updated_date",
                columnDescription: "As these are derived from user_profile table in TC , it is consisting of candidate/user created and updated date"
            },
            {
                "name": "user_version",
                columnDescription: "As these are derived from user_profile table in TC , it is consisting of candidate/user created and updated date"
            },
            {
                "name": "language_id",
                columnDescription: "What is the language spoken by the candidate "
            }
        ]
    },
    {
        "tableName": "candidate_registration_audits_dim",
        description: 'Once a candidate is registered , its registration details are captured and stored.',
        "items": [
            {
                "name": "candidate_request_audit_date_time",
                columnDescription: "When was the registration for candidate requested "
            },
            {
                "name": "candidate_username",
                columnDescription: "Which email has the user used for registration"
            },
            {
                "name": "candidate_url",
                columnDescription: "Url of the page on which the candidate username is provided to appear for the test"
            },
            {
                "name": "scores_reused",
                columnDescription: "Whether the scores for the candidate has been used for any other assessments"
            }
        ]
    },
    {
        "tableName": "candidate_count_dim",
        "items": [
            {
                "name": "contract_number",
                columnDescription: "Contact number of the candidate"
            },
            {
                "name": "contract_start_date",
                columnDescription: "Contract start date of the candidate"
            },
            {
                "name": "contract_end_date",
                columnDescription: "Contract end date of the candidate"
            },
            {
                "name": "candidate_utilization_date",
                columnDescription: "Units utilization of the candidate "
            },
            {
                "name": "candidate_first_occurance_date",
                columnDescription: "When was the first occurance of the candidate for assessment"
            },
            {
                "name": "candidate_validity_window_date",
                columnDescription: "What is the validity of the candidate to appear for the test"
            },
            {
                "name": "unit_usage",
                columnDescription: "What is the total amount of unit used by the candidate"
            }
        ]
    },
    {
        "tableName": "candidate_eeo_response_fact",
        description: 'To assess whether the candidates are being provided equal oportunity regardless of their Age , Gender and Race certain set of questions are asked to candidates',
        "items": [
            {
                "name": "eeo_question_id",
                columnDescription: "What is the question responded by candidate for Equal Employment Oportunity"
            },
            {
                "name": "question_text",
                columnDescription: "What is the actual question "
            },
            {
                "name": "eeo_category",
                columnDescription: "EEO Category lies between - Race , Age and Gender"
            },
            {
                "name": "question_response_id",
                columnDescription: "What was the answer submitted by candidate from multiple option provided"
            },
            {
                "name": "question_respponse_description",
                columnDescription: "What is meaning of multiple race options provided to candidates "
            },
            {
                "name": "responded_on",
                columnDescription: "When has the candidate responded to the question"
            }
        ]
    },
    {
        "tableName": "assessments_fact",
        description: 'Assessment_component_dim - contists of mapping assessment to component and content package',
        "items": [
            {
                "name": "hiring_team_id",
                columnDescription: "Unique identifying repsenting the team for whom the hiring is taking place"
            },
            {
                "name": "app_form"
            },
            {
                "name": "status",
                columnDescription: "What is the status of the assessment currently "
            },
            {
                "name": "assessment_startedon",
                columnDescription: "When was the assessment started and completed by the candidate"
            },
            {
                "name": "assessment_completedon",
                columnDescription: "When was the assessment started and completed by the candidate"
            },
            {
                "name": "package_type",
                columnDescription: "What is the package type of the assessment - Assessment , Composite or Report"
            },
            {
                "name": "package_classification_id",
                columnDescription: "Unique identifier for package classification id from assessment_component_dim"
            },
            {
                "name": "units",
                columnDescription: "How many units does the assessment consitutes of "
            },
            {
                "name": "expiration_days",
                columnDescription: "What is expiration days set , after which the assessment wont be open to appear to candiate "
            },
            {
                "name": "test_id",
                columnDescription: "Unique identifier for test and session for DB"
            },
            {
                "name": "test_session_id",
                columnDescription: "Unique identifier for test and session for DB"
            },
            {
                "name": "solution_id",
                columnDescription: "Unique identifier for solution"
            },
            {
                "name": "sitting",
                columnDescription: "Is the assessment is a sitting based"
            },
            {
                "name": "sitting_id - true/false",
                columnDescription: "Sitting id - unique identifier for DB"
            },
            {
                "name": "is_s2p",
                columnDescription: "To identify if the assessment is flowing from S2P system "
            },
            {
                "name": "s2p_testsession_id",
                columnDescription: "To identify if the assessment is flowing from S2P system "
            },
            {
                "name": "lastaccessedon",
                columnDescription: "When was the assessment last accessed on "
            },
            {
                "name": "s2p_assessment_level",
                columnDescription: "What is level of assessment on S2P system"
            },
            {
                "name": "percentile"
            },
            {
                "name": "sequence",
                columnDescription: "What is the sequence of the assessment in the product"
            },
            {
                "name": "passscore",
                columnDescription: "What is the set passing score "
            },
            {
                "name": "duration",
                columnDescription: "Duration for the assessment was accessed by the candidate"
            },
            {
                "name": "time_taken",
                columnDescription: "Time taken by the candidate in a assessment "
            },
            {
                "name": "num_correct",
                columnDescription: "Number of question correctly answered by candidate"
            },
            {
                "name": "num_incorrect",
                columnDescription: "number of questions skipped by the candidate"
            },
            {
                "name": "num_skipped",
                columnDescription: "Total number of questions in the assessment"
            },
            {
                "name": "total_number_of_questions",
                columnDescription: "How many questions are present in the assessment"
            },
            {
                "name": "overall",
                columnDescription: "Level of the particular assessment in the whole component"
            },
            {
                "name": "assessment_level_id",
                columnDescription: "We have 1-8 unique level ids present ,For composite package type as there are multipe assessments , we have different levels in it to identify each caterogy of the test"
            },
            {
                "name": "name",
                columnDescription: "Name of the assessment level in assessment component. Each of assessment are categorised among below job category - ,Graduate/University ,Junior Managerial ,Managerial & Professional ,Operational ,Semi-Skilled Technical ,Skilled Technical ,Skilled Technology ,Supervisory"
            },
            {
                "name": "description",
                columnDescription: "More details around assessment level - Same as assessment level name"
            },
            {
                "name": "code",
                columnDescription: "Same as assessment level name "
            },
            {
                "name": "test_restart_count",
                columnDescription: "If the assessemtn was restarted again by the candidate and its count"
            },
            {
                "name": "hurdle_status",
                columnDescription: "What is the hurdle status - ,Hurdle signifies like a stage which a candidate has to complete before moving ahead to the next one. ,Identifier for client to assess minimum selection critera "
            }
        ]
    },
    {
        "tableName": "assessment_profile_dim",
        "items": [
            {
                "name": "assessment_profile_id",
                columnDescription: "Unique identifier for storing it in DB"
            },
            {
                "name": "created_date",
                columnDescription: "When was the assessment profile created"
            },
            {
                "name": "name"
            },
            {
                "name": "updated_date",
                columnDescription: "When was the profile last updated"
            },
            {
                "name": "version",
                columnDescription: "What is latest version of the profile"
            },
            {
                "name": "company_id",
                columnDescription: "For which company the assessment profile has been created "
            },
            {
                "name": "created_by_user_id",
                columnDescription: "Which user created the assessment profile from user_admin_dim"
            },
            {
                "name": "updated_by_user_id",
                columnDescription: "Which user updated the assessment profile from user_admin_dim"
            },
            {
                "name": "description",
                columnDescription: "Additional details pertaining to the assessment profile"
            },
            {
                "name": "level",
                columnDescription: "For which experience level the assessment profile has been created"
            },
            {
                "name": "system_generated",
                columnDescription: "If the assessment profile has been genereted from system "
            },
            {
                "name": "is_active",
                columnDescription: "Is the assessment profile stil active "
            },
            {
                "name": "family"
            },
            {
                "name": "parent_ap_id",
                columnDescription: "Which profile is the parent profile to the existing one"
            }
        ]
    },
    {
        "tableName": "assessment_component_dim",
        description: 'Mapping of content package to assessment componenet to assessment is present in this table',
        "items": [
            {
                "name": "content_package_id",
                columnDescription: "All the detail for individual grain is present in the respective tables"
            },
            {
                "name": "content_package_name",
                columnDescription: "What is the name of the product"
            },
            {
                "name": "content_package_type",
                columnDescription: "What is the package type. We have three types of packages - Assessment , Composite and Report. ,Package type is assessment is for an assessment , report is for report page , whereas composite is for the package which consits of both."
            },
            {
                "name": "content_package_display_name",
                columnDescription: "What is the name of the product for reporting purpose"
            },
            {
                "name": "content_package_is_active",
                columnDescription: "Is the product active currently"
            },
            {
                "name": "content_package_display_to_user",
                columnDescription: "If the content package is enabled to user"
            },
            {
                "name": "content_package_classification_id",
                columnDescription: "What is the classification id of the product "
            },
            {
                "name": "content_package_classification_name",
                columnDescription: "Each of the product classified among -  Ability , Personality , Skills , Solutions , Survey , Custom ,to identify the test content of each of the product "
            },
            {
                "name": "content_package_classification_code",
                columnDescription: "Same as content_package_classification_name"
            },
            {
                "name": "content_package_friendly_name",
                columnDescription: "Maybe - that is visible on the platform website on the front end "
            },
            {
                "name": "content_package_language_count",
                columnDescription: "In how many languages the product has been configured"
            },
            {
                "name": "content_package_level_count"
            },
            {
                "name": "content_package_is_solution",
                columnDescription: "Is the product a sitting based/solution or standalone assessment. ,Please refer row 122 for more details"
            },
            {
                "name": "solution_id",
                columnDescription: "Incase the product is a solution/sitting based product what is its unique representation code in DB"
            },
            {
                "name": "solution_code",
                columnDescription: "What is unique representation corressponding to the solution"
            },
            {
                "name": "solution_label",
                columnDescription: "What is the label of the solution to be used for reporting purpose"
            },
            {
                "name": "sitting_id",
                columnDescription: "Sitting and solution flag are used to identify a JFA ,Sitting id is a unique number for storing it in DB"
            },
            {
                "name": "sitting_code",
                columnDescription: "What is the unique sitting code"
            },
            {
                "name": "sitting_label",
                columnDescription: "What is the label for reporting purpose"
            },
            {
                "name": "content_package_is_current",
                columnDescription: "Flag to represent the product is the latest version"
            },
            {
                "name": "content_package_tag",
                columnDescription: "Unique tag associated with each of the product"
            },
            {
                "name": "assessment_component_id",
                columnDescription: "Unique code for assessment component for storing in DB"
            },
            {
                "name": "assessment_component_is_multi_lingual",
                columnDescription: "Flag to represent if the assessment component can be spoken in multiple language"
            },
            {
                "name": "assessment_component_name",
                columnDescription: "What is the name of the assessment component"
            },
            {
                "name": "assessment_component_friendly_name",
                columnDescription: "Frendly name of assessment as represented on the Front End to the users"
            },
            {
                "name": "assessment_component_sequence",
                columnDescription: "What is the sequence of the assessment component in a content package/product"
            },
            {
                "name": "assessment_component_unique_tag",
                columnDescription: "Unique name given to the assessment "
            },
            {
                "name": "assessment_id",
                columnDescription: "Unique ID for storing it in DB"
            },
            {
                "name": "assessment_name",
                columnDescription: "What is the name of the assessment "
            },
            {
                "name": "assessment_category",
                columnDescription: "What is the category/kind of the assessment "
            },
            {
                "name": "assessment_language_id",
                columnDescription: "What is language_id from language table in which the assessment has been configured"
            },
            {
                "name": "assessment_language_name",
                columnDescription: "What is the language in which the product is configured"
            },
            {
                "name": "assessment_language_iso_code",
                columnDescription: "What is the language iso code (currency code)"
            },
            {
                "name": "assessment_level_name",
                columnDescription: "Default "
            }
        ]
    },
    {
        "tableName": "test_session_dim",
        description: 'Session Details for each of the assessment appeared by a candidate in a content_package is captured in test_session and details for each of test is captured in test_dim',
        "items": [
            {
                "name": "createdon",
                columnDescription: "When was the test session started , created , completed , lastaccessed and expires on details "
            },
            {
                "name": "startedon",
                columnDescription: "When was the test session started , created , completed , lastaccessed and expires on details "
            },
            {
                "name": "completedon",
                columnDescription: "When was the test session started , created , completed , lastaccessed and expires on details "
            },
            {
                "name": "lastaccessedon",
                columnDescription: "When was the test session started , created , completed , lastaccessed and expires on details "
            },
            {
                "name": "expireson",
                columnDescription: "When was the test session started , created , completed , lastaccessed and expires on details "
            },
            {
                "name": "is_active",
                columnDescription: "If the test session is stll active "
            },
            {
                "name": "last_delivered_question",
                columnDescription: "What was the last delivered question in the session "
            },
            {
                "name": "last_delivered_page",
                columnDescription: "What was the last delivered question in the session "
            },
            {
                "name": "page_count",
                columnDescription: "What is the page count of the session accessed by candidate"
            },
            {
                "name": "number_questions",
                columnDescription: "How many number of questions in the test session"
            },
            {
                "name": "number_skipped",
                columnDescription: "If any question was skipped by the candidate"
            },
            {
                "name": "response_reuse",
                columnDescription: "Response Reuse flag , set by the candidate if they allow their responses to be reused for any other in the test"
            },
            {
                "name": "reset",
                columnDescription: "If the test reset flag is enabled "
            },
            {
                "name": "test_session_version",
                columnDescription: "What is the version of the session"
            },
            {
                "name": "test_data_provider",
                columnDescription: "From where the test input is configured from "
            },
            {
                "name": "test_layout_provider",
                columnDescription: "User who has configured the layout of the test"
            },
            {
                "name": "page_config"
            },
            {
                "name": "session_type"
            },
            {
                "name": "actual_time_allowed",
                columnDescription: "What is the time allowed for test to be completed"
            },
            {
                "name": "time_elapsed",
                columnDescription: "How much of the time did the user stay on the test"
            },
            {
                "name": "skip_model_enabled",
                columnDescription: "If the skipping of the question has been enabled"
            },
            {
                "name": "expiration_days",
                columnDescription: "What is the expiry days for expiring the test"
            }
        ]
    },
    {
        "tableName": "test_dim",
        description: 'Test details for each of the session present in test_session_dim is present in tests_dim',
        "items": [
            {
                "name": "name",
                columnDescription: "Name of the test"
            },
            {
                "name": "description"
            },
            {
                "name": "unitvalue"
            },
            {
                "name": "isactive",
                columnDescription: "If the test if active to used in any product"
            },
            {
                "name": "timeallowed",
                columnDescription: "How much is the time allowed"
            },
            {
                "name": "warmup_id"
            },
            {
                "name": "passscore",
                columnDescription: "What is passscore configured for the test"
            },
            {
                "name": "module"
            },
            {
                "name": "version"
            },
            {
                "name": "deviation"
            },
            {
                "name": "criteria"
            },
            {
                "name": "createdate"
            },
            {
                "name": "moddate"
            },
            {
                "name": "modcustomer"
            },
            {
                "name": "moduser"
            },
            {
                "name": "progressmetric"
            },
            {
                "name": "reportlable"
            },
            {
                "name": "descriptivelable"
            },
            {
                "name": "factsheets_id"
            },
            {
                "name": "notes"
            },
            {
                "name": "devices"
            },
            {
                "name": "candidatelabel"
            },
            {
                "name": "generictag"
            },
            {
                "name": "master_id"
            },
            {
                "name": "functiontype"
            },
            {
                "name": "practice_id"
            },
            {
                "name": "tpi_code"
            },
            {
                "name": "labels_id"
            },
            {
                "name": "candidatelabels_id"
            },
            {
                "name": "frameworks_id"
            },
            {
                "name": "status"
            },
            {
                "name": "cloning_id"
            },
            {
                "name": "test_type"
            },
            {
                "name": "mean"
            },
            {
                "name": "stddev"
            },
            {
                "name": "skewness"
            },
            {
                "name": "kurtosis"
            },
            {
                "name": "externalref"
            }
        ]
    },
    {
        "tableName": "single_use_temporary_link_dim",
        description: `Each of the candidate appearing for the assessments can be traced in TC with respect to its session or single_use_link
        A single_use_link is actually the link which was provided to candidate to appear for the assessments
        
        Temporary links are created when a candidate hasnt registered in the system and will be unique each time a candidate opens the link without registration.
        `,
        "items": [
            {
                "name": "date_extented_mandually",
                columnDescription: "Details stored in single_use_temporary_link_dim is when candidates access the link without registration"
            },
            {
                "name": "language_id",
                columnDescription: "What is language for the session created"
            },
            {
                "name": "language_code",
                columnDescription: "What is language for the session created"
            },
            {
                "name": "link_url",
                columnDescription: "What is the unique link generated for the candidate to appear for the test"
            },
            {
                "name": "link",
                columnDescription: "What is the unique link generated for the candidate to appear for the test"
            },
            {
                "name": "version"
            },
            {
                "name": "project_status",
                columnDescription: "What is the current status of the project "
            },
            {
                "name": "current_stage",
                columnDescription: "On which stage the candidate last left the test"
            },
            {
                "name": "archived"
            },
            {
                "name": "duplicate_candidate"
            },
            {
                "name": "third_party_candidate_id",
                columnDescription: "What is the candidate_id for the candidate on the client system ,At the time of registration third_party_candidate is provided in the project_tag , but incase missed later is updated in this table "
            },
            {
                "name": "multi_use_link_id",
                columnDescription: "Apart from single_use_link , client also has an option to create multi_use_link incase candidate list is not provided. "
            },
            {
                "name": "hired_date",
                columnDescription: "Was the candidate hired , incase yes what is the hired date for the same"
            },
            {
                "name": "candidate_tag",
                columnDescription: "Additional detail for candidates"
            },
            {
                "name": "bulk_timer_extended"
            },
            {
                "name": "email",
                columnDescription: "what is the email of the candidate"
            },
            {
                "name": "expiry_timestamp",
                columnDescription: "When will the link expire"
            },
            {
                "name": "forward_url"
            },
            {
                "name": "max_usage",
                columnDescription: "What is the max allowed usage for the link "
            },
            {
                "name": "number_of_times_used",
                columnDescription: "What is the acutal number of times the link has been used"
            },
            {
                "name": "valid",
                columnDescription: "Is the link provided acutally valid"
            },
            {
                "name": "temporary_link_version",
                columnDescription: "What is the version of the temporary link"
            },
            {
                "name": "reference_session",
                columnDescription: "System generated unique code to trace the session "
            }
        ]
    },
    {
        "tableName": "session_dim",
        description: `Once a candidate registers on TC , a temporary link gets converted into single_use_link and is stored in session_dim`,
        "items": [
            {
                "name": "date_extented_mandually",
                columnDescription: "True/False flag if the expire date has been extended manually"
            },
            {
                "name": "language_id",
                columnDescription: "What is language for the session created"
            },
            {
                "name": "language_code",
                columnDescription: "What is language for the session created"
            },
            {
                "name": "link_url",
                columnDescription: "What is the unique link generated for the candidate to appear for the test"
            },
            {
                "name": "link",
                columnDescription: "What is the unique link generated for the candidate to appear for the test"
            },
            {
                "name": "sul_session_status_15_days",
                columnDescription: "Status of the SUL session on 15th day"
            },
            {
                "name": "sul_session_status",
                columnDescription: "Status of SUL now "
            },
            {
                "name": "sul_session_status_45_days",
                columnDescription: "Status of the session in 45 day"
            },
            {
                "name": "sul_session_status_raw",
                columnDescription: "Raw - status of the SUL from SUL table"
            },
            {
                "name": "sul_session_status_rg_candidate",
                columnDescription: "rg - rg_candidate_dim is source table in TC ,When clients sets expiry date exclusively for a candidate (expriation duration) , then the details is in a separte table and the same is stored in this column"
            },
            {
                "name": "project_status",
                columnDescription: "Current status of the SUL in a project"
            },
            {
                "name": "session_createdon",
                columnDescription: "When was the session created , started and exited by the candidate"
            },
            {
                "name": "session_startedon",
                columnDescription: "When was the session created , started and exited by the candidate"
            },
            {
                "name": "session_completedon",
                columnDescription: "When was the session created , started and exited by the candidate"
            },
            {
                "name": "current_stage",
                columnDescription: "At which step was the candidate on last login"
            },
            {
                "name": "archived"
            },
            {
                "name": "duplicate_candidate",
                columnDescription: "If the candidate has appeared previously , flag to represent the same "
            },
            {
                "name": "third_party_candidate_id",
                columnDescription: "What is the candidate_id for the candidate on the client system ,At the time of registration third_party_candidate is provided in the project_tag , but incase missed later is updated in this table "
            },
            {
                "name": "candidate_tag",
                columnDescription: "Additional detail for candidates"
            },
            {
                "name": "overall_percentile",
                columnDescription: "No data present in any environment "
            },
            {
                "name": "overall_rating",
                columnDescription: "No data present in any environment "
            },
            {
                "name": "overall_recommendation",
                columnDescription: "No data present in any environment "
            },
            {
                "name": "expiredate_15_days",
                columnDescription: "What is the expire date with 15 days "
            },
            {
                "name": "expiredate",
                columnDescription: "What is the actual expire date from SUL table "
            },
            {
                "name": "expiredate_45_days",
                columnDescription: "What is the expire date of the session on 45 days"
            },
            {
                "name": "expiredate_raw",
                columnDescription: "What is the original expiredate present in the SUL table "
            },
            {
                "name": "expiredate_rg_candidate",
                columnDescription: "rg - rg_candidate_dim is source table in TC ,When clients sets expiry date exclusively for a candidate (expriation duration) , then the details is in a separte table and the same is stored in this column"
            },
            {
                "name": "product_count",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "product_completed",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "product_started",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "product_not_started",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "product_recalled",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "product_not_invited",
                columnDescription: "What are the product count , product completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "assessment_count",
                columnDescription: "What are the assessment count , assessment completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "assessment_started",
                columnDescription: "What are the assessment count , assessment completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "assessment_completed",
                columnDescription: "What are the assessment count , assessment completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "assessments_started_not_completed",
                columnDescription: "What are the assessment count , assessment completed , started and other status of the candidate appearing for that particular session"
            },
            {
                "name": "hurdle_status",
                columnDescription: "No data present in any environment "
            }
        ]
    },
    {
        "tableName": "score_config_dim",
        description: `For each of the content package , score_tag and value_type are configured
        Score_tag is the tag mentioned against each scores by the client for their reference and accessibility.
        
        Value_type is the type of the score that has to be published/captured for a particural content_package
        `,
        "items": [
            {
                "name": "is_config_present",
                columnDescription: "Whether the configuration is present for the content_package or not "
            },
            {
                "name": "display_label",
                columnDescription: "Display value(for reporting purspose) for each of the score tag provided "
            },
            {
                "name": "is_display",
                columnDescription: "If the score tag configured is applicable for reporting "
            },
            {
                "name": "is_main_score",
                columnDescription: "If the score tag configured is main score to be used for assessing the candidate for selection"
            },
            {
                "name": "score_summary",
                columnDescription: "Score tag value provided by the client"
            },
            {
                "name": "score_tag",
                columnDescription: "Score_tag is the tag mentioned against each scores by the client for their reference and accessibility."
            },
            {
                "name": "sequence",
                columnDescription: "Each of the score tag configured is given a sequence number "
            },
            {
                "name": "version",
                columnDescription: "What is the version of the score tag configured"
            },
            {
                "name": "display_value_type",
                columnDescription: "Value type is type of the score on the basis of which the candidate is being evaluated , and the same is being represented for reporting purpose ,We have 7 value_types against which a score can be configured and they are - ,raw - A raw score ,percentile - Score in percentile ,sten  - standardised 1-10 scores commonly used in psychometric testing ,stive - based on time spent in each ,z-score - ,t-score ,other-scores"
            },
            {
                "name": "display_value_type_name",
                columnDescription: "Value type is type of the score on the basis of which the candidate is being evaluated , and the same is being represented for reporting purpose ,We have 7 value_types against which a score can be configured and they are - ,raw - A raw score ,percentile - Score in percentile ,sten  - standardised 1-10 scores commonly used in psychometric testing ,stive - based on time spent in each ,z-score - ,t-score ,other-scores"
            },
            {
                "name": "display_value_type_definition",
                columnDescription: "Value type is type of the score on the basis of which the candidate is being evaluated , and the same is being represented for reporting purpose ,We have 7 value_types against which a score can be configured and they are - ,raw - A raw score ,percentile - Score in percentile ,sten  - standardised 1-10 scores commonly used in psychometric testing ,stive - based on time spent in each ,z-score - ,t-score ,other-scores"
            },
            {
                "name": "is_extract",
                columnDescription: "Whether the score configurated can be extracted for evaluating the candidate"
            },
            {
                "name": "is_interview",
                columnDescription: "A flag to identify if the score tag is being configured for an interview assessment"
            },
            {
                "name": "is_development"
            },
            {
                "name": "is_detail"
            },
            {
                "name": "is_hurdle",
                columnDescription: "Flag to check if a hurdle is present "
            },
            {
                "name": "code",
                columnDescription: "what is code to uniquely identify the content package with the configured core"
            },
            {
                "name": "config_create_date",
                columnDescription: "When was the config created and last updated"
            },
            {
                "name": "config_update_date",
                columnDescription: "When was the config created and last updated"
            }
        ]
    },
    {
        "tableName": "content_package_score_config_dim",
        "items": [
            {
                "name": "same as score_config_dim",
                columnDescription: "Each of the score tag and its mapping to all the possible value types are present in this table, whereas in score_tag_dim only the value_type used for final reporting is captured "
            }
        ]
    },
    {
        "tableName": "scale_score_tag_dim",
        description: `Scale_type reference for each of the score_tag is present in this table`,
        "items": [
            {
                "name": "scale_type"
            },
            {
                "name": "tag_description"
            },
            {
                "name": "score_tag",
                columnDescription: "Unique identifier"
            },
            {
                "name": "is_custom_scale_type",
                columnDescription: "If the scalre type is the most latest one "
            },
            {
                "name": "value_type_score"
            }
        ]
    },
    {
        "tableName": "scale_tag_config_dim",
        description: 'Scale tag configuration is present in this table',
        "items": [
            {
                "name": "scale_tag"
            },
            {
                "name": "active",
                columnDescription: "Is the scale currently active"
            },
            {
                "name": "scale_description",
                columnDescription: "What is the additional details pertaining to the scale configured"
            },
            {
                "name": "updatedatetime",
                columnDescription: "What was the scale type was created and updated"
            },
            {
                "name": "createdatetime",
                columnDescription: "What was the scale type was created and updated"
            },
            {
                "name": "modcustomer",
                columnDescription: "Who was the user to have updated the scale type"
            },
            {
                "name": "scale_name",
                columnDescription: "what is the name for the scale type created"
            },
            {
                "name": "source_system",
                columnDescription: "What is source system - soda , s2p etc for which the scores was published"
            },
            {
                "name": "participant_level"
            },
            {
                "name": "admin_label",
                columnDescription: "Label for admin "
            },
            {
                "name": "scale_type",
                columnDescription: "What is the scale type for the score"
            },
            {
                "name": "display_label",
                columnDescription: "Display lable for reporting purpose of the scale type"
            }
        ]
    },
    {
        "tableName": "assessments_scores_fact_<year>",
        description: 'Scores are stores in this table for each candidate appearing for an assessment in a project for a particular client ',
        "items": [
            {
                "name": "company_id",
                columnDescription: "What is the company/client for which the score is captured"
            },
            {
                "name": "candidate_id",
                columnDescription: "Which candidate from candidate_dim has appeared"
            },
            {
                "name": "project_id",
                columnDescription: "What is the project against which the scores is captured"
            },
            {
                "name": "single_use_link_id",
                columnDescription: "What is the unique identifier for SUL in SUL table"
            },
            {
                "name": "sul_started_date",
                columnDescription: "SUL started date from single_use_temporary_link_dim"
            },
            {
                "name": "sul_completed_date",
                columnDescription: "SUL end date from single_use_temporary_link_dim"
            },
            {
                "name": "user_profile_id",
                columnDescription: "Unique identifier for User profile detail present in candidate_dim"
            },
            {
                "name": "content_package_id",
                columnDescription: "Unique identifier for content_package/product present in table content_package_battery_dim"
            },
            {
                "name": "candidate_content_package_id",
                columnDescription: "Unique identifier for the candidate content package"
            },
            {
                "name": "ccpm_start_date",
                columnDescription: "When was the content package started "
            },
            {
                "name": "ccpm_end_date",
                columnDescription: "When was the content package completed incase"
            },
            {
                "name": "s2p_testsession_id",
                columnDescription: "What is sessionid from source system - S2P"
            },
            {
                "name": "test_session_startedon",
                columnDescription: "When was the test session started on "
            },
            {
                "name": "test_session_completedon",
                columnDescription: "When was the test session completed on "
            },
            {
                "name": "sittings_id",
                columnDescription: "Unique idetifier to represent sitting "
            },
            {
                "name": "candidate_assessment_id",
                columnDescription: "Unique Id for candidate assessment "
            },
            {
                "name": "cam_start_date",
                columnDescription: "When was the assessment started by the candidate"
            },
            {
                "name": "cam_end_date",
                columnDescription: "When was the assessment completed by the candidate"
            },
            {
                "name": "assessment_id",
                columnDescription: "Unique identifier for the assessment "
            },
            {
                "name": "test_sequence",
                columnDescription: "What is the sequence of the test in the assessment "
            },
            {
                "name": "score_type",
                columnDescription: "what is the score type "
            },
            {
                "name": "scores_id",
                columnDescription: "Unique identifier to store the score in DB"
            },
            {
                "name": "is_package_score",
                columnDescription: "If the score has been captured for the package "
            },
            {
                "name": "norm_group_code"
            },
            {
                "name": "norm_category_code"
            },
            {
                "name": "score_id",
                columnDescription: "No data present"
            },
            {
                "name": "score_level"
            },
            {
                "name": "scale_tag",
                columnDescription: "What is the scale tag against which the score is captured"
            },
            {
                "name": "sequence",
                columnDescription: "What is the sequence of the scores corressponding to different score tag"
            },
            {
                "name": "version",
                columnDescription: "What is the version of the score captured"
            },
            {
                "name": "is_archived",
                columnDescription: "Binary flag to store in DB if the score has been archived"
            },
            {
                "name": "score_source"
            },
            {
                "name": "scale_score"
            },
            {
                "name": "sul_jp_id"
            },
            {
                "name": "scores_create_date",
                columnDescription: "When was the scores created"
            },
            {
                "name": "scores_create_year",
                columnDescription: "Which year was the scores created"
            },
            {
                "name": "scores_update_date",
                columnDescription: "When was the scores updated"
            },
            {
                "name": "value_type",
                columnDescription: "Scores is captured against value_type configured for in scores_config_dim table"
            },
            {
                "name": "score_value",
                columnDescription: "What is actual scores of the candidate"
            }
        ]
    },
    {
        "tableName": "uadetector_fact",
        description: 'Each test session appeared by the candidate is stored in the table with its device and browser details',
        "items": [
            {
                "name": "useragent_id",
                columnDescription: "Unique id to store the activity in DB"
            },
            {
                "name": "acrobat_version",
                columnDescription: "No data present"
            },
            {
                "name": "authorware_version",
                columnDescription: "No data present"
            },
            {
                "name": "browser_date",
                columnDescription: "when was the device browsed by the candidate"
            },
            {
                "name": "browser_date_id",
                columnDescription: "Unique id for date captured "
            },
            {
                "name": "browser_type",
                columnDescription: "What is the browser type used by the candidate to appear for the assessment - ,eg - SAFARI , CHROME , Google etc"
            },
            {
                "name": "browser_version",
                columnDescription: "What was the version of the brower used by the candidate"
            },
            {
                "name": "browser_os_name",
                columnDescription: "What was the OS name of the candidate device "
            },
            {
                "name": "colors",
                columnDescription: "No data present"
            },
            {
                "name": "connection_type",
                columnDescription: "No data present"
            },
            {
                "name": "default_media_player",
                columnDescription: "No data present"
            },
            {
                "name": "device_type",
                columnDescription: "Which device did candidate use - Mobile , Dekstop , Tablet etc"
            },
            {
                "name": "flash_version",
                columnDescription: "What was the flash version installed in the device "
            },
            {
                "name": "having_activexcontrols",
                columnDescription: "Flag for capturing if the active control was enabled in the device"
            },
            {
                "name": "insert_time_stamp",
                columnDescription: "Time of using the device while appearing for assessment"
            },
            {
                "name": "is_activeexenabled",
                columnDescription: "Flag to check if activex was enabled"
            },
            {
                "name": "is_adobe_acrobat_available",
                columnDescription: "Flag to check if acrobat was available"
            },
            {
                "name": "is_authorware_available",
                columnDescription: "Flag to check if authorware is available in the system"
            },
            {
                "name": "isdhtml",
                columnDescription: "Flag to check for html enablement"
            },
            {
                "name": "is_flash_available",
                columnDescription: "Flag to check if the flash was available in the system"
            },
            {
                "name": "is_java_enabled",
                columnDescription: "Flag to check for JAVA enablement in the device "
            },
            {
                "name": "is_persistent_cookie_enabled",
                columnDescription: "Flag to check cookie eneblement"
            },
            {
                "name": "is_session_cookie_enabled",
                columnDescription: "Flag to check cookie eneblement"
            },
            {
                "name": "java_version",
                columnDescription: "Which version of JAVA was enabled"
            },
            {
                "name": "jvm_make",
                columnDescription: "What was the jvm used "
            },
            {
                "name": "player_version",
                columnDescription: "What was the version player installed in candidate device"
            },
            {
                "name": "screen_resolution",
                columnDescription: "What was the screen resolution"
            },
            {
                "name": "text_size",
                columnDescription: "What was the text sixe of the page"
            },
            {
                "name": "time_off_set",
                columnDescription: "What was the time of set "
            },
            {
                "name": "test_session_id",
                columnDescription: "Test session Id from test_dim against which the device details are captured"
            },
            {
                "name": "workflow_session_id",
                columnDescription: "Workflow session id from worflow session dim"
            },
            {
                "name": "company_id",
                columnDescription: "Company id from company dim"
            },
            {
                "name": "project_id",
                columnDescription: "Project Id from project dim"
            },
            {
                "name": "candidate_id",
                columnDescription: "Candidate Id from candidate dim"
            },
            {
                "name": "ip_address",
                columnDescription: "IP address of the device used"
            }
        ]
    }
]