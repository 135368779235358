export const actionTypes = {
      GET_ALL_DE_LIST: 'GET_ALL_DE_LIST',
      GET_ALL_DE_LIST_SUCCESS: 'GET_ALL_DE_LIST_SUCCESS',
      GET_ALL_DE_LIST_FAILURE: 'GET_ALL_DE_LIST_FAILURE',
      GET_DE_REQUEST_HISTORY: 'GET_DE_REQUEST_HISTORY',
      GET_DE_REQUEST_HISTORY_SUCCESS: 'GET_DE_REQUEST_HISTORY_SUCCESS',
      GET_DE_REQUEST_HISTORY_FAILURE: 'GET_DE_REQUEST_HISTORY_FAILURE',
      GET_USER_LIST: 'GET_DE_USER_LIST',
      GET_USER_LIST_SUCCESS: 'GET_DE_USER_LIST_SUCCESS',
      GET_USER_LIST_FAILURE: 'GET_DE_USER_LIST_FAILURE',
      GET_COMPANY_LIST: 'GET_COMPANY_LIST',
      GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
      GET_COMPANY_LIST_FAILURE: 'GET_COMPANY_LIST_FAILURE',
      GET_CURRENT_DE_COMPANY_LIST: 'GET_CURRENT_DE_COMPANY_LIST',
      GET_CURRENT_DE_COMPANY_LIST_SUCCESS: 'GET_CURRENT_DE_COMPANY_LIST_SUCCESS',
      GET_CURRENT_DE_COMPANY_LIST_FAILURE: 'GET_CURRENT_DE_COMPANY_LIST_FAILURE',
      ADD_DE: 'ADD_DE',
      ADD_DE_SUCCESS: 'ADD_DE_SUCCESS',
      ADD_DE_FAILURE: 'ADD_DE_FAILURE',
      EDIT_DE: 'EDIT_DE',
      EDIT_DE_SUCCESS: 'EDIT_DE_SUCCESS',
      EDIT_DE_FAILURE: 'EDIT_DE_FAILURE',
      DELETE_DE: 'DELETE_DE',
      DELETE_DE_SUCCESS: 'DELETE_DE_SUCCESS',
      DELETE_DE_FAILURE: 'DELETE_DE_FAILURE',
      UPDATE_DE_INFO: 'UPDATE_DE_INFO',
      SELECT_ADD_DE: 'SELECT_ADD_DE',
      SELECT_EDIT_DE: 'SELECT_EDIT_DE',
      CLEAR_SELECTED_DE: 'CLEAR_SELECTED_DE',
      FILTER_COMPANY: 'FILTER_COMPANY',
      FILTER_USER: 'FILTER_USER',
      UPDATE_DE_LIST: 'UPDATE_DE_LIST',
      PROCESS_JOB: 'PROCESS_JOB',
      PROCESS_JOB_SUCCESS: 'PROCESS_JOB_SUCCESS',
      PROCESS_JOB_FAILURE: 'PROCESS_JOB_FAILURE',
      CLEAR_DE_REQUEST: 'CLEAR_DE_REQUEST'
}
