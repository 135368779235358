export const config = {
        baseUrl: process.env.REACT_APP_ENVIRONMENT === 'Local' ? 'https://data-analytics.eu.shl.zone/reportviewer' : '/reportviewer',
        jsonUrl: process.env.REACT_APP_ENVIRONMENT === 'Local' ? '/kpis_data' : '/kpis_data',
        apiUrl: {
                checkIsAdmin: '/user/isadmin',
                getAuthCode: '/auth/getAccessToken',
                getUserInfo: '/user/getUserInfo',
                submitReportRequest: '/report/access',
                isPIAccessRequestPending: '/report/access/isPending',
                viewRequest: '/report/access/all',
                requestApprove: '/report/access/changeStatus',
                reportHasAccess: '/report/access/hasAccess',
                logHistory: '/report/access/accessLog',
                logFilter: '/report/filter/audit',
                downloadReport: '/report/download',
                fetchTableauFilter: '/tableau/filter',
                fetchCompanyFilter: '/tableau/filter/getSourceCompanies',
                fetchBusinessUnit: '/tableau/filter/businessUnit',
                fetchOwnerEmployeeKey: '/tableau/filter/ownerEmployeeKey',
                fetchCompanyKey: '/tableau/filter/companyKey',
                fetchEmployeeKey: '/tableau/filter/employeeKey',
                tableauToken: '/tableau/token/getToken',
                deleteTableauToken: '/tableau/token',
                deleteTokenOnClose: '/tableau/token/dToken',
                isSessionActive: '/tableau/token/getTokenStatus',
                isUpdating: '/tableau/isUpdating',
                submitQueryResponse: '/report/disclaimer',
                getRoleList: '/user/roles',
                addNewRole: '/user/role',
                getUserList: '/user/getAll',
                addUser: '/user',
                editUser: '/user',
                deleteUser: '/user',
                getKPIList: '/kpi/getAll',
                addKpi: '/kpi',
                editKpi: '/kpi',
                deleteKpi: '/kpi',
                getReportList: '/report/getAll',
                addReport: '/report',
                editReport: '/report',
                deleteReport: '/report',
                updateReportNotification: '/report/notification',
                getNotificationList: '/notification/getAll',
                curdNotification: '/notification',
                getHomeNotification: '/notification/getUser',
                getUserSpecificRole: '/user/rolesInfo',
                getReportAndKpiList: '/kpi/getKpiReports',
                getFilterList: '/report/filter/getAll',
                addFilter: '/report/filter',
                editFilter: '/report/filter',
                deleteFilter: '/report/filter',
                copyFilter: '/report/filter/copy',
                updatePiDeleteStatus: '/report/disclaimer',
                getFavouriteReport: '/report/favourite',
                setFavouriteReport: '/report/favourite',
                removeFromFavourite: '/report/favourite',
                getAllDeList: '/de/request/getAll',
                getCompanyList: '/de/request/getCompanies',
                getCurrentDECompanies: '/de/request/getCurrentDECompanies',
                getDeUserList: '/de/request/getUsers',
                addDe: '/de/request',
                editDe: '/de/request',
                deleteDe: '/de/request',
                triggerDeJob: '/de/request/trigger',
                getRecentReports: '/report/getRecentReports',
                getHomeKpiData: '/homepage/data',
                publishReport: '/report/updateStatus',
                publishKpi: '/kpi/updateStatus',
                submitTableauRequest: '/tableau/request',
                checkTableauRequestPending: '/tableau/request/isPending',
                changeTableauRequestStatus: '/tableau/request',
                getAllTableauRequest: '/tableau/request/getAll',
                getAllCustomScoreGroup: '/scoreTag/getAllCustom',
                getAllScoreTagInCustomGroup: '/scoreTag/getAll',
                getAllTcScoreTag: '/scoreTag/getAllTcTags',
                getAllCompany: '/scoreTag/getCompanies',
                getAllContentPackage: '/scoreTag/getContentPackages',
                addCustomScoreGroup: '/scoreTag',
                deleteCustomScoreGroup: '/scoreTag',
                editCustomScoreGroup: '/scoreTag',
                getCandidatesServedPerMonth: '/homepage/candidates',
                getDropoutsPerMonth: '/homepage/dropouts',
                getKpiBiHomeData: '/homepage/biPortal',
                getTableauSuperUserList: '/tableau/superuser/getAll',
                addTableauSuperUser: '/tableau/superuser',
                deleteTableauSuperUser: '/tableau/superuser',
                processNonAggregatedData: '/tableau/superuser/aggregate',
                getAllCandidateCompany: '/sut/getAllCompanies',
                getAllSolutionTiming: '/sut/getSolutionTimings',
                getAllSutCompany: '/sut/getCompanies',
                getAllSolutionMapping: '/sut/getSolutionsMapping',
                getAllMappings: '/sut/getMappings',
                addSolutionMappings: '/sut',
                addSolutionTiming: '/sut/solutionTimings',
                getExtract: '/extract/getReportExtract',
                addExtract: '/extract',
                editExtract: '/extract',
                deleteExtract: '/extract',
                getAllExtracts: '/extract/getReportExtract',
                getCategoryList: '/category/getAll',
                addCategory: '/category',
                editCategory: '/category',
                deleteCategory: '/category',
                publishCategory: '/category/updateStatus',
                getCaReportList: '/ca/report/getAll',
                addCaReport: '/ca/report',
                editCaReport: '/ca/report',
                deleteCaReport: '/ca/report',
                publishCaReport: '/ca/report/updateStatus',
                getCategoryReports: '/category/getCategoryReports',
                getAllFactList: '/fact/getAll',
                getAllGeneratedFactList: '/fact/getAllGeneratedFacts',
                addFact: '/fact',
                editFact: '/fact',
                deleteFact: '/fact',
                addQuiz: '/quiz',
                deleteQuiz: '/quiz',
                editQuiz: '/quiz',
                getAllGeneratedQuizList: '/quiz/getAllGeneratedQuizs',
                getAllQuizList: '/quiz/getAll',
                getAllEmailGroups: '/emailGroup/getAllGroups',
                sendEmailToGroups: '/emailGroup',
                getDeRequestHistory: '/de/request/getByDate',
                getAllUserGroups: '/userGroup/getAll',
                addUserGroup: '/userGroup',
                editUserGroup: '/userGroup',
                deleteUserGroup: '/userGroup',
                getUserAllCompanyList: '/user/company/getUserMappings',
                addNewCompany: '/user/company',
                isSuperDepartment: '/user/isSuperDepartment',
                getUserAllPendingCompanyList: '/tableau/filter/getCompanies',
                getSuperUserListApprover: '/user/company/getSuperUserMappings',
                getCompanyUserListApprover: '/user/company/getAll',
                updateDECompanyStatus: '/user/company/updateStatus',
                addNewExtractRequest: '/rtb/extract',
                getAllExtractRequests: '/rtb/extract/getAll',
                updateExtractRequestStatus: '/rtb/extract/updateStatus',
                getAllGlueJobList: '/glue/jobStatus',
                getHistory: '/report/history',
                addUserJiraInfo: '/jira/getData',
                getLeaderboardData: '/awards/getLeaderboardData',
                manageAward: '/awards',
                getAllAwards: '/awards/getAllAwards',
                getAwardsByType: '/awards/getAwardsByType',
                getMyReportees: '/timesheetUser/getMyUsers',
                getUserSpecificAward: '/awards/getUserAwards',
                sendCertificateEmail: '/awards/sendAwardEmail',
                getDigitalCycle: '/awards/getDigitalCycle',
                manageTimesheetProject: '/timesheetProject',
                timesheetGetAllProject: '/timesheetProject/getAllProject',
                timesheetGetAllUsersProject: '/timesheetProject/getAllUsersProject',
                manageTimesheetInitiative: '/timesheetInitiative',
                timesheetGetAllInitiative: '/timesheetInitiative/getAllInitiative',
                timesheetGetAllUsersInitiative: '/timesheetInitiative/getAllUsersInitiative',
                timesheetMarkInitiativeComplete: '/timesheetInitiative/markInitiativeComplete',
                timesheetInitiativeBulkUpload: '/timesheetInitiative/bulkUpload',
                manageTimesheetSquad: '/timesheetSquad',
                timesheetGetAllSquad: '/timesheetSquad/getAllSquad',
                timesheetUsers: '/timesheetUser',
                timesheetGetAllUsers: '/timesheetUser/getAllTimesheetUser',
                timesheetMyEntry: '/timesheetEntry/mytimesheet',
                timesheetTeamEntry: '/timesheetEntry/teamtimesheet',
                timesheetUserHours: '/timesheetUser/getUserHours',
                timesheetMissingUser: '/timesheetUser/getMissingTimesheetUser',
                timesheetGetSelf: '/timesheetUser/getUserByToken',
                requestSecureExtract: '/secureExtract',
                getAllKpi: '/allKpis.json',
                getPublishedKpi: '/publishedKpis.json',
                timesheetUserUpdateStatus: '/timesheetUser/updateUserStatus',
                timesheetUserBulkUpload: '/timesheetUser/bulkUpload',
                extractHideShow: '/extract/isHide'
        }
}