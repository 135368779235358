import { useState, useEffect } from 'react'
import './FieldCommon.scss'
import { Tooltip } from 'primereact/tooltip'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

interface IText {
    value: any,
    update: (name: string, value: string, displayLabel: string, valueLabels?: any) => void,
    label: string,
    name: string,
    error?: boolean,
    maxLimit?: number,
    disabled?: boolean,
    hint?: string,
}

const Text = ({ value, update, label, name, error, maxLimit, disabled, hint }: IText) => {
    const [remainingChar, setRemainingChar] = useState<number>()


    const onUpdate = (e: any) => {
        update(name, e.currentTarget.value, label);
        if (maxLimit) {
            setRemainingChar(maxLimit - (e.currentTarget.value.length))
        }
    }

    useEffect(() => {
        if (maxLimit && value) {
            setRemainingChar(maxLimit - (value.length));
        } else if (maxLimit) {
            setRemainingChar(maxLimit);
        }
    }, [value])

    return (
        <div className='d-flex flex-column align-items-end '>
            <CustomTooltip text={value} target={`.${name.replace(/[^a-zA-Z]/g, 'a')}-value`} />

            {/* <span className={`${name.replace(/[^a-zA-Z0-9]/g, '')}-value value`} data-pr-tooltip={value} >
                        {value}
                    </span> */}
            <input maxLength={maxLimit}
                className={"form-control input-text shadow-none" + (error ? ' invalid ' : ' ') + (disabled ? `${name.replace(/[^a-zA-Z]/g, 'a')}-value` : '')}
                aria-invalid={error} value={value} onChange={onUpdate}
                placeholder={`Enter ${label}`} readOnly={disabled}
                style={{ borderColor: error ? 'rgb(220, 53, 69)' : '', opacity: disabled ? '0.6' : '1' }}
            />
            {maxLimit && <small className='text-primary l1-font-size' >{remainingChar}/{maxLimit} characters left </small>}
            <small className=' text-primary l1-font-size'>{hint}</small>
        </div>)
}

export default Text