import { useState } from "react";
import { IProcess, IStep } from "../../../constants/ProcessFlowType";
import './ProcessComponent.scss';
interface IProps {
    processName: string,
    processDesc: string,
    chartFlow?:boolean,
    chartPath?:string,
    steps: IStep[],
    processIndex: number,
    status: number
}



const ProcessComponent = ({ processName, processDesc, steps,chartFlow,chartPath, processIndex, status }: IProps) => {

    const [panel, setPanel] = useState<string>('');

    const handlePanel = (id: string) => {
        if (panel !== '') {
            document.getElementById(panel)?.classList.remove('show')
        }
        setPanel(id)

    }

    return (
        <div className="process-container w-100 d-flex flex-column text-start" >
            <h4 className="process-heading fw-bold py-3 px-4">{processName}</h4>
            <p className="mt-1 px-4 process-desc "><small>{processDesc}</small> </p>

            <div className="d-flex justify-content-between info-container py-4 px-4">
                {
                  !chartFlow ?  steps.map((item: IStep, index: any) => (
                        <div className={`step-container ${index < status ? 'completed' : ''} ${index <= status ? 'step-completed':''} d-flex flex-column align-items-start justify-content-center`}>
                            <small className="fw-bold">{item.title}</small>
                            <div className="text-center count-container">
                                <button onClick={() => handlePanel(`step-${processIndex}${index}`)} className="step-btn rounded-circle" type='button' data-bs-toggle='collapse' data-bs-target={`#step-${processIndex}${index}`} aria-expanded="false" aria-controls={`step-${processIndex}${index}`}  ><span className="l2-font-size">{index + 1}</span></button>
                            </div>

                            <div id={`step-${processIndex}${index}`} className='collapse-item collapse multi p-2 shadow mt-2'  >
                                <span className="l1-font-size" >{item.desc}</span>
                            </div>
                        </div>

                    ))
                    :<img className="w-100" src={process.env.PUBLIC_URL+ chartPath} alt="" ></img>
                }
            </div>


        </div>
    )
}

export default ProcessComponent;