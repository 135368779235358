import actionTypes from "../actions/JiraActionTypes"
import { IJira } from "../types/Jira"

const initialState:IJira = {
    addUserJiraInfoStatus:{
        success:false,
        loading:false,
        error:false
    },
    currentUserJiraInfo:{
        query:'',
        token:'',
        isRtbQuery:false
    }
}


const jiraReducer = (state=initialState , action:any):IJira =>  {
    switch(action.type) {
        case actionTypes.ADD_USER_JIRA_INFO: {
            return {
                ...state,
                addUserJiraInfoStatus:{loading:true , success:false , error:false}
            }  
        }
        case actionTypes.ADD_USER_JIRA_INFO_SUCCESS: {
            return {
                ...state,
                addUserJiraInfoStatus:{loading:false ,success:true , error:false}
            }
        }
        case actionTypes.ADD_USER_JIRA_INFO_FAILURE : {
            return {
                ...state,
                addUserJiraInfoStatus:{loading:false , success:false , error:true}
            }
        }
        case actionTypes.UPDATE_USER_JIRA_INFO: {
            return {
                ...state,
                currentUserJiraInfo:Object.assign({} , state.currentUserJiraInfo ,action.payload)
            }
        }
        case actionTypes.CLEAR_JIRA_INFO : {
            return {
               ...initialState
            }
        }
        default: return state
        
    } 

}

export default jiraReducer