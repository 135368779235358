import React from "react";
import tokenService from "../api/token.service";

const LoginAuthGuard = ({ children, isLogin }) => {
    const auth = isLogin;
    const user = tokenService.getUser();
    if (auth || (user && user.data) || process.env.REACT_APP_ENVIRONMENT === 'Local') {
        return children
    } else {
        const path = window.location.pathname
        sessionStorage.setItem('lastUrl', path)
        window.location.href = `https://sso.connect.pingidentity.com/sso/as/authorization.oauth2?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid&response_type=code`;
        return null
    }
}

export default LoginAuthGuard;