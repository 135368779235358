import { actionType } from "../actions/PiDeleteStatusActionType";
import { IPiDataDeleteStatus } from "../types/PiDataDelete";
const initialState:IPiDataDeleteStatus= {
     piDataDeleteStatus :{
         success:false,
         loading:false,
         error:false
     }
}

const piDataDeleteReducer=(state=initialState , action:any):IPiDataDeleteStatus => {
    switch(action.type){
        case actionType.UPDATE_PI_DELETE_STATUS: {
            return {
                piDataDeleteStatus:Object.assign({} , state.piDataDeleteStatus ,{loading:true , error:false , success:false } )
            }
        }
        case actionType.UPDATE_PI_DELETE_STATUS_SUCCESS : {
            return {
                piDataDeleteStatus:Object.assign({} , state.piDataDeleteStatus , {loading:false , error:false , success:true})
            }
        }
        case actionType.UPDATE_PI_DELETE_STATUS_FAILURE : {
            return {
                piDataDeleteStatus:Object.assign({} , state.piDataDeleteStatus ,{loading:false , error:true , success:false} )
            }
        }
        default: 
        return state
    }

}
export default piDataDeleteReducer;