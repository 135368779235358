import { IManageCategory } from '../types/ManageCategory'
import actionType from '../actions/ManageCategoryActionType'

const initialState: IManageCategory = {
    categoryList: {
        loading: false,
        success: false,
        error: false,
        categories: []
    },
    addCategoryStatus: {
        loading: false,
        success: false,
        error: false
    },
    editCategoryStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteCategoryStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentCategory: {
        categoryName: '',
        categoryId: '',
        icon: '',
        description: '',
        appName: 'client-analytics'
    },
    editingCategory: {
        categoryName: '',
        categoryId: '',
        icon: '',
        description: '',
        appName: ''
    },
    publishCategoryStatus: {
        loading: false,
        success: false,
        error: false
    }

}

const manageCategoryReducer = (state = initialState, action: any): IManageCategory => {
    switch (action.type) {
        case actionType.GET_CATEGORY_LIST: {
            return {
                ...state,
                categoryList: Object.assign({}, {
                    success: false,
                    error: false,
                    categories: []
                }, { loading: true })
            }
        }
        case actionType.GET_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                categoryList: Object.assign({}, state.categoryList, { loading: false, categories: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_CATEGORY_LIST_ERROR: {
            return {
                ...state,
                categoryList: Object.assign({}, state.categoryList, { loading: false, error: true, success: false, categories: [] })

            }
        }
        case actionType.ADD_CATEGORY: {
            return {
                ...state,
                addCategoryStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_CATEGORY_SUCCESS: {
            return {
                ...state,
                addCategoryStatus: Object.assign({}, state.addCategoryStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_CATEGORY_ERROR: {
            return {
                ...state,
                addCategoryStatus: Object.assign({}, state.addCategoryStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_CATEGORY: {
            return {
                ...state,
                editCategoryStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_CATEGORY_SUCCESS: {
            return {
                ...state,
                editCategoryStatus: Object.assign({}, state.editCategoryStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_CATEGORY_ERROR: {
            return {
                ...state,
                editCategoryStatus: Object.assign({}, state.editCategoryStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_CATEGORY: {
            return {
                ...state,
                deleteCategoryStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_CATEGORY_SUCCESS: {
            return {
                ...state,
                deleteCategoryStatus: Object.assign({}, state.deleteCategoryStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_CATEGORY_ERROR: {
            return {
                ...state,
                deleteCategoryStatus: Object.assign({}, state.deleteCategoryStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_CATEGORY: {
            return {
                ...state,
                currentCategory: Object.assign({}, {
                    categoryName: '',
                    categoryId: '',
                    icon: '',
                    description: '',
                    appName: ''
                }),
                editingCategory: {
                    categoryName: '',
                    categoryId: '',
                    icon: '',
                    description: '',
                    appName: ''
                }
            }
        }

        case actionType.SELECT_EDIT_CATEGORY: {
            return {
                ...state,
                currentCategory: Object.assign({}, action.payload),
                editingCategory: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_CATEGORY: {
            return {
                ...state,
                currentCategory: Object.assign({}, state.currentCategory, { ...action.payload })
            }
        }

        case actionType.CLEAR_CATEGORY: {
            return {
                ...state,
                currentCategory: {
                    categoryName: '',
                    categoryId: '',
                    icon: '',
                    description: '',
                    appName: ''
                },
                editingCategory: {
                    categoryName: '',
                    categoryId: '',
                    icon: '',
                    description: '',
                    appName: ''
                }
            }
        }
        case actionType.PUBLISH_CATEGORY: {
            return {
                ...state,
                publishCategoryStatus: Object.assign({}, { loading: true, success: false, error: false })
            }
        }
        case actionType.PUBLISH_CATEGORY_SUCCESS: {
            return {
                ...state,
                publishCategoryStatus: Object.assign({}, { loading: false, success: true, error: false })
            }
        }
        case actionType.PUBLISH_CATEGORY_FAILURE: {
            return {
                ...state,
                publishCategoryStatus: Object.assign({}, { loading: false, success: false, error: true })
            }
        }

        case actionType.UPDATE_CATEGORY_DATA: {
            return {
                ...state,
                categoryList: { loading: false, categories: action.payload, success: true, error: false }
            }
        }
        default:
            return state
    }
}

export default manageCategoryReducer