import React, { useEffect, useState } from 'react'
import './ManageFact.scss'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux'
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import { getFactList, selectAddFact, selectEditFact, updateFactInfo, addFactList, editFact, deleteFact, clearSelectedFact, updateFactList } from '../../../actions/ManageFactAction';
import { showNotification } from '../../../actions/notificationAction'
import { IFact, IDeleteFactPayload, IFactError, IStatus } from '../../../types/ManageFact'
import { IStoreState } from '../../../types/StoreState'
import ManageFactForm from './ManageFactForm';
import { INotification } from '../../../types/notification';
import Table, { IAction, IColumn } from '../../shared/Table/Table';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { FilterService } from 'primereact/api';
import { isEqual } from '../../../util/arrayFunction';

interface IProps {
  updateBreadCrumb: (breadcrumb: any) => void,
  clearBreadCrumb: () => void,
  getFactList: () => void,
  facts: IFact[],
  selectAddFact: () => void,
  selectEditFact: (data: IFact) => void,
  addFact: (payload: IFact) => void,
  editFact: (payload: IFact) => void,
  deleteFact: (payload: IDeleteFactPayload) => void,
  clearSelectedFact: () => void,
  updateFactList: (data:any) => void 
  currentFact: IFact,
  editingFact?: IFact,
  addFactStatus: IStatus,
  editFactStatus: IStatus,
  deleteFactStatus: IStatus,
  showNotification: (notification: INotification) => void
}

const ManageFact = ({ updateBreadCrumb,
  facts,
  clearBreadCrumb,
  getFactList,
  selectAddFact,
  selectEditFact,
  addFact,
  editFact,
  deleteFact,
  clearSelectedFact,
  currentFact,
  updateFactList,
  editingFact,
  addFactStatus,
  editFactStatus,
  deleteFactStatus,
  showNotification }: IProps) => {
  const [displayManageFactAddDialog, setDisplayManageFactAddDialog] = useState<boolean>(false);
  const [displayManageFactDeleteDialog, setDisplayManageFactDeleteDialog] = useState<boolean>(false);
  const [formError, setFormError] = useState<IFactError>()
  
  const filterObject = {
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'question': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'value': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'query': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'isDynamic':{ operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }

  }

  useEffect(() => {
    const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'AllFact', url: '/' }]
    updateBreadCrumb(breadCrumb);
    getFactList();
    return () => {
      clearBreadCrumb();
      scrollAuto()
    };
  }, [])

  useEffect(() => {
    if (deleteFactStatus && !deleteFactStatus.loading && deleteFactStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Deleted Successfully',
        detail: `Fact deleted successfully.`, life: 2000
      }
      showNotification(notification)
      const newFactList = facts.filter((item:IFact) => item.id !== currentFact.id)
      clearSelectedFact();
      updateFactList(newFactList)
      setDisplayManageFactDeleteDialog(false);
    }
  }, [deleteFactStatus])

  useEffect(() => {
    if (editFactStatus && !editFactStatus.loading && editFactStatus.success) {
      const newFactList = facts.map((item:IFact) => item.id === currentFact.id ? currentFact : item )
      clearSelectedFact()
      updateFactList(newFactList)
      setDisplayManageFactAddDialog(false)
    }
  }, [editFactStatus])

  useEffect(() => {
    if (addFactStatus && !addFactStatus.loading && addFactStatus.success) {
      clearSelectedFact()
      getFactList()
      setDisplayManageFactAddDialog(false)
    }
  }, [addFactStatus])

  const scrollHidden = () => {
    document.body.style.overflow = 'hidden'
  }

  const scrollAuto = () => {
    document.body.style.overflow = 'auto'
  }

  const validForm = () => {
    let formErr: IFactError = {
      questionError: false,
      queryError:false
    }
    if (!currentFact.question) {
      formErr.questionError = true
    }
    if(currentFact.isDynamic && !currentFact.query)  {
      formErr.queryError=true
    }
  
    setFormError(formErr)

    for(let item of Object.values(formErr) ){
      if(item === true)
      return false;
    }

    return  true;
  }

  const addNewFact = () => {
    selectAddFact();
    setDisplayManageFactAddDialog(true)
    scrollHidden();
  }


  const save = () => {
    if (!validForm()) return
    if (editingFact) {
      if(isEqual(currentFact , editingFact)) {
        clearSelectedFact();
        setDisplayManageFactAddDialog(false)
        scrollAuto()
        return 
      }
      
      editFact(currentFact)
      scrollAuto();
      return
    }
    addFact(currentFact)
    scrollAuto();
  }

  const cancel = () => {
    clearSelectedFact()
    setDisplayManageFactAddDialog(false);
    let formErr: IFactError = {
      questionError: false,
      queryError:false
    }
    setFormError(formErr);
    scrollAuto();
  }

  //for delete feature


  const onDeleteFact = (fact: any) => {
    selectEditFact(fact)
    setDisplayManageFactDeleteDialog(true);
  }

  const onDeleteFactReject = () => {
    clearSelectedFact()
    setDisplayManageFactDeleteDialog(false);
  }

  const onDeleteFactProceed = () => {
    deleteFact({ id: currentFact.id })
  }

  //for edit feature 
  const onEditFact = (fact: IFact) => {
    selectEditFact(fact)
    setDisplayManageFactAddDialog(true)
    scrollHidden();
  }


  const renderDialogFooter = () => {
    return (
      <div className=''>
        <button className='me-3 btn btn-danger' onClick={cancel}> <span className='mdi mdi-close'></span> Cancel</button>
        <button className='btn btn-success' onClick={save}>Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
      </div>
    )

  }

  const renderDeleteDialogFooter = () => {
    return (
      <div className='d-flex justify-content-center'>
        <button className='me-3 btn btn-danger' onClick={onDeleteFactReject}>  No </button>
        <button className='btn btn-success' onClick={onDeleteFactProceed}> Yes </button>
      </div>
    )
  }

  const columns: IColumn[] = [{
    field: 'question',
    header: 'Question',
    applyFilter: true
  },
  {
    field: 'query',
    header: 'Query',
    applyFilter: true
  },
  {
    field: 'value',
    header: 'Value',
    applyFilter: true
  },
  {
    field: 'isDynamic',
    header: 'Dynamic',
    applyFilter: true
  }

]

  const action: IAction[] = [{
    action: onEditFact,
    cssClass: 'text-secondary mdi mdi-pencil',
    title: 'Edit',
    header: ''
  }, {
    action: onDeleteFact,
    cssClass: 'text-danger mdi mdi-delete',
    title: 'Delete',
    header: ''
  }]


  return (
    <div className=' manage-fact container mt-3 d-flex justify-content-center'>
      <div className='shadow-sm p-2'>
        <Dialog draggable={false} header={<h4 className='dialog-heading'>Please Fill The Below Details To Create New Fact</h4>} visible={displayManageFactAddDialog} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} footer={renderDialogFooter()} onHide={cancel} >
          {currentFact ? <ManageFactForm formError={formError}></ManageFactForm> : null}
        </Dialog>

        <Dialog draggable={false} header='' visible={displayManageFactDeleteDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={onDeleteFactReject} >
          <div className='dialog-heading text-center'>
            <h4 className='text-danger'>Do You Really Want To Delete this Fact </h4>
          </div>
        </Dialog>
        <div className='d-flex justify-content-between align-items-center p-2'>
          <h3 className='text-global-custom fw-bold '>All Fact</h3>
          <button className='btn btn-success' onClick={() => addNewFact()}><span className='mdi mdi-plus l3-font-size'></span> Add Fact</button>
        </div>
        <Table data={facts} filterObject={filterObject} column={columns} actions={action} paginator={true} ></Table>

      </div>
    </div>

  )
}

const mapStateToProps = (state: IStoreState) => ({
  facts: state.manageFact.factList.facts,
  currentFact: state.manageFact.currentFact,
  editingFact: state.manageFact.editingFact,
  addFactStatus: state.manageFact.addFactStatus,
  editFactStatus: state.manageFact.editFactStatus,
  deleteFactStatus: state.manageFact.deleteFactStatus
})


const mapDispatchToProps = (dispatch: any) => ({
  updateBreadCrumb: (data: any) => {
    dispatch(updateBreadCrumb(data));
  },
  clearBreadCrumb: () => {
    dispatch(clearBreadCrumb())
  },
  getFactList: () => {
    dispatch(getFactList())
  },
  selectAddFact: () => {
    dispatch(selectAddFact())
  },
  selectEditFact: (data: IFact) => {
    dispatch(selectEditFact(data))
  },
  updateFactInfo: (data: any) => {
    dispatch(updateFactInfo(data))
  },
  addFact: (payload: IFact) => {
    dispatch(addFactList(payload))
  },
  editFact: (payload: IFact) => {
    dispatch(editFact(payload))
  },
  deleteFact: (payload: IDeleteFactPayload) => {
    dispatch(deleteFact(payload))
  },
  clearSelectedFact: () => {
    dispatch(clearSelectedFact())
  },
  showNotification: (notification: INotification) => {
    dispatch(showNotification(notification))
  },
  updateFactList: (data:any) => {
    dispatch(updateFactList(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageFact);