const actionType = {
    GET_USER_ALL_COMPANY_LIST: 'GET_USER_ALL_COMPANY_LIST',
    GET_USER_ALL_COMPANY_LIST_SUCCESS: 'GET_USER_ALL_COMPANY_LIST_SUCCESS',
    GET_USER_ALL_COMPANY_LIST_ERROR: 'GET_USER_ALL_COMPANY_LIST_ERROR',
    GET_USER_ALL_PENDING_COMPANY_LIST: 'GET_USER_ALL_PENDING_COMPANY_LIST',
    GET_USER_ALL_PENDING_COMPANY_LIST_SUCCESS: 'GET_USER_ALL_PENDING_COMPANY_LIST_SUCCESS',
    GET_USER_ALL_PENDING_COMPANY_LIST_ERROR: 'GET_USER_ALL_PENDING_COMPANY_LIST_ERROR',
    ADD_NEW_COMPANY_STATUS: 'ADD_NEW_COMPANY_STATUS',
    ADD_NEW_COMPANY_STATUS_SUCCESS: 'ADD_NEW_COMPANY_STATUS_SUCCESS',
    ADD_NEW_COMPANY_STATUS_ERROR: 'ADD_NEW_COMPANY_STATUS_ERROR',
    IS_SUPER_DEPARTMENT_STATUS: 'IS_SUPER_DEPARTMENT_STATUS',
    IS_SUPER_DEPARTMENT_STATUS_SUCCESS: 'IS_SUPER_DEPARTMENT_STATUS_SUCCESS',
    IS_SUPER_DEPARTMENT_STATUS_ERROR: 'IS_SUPER_DEPARTMENT_STATUS_ERROR',
    UPDATE_CURRENT_REQUEST: 'UPDATE_CURRENT_REQUEST',
    CLEAR_SMART_DE_STATE: 'CLEAR_SMART_DE_STATE',
    GET_PENDING_SUPER_USER: 'GET_PENDING_SUPER_USER',
    GET_PENDING_SUPER_USER_SUCCESS: 'GET_PENDING_SUPER_USER_SUCCESS',
    GET_PENDING_SUPER_USER_ERROR: 'GET_PENDING_SUPER_USER_ERROR',
    GET_APPROVED_SUPER_USER: 'GET_APPROVED_SUPER_USER',
    GET_APPROVED_SUPER_USER_SUCCESS: 'GET_APPROVED_SUPER_USER_SUCCESS',
    GET_APPROVED_SUPER_USER_ERROR: 'GET_APPROVED_SUPER_USER_ERROR',
    UPDATE_SUPER_USER_REQUEST: 'UPDATE_SUPER_USER_REQUEST',
    UPDATE_SUPER_USER_REQUEST_SUCCESS: 'UPDATE_SUPER_USER_REQUEST_SUCCESS',
    UPDATE_SUPER_USER_REQUEST_ERROR: 'UPDATE_SUPER_USER_REQUEST_ERROR',
    GET_PENDING_COMPANY_USER: 'GET_PENDING_COMPANY_USER',
    GET_PENDING_COMPANY_USER_SUCCESS: 'GET_PENDING_COMPANY_USER_SUCCESS',
    GET_PENDING_COMPANY_USER_ERROR: 'GET_PENDING_COMPANY_USER_ERROR',
    GET_APPROVED_COMPANY_USER: 'GET_APPROVED_COMPANY_USER',
    GET_APPROVED_COMPANY_USER_SUCCESS: 'GET_APPROVED_COMPANY_USER_SUCCESS',
    GET_APPROVED_COMPANY_USER_ERROR: 'GET_APPROVED_COMPANY_USER_ERROR',
    UPDATE_COMPANY_USER_REQUEST: 'UPDATE_COMPANY_USER_REQUEST',
    UPDATE_COMPANY_USER_REQUEST_SUCCESS: 'UPDATE_COMPANY_USER_REQUEST_SUCCESS',
    UPDATE_COMPANY_USER_REQUEST_ERROR: 'UPDATE_COMPANY_USER_REQUEST_ERROR',
    CLEAR_ACTION_STATUS: 'CLEAR_ACTION_STATUS',
    UPDATE_PENDING_COMPANY_USER: 'UPDATE_PENDING_COMPANY_USER',
    UPDATE_APPROVED_COMPANY_USER: 'UPDATE_APPROVED_COMPANY_USER',
    FILTER_SMART_DE_COMPANY: 'FILTER_SMART_DE_COMPANY',
}
export default actionType