import { ITimesheetProjectReducer } from "../types/Timesheet";
import actionType from '../actions/TimesheetProjectActionTypes'

const initialState: ITimesheetProjectReducer = {
    projects: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    userProjects: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentProject: {
        projectName: ''
    }
}

const TimesheetProjectReducer = (state = initialState, action: any): ITimesheetProjectReducer => {
    switch (action.type) {
        case actionType.GET_PROJECT_LIST: {
            return {
                ...state,
                projects: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_PROJECT_LIST_SUCCESS: {
            return {
                ...state,
                projects: Object.assign({}, state.projects, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_PROJECT_LIST_ERROR: {
            return {
                ...state,
                projects: Object.assign({}, state.projects, { loading: false, error: true, success: false, list: [] })

            }
        }

        case actionType.GET_USER_PROJECT_LIST: {
            return {
                ...state,
                userProjects: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_USER_PROJECT_LIST_SUCCESS: {
            return {
                ...state,
                userProjects: Object.assign({}, state.userProjects, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_PROJECT_LIST_ERROR: {
            return {
                ...state,
                userProjects: Object.assign({}, state.userProjects, { loading: false, error: true, success: false, list: [] })

            }
        }
        case actionType.ADD_PROJECT: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_PROJECT_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_PROJECT_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_PROJECT: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_PROJECT_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_PROJECT_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_PROJECT: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_PROJECT_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_PROJECT_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_PROJECT: {
            return {
                ...state,
                currentProject: {
                    projectName: ''
                }
            }
        }

        case actionType.SELECT_EDIT_PROJECT: {
            return {
                ...state,
                currentProject: Object.assign({}, action.payload),
                editProject: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_PROJECT: {
            return {
                ...state,
                currentProject: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_PROJECT: {
            delete state['editProject'];
            return {
                ...state,
                currentProject: {
                    projectName: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
            }
        }
        default:
            return state
    }
}

export default TimesheetProjectReducer;