import { Editor } from 'primereact/editor'
import { useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import './FieldCommon.scss';


interface ITextEditor {
    value: any,
    update: (name: string, value: string, displayLabel: string, valueLabels?: any) => void,
    label: string,
    name: string,
    error?: boolean,
    maxLimit?: number,
    disabled?: boolean,
    noImage?: boolean
}

const TextEditor = forwardRef(({ error, disabled, maxLimit, name, label, value, update, noImage }: ITextEditor, ref) => {
    const editorRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
        isValid: () => {
            if (editorRef && editorRef.current) {
                let current: any = editorRef.current;
                const text = current.getQuill().getText().trim();
                if (text.length > 0) return true;

                if (current.getQuill().getContents() && current.getQuill().getContents().ops) {
                    let image = current.getQuill().getContents().ops.find((item: any) => {
                        if (item && item.insert && typeof item.insert === 'object') {
                            let keys = Object.keys(item.insert)
                            return keys.includes('image')
                        }
                        return false
                    })
                    return image
                }
                return false
            }
        },
        getStoreBody: () => {
            if (editorRef && editorRef.current) {
                let current: any = editorRef.current;
                let text = current.getQuill().getText()
                if (!text.trim()) {
                    text = 'Email contain image only';
                }
                return text
            }
        }
    }));

    useEffect(() => {
        let i: any = document.querySelector('input.ql-image')
        if (i) {
            i.accept = 'image/png, image/jpeg'
        }
    }, []);

    const onUpdate = (e: any) => {
        update(name, e.htmlValue, label);
    }

    const bindings = {
        tab: {
            key: 'tab',
            handler: function (range: any, context: any) {
                editorRef.current.getQuill().insertText(range, '\t')
            }
        }
    }

    const getToolbarOptions = () => {
        if (noImage) {
            return toolbarOptions;
        }
        else {
            toolbarOptions.push(['image'])
            return toolbarOptions;
        }
    }


    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }],      // superscript/subscript

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }]     // dropdown with defaults from theme

        // remove formatting button
    ];

    const modules = {
        toolbar: getToolbarOptions(),
        keyboard: {
            bindings: bindings
        }
    }


    return (
        <Editor ref={editorRef} modules={modules} style={{ 'height': '250px' }} className={`text-editor ${error ? ' invalid' : ''}`} value={value} onTextChange={onUpdate} placeholder={`Enter ${label}`} readOnly={disabled} maxLength={maxLimit} />
    )
})

export default TextEditor;