import { IRequestReducer } from '../types/viewRequest';
import actionTypes from '../actions/viewPendingRequestActionTypes'
const intialState: IRequestReducer = {
    requestData: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    actionStatus: {
        loading: false,
        success: false,
        error: false,
        data: null
    }
}

const viewRequestReducer = (state = intialState, action: any) => {
    switch (action.type) {
        case actionTypes.FETCH_REQUESTS: {
            return {
                ...state,
                requestData: Object.assign({}, state.requestData, { loading: true })
            }
        }
        case actionTypes.FETCH_REQUESTS_SUCCESS: {
            return {
                ...state,
                requestData: Object.assign({}, state.requestData, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.FETCH_REQUESTS_ERROR: {
            return {
                ...state,
                requestData: Object.assign({}, state.requestData, { loading: false, success: false, data: action.payload, error: true })
            }
        }

        case actionTypes.START_ACTION: {
            return {
                ...state,
                actionStatus: Object.assign({}, state.requestData, { loading: true })
            }
        }
        case actionTypes.ACTION_SUCCESS: {
            return {
                ...state,
                actionStatus: Object.assign({}, state.requestData, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.ACTION_ERROR: {
            return {
                ...state,
                actionStatus: Object.assign({}, state.requestData, { loading: false, success: false, data: action.payload, error: true })
            }
        }

        case actionTypes.CLEAR_PI_REQUEST: {
            return {
                ...state,
                actionStatus: {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                }
            }
        }

        case actionTypes.UPDATE_REQUEST: {
            return {
                ...state,
                requestData: { loading: false, success: true, data: action.payload, error: false }
            }
        }

        default:
            return state
    }
}

export default viewRequestReducer