const actionTypes = {
        UPDATE_CURRENT_CATEGORY:'CUSTOM_DASHBOARD_UPDATE_CURRENT_CATEGORY',
        UPDATE_CURRENT_CUSTOM_DASHBOARD_REPORT:'UPDATE_CURRENT_CUSTOM_DASHBOARD_REPORT',
        GET_CATEGORY_REPORTS:'GET_CATEGORY_REPORTS_CUSTOM_DASHBOARD',
        GET_CATEGORY_REPORTS_SUCCESS:'GET_CATEGORY_REPORTS_CUSTOM_DASHBOARD_SUCCESS',
        GET_CATEGORY_REPORTS_FAILURE:'GET_CATEGORY_REPORTS_CUSTOM_DASHBOARD_FAILURE',
        CLEAR_CUSTOM_DASHBOARD_REPORT_STATE:'CLEAR_CUSTOM_DASHBOARD_REPORT_STATE'
        
}

export default actionTypes