import { error } from "console";
import SmartUsageActionTypes from "src/actions/SmartUsageActionTypes";
import { ISmartUsage } from "src/types/SmartUsage";

const initialState: ISmartUsage = {
  userCountByMonth: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  userCountByQuarter: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  reportWiseMatrixByQuarter: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  departmentWiseMatrixByMonth: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
  departmentWiseMatrixByQuarter: {
    loading: false,
    error: false,
    success: false,
    data: [],
  }
};

const smartUsageReducer = (state = initialState, action: any): ISmartUsage => {
  switch (action.type) {
    case SmartUsageActionTypes.GET_USER_COUNT_BY_MONTH:
      return {
        ...state,
        userCountByMonth: {
          ...state.userCountByMonth,
          loading: true,
          error: false,
          success: false,
        },
      };
    case SmartUsageActionTypes.GET_USER_COUNT_BY_MONTH_SUCCESS:
      return {
        ...state,
        userCountByMonth: {
          loading: false,
          error: false,
          success: true,
          data: action.payload,
        },
      };
    case SmartUsageActionTypes.GET_USER_COUNT_BY_MONTH_ERROR:
      return {
        ...state,
        userCountByMonth: {
          loading: false,
          error: true,
          success: false,
          data: [],
        },
      };

    case SmartUsageActionTypes.GET_USER_COUNT_BY_QUARTER:
      return {
        ...state,
        userCountByQuarter: {
          ...state.userCountByQuarter,
          loading: true,
          error: false,
          success: false,
        },
      };
    case SmartUsageActionTypes.GET_USER_COUNT_BY_QUARTER_SUCCESS:
      return {
        ...state,
        userCountByQuarter: {
          loading: false,
          error: false,
          success: true,
          data: action.payload,
        },
      };

    case SmartUsageActionTypes.GET_USER_COUNT_BY_QUARTER_ERROR:
      return {
        ...state,
        userCountByQuarter: {
          loading: false,
          error: true,
          success: false,
          data: [],
        },
      };

    case SmartUsageActionTypes.GET_REPORT_WISE_QUARTER_MATRIX:
      return {
        ...state,
        reportWiseMatrixByQuarter: {
          ...state.reportWiseMatrixByQuarter,
          loading: true,
          error: false,
          success: false
        }
      }

    case SmartUsageActionTypes.GET_REPORT_WISE_QUARTER_MATRIX_SUCCESS:
      return {
        ...state,
        reportWiseMatrixByQuarter: {
          loading: false,
          error: false,
          success: true,
          data: action.payload
        }
      }

    case SmartUsageActionTypes.GET_REPORT_WISE_QUARTER_MATRIX_ERROR:
      return {
        ...state,
        reportWiseMatrixByQuarter: {
          loading: false,
          error: true,
          success: false,
          data: []
        }
      }

    case SmartUsageActionTypes.CLEAR_SMART_USAGE:
      return {
        ...state,
        userCountByMonth: {
          loading: false,
          error: false,
          success: false,
          data: [],
        },
        userCountByQuarter: {
          loading: false,
          error: false,
          success: false,
          data: [],
        },
        reportWiseMatrixByQuarter: {
          loading: false,
          error: false,
          success: false,
          data: []
        }
      }

    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_MONTH_MATRIX:
      return {
        ...state,
        departmentWiseMatrixByMonth: {
          ...state.departmentWiseMatrixByMonth,
          loading: true,
          error: false,
          success: false
        }
      }
    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_MONTH_MATRIX_SUCCESS:
      return {
        ...state,
        departmentWiseMatrixByMonth: {
          loading: false,
          error: false,
          success: true,
          data: action.payload
        }
      }
    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_MONTH_MATRIX_ERROR:
      return {
        ...state,
        departmentWiseMatrixByMonth: {
          loading: false,
          error: true,
          success: false,
          data: []
        }
      }

    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_QUARTER_MATRIX:
      return {
        ...state,
        departmentWiseMatrixByQuarter: {
          ...state.departmentWiseMatrixByQuarter,
          loading: true,
          error: false,
          success: false
        }
      }
    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_QUARTER_MATRIX_SUCCESS:
      return {
        ...state,
        departmentWiseMatrixByQuarter: {
          loading: false,
          error: false,
          success: true,
          data: action.payload
        }
      }
    case SmartUsageActionTypes.GET_DEPARTMENT_WISE_QUARTER_MATRIX_ERROR:
      return {
        ...state,
        departmentWiseMatrixByQuarter: {
          loading: false,
          error: true,
          success: false,
          data: []
        }
      }
    default:
      return state;
  }
};

export default smartUsageReducer;
