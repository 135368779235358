import actionTypes from './ManageNotificationsActionTypes'
import { ManageNotificationsApi } from '../api/manageNotificationsApi';
import { IUserNotification } from '../types/ManageNotification'
import moment from 'moment';
const api = new ManageNotificationsApi()

export const getNotificationsList = () => {
    return api.getNotificationsList({}, _getNotificationsList, getNotificationsListSuccess, getNotificationsListFailure)
}

const _getNotificationsList = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_LIST,
    }
}

export const getNotificationsListSuccess = (data: any) => {
    const payload = data.data.map((item: any) => {
        item.startDate = moment(item.startDate).format('YYYY-MM-DD');
        item.endDate = moment(item.endDate).format('YYYY-MM-DD');
        return item
    })
    return {
        type: actionTypes.GET_NOTIFICATIONS_LIST_SUCCESS,
        payload: payload
    }
}

export const getNotificationsListFailure = (data: any) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_LIST_ERROR,
        payload: data
    }
}

export const addNotificationsList = (payload: IUserNotification) => {
    return api.addNewNotifications(payload, _addNotificationsList, addNotificationsListSuccess, addNotificationsListFailure)
}

const _addNotificationsList = () => {
    return {
        type: actionTypes.ADD_NOTIFICATIONS,
    }
}

export const addNotificationsListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_NOTIFICATIONS_SUCCESS,
        payload: data
    }
}

export const addNotificationsListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_NOTIFICATIONS_ERROR,
        payload: data
    }
}

export const editNotifications = (payload: IUserNotification) => {
    return api.editNotifications(payload, _editNotifications, editNotificationsSuccess, editNotificationsFailure)
}

const _editNotifications = () => {
    return {
        type: actionTypes.EDIT_NOTIFICATIONS,
    }
}

export const editNotificationsSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_NOTIFICATIONS_SUCCESS,
        payload: data.users
    }
}

export const editNotificationsFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_NOTIFICATIONS_ERROR,
        payload: data
    }
}

export const deleteNotifications = (payload: { id: any }) => {
    return api.deleteNotifications(payload, _deleteNotifications, deleteNotificationsSuccess, deleteNotificationsFailure)
}

const _deleteNotifications = () => {
    return {
        type: actionTypes.DELETE_NOTIFICATIONS,
    }
}

export const deleteNotificationsSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_NOTIFICATIONS_SUCCESS,
        payload: data
    }
}

export const deleteNotificationsFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_NOTIFICATIONS_ERROR,
        payload: data
    }
}

export const selectAddNotifications = () => {
    return {
        type: actionTypes.SELECT_ADD_NOTIFICATIONS,
    }
}

export const selectEditNotifications = (data: IUserNotification) => {
    return {
        type: actionTypes.SELECT_EDIT_NOTIFICATIONS,
        payload: data
    }
}

export const updateNotificationsInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATIONS,
        payload: data
    }
}

export const clearSelectedNotifications = () => {
    return {
        type: actionTypes.CLEAR_NOTIFICATIONS
    }
}

export const updateNotificationsList = (data: IUserNotification[]) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATIONS_LIST,
        payload: data
    }
}
