import actionTypes from './tableauReportActionTypes'
import { TableauTokenApi } from '../api/tableauReportApi';
import TokenService from '../api/token.service'
import store from '../store/store'

import { ILogFilterPayload } from '../types/tableauReport';
import tokenService from '../api/token.service';

const api = new TableauTokenApi()

export const deleteToken = () => {
    const token = TokenService.getTableauToken();
    if (token) {
        return api.deleteToken(_deleteToken, deleteTokenSuccess, deleteTokenFailure)
    } else {
        return _deleteToken
    }
}

const _deleteToken = () => {
    return {
        type: actionTypes.DELETE_TOKEN,
    }
}

export const deleteTokenSuccess = (data: any) => {
    TokenService.deleteTableauToken()
    return {
        type: actionTypes.DELETE_TOKEN_SUCCESS,
        payload: data.data
    }
}

export const deleteTokenFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_TOKEN_ERROR,
        payload: data
    }
}



export const updateToken = (data: any) => {
    TokenService.setTableauToken(data)
    return {
        type: actionTypes.UPDATE_TOKEN,
        payload: data
    }
}

export const logHistory = (payload: any) => {
    let p = { ...payload };
    p['department'] = store.getState().login.userInfo.data.department;
    p['office'] = store.getState().login.userInfo.data.office;
    p['region'] = store.getState().login.userInfo.data.region;
    return api.logViewHistory(p, _logHistory, logHistorySuccess, logHistoryFailure)
}

const _logHistory = () => {
    return {
        type: actionTypes.LOG_VIEW_HOSTORY,
    }
}

export const logHistorySuccess = (data: any) => {
    return {
        type: actionTypes.LOG_VIEW_HOSTORY_SUCCESS,
        payload: data.reportViewAuditId
    }
}

export const logHistoryFailure = (data: any) => {
    return {
        type: actionTypes.LOG_VIEW_HOSTORY_ERROR,
        payload: data
    }
}

export const logFilter = (payload: any) => {
    return api.logFilter(payload, _logFilter, logFilterSuccess, logFilterFailure)
}

const _logFilter = () => {
    return {
        type: actionTypes.LOG_APPLIED_FILTER,
    }
}

export const logFilterSuccess = (data: any) => {
    return {
        type: actionTypes.LOG_APPLIED_FILTER_SUCCESS,
        payload: data
    }
}

export const logFilterFailure = (data: any) => {
    return {
        type: actionTypes.LOG_APPLIED_FILTER_ERROR,
        payload: data
    }
}

export const checkTokenStatus = () => {
    return api.deleteToken(_checkTokenStatus, checkTokenStatus, checkTokenStatus)
}

const _checkTokenStatus = () => {
    return {
        type: actionTypes.CHECK_TOKEN_STATUS,
    }
}

export const checkTokenStatusSuccess = (data: any) => {
    return {
        type: actionTypes.CHECK_TOKEN_STATUS_SUCCESS,
        payload: data.data
    }
}

export const checkTokenStatusFailure = (data: any) => {
    TokenService.deleteTableauToken()
    return {
        type: actionTypes.CHECK_TOKEN_STATUS_ERROR,
        payload: data
    }
}

export const downloadReport = (payload: any) => {
    return api.downloadReport(payload, _downloadReport, downloadReportSuccess, downloadReportFailure)
}

const _downloadReport = () => {
    return {
        type: actionTypes.DOWNLOAD_REPORT,
    }
}

export const downloadReportSuccess = (data: any) => {
    return {
        type: actionTypes.DOWNLOAD_REPORT_SUCCESS,
        payload: data
    }
}

export const downloadReportFailure = (data: any) => {
    return {
        type: actionTypes.DOWNLOAD_REPORT_ERROR,
        payload: data
    }
}

export const clearDownloadStatus = () => {
    return {
        type: actionTypes.CLEAR_DOWNLOAD_STATUS
    }
}

export const clearLogViewHistory = () => {
    return {
        type: actionTypes.CLEAR_LOG_VIEW_HISTORY
    }
}

export const seacuredownloadRequest = (payload: any) => {
    return api.seacuredownloadRequest(payload, _seacuredownloadRequest, seacuredownloadRequestSuccess, seacuredownloadRequestFailure)
}

const _seacuredownloadRequest = () => {
    return {
        type: actionTypes.SECURE_DOWNLOAD_REQUEST,
    }
}

export const seacuredownloadRequestSuccess = (data: any) => {
    return {
        type: actionTypes.SECURE_DOWNLOAD_REQUEST_SUCCESS,
        payload: data
    }
}

export const seacuredownloadRequestFailure = (data: any) => {
    return {
        type: actionTypes.SECURE_DOWNLOAD_REQUEST_ERROR,
        payload: data
    }
}

export const clearSecureDownloadStatus = () => {
    return {
        type: actionTypes.CLEAR_SECURE_DOWNLOAD_REQUEST
    }
}