import { IReportRequest } from '../types/reportRequest';
import actionTypes from '../actions/reportRequestActionTypes'
const intialState: IReportRequest = {
    reportData: {
        justification: ''
    },
    requestStatus: {
        loading: false,
        success: false,
        error: false,
        data: null
    },
    hasAccess: {
        loading: false,
        success: false,
        error: false,
        data: null
    },
    isRequestPending: {
        loading: false,
        success: false,
        error: false,
        data: null
    }
}

const reportRequestReducer = (state = intialState, action: any): IReportRequest => {
    switch (action.type) {
        case actionTypes.SUBMIT_REQUEST: {
            return {
                ...state,
                requestStatus: Object.assign({}, state.requestStatus, { loading: true, error: false, success: false })
            }
        }
        case actionTypes.SUBMIT_REQUEST_SUCCESS: {
            return {
                ...state,
                requestStatus: Object.assign({}, state.requestStatus, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.SUBMIT_REQUEST_ERROR: {
            return {
                ...state,
                requestStatus: Object.assign({}, state.requestStatus, { loading: false, success: false, data: action.payload, error: true })
            }
        }
        case actionTypes.UPDATE_REQUEST_DATA: {
            return {
                ...state,
                reportData: Object.assign({}, state.reportData, { ...action.payload })
            }
        }

        case actionTypes.CLEAR_DATA: {
            return Object.assign({}, {
                reportData: {
                    justification: ''
                },
                requestStatus: {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                },
                hasAccess: {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                },
                isRequestPending: {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                }
            });
        }

        case actionTypes.CHECK_HAS_ACCESS: {
            return {
                ...state,
                hasAccess: Object.assign({}, state.hasAccess, { loading: true })
            }
        }
        case actionTypes.CHECK_HAS_ACCESS_SUCCUESS: {
            return {
                ...state,
                hasAccess: Object.assign({}, state.hasAccess, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.CHECK_HAS_ACCESS_ERROR: {
            return {
                ...state,
                hasAccess: Object.assign({}, state.hasAccess, { loading: false, success: false, data: action.payload, error: true })
            }
        }

        case actionTypes.CHECK_REQUEST_STATUS: {
            return {
                ...state,
                isRequestPending: Object.assign({}, state.isRequestPending, { loading: true, success: false })
            }
        }
        case actionTypes.CHECK_REQUEST_STATUS_SUCCESS: {
            return {
                ...state,
                isRequestPending: Object.assign({}, state.isRequestPending, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.CHECK_REQUEST_STATUS_ERROR: {
            return {
                ...state,
                isRequestPending: Object.assign({}, state.isRequestPending, { loading: false, success: false, data: action.payload, error: true })
            }
        }

        case actionTypes.CLEAR_HAS_ACCESS: {
            return {
                ...state,
                hasAccess: {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                }
            }
        }

        default:
            return state
    }
}

export default reportRequestReducer