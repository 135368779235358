import actionTypes from "../actions/CaReportActionType"
import { IClientAnalyticsReport } from "../types/CaReport"
import { IStoreState } from "../types/StoreState"


const intialState:IClientAnalyticsReport =  {
       allCategoryReports: {
          loading:false,
          success:false,
          error:false,
          data:[]
       },
       currentCaReport: {
        name: '',
        id: '',
        description: '',
        internalUrl: '',
        externalUrl:'',
        categoryId: '',
        status: '',
        roles: ''
       },
       currentCategory : {
         categoryName:'',
         categoryId:'',
         icon:'',
         description:'',
         reports:[]
       }
} 


const caReportReducer = (state = intialState , action:any):IClientAnalyticsReport => {
            switch(action.type) {
                case actionTypes.GET_CATEGORY_REPORTS : {
                    return {
                        ...state,
                        allCategoryReports: {loading:true ,success:false , error:false , data:[]}
                    }
                }
                case actionTypes.GET_CATEGORY_REPORTS_SUCCESS : {
                    return {
                        ...state,
                        allCategoryReports: {loading:false ,success:true , error:false , data:action.payload}
                    }
                }
                case actionTypes.GET_CATEGORY_REPORTS_FAILURE : {
                    return {
                        ...state,
                        allCategoryReports: {loading:false ,success:false , error:true , data:[]}
                    }
                }
                case actionTypes.UPDATE_CURRENT_CATEGORY : {
                    return {
                        ...state,
                        currentCategory : action.payload
                    }
                }
                case actionTypes.UPDATE_CURRENT_CA_REPORT : {
                    return {
                        ...state,
                        currentCaReport : action.payload
                    }
                }
                case actionTypes.CLEAR_CA_REPORT_STATE : {
                    return {
                        ...state,
                        currentCaReport: {
                            name: '',
                            id: '',
                            description: '',
                            internalUrl: '',
                            externalUrl:'',
                            categoryId: '',
                            status: '',
                            roles: ''
                           },
                           currentCategory : {
                             categoryName:'',
                             categoryId:'',
                             icon:'',
                             description:'',
                             reports:[]
                           }
                    }
                }
                default : {
                    return state
                }
            }  
}

export default caReportReducer;