const actionType = {
    GET_USER_LIST: 'GET_USER_LIST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_ERROR: 'GET_USER_LIST_ERROR',
    ADD_USER: 'ADD_USER',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_ERROR: 'ADD_USER_ERROR',
    EDIT_USER: 'EDIT_USER',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_ERROR: 'EDIT_USER_ERROR',
    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',
    SELECT_ADD_USER: 'SELECT_ADD_USER',
    SELECT_EDIT_USER: 'SELECT_EDIT_USER',
    UPDATE_USER: 'UPDATE_USER',
    CLEAR_USER: 'CLEAR_USER',
    UPDATE_USER_LIST:'UPDATE_USER_LIST'
}

export default actionType