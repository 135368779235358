import { ITimesheetMembersReducer } from "../types/timesheetUser";
import actionType from '../actions/TimesheetUsersActionTypes';

const initialState: ITimesheetMembersReducer = {
    users: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    missingUsers: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentUser: {
        userId: '',
        userName: '',
        empId: 0,
        empType: '',
        deptCode: '',
        lead: '',
        hoursRequired: 45,
        country: ''
    },
    markResignedActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to manage the state of bulk upload initiative action
    bulkUploadActionStatus: {
        loading: false,
        success: false,
        error: false
    },
}

const TimesheetUsersReducer = (state = initialState, action: any): ITimesheetMembersReducer => {
    switch (action.type) {
        case actionType.GET_USERS_LIST: {
            return {
                ...state,
                users: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_USERS_LIST_SUCCESS: {
            return {
                ...state,
                users: Object.assign({}, state.users, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USERS_LIST_ERROR: {
            return {
                ...state,
                users: Object.assign({}, state.users, { loading: false, error: true, success: false, list: [] })

            }
        }
        case actionType.ADD_USERS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_USERS_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_USERS_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_USERS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_USERS_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_USERS_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_USERS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_USERS_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_USERS_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_USERS: {
            return {
                ...state,
                currentUser: {
                    userId: '',
                    userName: '',
                    empId: 0,
                    empType: '',
                    deptCode: '',
                    lead: '',
                    hoursRequired: 45,
                    country: ''
                }
            }
        }

        case actionType.SELECT_EDIT_USERS: {
            return {
                ...state,
                currentUser: Object.assign({}, action.payload),
                editUser: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_USERS: {
            return {
                ...state,
                currentUser: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_USERS: {
            delete state['editUser'];
            return {
                ...state,
                currentUser: {
                    userId: '',
                    userName: '',
                    empId: 0,
                    empType: '',
                    deptCode: '',
                    lead: '',
                    hoursRequired: 45,
                    country: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                markResignedActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                bulkUploadActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }
        case actionType.GET_MISSING_USERS_LIST: {
            return {
                ...state,
                missingUsers: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_MISSING_USERS_LIST_SUCCESS: {
            return {
                ...state,
                missingUsers: Object.assign({}, state.users, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_MISSING_USERS_LIST_ERROR: {
            return {
                ...state,
                missingUsers: Object.assign({}, state.users, { loading: false, error: true, success: false, list: [] })
            }
        }



        case actionType.BULK_UPLOAD_USER: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.BULK_UPLOAD_USER_SUCCESS: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, state.bulkUploadActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.BULK_UPLOAD_USER_ERROR: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, state.bulkUploadActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.MARK_RESIGNED_USER: {
            return {
                ...state,
                markResignedActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.MARK_RESIGNED_USER_SUCCESS: {
            return {
                ...state,
                markResignedActionStatus: Object.assign({}, state.markResignedActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.MARK_RESIGNED_USER_ERROR: {
            return {
                ...state,
                markResignedActionStatus: Object.assign({}, state.markResignedActionStatus, { loading: false, error: true, success: false })

            }
        }
        default:
            return state
    }
}

export default TimesheetUsersReducer;