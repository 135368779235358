import { Carousel } from "primereact/carousel";
import { useEffect, useState } from "react";
import { clearBreadCrumb, updateBreadCrumb } from "../../../actions/BreadCrumbAction";
import { IBreadCrumbData } from "../../../types/BreadCrumb";
import QuizField from "../../shared/QuizField/QuizField";
import { connect } from 'react-redux'
import './Quiz.scss'
import { IStoreState } from "../../../types/StoreState";
import { IGeneratedQuiz } from "../../../types/ManageQuiz";
import { getAllGeneratedQuizList } from "../../../actions/ManageQuizAction";

interface IProps {
    updateBreadCrumb: (data: IBreadCrumbData[]) => void,
    clearBreadCrumb: () => void,
    generatedQuizs: IGeneratedQuiz[],
    getAllGeneratedQuizList: () => void
}

const Quiz = ({ updateBreadCrumb, clearBreadCrumb, generatedQuizs, getAllGeneratedQuizList }: IProps) => {
    const [qans, setQans] = useState<any[]>([]);
    const [score, setScore] = useState<number>(0);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [quizs, setQuestions] = useState<IGeneratedQuiz[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'Quiz', url: '/' }];
        updateBreadCrumb(breadCrumb);
        getAllGeneratedQuizList();

        return () => {
            setQans([]);
            setIsCompleted(false);
            setScore(0);
            clearBreadCrumb()
        }
    }, [])

    useEffect(() => {
        getTopFiveQuestions();
        generateRandomQuestions();
    }, [generatedQuizs])

    const onUpdate = (question: string, value: string, index: number) => {
        const _qans = [...qans];
        _qans[index] = { question: question, answer: value };
        setQans(_qans);
    }

    const getTopFiveQuestions = () => {
        let _quizs: IGeneratedQuiz[] = [];
        for (let i = 0; i < Math.min(generatedQuizs.length, 5); i++) {
            _quizs.push(generatedQuizs[i]);
        }
        setQuestions(_quizs);
    }

    const generateRandomQuestions = () => {
        let _quizs: IGeneratedQuiz[] = [];
        let max = generatedQuizs.length;
        while (_quizs.length < Math.min(generatedQuizs.length, 5)) {
            let index = Math.floor(Math.random() * max);
            if (_quizs.indexOf(generatedQuizs[index]) === -1)
                _quizs.push(generatedQuizs[index]);
        }
        setQuestions(_quizs);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        let score = 0;
        quizs.forEach((item: IGeneratedQuiz, index: any) => {
            if (qans[index] && qans[index].answer == item.answer)
                score++;
        })

        setScore(score);
        setIsCompleted(true);

    }


    const getStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(<span className={`mdi star-icon mdi-star ${i <= score ? 'scored' : 'unscored'}`}></span>)
        }
        return stars
    }


    return (
        <div className="w-100 quiz-parent py-4">
            {isCompleted ? <div className="d-flex w-100 h-100 align-items-center justify-content-center  py-4">
                <div className="result-container shadow  p-4 d-flex flex-column justify-content-center align-items-around rounded">
                    <div className="score-container p-2 rounded mb-3 shadow-box">
                        <h2 className="">Thank you for taking part.</h2>
                        <span>You have a score of {score}/{quizs.length}.</span>
                    </div>
                    <div className="stars-container p-2 rounded shadow-box">
                        {getStars()}
                    </div>
                </div>
            </div>
                : <div className="quiz-wrapper">
                    {quizs.length ?
                        <div className="quiz-container" >
                            <div className=" l2-font-size">
                                Thank you for taking part in this quiz. Please answers all questions.
                                <br />
                                <small className="text-info l0-font-size">Answers to these questions are valid for the {process.env.REACT_APP_ENVIRONMENT_NAME} region.</small></div>
                            <div id='carouselContainer' className="carousel side w-75 mx-auto" data-bs-interval='false' data-bs-wrap="false">
                                <div className="carousel-inner h-100">
                                    {quizs.length && quizs.map((item: IGeneratedQuiz, index: number) => (
                                        <div key={'q#' + index} className={`carousel-item h-100 ${index === 0 ? 'active' : ''}`}>
                                            <QuizField quizIndex={index} {...item} update={onUpdate} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex w-75 justify-content-end px-5 mx-auto">
                                <button className='btn submit-btn shadow-none me-3' type='button' data-bs-slide='prev' data-bs-target="#carouselContainer" disabled={currentIndex === 0} onClick={() => setCurrentIndex(currentIndex - 1)}>

                                    <span className="sr-only">Previous</span>
                                </button>
                                <button className=' btn submit-btn shadow-none me-3' type='button' data-bs-slide='next' data-bs-target="#carouselContainer" disabled={currentIndex === 4} onClick={() => setCurrentIndex(currentIndex + 1)}>
                                    <span className="sr-only">Next</span>
                                </button>
                                <button onClick={onSubmit} className="btn submit-btn shadow-none"><span>Submit</span></button>
                            </div>
                        </div>

                        : <h4 className="fw-bold" >No quizs for now </h4>}
                </div>}




        </div>
    )
}

const mapStateToProps = (state: IStoreState) => ({
    generatedQuizs: state.manageQuiz.generatedQuizList.generatedQuizs
})

const mapDispatchToProps = (dispatch: any) => ({
    updateBreadCrumb: (breadcrumb: IBreadCrumbData[]) => {
        dispatch(updateBreadCrumb(breadcrumb))
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    getAllGeneratedQuizList: () => {
        dispatch(getAllGeneratedQuizList());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);