import actionType from "./BreadCrumbActionTypes"
export const updateBreadCrumb = (data: any) => {
    return {
        type: actionType.UPDATE_BREADCRUMB,
        payload: data
    }
}

export const clearBreadCrumb = () => {
    return {
        type: actionType.CLEAR_BREADCRUMB
    }
}