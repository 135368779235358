import { ApplicationList, IApplicationList, otherLinks } from "src/constants/ApplicationList";
import { Link } from 'react-router-dom'
import './BiSidenav.scss';
import { connect } from "react-redux";
import { IStoreState } from "../../../types/StoreState";
import { rolesList } from "src/constants/RoleList";

interface ISideNavProps {
  userRoles?: number[],
  userInfo?: any
}

const BiSidenav = ({ userRoles, userInfo }: ISideNavProps) => {
  return (
    <div id="bi-sidenav" className="bi-sidenav d-flex flex-column ">
      <div className="sidenav-header d-flex  align-items-center justify-content-center px-3 py-2 mb-4">
        {/* <div className="rounded-circle  user-container d-flex  justify-content-center align-items-center">
          {userInfo && userInfo.data && `${userInfo.data.fname[0]} ${userInfo.data.lname[0]}`}
        </div>
        <span className="text-light ms-2">{userInfo && userInfo.data && `${userInfo.data.fname} ${userInfo.data.lname}`}</span> */}
        <Link to={'/'}><div className='brand-logo d-flex align-items-center h-100'><img className='logo-img' src={process.env.PUBLIC_URL + '/asset/logo.png'} alt="" /> </div></Link>

      </div>
      <div className="sidenav-body">
        <ul className="px-3 ">
          {ApplicationList.map((app: IApplicationList) => (
            !app.role || (userRoles && userRoles.some(item => item === app.role)) ? <Link key={app.url} to={`${app.url}`}>
              <li className="application w-100 py-1  my-3  text-start"><span>{app.appName}</span></li>
            </Link> : null
          ))}
          {process.env.REACT_APP_ENVIRONMENT_NAME === 'AU' && <>
            {(userRoles && userRoles.some(item => item === rolesList.TIMESHEETADMIN)) || (userInfo && userInfo.data && userInfo.data.department.toLowerCase() === 'digital') ? <Link to='/timesheet' >
              <li className="application w-100 py-1  my-3  text-start"><span>Digital Timesheet</span></li>
            </Link> : null}

            {(userRoles && userRoles.some(item => item === rolesList.LEADERBOARDADMIN)) || (userInfo && userInfo.data && userInfo.data.department.toLowerCase() === 'digital') ? <Link to='/leaderboard' >
              <li className="application w-100 py-1  my-3  text-start"><span>Digital R&R</span></li>
            </Link> : null}
          </>}

          {userRoles && userRoles.some(item => item === rolesList.ADMIN || item === rolesList.REPORT_DEVELOPER) ? <Link to='/flavours-of-data' >
            <li className="application w-100 py-1  my-3  text-start"><span>Flavours of Data</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.SUT) ? <Link to='/manageSut' >
            <li className="application w-100 py-1  my-3  text-start"><span>SUT Configurations</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.SUT) ? <Link to='/candidateUniqueness' >
            <li className="application w-100 py-1  my-3  text-start"><span>Candidate Uniqueness</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.ADMIN) ? <Link to='managerole' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage Role</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.ADMIN) ? <Link to='manageuser' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage User</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.ADMIN) ? <Link to='/manageFact' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage Fact</span></li>
          </Link> : null}

          {/* {userRoles && userRoles.some(item => item === rolesList.ADMIN) ? <Link to='/manageQuiz' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage Quiz</span></li>
          </Link> : null} */}

          {userRoles && userRoles.some(item => item === rolesList.ADMIN) ? <Link to='/manageNotification' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage Notifications</span></li>
          </Link> : null}

          {userRoles && userRoles.some(item => item === rolesList.EMAIL_SERVICE) ? <Link to='/manageUserGroup' >
            <li className="application w-100 py-1  my-3  text-start"><span>Manage User Group</span></li>
          </Link> : null}

        </ul>
      </div>
      <div className="mt-auto sidenav-bottom py-3">
        <ul className="mb-0 px-3">
          {otherLinks.map((app: IApplicationList) => (
            <Link key={app.url} to={`${app.url}`}>
              <li className="application w-100 text-start"><span>{app.appName}</span></li>
            </Link>
          ))}
        </ul>
      </div>

    </div>
  )

}

const mapStateToProps = (state: IStoreState) => ({
  userRoles: state.login.userRoles.roles,
  userInfo: state.login.userInfo

})

export default connect(mapStateToProps, null)(BiSidenav);