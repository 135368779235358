import { IManageQuiz } from "../types/ManageQuiz";
import actionType from '../actions/ManageQuizActiontype'

const initialState: IManageQuiz = {
    quizList: {
        loading: false,
        success: false,
        error: false,
        quizs: []
    },
    generatedQuizList: {
        loading: false,
        success: false,
        error: false,
        generatedQuizs: []
    },
    addQuizStatus: {
        loading: false,
        success: false,
        error: false
    },
    editQuizStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteQuizStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentQuiz: {
        quizId: null,
        question: '',
        answer: '',
        isDynamic: false,
        options: []
    },
    editingQuiz: {
        quizId: null,
        question: '',
        answer: '',
        isDynamic: false,
        options: []
    },
    quizError: {
        questionError: false,
        answerError: false,
        noOptionError:false,
        optionsError: []
    }

}

const manageQuizReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_QUIZ_LIST: {
            return {
                ...state,
                quizList: Object.assign({}, {
                    success: false,
                    error: false,
                    quizs: []
                }, { loading: true })
            }
        }
        case actionType.GET_QUIZ_LIST_SUCCESS: {
            return {
                ...state,
                quizList: Object.assign({}, state.quizList, { loading: false, quizs: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_QUIZ_LIST_ERROR: {
            return {
                ...state,
                quizList: Object.assign({}, state.quizList, { loading: false, error: true, success: false })

            }
        }
        case actionType.GET_ALL_GENERATED_QUIZ_LIST: {
            return {
                ...state,
                generatedQuizList: Object.assign({}, {
                    success: false,
                    error: false,
                    generatedQuizs: []
                }, { loading: true })
            }
        }

        case actionType.GET_ALL_GENERATED_QUIZ_LIST_SUCCESS: {
            return {
                ...state,
                generatedQuizList: Object.assign({}, state.generatedQuizList, { loading: false, generatedQuizs: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_ALL_GENERATED_QUIZ_LIST_FAILURE: {
            return {
                ...state,
                generatedQuizList: Object.assign({}, state.generatedQuizList, { loading: false, error: true, success: false })

            }
        }

        case actionType.ADD_QUIZ: {
            return {
                ...state,
                addQuizStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_QUIZ_SUCCESS: {
            return {
                ...state,
                addQuizStatus: Object.assign({}, state.addQuizStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_QUIZ_ERROR: {
            return {
                ...state,
                addQuizStatus: Object.assign({}, state.addQuizStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_QUIZ: {
            return {
                ...state,
                editQuizStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_QUIZ_SUCCESS: {
            return {
                ...state,
                editQuizStatus: Object.assign({}, state.editQuizStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_QUIZ_ERROR: {
            return {
                ...state,
                editQuizStatus: Object.assign({}, state.editQuizStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_QUIZ: {
            return {
                ...state,
                deleteQuizStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_QUIZ_SUCCESS: {
            return {
                ...state,
                deleteQuizStatus: Object.assign({}, state.deleteQuizStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_QUIZ_ERROR: {
            return {
                ...state,
                deleteQuizStatus: Object.assign({}, state.deleteQuizStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_QUIZ: {
            return {
                ...state,
                currentQuiz: Object.assign({}, {
                    quizId: null,
                    question: '',
                    answer: '',
                    isDynamic: false,
                    options: []
                }),
                editingQuiz: null
            }
        }

        case actionType.SELECT_EDIT_QUIZ: {
            return {
                ...state,
                currentQuiz: Object.assign({}, action.payload),
                editingQuiz: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_QUIZ: {
            return {
                ...state,
                currentQuiz: Object.assign({}, state.currentQuiz, { ...action.payload })
            }
        }

        case actionType.CLEAR_QUIZ: {
            return {
                ...state,
                currentQuiz: {
                    quizId: null,
                    question: '',
                    answer: '',
                    isDynamic: false,
                    options: []
                },
                editingQuiz: {
                    quizId: null,
                    question: '',
                    answer: '',
                    isDynamic: false,
                    options: []
                },
                addQuizStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editQuizStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteQuizStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                quizError: {
                    questionError: false,
                    answerError: false,
                    optionsError: []
                }
            }
        }

        case actionType.UPDATE_QUIZ_LIST: {
            return {
                ...state,
                quizList: Object.assign({}, state.quizList, { loading: false, quizs: action.payload, success: true, error: false })
            }
        }
        case actionType.UPDATE_QUIZ_ERROR: {
            return {
                ...state,
                quizError:Object.assign({} ,action.payload)
            }
        }
        default:
            return state
    }
}

export default manageQuizReducer