import React, { useEffect, useState } from 'react'
import './ManageUserGroup.scss'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux'
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import { getUserGroupList, selectAddUserGroup, selectEditUserGroup, updateUserGroupInfo, addUserGroupList, editUserGroup, deleteUserGroup, clearSelectedUserGroup, updateUserGroupList, clearUserGroup } from '../../../actions/ManageUserGroupAction';
import { showNotification } from '../../../actions/notificationAction'
import { IUserGroup, IUserGroupAddPayload, IUserGroupEditPayload, IDeleteUserGroupPayload, IUserGroupError, IStatus } from '../../../types/ManageUserGroup'
import { IStoreState } from '../../../types/StoreState'
import ManageUserGroupForm from './ManageUserGroupForm';
import { INotification } from '../../../types/notification';
import Table, { IAction, IColumn } from '../../shared/Table/Table';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { isEqual } from '../../../util/arrayFunction';

interface IProps {
  updateBreadCrumb: (breadcrumb: any) => void,
  clearBreadCrumb: () => void,
  getUserGroupList: () => void,
  userGroups: IUserGroup[],
  selectAddUserGroup: () => void,
  selectEditUserGroup: (data: IUserGroup) => void,
  addUserGroup: (payload: IUserGroupAddPayload) => void,
  editUserGroup: (payload: IUserGroupEditPayload) => void,
  deleteUserGroup: (payload: IDeleteUserGroupPayload) => void,
  clearSelectedUserGroup: () => void,
  clearUserGroup: () => void,
  updateUserGroupList: (data: any) => void
  currentUserGroup: IUserGroup,
  editingUserGroup?: IUserGroup,
  addUserGroupStatus: IStatus,
  editUSerStatus: IStatus,
  deleteUserGroupStatus: IStatus,
  showNotification: (notification: INotification) => void
}

const ManageUserGroup = ({ updateBreadCrumb,
  userGroups,
  clearBreadCrumb,
  getUserGroupList,
  selectAddUserGroup,
  selectEditUserGroup,
  addUserGroup,
  editUserGroup,
  deleteUserGroup,
  clearSelectedUserGroup,
  clearUserGroup,
  currentUserGroup,
  updateUserGroupList,
  editingUserGroup,
  addUserGroupStatus,
  editUSerStatus,
  deleteUserGroupStatus,
  showNotification }: IProps) => {
  const [displayManageUserGroupAddDialog, setDisplayManageUserGroupAddDialog] = useState<boolean>(false);
  const [displayManageUserGroupDeleteDialog, setDisplayManageUserGroupDeleteDialog] = useState<boolean>(false);
  const [formError, setFormError] = useState<IUserGroupError>()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const filterObject = {
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'groupName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'users': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  useEffect(() => {
    const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'AllUserGroups', url: '/' }]
    updateBreadCrumb(breadCrumb);
    getUserGroupList();
    return () => {
      clearBreadCrumb();
      clearUserGroup();
      scrollAuto()
    };
  }, [])

  useEffect(() => {
    if (deleteUserGroupStatus && !deleteUserGroupStatus.loading && deleteUserGroupStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Deleted Successfully',
        detail: `${currentUserGroup.groupName} is deleted successfully.`, life: 2000
      }
      showNotification(notification)
      const newUserGroupList = userGroups.filter((item: IUserGroup) => item.groupId !== currentUserGroup.groupId)
      clearSelectedUserGroup();
      updateUserGroupList(newUserGroupList)
      setDisplayManageUserGroupDeleteDialog(false);
    }
  }, [deleteUserGroupStatus])

  useEffect(() => {
    if (editUSerStatus && !editUSerStatus.loading && editUSerStatus.success) {
      const newUserGroupList = userGroups.map((item: IUserGroup) => item.groupId === currentUserGroup.groupId ? currentUserGroup : item)
      clearSelectedUserGroup()
      updateUserGroupList(newUserGroupList)
      setDisplayManageUserGroupAddDialog(false)
    }
  }, [editUSerStatus])

  useEffect(() => {
    if (addUserGroupStatus && !addUserGroupStatus.loading && addUserGroupStatus.success) {
      clearSelectedUserGroup()
      getUserGroupList()
      setDisplayManageUserGroupAddDialog(false)
    }
  }, [addUserGroupStatus])

  const scrollHidden = () => {
    document.body.style.overflow = 'hidden'
  }

  const scrollAuto = () => {
    document.body.style.overflow = 'auto'
  }

  const validForm = () => {
    let formErr: IUserGroupError = {
      groupNameError: false,
      usersError: false
    }
    if (!currentUserGroup.groupName) {
      formErr.groupNameError = true
    }
    if (!currentUserGroup.users) {
      formErr.usersError = true
    } else {
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(shl.com)$/i;
      let _users = currentUserGroup.users.split(",");
      if (!_users.length || _users.some((item: string) => !re.test(item))) {
        formErr.usersError = true

      }
    }
    setFormError(formErr)

    for (let item of Object.values(formErr)) {
      if (item) {
        return false
      }
    }

    return true
  }

  const addNewUserGroup = () => {
    selectAddUserGroup();
    setDisplayManageUserGroupAddDialog(true)
    scrollHidden();
    setIsEdit(false)
  }

  const save = () => {
    if (!validForm()) return
    if (editingUserGroup) {
      if (isEqual(currentUserGroup, editingUserGroup)) {
        clearSelectedUserGroup();
        setDisplayManageUserGroupAddDialog(false)
        scrollAuto()
        return
      }
      editUserGroup(currentUserGroup)
      scrollAuto();
      return
    }
    addUserGroup(currentUserGroup)
    scrollAuto();
  }

  const cancel = () => {
    clearSelectedUserGroup()
    setDisplayManageUserGroupAddDialog(false);
    let formErr: IUserGroupError = {
      groupNameError: false,
      usersError: false
    }
    setFormError(formErr);
    scrollAuto();
  }

  //for delete feature


  const onDeleteUserGroup = (userGroup: any) => {
    selectEditUserGroup(userGroup)
    setDisplayManageUserGroupDeleteDialog(true);
  }

  const onDeleteUserGroupReject = () => {
    clearSelectedUserGroup()
    setDisplayManageUserGroupDeleteDialog(false);
  }

  const onDeleteUserGroupProceed = () => {
    deleteUserGroup({ id: currentUserGroup.groupId })
  }

  //for edit feature 
  const onEditUserGroup = (userGroup: IUserGroup) => {
    selectEditUserGroup(userGroup)
    setDisplayManageUserGroupAddDialog(true)
    scrollHidden();
    setIsEdit(true)
  }


  const renderDialogFooter = () => {
    return (
      <div className=''>
        <button className='me-3 btn btn-danger' onClick={cancel}> <span className='mdi mdi-close'></span> Cancel</button>
        <button className='btn btn-success' onClick={save}>Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
      </div>
    )

  }

  const renderDeleteDialogFooter = () => {
    return (
      <div className='d-flex justify-content-center'>
        <button className='me-3 btn btn-danger' onClick={onDeleteUserGroupReject}>  No </button>
        <button className='btn btn-success' onClick={onDeleteUserGroupProceed}> Yes </button>
      </div>
    )
  }


  const columns: IColumn[] = [{
    field: 'groupName',
    header: 'Group Name',
    applyFilter: true
  },
  {
    field: 'users',
    header: 'Users',
    applyFilter: true
  }
  ]

  const action: IAction[] = [{
    action: onEditUserGroup,
    cssClass: 'text-secondary mdi mdi-pencil',
    title: 'Edit',
    header: ''
  }, {
    action: onDeleteUserGroup,
    cssClass: 'text-danger mdi mdi-delete',
    title: 'Delete',
    header: ''
  }]


  return (
    <div className=' manage-user-group container mt-3 d-flex justify-content-center'>
      <div className='shadow-sm p-2'>
        <Dialog resizable={false} draggable={false} header={<h4 className='dialog-heading'>Please Fill The Below Details To {isEdit ? 'Edit' : 'Create New'} User Group</h4>} visible={displayManageUserGroupAddDialog} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderDialogFooter()} onHide={cancel} >
          {currentUserGroup ? <ManageUserGroupForm isEdit={isEdit} formError={formError}></ManageUserGroupForm> : null}
        </Dialog>

        <Dialog resizable={false} draggable={false} header='' visible={displayManageUserGroupDeleteDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={onDeleteUserGroupReject} >
          <div className='dialog-heading text-center'>
            <h6 className='text-danger'>{`Do You Really Want To Delete the User Group: ${currentUserGroup?.groupName} ? `}</h6>
          </div>
        </Dialog>
        <div className='d-flex justify-content-between align-items-center p-2'>
          <h3 className='text-global-custom fw-bold'>All User Groups</h3>
          <button className='btn btn-success' onClick={() => addNewUserGroup()}><span className='mdi mdi-plus l3-font-size'></span> Add User Group</button>
        </div>
        <Table data={userGroups} filterObject={filterObject} column={columns} actions={action} paginator={true} ></Table>

      </div>
    </div>

  )
}

const mapStateToProps = (state: IStoreState) => ({
  userGroups: state.manageUserGroup.userGroupList.userGroups,
  currentUserGroup: state.manageUserGroup.currentUserGroup,
  editingUserGroup: state.manageUserGroup.editingUserGroup,
  addUserGroupStatus: state.manageUserGroup.addUserGroupStatus,
  editUSerStatus: state.manageUserGroup.editUserGroupStatus,
  deleteUserGroupStatus: state.manageUserGroup.deleteUserGroupStatus
})


const mapDispatchToProps = (dispatch: any) => ({
  updateBreadCrumb: (data: any) => {
    dispatch(updateBreadCrumb(data));
  },
  clearBreadCrumb: () => {
    dispatch(clearBreadCrumb())
  },
  getUserGroupList: () => {
    dispatch(getUserGroupList())
  },
  selectAddUserGroup: () => {
    dispatch(selectAddUserGroup())
  },
  selectEditUserGroup: (data: IUserGroup) => {
    dispatch(selectEditUserGroup(data))
  },
  updateUserGroupInfo: (data: any) => {
    dispatch(updateUserGroupInfo(data))
  },
  addUserGroup: (payload: IUserGroupAddPayload) => {
    dispatch(addUserGroupList(payload))
  },
  editUserGroup: (payload: IUserGroupEditPayload) => {
    dispatch(editUserGroup(payload))
  },
  deleteUserGroup: (payload: IDeleteUserGroupPayload) => {
    dispatch(deleteUserGroup(payload))
  },
  clearSelectedUserGroup: () => {
    dispatch(clearSelectedUserGroup())
  },
  clearUserGroup: () => {
    dispatch(clearUserGroup())
  },
  showNotification: (notification: INotification) => {
    dispatch(showNotification(notification))
  },
  updateUserGroupList: (data: any) => {
    dispatch(updateUserGroupList(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserGroup);