
import './CaLoader.scss'
const CaLoader = () => {
    return (
        <div className="ca-loader-container d-flex justify-content-center align-items-center" >
            <div className='icons-wrapper d-flex flex-column'>

                    <span className="mdi mdi-cog"></span>
                    <span className="mdi mdi-cog"></span>
                    <span className="mdi mdi-cog"></span>

            </div>


        </div>
    )
}

export default CaLoader