import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux'
import './BreadCrumb.scss'


const BreadCrumbs = (props: any) => {

    const { pathname } = useLocation();
    const { breadCrumb } = props;

    return (
        <div >
            <nav className='breadcrumb' aria-label="breadcrumb">
                {breadCrumb && breadCrumb.length > 0 ? <ol className='fw-bold l2-font-size breadcrumb p-2 px-4 my-2'>

                    {breadCrumb.map((item: any, index: any) => {
                        const isLast = index === breadCrumb.length - 1 ? true : false;
                        return (
                            isLast
                                ? <li key={`item${index}`} className='breadcrumb-item active' aria-current="page" > {item.label} </li>
                                : <li key={`item${index}`} className='breadcrumb-item'> <Link to={item.url} > {item.label} </Link></li>
                        )
                    })}

                </ol> : null}
            </nav>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    breadCrumb: state.breadCrumb.breadCrumb
})


export default connect(mapStateToProps, null)(BreadCrumbs)