import { act } from "react-dom/test-utils";
import { actionTypes } from "../actions/ManageGlueJobActionType";
import { IManageGlueJob } from "../types/ManageGlueJob";
const initialState: IManageGlueJob = {
    eu: {
        loading: false,
        error: false,
        success: false,
        data: {
            cpuUtilization: 0,
            glueJobs: []
        }
    },
    us: {
        loading: false,
        error: false,
        success: false,
        data: {
            cpuUtilization: 0,
            glueJobs: []
        }
    },
    au: {
        loading: false,
        error: false,
        success: false,
        data: {
            cpuUtilization: 0,
            glueJobs: []
        }
    },
    cn: {
        loading: false,
        error: false,
        success: false,
        data: {
            cpuUtilization: 0,
            glueJobs: []
        }
    }
}

const ManageGlueJobReducer = (state = initialState, action: any): IManageGlueJob => {
    switch (action.type) {
        case actionTypes.GET_ALL_GLUE_JOB_LIST: {
            return {
                ...state,
                [action.region]: Object.assign({}, { loading: true, success: false, error: false, data: { cpuUtilization: 0, glueJobs: [] } })
            }
        }
        case actionTypes.GET_ALL_GLUE_JOB_LIST_SUCCESS: {
            return {
                ...state,
                [action.region]: Object.assign({}, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_GLUE_JOB_LIST_FAILURE: {
            return {
                ...state,
                [action.region]: Object.assign({}, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        default:
            return state
    }
}

export default ManageGlueJobReducer;