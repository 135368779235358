import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageUserGroupApi {

    getUserGroupList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getAllUserGroups, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewUserGroup(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addUserGroup, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editUserGroup(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.editUserGroup, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteUserGroup(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteUserGroup, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}