import actionTypes from "../actions/tableauRequestActionType";
import { IManageTableauRequest } from "../types/tableauRequest";


const intialState:IManageTableauRequest = {
    tableauRequestStatus :{
        loading: false,
        success: false,
        error: false
    },
    raisedTableauRequestStatus : {
        loading:false,
        success: false,
        error: false,
        data:null
    },
    changeStatusActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    allTableauRequest: {
        loading:false,
        success: false,
        error: false,
        data:[]
    }
}


export const TableauRequestReducer = (state=intialState , action:any):IManageTableauRequest =>  {
        switch(action.type) {
            case actionTypes.SUBMIT_TABLEAU_REQUEST : {
                return {
                    ...state,
                    tableauRequestStatus : Object.assign({} , state.tableauRequestStatus , {loading:true , success:false , error:false}  )
                }
            }
            case actionTypes.SUBMIT_TABLEAU_REQUEST_SUCCESS : {
                return {
                    ...state,
                    tableauRequestStatus : Object.assign({} , state.tableauRequestStatus , {loading:false , success:true , error:false}  )
                }
            }
            case actionTypes.SUBMIT_TABLEAU_REQUEST_ERROR : {
                return {
                    ...state,
                    tableauRequestStatus : Object.assign({} , state.tableauRequestStatus , {loading:false , success:false , error:true}  )
                }
            }
            case actionTypes.CHECK_TABLEAU_REQUEST_STATUS : {
                return {
                    ...state,
                    raisedTableauRequestStatus : Object.assign({} , state.raisedTableauRequestStatus , {loading:true , success:false , error:false}  )
                }
            }
            case actionTypes.CHECK_TABLEAU_REQUEST_STATUS_SUCCESS : {
                return {
                    ...state,
                    raisedTableauRequestStatus : Object.assign({} , state.raisedTableauRequestStatus , {loading:false , success:true , error:false , data:action.payload }  )
                }
            }
            case actionTypes.CHECK_TABLEAU_REQUEST_STATUS_ERROR : {
                return {
                    ...state,
                    raisedTableauRequestStatus : Object.assign({} , state.raisedTableauRequestStatus , {loading:false , success:false , error:true , data:action.payload}  )
                }
            }

            case actionTypes.GET_ALL_TABLEAU_REQUEST : {
                return  {
                    ...state,
                    allTableauRequest:Object.assign({} , state.allTableauRequest , {loading:true , success:false , error:false , data:[] })
                }
            }
            case actionTypes.GET_ALL_TABLEAU_REQUEST_SUCCESS : {
                return  {
                    ...state,
                    allTableauRequest:Object.assign({} , state.allTableauRequest , {loading:false , success:true , error:false , data:action.payload.data })
                }
            }
            case actionTypes.GET_ALL_TABLEAU_REQUEST_FAILURE : {
                return  {
                    ...state,
                    allTableauRequest:Object.assign({} , state.allTableauRequest , {loading:false , success:false , error:true , data:action.payload })
                }
            }

            case actionTypes.CHANGE_TABLEAU_REQUEST_STATUS : {
                return {
                    ...state,
                    changeStatusActionStatus:Object.assign({} ,state.changeStatusActionStatus , {loading:true , success:false , error:false } )
                }
            }
            case actionTypes.CHANGE_TABLEAU_REQUEST_STATUS_SUCCESS : {
                return {
                    ...state,
                    changeStatusActionStatus:Object.assign({} ,state.changeStatusActionStatus , {loading:false , success:true , error:false } )
                }
            }
            case actionTypes.CHANGE_TABLEAU_REQUEST_STATUS_FAILURE : {
                return {
                    ...state,
                    changeStatusActionStatus:Object.assign({} ,state.changeStatusActionStatus , {loading:false , success:false , error:true } )
                }
            }
            case actionTypes.UPDATE_TABLEAU_REQUEST : {
                return {
                    ...state,
                    allTableauRequest:Object.assign({} , state.allTableauRequest , { loading:false , success:true , error:false , data:action.payload })
                }
            }
            case actionTypes.CLEAR_PENDING_TABLEAU_REQUEST_DATA : {
                return {
                    ...state,
                    changeStatusActionStatus: {
                        loading: false,
                        success: false,
                        error: false
                    },
                    allTableauRequest: {
                        loading:false,
                        success: false,
                        error: false,
                        data:[]
                    }

                }
            }
            case actionTypes.CLEAR_TABLEAU_REQUEST_DATA : {
                return {
                    ...state ,
                    tableauRequestStatus :{
                        loading: false,
                        success: false,
                        error: false
                    },
                    raisedTableauRequestStatus : {
                        loading:false,
                        success: false,
                        error: false,
                        data:null
                    }

                }
            }
            
            default: 
            return state

        }
}

export default TableauRequestReducer