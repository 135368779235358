const actionType = {
    GET_QUIZ_LIST: 'GET_QUIZ_LIST',
    GET_QUIZ_LIST_SUCCESS: 'GET_QUIZ_LIST_SUCCESS',
    GET_QUIZ_LIST_ERROR: 'GET_QUIZ_LIST_ERROR',
    ADD_QUIZ: 'ADD_QUIZ',
    ADD_QUIZ_SUCCESS: 'ADD_QUIZ_SUCCESS',
    ADD_QUIZ_ERROR: 'ADD_QUIZ_ERROR',
    EDIT_QUIZ: 'EDIT_QUIZ',
    EDIT_QUIZ_SUCCESS: 'EDIT_QUIZ_SUCCESS',
    EDIT_QUIZ_ERROR: 'EDIT_QUIZ_ERROR',
    DELETE_QUIZ: 'DELETE_QUIZ',
    DELETE_QUIZ_SUCCESS: 'DELETE_QUIZ_SUCCESS',
    DELETE_QUIZ_ERROR: 'DELETE_QUIZ_ERROR',
    SELECT_ADD_QUIZ: 'SELECT_ADD_QUIZ',
    SELECT_EDIT_QUIZ: 'SELECT_EDIT_QUIZ',
    UPDATE_QUIZ: 'UPDATE_QUIZ',
    CLEAR_QUIZ: 'CLEAR_QUIZ',
    UPDATE_QUIZ_LIST:'UPDATE_QUIZ_LIST',
    GET_ALL_GENERATED_QUIZ_LIST:'GET_ALL_GENERATED_QUIZ_LIST',
    GET_ALL_GENERATED_QUIZ_LIST_SUCCESS:'GET_ALL_GENERATED_QUIZ_LIST_SUCCESS',
    GET_ALL_GENERATED_QUIZ_LIST_FAILURE:'GET_ALL_GENERATED_QUIZ_LIST_FAILURE',
    UPDATE_QUIZ_ERROR:'UPDATE_QUIZ_ERROR'

}

export default actionType