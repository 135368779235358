

import { ICategoryReports } from "../types/CaReport";
import { IKpi } from "../types/reportList";
import { ITreeNode } from "../types/SiteMap";

const getReportList = (kpis: IKpi[]) => {
    let updatedKpi = kpis.map(item => {
        return {
            label: item.kpi,
            url: `/smart/kpi/${item.kpiId}`,
            children: item.reports.map(item => {
                return {
                    label: item.name,
                    url: `/smart/report/${item.kpiId}/${item.id}`
                }
            })
        }
    })

    return updatedKpi;
}

const getCategoryList = (cat: ICategoryReports[]) => {
    let updatedKpi = cat.map(item => {
        return {
            label: item.categoryName,
            url: `/smart/${item.categoryId}`,
            children: item.reports.map(item => {
                return {
                    label: item.name,
                    url: `/clientAnalytics/report/${item.categoryId}/${item.id}`
                }
            })
        }
    })

    return updatedKpi;
}


const smartNodesList = (kpis: IKpi[]) => {
    return [
        {
            label: 'Manage Score Group',
            url: '/smart/manageScoreGroup',
            children: [],
            expanded: true,
            color: 'lilac'
        },
        {
            label: 'Client PII Data Request',
            url: '/smart/smartDeRequest',
            children: [],
            expanded: true,
            color: 'lilac'
        },
        {
            label: 'Pending Tableau Requests',
            url: '/smart/pendingTableauRequest',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 6
        },
        {
            label: 'Pending PII Request',
            url: '/smart/pendingCompanyRequest/companyuser',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 3
        },
        {
            label: 'View/Terminate PII Access',
            url: '/smart/viewCompanyAccess/companyuser',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 3
        },
        {

            label: 'Manage Tableau Super User',
            url: '/smart/manageTableauSuperUser',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 6
        },
        {
            label: 'Manage KPI',
            url: '/smart/managekpi',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 2
        },
        {
            label: 'Manage Report',
            url: '/smart/managereport',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 2
        },
        {
            label: 'Data Entitlement Request',
            url: '/smart/manageDe',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 4
        },
        {
            label: 'SMART Company Access Request',
            url: '/smart/smartDeRequest',
            children: [],
            expanded: true,
            color: 'lilac',
            role: 3
        },
        ...getReportList(kpis),
        {
            label: 'Help',
            url: '/smart/help'
        }
    ];
}

const caNodeList = (category: ICategoryReports[]) => {
    return [
        {
            label: 'Manage Category',
            url: '/clientAnalytics/manageCategory',
            children: [],
            color: 'lilac',
            expanded: true,
            role: 102
        },
        {
            label: 'Manage Dashboards',
            url: '/clientAnalytics/manageCaReport',
            children: [],
            color: 'lilac',
            expanded: true,
            role: 102
        },
        ...getCategoryList(category),
        {
            label: 'Help',
            url: '/clientAnalytics/help'
        }
    ]
}

const requests: ITreeNode[] = [
    {
        label: 'Raise ad-hoc/BAU report request',
        url: 'https://jira.shl.systems/plugins/servlet/theme/portal/1/group/24',
        isExternal: true
    }, {
        label: 'CA Data Entitlement Request',
        url: '/deRequest'
    }, {
        label: 'Client PII data request',
        url: '/smart/smartDeRequest',
    }
]


export const chartNodeList = (kpis: IKpi[], category: ICategoryReports[]) => {
    return [
        {
            label: 'SHL Analytics',
            url: '/',
            expanded: true,
            color: 'lime',
            isTop: true,
            children: [
                {
                    label: 'SMART',
                    url: '/smart',
                    children: smartNodesList(kpis)
                },
                //uncomment to add CA back
                // {
                //     label: 'Client Analytics',
                //     url: '/clientAnalytics',
                //     children: caNodeList(category)
                // },
                {
                    label: 'Raise a Request',
                    url: '/requests',
                    children: requests
                },
                {
                    label: 'SUT Configurations',
                    url: '/manageSut',
                    children: [],
                    expanded: true,
                    color: 'lilac',
                    role: 101
                },
                {
                    label: 'Candidate Uniqueness',
                    url: '/candidateUniqueness',
                    children: [],
                    expanded: true,
                    color: 'lilac',
                    role: 101
                },
                {
                    label: 'Manage Role',
                    url: '/managerole',
                    children: [],
                    expanded: true,
                    color: 'lilac',
                    role: 1
                },
                {
                    label: 'Manage User',
                    url: '/manageuser',
                    children: [],
                    expanded: true,
                    color: 'lilac',
                    role: 1
                },
                {
                    label: 'Manage Fact',
                    url: '/manageFact',
                },
                // {
                //     label: 'Manage Quiz',
                //     url: '/manageQuiz',
                // },
                {
                    label: 'Help',
                    url: '/help',
                },
                {
                    label: 'Site Map',
                    url: '/site-map',
                },
                {
                    label: 'Process Flow',
                    url: '/processFlow',
                },
                // {
                //     label: 'Quiz',
                //     url: '/quiz'
                // }

            ]
        }
    ]
}


export const getNodeList = (kpis: IKpi[], category: ICategoryReports[]) => {
    return chartNodeList(kpis, category)
}