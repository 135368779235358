import './Requests.scss';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import { IBreadCrumbData } from '../../../types/BreadCrumb';
import { useEffect } from 'react';
import { IStoreState } from 'src/types/StoreState';
import { rolesList } from 'src/constants/RoleList';

interface IRequest {
    label: string,
    url: string,
    desc: string,
    icon: string,
    external?: boolean,
    role?: number

}

interface IProps {
    updateBreadCrumb: (breachcrumb: IBreadCrumbData[]) => void,
    clearBreadCrumb: () => void,
    userRoles: number[]
}

const Requests = ({ updateBreadCrumb, clearBreadCrumb, userRoles }: IProps) => {

    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '' }, { label: 'Requests', url: 'requests' }]
        updateBreadCrumb(breadCrumb);
        return () => {
            clearBreadCrumb();
        };
    }, [])

    const requests: IRequest[] = [{
        //     label: 'PI Data Access',
        //     url: '/piAccessRequest',
        //     desc: `You can submit a request for PI data access through this link. DPO approval must be obtained for access to PI Data.`,
        //     icon: 'mdi-bag-personal'
        // }, {
    //     desc: 'You can request a Tableau user permit using this link. Tableau team approval is necessary for this.',
    //     label: 'Tableau Access',
    //     url: '/requestTableauAccess',
    //     icon: 'mdi-monitor-dashboard'
    // }, {
        label: 'Raise ad-hoc/BAU report request',
        external: true,
        url: 'https://jira.shl.systems/plugins/servlet/theme/portal/1/group/24',
        desc: `To raise a JIRA ticket to request data not available via SMART reports.`,
        icon: 'mdi-database-search'
    }, {
        label: 'CA Data Entitlement Request',
        url: '/deRequest',
        desc: `Using this link, you can request users access to company information on the analytics dashboard.`,
        icon: 'mdi-account',
        role: rolesList.CSIT
    },
    {
        label: 'Client PII data request',
        url: '/smart/smartDeRequest',
        desc: `To raise a request to access PII data for a client on SMART reports.`,
        icon: 'mdi-account'
    }
    ]

    return (<div className='p-3 request-container'>
        <h4 className='fw-bold p-2 border-bottom text-start kpi-title mb-3'>
            Requests
        </h4>
        <div className='row l2-font-size'>
            {requests.map((item: IRequest, index: any) => {
                return !item.role || (userRoles && userRoles.some(i => i === item.role)) ? <div className={'tile col-6 col-md-4 col-lg-4 mb-3'}>
                    {!item.external ? <div className='h-100 shadow-box tile-box'>
                        <Link to={`${item.url}`}>
                            <div className='d-flex '>
                                <span className={`mdi icon me-2 ${item.icon}`}></span>
                                <span className='report-title me-auto'>{item.label}</span>
                            </div>
                            <div className='desc'>{item.desc}</div>
                        </Link>
                        <div className='view-link l1-font-size d-flex justify-content-between'><Link to={`${item.url}`}><span title='Raise request' >Request</span><span className='mdi mdi-chevron-double-right '></span></Link><Link to={{ pathname: '/processFlow', hash: `${item.label}` }} ><span title='Know process workflow' >Process flow</span></Link> </div>
                    </div> : <div className='h-100 shadow-box tile-box'>

                        <a href={`${item.url}`} target='_blank' rel="noreferrer">
                            <div className='d-flex '>
                                <span className={`mdi icon me-2 ${item.icon}`}></span>
                                <span className='report-title me-auto'>{item.label}</span>
                            </div>
                            <div className='desc' title={item.desc}>{item.desc}</div>
                        </a>
                        <div className='view-link l1-font-size d-flex justify-content-between'><a href={`${item.url}`} target='_blank' rel="noreferrer"><span className=''>Request</span><span className='mdi mdi-chevron-double-right '></span></a> <Link to={{ pathname: '/processFlow', hash: `${item.label}` }} ><span title='Know process workflow' >Process flow</span></Link> </div>

                    </div>}
                </div> : null
            })}
        </div>

    </div>)
}

const mapStateToProps = (state: IStoreState) => ({
    userRoles: state.login.userRoles.roles
})

const mapDispatchToProps = (dispatch: any) => ({
    updateBreadCrumb: (breadcrumb: IBreadCrumbData[]) => {
        dispatch(updateBreadCrumb(breadcrumb))
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Requests);