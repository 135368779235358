import { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios'
import { useNavigate, Navigate } from 'react-router';
import { config } from '../../../api/config';
import './TableauReport.scss'
import Loader from '../loader/Loader';
import TokenService from '../../../api/token.service'
import tokenService from '../../../api/token.service';
import ErrorHandler from '../../ErrorHandler/ErrorHandler';

const { tableau }: any = window;


interface ITableau {
    report: any,
    styles: any,
    hideToolBar?: boolean,
    updateToken: (token: any) => void,
    token: any,
    updateIntialLoad: (status: boolean) => void,
    updateDownload?: (status: boolean) => void
}

const TableauReport = forwardRef(({ updateDownload, report, styles, hideToolBar, updateToken, token, updateIntialLoad }: ITableau, ref) => {

    const tableauContainer = useRef(null)
    const [noAccess, setNoAccess] = useState(false)
    const [sessionRunning, setSessionRunning] = useState(false)
    const [loading, setLoading] = useState(0)
    const [workbook, setWorkbook] = useState<any>()
    const [viz, setViz] = useState<any>()
    const [appliedFilter, setAppliedFilter] = useState<any>()
    const [pendingFilter, setPendingFilter] = useState<any>()
    const [errorInRun, setErrorInRun] = useState<boolean>(false)
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            if (tokenService.getTokenInterval()) {
                clearInterval(tokenService.getTokenInterval())
                tokenService.deleteTokenInterval()
            }
        }
    }, [])
    useImperativeHandle(ref, () => ({
        initViz: loadDashboard,
        applyFilter: async (filter: any) => {
            if (!TokenService.getTableauToken()) return;
            const isActive = await axios.get(config.baseUrl + config.apiUrl.isSessionActive + '?token=' + tokenService.getTableauToken());
            if (isActive && isActive.status === 200 && isActive.data && isActive.data.status === 'OK') {
                if (isActive.data.tokenStatus.toUpperCase() !== "ACTIVE") {
                    TokenService.deleteTableauToken();
                    navigate('/')
                    return
                }
            }
            if (viz) {
                for (let item of Object.keys(filter)) {
                    workbook.changeParameterValueAsync(
                        item,
                        filter[item]);
                }
            }
        },
        dispose: () => {
            if (viz)
                viz.dispose()
        },
        download: () => {
            if (viz) {
                viz.showDownloadDialog()
            }
        }
    }));


    const deleteSession = async () => {
        const deleteToken = await axios.delete(config.baseUrl + config.apiUrl.deleteTableauToken);
        if (deleteToken && deleteToken.status === 200 && deleteToken.data && deleteToken.data.status === 'OK') {
            loadDashboard(appliedFilter)
        }
    }

    const applyPendingFilter = (workbook: any, pendingFilter: any) => {
        for (let item of Object.keys(pendingFilter)) {
            setLoading(prev => prev + 1)
            workbook.changeParameterValueAsync(
                item,
                pendingFilter[item]).then((data: any) => {
                    if (data) {
                        setLoading(prev => prev - 1)
                    }
                });
        }
    }

    const loadDashboard = async (filter: any) => {
        updateIntialLoad(false)
        let viz: any;
        setSessionRunning(false)
        setLoading(prev => prev + 1)
        let newFilter;
        let pendingFilters: any;
        const options = {
            hideTabs: true,
            showTabs: false,
            hideToolbar: hideToolBar,
            render: true,
            device: 'desktop',
            onFirstInteractive: function () {
                updateDownload && updateDownload(true);
                const workbook = viz.getWorkbook();
                setWorkbook(workbook)
                applyPendingFilter(workbook, pendingFilters)
                setLoading(prev => prev - 1)
                viz.addEventListener(tableau.TableauEventName.FILTER_CHANGE, async () => {
                    if (!TokenService.getTableauToken()) return;
                    const isActive = await axios.get(config.baseUrl + config.apiUrl.isSessionActive + '?token=' + tokenService.getTableauToken());
                    if (isActive && isActive.status === 200 && isActive.data && isActive.data.status === 'OK') {
                        if (isActive.data.tokenStatus.toUpperCase() !== "ACTIVE") {
                            TokenService.deleteTableauToken();
                            navigate('/')
                        }
                    }
                })
                viz.addEventListener(tableau.TableauEventName.TAB_SWITCH, () => {
                    let size = viz && viz.getWorkbook() && viz.getWorkbook().getActiveSheet() && viz.getWorkbook().getActiveSheet().getSize().minSize
                    viz.setFrameSize('100%', size.height)
                })
                const checkInt = setInterval(async () => {
                    if (!TokenService.getTableauToken()) return;
                    const isActive = await axios.get(config.baseUrl + config.apiUrl.isSessionActive + '?token=' + tokenService.getTableauToken());
                    if (isActive && isActive.status === 200 && isActive.data && isActive.data.status === 'OK') {
                        if (isActive.data.tokenStatus.toUpperCase() !== "ACTIVE") {
                            TokenService.deleteTableauToken();
                            navigate('/')
                        }
                    }
                }, 10 * 60 * 1000);
                tokenService.setTokenInterval(checkInt)
            },
        };
        const result = generateTableauFilter(filter);
        newFilter = result.newFilter;
        pendingFilters = result.pendingFilter;
        let tokenData;
        try {
            tokenData = await axios.get(config.baseUrl + config.apiUrl.tableauToken);
        } catch (err) {
            setErrorInRun(true);
            setLoading(prev => prev - 1);
            console.log(err);
        }
        if (tokenData && tokenData.status === 200) {
            if (tokenData.data.token && tokenData.data.token != -1) {
                if (tokenData.data.token == 0) {
                    setLoading(prev => prev - 1)
                    setSessionRunning(true)
                    setAppliedFilter(filter)
                    updateIntialLoad(true)
                    return
                }
                updateToken(tokenData.data.token)
                if (viz) {
                    viz.dispose();
                }
                const url = `${process.env.REACT_APP_TABLEAU_SERVER}/trusted/${tokenData.data.token}/t/${process.env.REACT_APP_TABLEAU_SITE}/views/${report.url || report.internalUrl || report.externalUrl}`;
                // try {
                viz = new tableau.Viz(tableauContainer.current, url, { ...options, ...newFilter })
                setViz(viz)
            } else {
                updateIntialLoad(true)
                setNoAccess(true)
                setLoading(prev => prev - 1)
            }
        } else {
            setErrorInRun(true)
            setLoading(prev => prev - 1)
        }
    }

    const generateTableauFilter = (filters: any) => {
        let newFilter: any = {}
        let pendingFilter: any = {}
        let filtersLength = 0;
        for (let key of Object.keys(filters)) {
            filtersLength = filtersLength + key.length + ((filters[key] && filters[key].toString().length) || 0);
            if (filtersLength < 1100) {
                if (typeof filters[key] === 'string') {
                    newFilter[key] = filters[key].replace(/,/g, '\\,')
                } else {
                    newFilter[key] = filters[key]
                }
            } else {
                filtersLength = filtersLength - key.length - filters[key].toString().length
                pendingFilter[key] = filters[key]
            }
        }
        setPendingFilter(pendingFilter)
        return { newFilter, pendingFilter }
    }

    return (
        <>
            <Loader show={loading} text={'Loading...'}></Loader>
            {!errorInRun ?
                !noAccess
                    ? !sessionRunning
                        ? <div className='tableau-container' style={styles} ref={tableauContainer}>
                        </div>
                        : <div className=" d-flex justify-content-center my-5 ErrorHandler-container text-white">
                            <div className=" row Errorhandler-wrapper py-4 rounded shadow-sm">
                                <div className="col-3 ">
                                    <span className="mdi text-grey mdi-emoticon-sad-outline" ></span>
                                </div>
                                <div className="text-start col-9 d-flex justify-content-center flex-column l2-font-size">
                                    <h2 className="mb-4">Another Tableau Session Running</h2>
                                    <p className="text-grey">There is one another SMART dashboard already open in your browser</p>
                                    <p className="text-grey">If you wish to continue, Please click on the button below, the previous dashboard will be terminated</p>
                                    <button className="btn btn-secondary" onClick={deleteSession}>Continue</button>
                                </div>
                            </div>
                        </div>
                    : <Navigate to={'/requestTableauAccess'} /> : <ErrorHandler title='Unexpected Error' description='Sorry, We are facing an unexpected error while displaying report. We are trying our best to resolve this issue.' emoji='mdi-emoticon-sad-outline'></ErrorHandler>}
        </>
    )
})

export default TableauReport