const actionType = {   
    GET_MYENTRY_LIST: 'GET_MYENTRY_LIST',
    GET_MYENTRY_LIST_SUCCESS: 'GET_MYENTRY_LIST_SUCCESS',
    GET_MYENTRY_LIST_ERROR: 'GET_MYENTRY_LIST_ERROR',
    ADD_MYENTRY: 'ADD_MYENTRY',
    ADD_MYENTRY_SUCCESS: 'ADD_MYENTRY_SUCCESS',
    ADD_MYENTRY_ERROR: 'ADD_MYENTRY_ERROR',
    EDIT_MYENTRY: 'EDIT_MYENTRY',
    EDIT_MYENTRY_SUCCESS: 'EDIT_MYENTRY_SUCCESS',
    EDIT_MYENTRY_ERROR: 'EDIT_MYENTRY_ERROR',
    DELETE_MYENTRY: 'DELETE_MYENTRY',
    DELETE_MYENTRY_SUCCESS: 'DELETE_MYENTRY_SUCCESS',
    DELETE_MYENTRY_ERROR: 'DELETE_MYENTRY_ERROR',
    UPDATE_MYENTRY: 'UPDATE_MYENTRY',
    SELECT_ADD_MYENTRY: 'SELECT_ADD_MYENTRY',
    SELECT_EDIT_MYENTRY: 'SELECT_EDIT_MYENTRY',
    CLEAR_MYENTRY: 'CLEAR_MANAGE_MYENTRY',
    GET_MY_HOUR_REQUIRED: 'GET_MY_HOUR_REQUIRED',
    GET_MY_HOUR_REQUIRED_SUCCESS: 'GET_MY_HOUR_REQUIRED_SUCCESS',
    GET_MY_HOUR_REQUIRED_ERROR: 'GET_MY_HOUR_REQUIRED_ERROR',
    GET_SELF: 'GET_SELF',
    GET_SELF_SUCCESS: 'GET_SELF_SUCCESS',
    GET_SELF_ERROR: 'GET_SELF_ERROR',
    UPDATE_MY_ENTRY_LIST: 'UPDATE_MY_ENTRY_LIST'
}

export default actionType