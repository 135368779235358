import './BIPortal.scss';
import React, { Suspense, useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { IStoreState } from '../../types/StoreState';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import LoginAuthGuard from '../../Guard/LoginAuthGuard';
import RoleAuthGuard from '../../Guard/RoleAuthGuard';
import BiSidenav from './BiSidenav/BiSidenave';
import BiHeader from './BiHeader/BiHeader';
import BiHomePage from './BiHomePage/BiHomePage';
import Requests from './Requests/Requests';
import ReportRequest from '../Smart/ReportRequest/ReportRequest';
import RequestTableauAccess from '../Smart/requestTableauAccess/requestTableauAccess';
import ManageDe from '../ManageDe/ManageDe';
import Loader from '../shared/loader/Loader';
import SiteMap from './SiteMap/SiteMap';
import NoAccessPage from '../NoAccessPage/NoAccessPage';
import Quiz from './Quiz/Quiz';
import Help from '../shared/SharedHelpPage/Help';
import ProcessFlow from './ProcessFlow/ProcessFlow';
import CaReport from '../ClientAnalytics/CaReport/CaReport';
import ManageFact from './ManageFact/ManageFact';
import ManageQuiz from './ManageQuiz/ManageQuiz';
import CandidateUniquenes from './CandidateUniquenes/CandidateUniquenes';
import ManageSolutionMapping from './ManageSolutionMapping/ManageSolutionMapping';
import Confirmation from '../shared/Confirmation/Confirmation';
import ManageRole from './ManageRole/ManageRole';
import ManageUser from './ManageUser/ManageUser';
import ManageUserGroup from './ManageUserGroup/ManageUserGroup';
import GroupEmail from './GroupEmail/GroupEmail';
import ManageNotification from './ManageNotification/ManageNotification';
import AllNotification from './AllNotification/AllNotification';
import { getHomeNotification } from 'src/actions/loginAction';
import { rolesList } from 'src/constants/RoleList';
import ViewHistory from '../Smart/ViewHistory/ViewHistory';
import Jira from '../Jira/Jira';
import UserNotification from '../UserNotification/UserNotification';
import DataDictionary from './DataDictionary/DataDictionary';

//class start
const BIPortal = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        props.getHomeNotification();
    }, [])

    const viewAllNotification = () => {
        navigate('/notification');
    }


    return (
        <div className='w-100 d-flex bi-main-container'>
            <UserNotification navigate={viewAllNotification} />
            <BiSidenav></BiSidenav>
            <div id='bi-home' className='bi-home'>
                <BiHeader />
                <BreadCrumbs />
                <Routes>

                    <Route index element={
                        <LoginAuthGuard isLogin={props.isLogin.data} >
                            <BiHomePage />
                        </LoginAuthGuard>}
                    />

                    <Route path='/requests' element={
                        <LoginAuthGuard isLogin={props.isLogin.data} >
                            <Loader {...props.loader} />
                            <Requests />
                        </LoginAuthGuard>}
                    />
                    <Route
                        path="/piAccessRequest"
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data} >
                                <Loader {...props.loader} />
                                <ReportRequest />
                            </LoginAuthGuard>
                        }
                    />
                    <Route path='/requestTableauAccess'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <Loader {...props.loader} />
                                <RequestTableauAccess></RequestTableauAccess>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='clientReport/:kpiId/:reportId' element={
                        <LoginAuthGuard isLogin={props.isLogin.data}>
                            <CaReport></CaReport>
                        </LoginAuthGuard>
                    } />


                    <Route path='/deRequest'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.CSIT]} noAccessUrl={'/noaccess'}>
                                    <Loader {...props.loader} />
                                    <ManageDe></ManageDe>
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />
                    <Route path='/site-map'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <Loader {...props.loader} />

                                <SiteMap />
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/processFlow' element={
                        <ProcessFlow></ProcessFlow>
                    } />

                    <Route path='/manageFact' element={
                        <RoleAuthGuard requireRole={[rolesList.ADMIN]}>
                            <Loader {...props.loader} />
                            <ManageFact></ManageFact>
                        </RoleAuthGuard>

                    } />

                    {/* <Route path='/manageQuiz' element={
                        <RoleAuthGuard requireRole={[rolesList.ADMIN]}>
                            <Loader {...props.loader} />
                            <ManageQuiz></ManageQuiz>
                        </RoleAuthGuard>

                    } /> */}

                    <Route path='/manageSut'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.SUT]}>
                                    <Loader {...props.loader} />
                                    <ManageSolutionMapping></ManageSolutionMapping>
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />
                    <Route path='/candidateUniqueness'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.SUT]}>
                                    <Loader {...props.loader} />
                                    <CandidateUniquenes />
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/confirmation/:url'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <Loader {...props.loader} />
                                <Confirmation></Confirmation>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/manageRole'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.ADMIN]}>
                                    <Loader {...props.loader} />
                                    <ManageRole />
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/manageuser'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.ADMIN]}>
                                    <Loader {...props.loader} />
                                    <ManageUser />
                                </RoleAuthGuard>
                            </LoginAuthGuard>

                        } />
                    <Route path='/manageUserGroup'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.EMAIL_SERVICE]}>
                                    <Loader {...props.loader} />
                                    <ManageUserGroup></ManageUserGroup>
                                </RoleAuthGuard>
                            </LoginAuthGuard>

                        } />
                    <Route path='/sendEmail'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.EMAIL_SERVICE]}>
                                    <Loader {...props.loader} />
                                    <GroupEmail></GroupEmail>
                                </RoleAuthGuard>
                            </LoginAuthGuard>

                        } />

                    <Route path='/manageNotification'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <RoleAuthGuard requireRole={[rolesList.ADMIN]}>
                                    <Loader {...props.loader} />
                                    <ManageNotification></ManageNotification>
                                </RoleAuthGuard>
                            </LoginAuthGuard>

                        } />

                    <Route path='/notification'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data}>
                                <Loader {...props.loader} />
                                <AllNotification></AllNotification>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/jira'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data} >
                                <RoleAuthGuard requireRole={[6]} >
                                    <Jira></Jira>
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/flavours-of-data'
                        element={
                            <LoginAuthGuard isLogin={props.isLogin.data} >
                                <RoleAuthGuard requireRole={[rolesList.ADMIN, rolesList.REPORT_DEVELOPER]} >
                                    <DataDictionary></DataDictionary>
                                </RoleAuthGuard>
                            </LoginAuthGuard>
                        }
                    />

                    <Route path='/noaccess' element={<NoAccessPage />} />

                    <Route path='/quiz' element={
                        <LoginAuthGuard isLogin={props.isLogin.data}>
                            <Loader {...props.loader} />
                            <Quiz></Quiz>
                        </LoginAuthGuard>
                    } />


                    <Route path='/help' element={
                        <Help appName='SHL-Analytics' ></Help>
                    } />

                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </div>
        </div>
    );
}


//redux work start
const mapStateToProps = (state: IStoreState) => ({
    isLogin: state.login.isLogin,
    userRoles: state.login.userRoles,
    loader: state.notification.loader
})

const mapDispatchToProps = (dispatch: any) => ({
    getHomeNotification: () => {
        dispatch(getHomeNotification())
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(BIPortal);
