const actionType = {
    GET_NOTIFICATIONS_LIST: 'GET_NOTIFICATIONS_LIST',
    GET_NOTIFICATIONS_LIST_SUCCESS: 'GET_NOTIFICATIONS_LIST_SUCCESS',
    GET_NOTIFICATIONS_LIST_ERROR: 'GET_NOTIFICATIONS_LIST_ERROR',
    ADD_NOTIFICATIONS: 'ADD_NOTIFICATIONS',
    ADD_NOTIFICATIONS_SUCCESS: 'ADD_NOTIFICATIONS_SUCCESS',
    ADD_NOTIFICATIONS_ERROR: 'ADD_NOTIFICATIONS_ERROR',
    EDIT_NOTIFICATIONS: 'EDIT_NOTIFICATIONS',
    EDIT_NOTIFICATIONS_SUCCESS: 'EDIT_NOTIFICATIONS_SUCCESS',
    EDIT_NOTIFICATIONS_ERROR: 'EDIT_NOTIFICATIONS_ERROR',
    DELETE_NOTIFICATIONS: 'DELETE_NOTIFICATIONS',
    DELETE_NOTIFICATIONS_SUCCESS: 'DELETE_NOTIFICATIONS_SUCCESS',
    DELETE_NOTIFICATIONS_ERROR: 'DELETE_NOTIFICATIONS_ERROR',
    SELECT_ADD_NOTIFICATIONS: 'SELECT_ADD_NOTIFICATIONS',
    SELECT_EDIT_NOTIFICATIONS: 'SELECT_EDIT_NOTIFICATIONS',
    UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    UPDATE_NOTIFICATIONS_LIST: 'UPDATE_NOTIFICATIONS_LIST'
}

export default actionType