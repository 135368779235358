export const actionTypes = {
    GET_ALL_EMAIL_GROUP_LIST: 'GET_ALL_EMAIL_GROUP_LIST',
    GET_ALL_EMAIL_GROUP_LIST_SUCCESS: 'GET_ALL_EMAIL_GROUP_LIST_SUCCESS',
    GET_ALL_EMAIL_GROUP_LIST_FAILURE: 'GET_ALL_EMAIL_GROUP_LIST_FAILURE',
    SEND_EMAIL_TO_GROUPS: 'SEND_EMAIL_TO_GROUPS',
    SEND_EMAIL_TO_GROUPS_SUCCESS: 'SEND_EMAIL_TO_GROUPS_SUCCESS',
    SEND_EMAIL_TO_GROUPS_FAILURE: 'SEND_EMAIL_TO_GROUPS_FAILURE',
    CLEAR_EMAIL_GROUP:'CLEAR_EMAIL_GROUP',
    UPDATE_GROUP_EMAIL_INFO:'UPDATE_GROUP_EMAIL_INFO'
}
