import { IUserRoleState } from "../types/UserRole";
import actionType from '../actions/UserRoleActionTypes'

const initialState: IUserRoleState = {
    addRole: {
        loading: false,
        success: false,
        error: false,
        data: null
    },
    roles: {
        loading: false,
        success: false,
        error: false,
        data: []
    }

}

const userRoleReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_ROLE_LIST: {
            return {
                ...state,
                roles: Object.assign({}, {
                    success: false,
                    error: false,
                    data: []
                }, { loading: true })
            }
        }
        case actionType.GET_ROLE_LIST_SUCCESS: {
            return {
                ...state,
                roles: Object.assign({}, state.roles, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_ROLE_LIST_ERROR: {
            return {
                ...state,
                roles: Object.assign({}, state.roles, { loading: false, error: true, success: false })

            }
        }

        case actionType.ADD_NEW_ROLE: {
            return {
                ...state,
                addRole: Object.assign({}, state.addRole, { loading: true, success: false, error: false })
            }
        }
        case actionType.ADD_NEW_ROLE_SUCCESS: {
            return {
                ...state,
                addRole: Object.assign({}, state.addRole, { loading: false, data: action.payload, success: true, })
            }
        }
        case actionType.ADD_NEW_ROLE_ERROR: {
            return {
                ...state,
                addRole: Object.assign({}, state.addRole, { loading: false, error: true, success: false, })
            }
        }
        default:
            return state
    }
}

export default userRoleReducer