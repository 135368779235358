const actionType = {
    GET_USER_COUNT_BY_MONTH: 'GET_USER_COUNT_BY_MONTH',
    GET_USER_COUNT_BY_MONTH_SUCCESS: 'GET_USER_COUNT_BY_MONTH_SUCCESS',
    GET_USER_COUNT_BY_MONTH_ERROR: 'GET_USER_COUNT_BY_MONTH_ERROR',
    GET_USER_COUNT_BY_QUARTER: 'GET_USER_COUNT_BY_QUARTER',
    GET_USER_COUNT_BY_QUARTER_SUCCESS: 'GET_USER_COUNT_BY_QUARTER_SUCCESS',
    GET_USER_COUNT_BY_QUARTER_ERROR: 'GET_USER_COUNT_BY_QUARTER_ERROR',
    GET_REPORT_WISE_QUARTER_MATRIX: 'GET_REPORT_WISE_QUARTER_MATRIX',
    GET_REPORT_WISE_QUARTER_MATRIX_SUCCESS: 'GET_REPORT_WISE_QUARTER_MATRIX_SUCCESS',
    GET_REPORT_WISE_QUARTER_MATRIX_ERROR: 'GET_REPORT_WISE_QUARTER_MATRIX_ERROR',
    GET_DEPARTMENT_WISE_MONTH_MATRIX: 'GET_DEPARTMENT_WISE_MONTH_MATRIX',
    GET_DEPARTMENT_WISE_MONTH_MATRIX_SUCCESS: 'GET_DEPARTMENT_WISE_MONTH_MATRIX_SUCCESS',
    GET_DEPARTMENT_WISE_MONTH_MATRIX_ERROR: 'GET_DEPARTMENT_WISE_MONTH_MATRIX_ERROR',
    GET_DEPARTMENT_WISE_QUARTER_MATRIX: 'GET_DEPARTMENT_WISE_QUARTER_MATRIX',
    GET_DEPARTMENT_WISE_QUARTER_MATRIX_SUCCESS: 'GET_DEPARTMENT_WISE_QUARTER_MATRIX_SUCCESS',
    GET_DEPARTMENT_WISE_QUARTER_MATRIX_ERROR: 'GET_DEPARTMENT_WISE_QUARTER_MATRIX_ERROR',
    CLEAR_SMART_USAGE: 'CLEAR_SMART_USAGE'
}

export default actionType;