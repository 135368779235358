const actionType = {
    GET_TABLEAU_SUPER_USER_LIST: 'GET_TABLEAU_SUPER_USER_LIST',
    GET_TABLEAU_SUPER_USER_LIST_SUCCESS: 'GET_TABLEAU_SUPER_USER_LIST_SUCCESS',
    GET_TABLEAU_SUPER_USER_LIST_ERROR: 'GET_TABLEAU_SUPER_USER_LIST_ERROR',
    ADD_TABLEAU_SUPER_USER: 'ADD_TABLEAU_SUPER_USER',
    ADD_TABLEAU_SUPER_USER_SUCCESS: 'ADD_TABLEAU_SUPER_USER_SUCCESS',
    ADD_TABLEAU_SUPER_USER_ERROR: 'ADD_TABLEAU_SUPER_USER_ERROR',
    DELETE_TABLEAU_SUPER_USER: 'DELETE_TABLEAU_SUPER_USER',
    DELETE_TABLEAU_SUPER_USER_SUCCESS: 'DELETE_TABLEAU_SUPER_USER_SUCCESS',
    DELETE_TABLEAU_SUPER_USER_ERROR: 'DELETE_TABLEAU_SUPER_USER_ERROR',
    SELECT_ADD_TABLEAU_SUPER_USER: 'SELECT_ADD_TABLEAU_SUPER_USER',
    UPDATE_TABLEAU_SUPER_USER: 'UPDATE_TABLEAU_SUPER_USER',
    CLEAR_TABLEAU_SUPER_USER: 'CLEAR_TABLEAU_SUPER_USER',
    UPDATE_TABLEAU_SUPER_USER_LIST:'UPDATE_TABLEAU_SUPER_USER_LIST',
    SELECT_DELETE_TABLEAU_SUPER_USER: 'SELECT_DELETE_TABLEAU_SUPER_USER',
    PROCESS_NON_AGGREGATED_DATA:'PROCESS_NON_AGGREGATED_DATA',
    PROCESS_NON_AGGREGATED_DATA_SUCCESS:'PROCESS_NON_AGGREGATED_DATA_SUCCESS',
    PROCESS_NON_AGGREGATED_DATA_FAILURE:'PROCESS_NON_AGGREGATED_DATA_FAILURE',
    CLEAR_STATE:'CLEAR_STATE'
}

export default actionType