import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { JsxChild } from 'typescript'
import './Caraousel.scss'

interface ICarousel {
    children: JsxChild[],
    height: number,
    item_width: number,
    numberOfItem: number,
    inputMaxHeight?: number,
    name: string,
    rotated?: (index: number) => void
}

const Carousel = ({ children, item_width, numberOfItem, height, inputMaxHeight, name, rotated }: ICarousel) => {
    const innerContainer = useRef<HTMLDivElement>(null);
    const outerContainer = useRef<HTMLDivElement>(null);
    const [childHeight, setChildHeight] = useState<number>(0)

    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    // console.log(dimensions);
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }


    useEffect(() => {
        setChildHeight(height)
    }, [height])

    useEffect(() => {
        setInterval(() => {
            rotate()
        }, 3000)
        window.addEventListener("resize", handleResize, false);
        window.addEventListener("visibilitychange", setVisible, false)
        return () => {
            window.removeEventListener('visibilitychange', setVisible, false);
            window.sessionStorage.removeItem(`currentIndex_${name}`)
            window.sessionStorage.removeItem(`caraousel_${name}_stop`);
        }
    }, [])

    useEffect(() => {
        if (innerContainer && innerContainer.current) {
            let maxHeight = 0;
            let length = innerContainer.current.children.length;
            for (let i = 0; i < length; i++) {
                maxHeight = innerContainer.current.children[i].clientHeight > maxHeight ? innerContainer.current.children[i].clientHeight : maxHeight
            }
            if (inputMaxHeight && inputMaxHeight < maxHeight) {
                setChildHeight(inputMaxHeight)
                return
            }
            setChildHeight(maxHeight)
        }
    }, [children, dimensions])

    const setVisible = () => {
        if (document.visibilityState === 'visible') {
            start();
        } else if (document.visibilityState === 'hidden') {
            stop();
        }
    }

    const rotate = () => {
        if (innerContainer && innerContainer.current) {
            let index = Number(window.sessionStorage.getItem(`currentIndex_${name}`)) || 0
            const stop = window.sessionStorage.getItem(`caraousel_${name}_stop`);
            if (stop && stop === 'stop') return;
            let length = innerContainer.current.children.length;
            if (index > length - 1) index = 0;
            if (length < 2) return;
            let child: any = innerContainer.current.children[index];
            let width = child.getBoundingClientRect() && child.getBoundingClientRect().width;
            innerContainer.current.scroll({ left: (index + 1) * width, top: 0, behavior: 'smooth' })
            child = child.cloneNode(true)
            innerContainer.current.appendChild(child)
            window.sessionStorage.setItem(`currentIndex_${name}`, (index + 1).toString())
            rotated && rotated(index);
        }
    }

    const stop = () => {
        window.sessionStorage.setItem(`caraousel_${name}_stop`, 'stop')
    }

    const start = () => {
        window.sessionStorage.setItem(`caraousel_${name}_stop`, 'start')
    }

    return (
        <div className='d-flex align-items-center caraousel-container'>
            <div ref={outerContainer} className='outer-container' style={{ height: `${childHeight}px` }}>
                <div ref={innerContainer} onMouseOver={stop} onMouseEnter={stop} onMouseLeave={start} className='inner-container' style={{ height: `${childHeight + 20}px` }}>
                    {children.map((item: any) => <div className='w-100 child d-flex align-item-center'>
                        {item}
                    </div>)}
                </div>
            </div>
        </div>)
}

export default Carousel