import { IManageNotification } from "../types/ManageNotification";
import actionType from '../actions/ManageNotificationsActionTypes'
import moment from "moment";

const initialState: IManageNotification = {
    notificationsList: {
        loading: false,
        success: false,
        error: false,
        notifications: []
    },
    addNotificationsStatus: {
        loading: false,
        success: false,
        error: false
    },
    editNotificationsStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteNotificationsStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentNotifications: {
        title: '',
        notification: '',
        icon: '',
        roles: '',
        users: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    },
    editingNotifications: {
        notificationId: 0,
        title: '',
        notification: '',
        icon: '',
        roles: '',
        users: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    }

}

const manageNotificationReducer = (state = initialState, action: any): IManageNotification => {
    switch (action.type) {
        case actionType.GET_NOTIFICATIONS_LIST: {
            return {
                ...state,
                notificationsList: Object.assign({}, {
                    success: false,
                    error: false,
                    notifications: []
                }, { loading: true })
            }
        }
        case actionType.GET_NOTIFICATIONS_LIST_SUCCESS: {
            return {
                ...state,
                notificationsList: Object.assign({}, state.notificationsList, { loading: false, notifications: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_NOTIFICATIONS_LIST_ERROR: {
            return {
                ...state,
                notificationsList: Object.assign({}, state.notificationsList, { loading: false, error: true, success: false, notificationss: [] })

            }
        }
        case actionType.ADD_NOTIFICATIONS: {
            return {
                ...state,
                addNotificationsStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                addNotificationsStatus: Object.assign({}, state.addNotificationsStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                addNotificationsStatus: Object.assign({}, state.addNotificationsStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_NOTIFICATIONS: {
            return {
                ...state,
                editNotificationsStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                editNotificationsStatus: Object.assign({}, state.editNotificationsStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                editNotificationsStatus: Object.assign({}, state.editNotificationsStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_NOTIFICATIONS: {
            return {
                ...state,
                deleteNotificationsStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                deleteNotificationsStatus: Object.assign({}, state.deleteNotificationsStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                deleteNotificationsStatus: Object.assign({}, state.deleteNotificationsStatus, { loading: false, error: true, success: false })

            }
        }
        case actionType.UPDATE_NOTIFICATIONS_LIST: {
            return {
                ...state,
                notificationsList: Object.assign({}, state.notificationsList, { loading: false, notifications: action.payload, success: true, error: false })
            }
        }

        case actionType.SELECT_ADD_NOTIFICATIONS: {
            return {
                ...state,
                currentNotifications: {
                    title: '',
                    notification: '',
                    icon: '',
                    roles: '',
                    users: '',
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                editingNotifications: {
                    title: '',
                    notification: '',
                    icon: '',
                    roles: '',
                    users: '',
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                }
            }
        }

        case actionType.SELECT_EDIT_NOTIFICATIONS: {
            return {
                ...state,
                currentNotifications: Object.assign({}, action.payload),
                editingNotifications: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_NOTIFICATIONS: {
            return {
                ...state,
                currentNotifications: Object.assign({}, state.currentNotifications, { ...action.payload })
            }
        }

        case actionType.CLEAR_NOTIFICATIONS: {
            return { ...initialState, notificationsList: state.notificationsList }
        }

        default:
            return state
    }
}

export default manageNotificationReducer