import actionTypes from './ManageFactActionType'
import { ManageFactApi } from '../api/manageFactApi'
import { IFact, IDeleteFactPayload } from '../types/ManageFact'
const api = new ManageFactApi()

export const getFactList = () => {
    return api.getFactList({}, _getFactList, getFactListSuccess, getFactListFailure)
}

const _getFactList = () => {
    return {
        type: actionTypes.GET_FACT_LIST,
    }
}

export const getFactListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_FACT_LIST_SUCCESS,
        payload: data.data
    }
}

export const getFactListFailure = (data: any) => {
    return {
        type: actionTypes.GET_FACT_LIST_ERROR,
        payload: data
    }
}

export const addFactList = (payload: IFact) => {
    return api.addNewFact(payload, _addFactList, addFactListSuccess, addFactListFailure)
}

const _addFactList = () => {
    return {
        type: actionTypes.ADD_FACT,
    }
}

export const addFactListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_FACT_SUCCESS,
        payload: data.users
    }
}

export const addFactListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_FACT_ERROR,
        payload: data
    }
}

export const editFact = (payload: IFact) => {
    return api.editFact(payload, _editFact, editFactSuccess, editFactFailure)
}

const _editFact = () => {
    return {
        type: actionTypes.EDIT_FACT,
    }
}

export const editFactSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_FACT_SUCCESS,
        payload: data.users
    }
}

export const editFactFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_FACT_ERROR,
        payload: data
    }
}

export const deleteFact = (payload: IDeleteFactPayload) => {
    return api.deleteFact(payload, _deleteFact, deleteFactSuccess, deleteFactFailure)
}

const _deleteFact = () => {
    return {
        type: actionTypes.DELETE_FACT,
    }
}

export const deleteFactSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_FACT_SUCCESS,
        payload: data.data
    }
}

export const deleteFactFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_FACT_ERROR,
        payload: data
    }
}

export const selectAddFact = () => {
    return {
        type: actionTypes.SELECT_ADD_FACT,
    }
}

export const selectEditFact = (data: IFact) => {
    return {
        type: actionTypes.SELECT_EDIT_FACT,
        payload: data
    }
}

export const updateFactInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_FACT,
        payload: data
    }
}

export const clearSelectedFact = ()=> {
    return {
        type: actionTypes.CLEAR_FACT
    }
}

export const updateFactList = (data: IFact[]) => {
    return {
        type: actionTypes.UPDATE_FACT_LIST,
        payload: data
    }
}

export const getAllGeneratedFactList= () => {
    return api.getAllGeneratedFactList({noLoader: true} , _getAllGeneratedFactList , getAllGeneratedFactListSuccess , getAllGeneratedFactListFailure)
}

const _getAllGeneratedFactList = () => {
    return {
        type:actionTypes.GET_ALL_GENERATED_FACT_LIST
    }
} 

const getAllGeneratedFactListSuccess = (data:any) => {
    return {
        type:actionTypes.GET_ALL_GENERATED_FACT_LIST_SUCCESS,
        payload:data.data
    }
} 

const getAllGeneratedFactListFailure = (data:any) => {
    return {
        type:actionTypes.GET_ALL_GENERATED_FACT_LIST_FAILURE,
        payload:data
    }
} 