import actionTypes from './ManageUserGroupActionTypes'
import { ManageUserGroupApi } from '../api/manageUserGroupApi';
import { IUserGroup, IUserGroupAddPayload, IUserGroupEditPayload, IDeleteUserGroupPayload } from '../types/ManageUserGroup'
const api = new ManageUserGroupApi()

export const getUserGroupList = () => {
    return api.getUserGroupList({}, _getUserGroupList, getUserGroupListSuccess, getUserGroupListFailure)
}

const _getUserGroupList = () => {
    return {
        type: actionTypes.GET_USER_GROUP_LIST,
    }
}

export const getUserGroupListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_USER_GROUP_LIST_SUCCESS,
        payload: data.data
    }
}

export const getUserGroupListFailure = (data: any) => {
    return {
        type: actionTypes.GET_USER_GROUP_LIST_ERROR,
        payload: data
    }
}

export const addUserGroupList = (payload: IUserGroupAddPayload) => {
    return api.addNewUserGroup(payload, _addUserGroupList, addUserGroupListSuccess, addUserGroupListFailure)
}

const _addUserGroupList = () => {
    return {
        type: actionTypes.ADD_USER_GROUP,
    }
}

export const addUserGroupListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_USER_GROUP_SUCCESS,
        payload: data.data
    }
}

export const addUserGroupListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_USER_GROUP_ERROR,
        payload: data
    }
}

export const editUserGroup = (payload: IUserGroupEditPayload) => {
    return api.editUserGroup(payload, _editUserGroup, editUserGroupSuccess, editUserGroupFailure)
}

const _editUserGroup = () => {
    return {
        type: actionTypes.EDIT_USER_GROUP,
    }
}

export const editUserGroupSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_USER_GROUP_SUCCESS,
        payload: data.data
    }
}

export const editUserGroupFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_USER_GROUP_ERROR,
        payload: data
    }
}

export const deleteUserGroup = (payload: IDeleteUserGroupPayload) => {
    return api.deleteUserGroup(payload, _deleteUserGroup, deleteUserGroupSuccess, deleteUserGroupFailure)
}

const _deleteUserGroup = () => {
    return {
        type: actionTypes.DELETE_USER_GROUP,
    }
}

export const deleteUserGroupSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_USER_GROUP_SUCCESS,
        payload: data.data
    }
}

export const deleteUserGroupFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_USER_GROUP_ERROR,
        payload: data
    }
}

export const selectAddUserGroup = () => {
    return {
        type: actionTypes.SELECT_ADD_USER_GROUP,
    }
}

export const selectEditUserGroup = (data: IUserGroup) => {
    return {
        type: actionTypes.SELECT_EDIT_USER_GROUP,
        payload: data
    }
}

export const updateUserGroupInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_USER_GROUP,
        payload: data
    }
}

export const clearSelectedUserGroup = ()=> {
    return {
        type: actionTypes.CLEAR_SELECTED_USER_GROUP
    }
}

export const clearUserGroup = ()=> {
    return {
        type: actionTypes.CLEAR_USER_GROUP
    }
}

export const updateUserGroupList = (data: IUserGroup[]) => {
    return {
        type: actionTypes.UPDATE_USER_GROUP_LIST,
        payload: data
    }
}
