const actionType = {
   
    GET_SQUAD_LIST: 'GET_SQUAD_LIST',
    GET_SQUAD_LIST_SUCCESS: 'GET_SQUAD_LIST_SUCCESS',
    GET_SQUAD_LIST_ERROR: 'GET_SQUAD_LIST_ERROR',
    ADD_SQUAD: 'ADD_SQUAD',
    ADD_SQUAD_SUCCESS: 'ADD_SQUAD_SUCCESS',
    ADD_SQUAD_ERROR: 'ADD_SQUAD_ERROR',
    EDIT_SQUAD: 'EDIT_SQUAD',
    EDIT_SQUAD_SUCCESS: 'EDIT_SQUAD_SUCCESS',
    EDIT_SQUAD_ERROR: 'EDIT_SQUAD_ERROR',
    DELETE_SQUAD: 'DELETE_SQUAD',
    DELETE_SQUAD_SUCCESS: 'DELETE_SQUAD_SUCCESS',
    DELETE_SQUAD_ERROR: 'DELETE_SQUAD_ERROR',
    UPDATE_SQUAD: 'UPDATE_SQUAD',
    SELECT_ADD_SQUAD: 'SELECT_ADD_SQUAD',
    SELECT_EDIT_SQUAD: 'SELECT_EDIT_SQUAD',
    CLEAR_SQUAD: 'CLEAR_MANAGE_SQUAD',
}

export default actionType