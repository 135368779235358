import { CandidateUniquenesApi } from "../api/candidateUniquenesApi";
import actionType from "./candidateUniquenesActionType";
import store from "../store/store";
import { ISolutionTiming } from "../types/candidateUniqueness";

const api = new CandidateUniquenesApi();


export const getAllCompany = () => {
    return api.getAllCompany({}, _getAllCompany, getAllCompanySuccess, getAllCompanyFailure)
}


const _getAllCompany = () => {
    return {
        type: actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE
    }
}

const getAllCompanySuccess = (data: any) => {
    return {
        type: actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE_SUCCESS,
        payload: data.data
    }
}

const getAllCompanyFailure = (data: any) => {
    return {
        type: actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE_FAILURE,
        payload: data
    }
}


export const getAllSolutionTiming = (payload: any) => {
    return api.getAllSolutionTiming(payload, _getAllSolutionTiming, getAllSolutionTimingSuccess, getAllSolutionTimingFailure)
}

const _getAllSolutionTiming = () => {
    return {
        type: actionType.GET_ALL_SULUTION_TIMELINES
    }
}

const getAllSolutionTimingSuccess = (data: any) => {
    let solutionTiming: any = data.data
    solutionTiming = solutionTiming.map((item: ISolutionTiming) => {
        item.originalTiming = item.timing
        return item
    })
    return {
        type: actionType.GET_ALL_SULUTION_TIMELINES_SUCCESS,
        payload: solutionTiming
    }
}

const getAllSolutionTimingFailure = (data: any) => {
    return {
        type: actionType.GET_ALL_SULUTION_TIMELINES_FAILURE,
        payload: data
    }
}

export const updateSolutionTimings = (data: any) => {
    return {
        type: actionType.UPDATE_TIMELINE,
        payload: data
    }
}

export const addSolutionTimings = (payload: any) => {
    return api.addSolutionTimings(payload, _addSolutionTimings, addSolutionTimingsSuccess, addSolutionTimingsFailure)
}

const _addSolutionTimings = () => {
    return {
        type: actionType.ADD_SOLUTION_TIMINGS
    }
}

const addSolutionTimingsSuccess = (data: any) => {
    return {
        type: actionType.ADD_SOLUTION_TIMINGS_SUCCESS,
        payload: data
    }
}

const addSolutionTimingsFailure = (data: any) => {
    return {
        type: actionType.ADD_SOLUTION_TIMINGS_FAILURE,
        payload: data
    }
}

export const filterSutCompany = (searchKey: any, companyId: any) => {
    const state = store.getState();
    let count = 0;
    const filteredCompany = [];
    for (let item of state.candidateUniquenes.allCompanyList.data) {
        if (count > 100) {
            break
        }

        if (item && item.label.toLowerCase().includes(searchKey.toLowerCase())) {
            filteredCompany.push(item)
            count++
        }
    }
    if (companyId) {
        let currentCompany = state.candidateUniquenes.filteredSutTimingsCompanyList.find(item => item.value === companyId)
        filteredCompany.push(currentCompany)
    }

    return {
        type: actionType.FILTER_SUT_TIMING_COMPANY,
        payload: filteredCompany
    }
}

export const resetAllSolutionTimings = () => {
    let solutionTiming: any = store.getState().candidateUniquenes.originalTimings

    return {
        type: actionType.RESET_ALL_SOLUTION_TIMINGS,
        payload: solutionTiming
    }
}

export const clear = () => {
    return {
        type: actionType.CLEAR_SUT_TIMING_STATE
    }
}

export const addMoreCompany = () => {
    const state = store.getState();
    const filteredCompany = [...state.candidateUniquenes.filteredSutTimingsCompanyList, ...state.candidateUniquenes.allCompanyList.data.slice(0, 100)];

    return {
        type: actionType.FILTER_SUT_TIMING_COMPANY,
        payload: filteredCompany
    }
}

export const updateoriginalTimings = () => {
    const state = store.getState();
    let solutionTiming: any = state.candidateUniquenes.allSolutionTimingList.data
    solutionTiming = solutionTiming.map((item: ISolutionTiming) => {
        item.originalTiming = item.timing
        return item
    })
    return {
        type: actionType.UPDATE_ORIGINAL_TIMINGS,
        payload: solutionTiming
    }
}






