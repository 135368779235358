import { ITimesheetTeamEntryReducer } from "../types/Timesheet";
import actionType from '../actions/TimesheetTeamEntryActionTypes'

const initialState: ITimesheetTeamEntryReducer = {
    entry: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    }
}

const TimesheetTeamEntryReducer = (state = initialState, action: any): ITimesheetTeamEntryReducer => {
    switch (action.type) {
        case actionType.GET_TEAMENTRY_LIST: {
            return {
                ...state,
                entry: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_TEAMENTRY_LIST_SUCCESS: {
            return {
                ...state,
                entry: Object.assign({}, state.entry, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_TEAMENTRY_LIST_ERROR: {
            return {
                ...state,
                entry: Object.assign({}, state.entry, { loading: false, error: true, success: false, list: [] })

            }
        }
        case actionType.ADD_TEAMENTRY: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_TEAMENTRY_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_TEAMENTRY_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_TEAMENTRY: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_TEAMENTRY_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_TEAMENTRY_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_TEAMENTRY: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_TEAMENTRY_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_TEAMENTRY_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_TEAMENTRY: {
            return {
                ...state,
                currentEntry: {
                    userId: '',
                    projectId: 0,
                    projectName: '',
                    squadId: 0,
                    squadName: '',
                    weekCommencing: '',
                    businessPlanningConcept: 0,
                    projectManagement: 0,
                    analysis: 0,
                    designBuild: 0,
                    newDevelopment: 0,
                    testing: 0,
                    support: 0,
                    leave: 0,
                    sickLeave: 0,
                    generalAdmin: 0,
                    overtime: 0,
                    overtimeType: '',
                    hoursRequired: 0,
                    totalHours: 0,
                    status: '',
                    initiatives: ''
                }
            }
        }

        case actionType.SELECT_EDIT_TEAMENTRY: {
            return {
                ...state,
                currentEntry: Object.assign({}, action.payload),
                editEntry: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_TEAMENTRY: {
            return {
                ...state,
                currentEntry: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_TEAMENTRY: {
            delete state['editEntry'];
            return {
                ...state,
                currentEntry: {
                    userId: '',
                    projectId: 0,
                    projectName: '',
                    squadId: 0,
                    squadName: '',
                    weekCommencing: '',
                    businessPlanningConcept: 0,
                    projectManagement: 0,
                    analysis: 0,
                    designBuild: 0,
                    newDevelopment: 0,
                    testing: 0,
                    support: 0,
                    leave: 0,
                    sickLeave: 0,
                    generalAdmin: 0,
                    overtime: 0,
                    overtimeType: '',
                    hoursRequired: 0,
                    totalHours: 0,
                    status: '',
                    initiatives: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
            }
        }
        default:
            return state
    }
}

export default TimesheetTeamEntryReducer;