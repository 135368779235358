const actionType = {
    GET_FACT_LIST: 'GET_FACT_LIST',
    GET_FACT_LIST_SUCCESS: 'GET_FACT_LIST_SUCCESS',
    GET_FACT_LIST_ERROR: 'GET_FACT_LIST_ERROR',
    ADD_FACT: 'ADD_FACT',
    ADD_FACT_SUCCESS: 'ADD_FACT_SUCCESS',
    ADD_FACT_ERROR: 'ADD_FACT_ERROR',
    EDIT_FACT: 'EDIT_FACT',
    EDIT_FACT_SUCCESS: 'EDIT_FACT_SUCCESS',
    EDIT_FACT_ERROR: 'EDIT_FACT_ERROR',
    DELETE_FACT: 'DELETE_FACT',
    DELETE_FACT_SUCCESS: 'DELETE_FACT_SUCCESS',
    DELETE_FACT_ERROR: 'DELETE_FACT_ERROR',
    SELECT_ADD_FACT: 'SELECT_ADD_FACT',
    SELECT_EDIT_FACT: 'SELECT_EDIT_FACT',
    UPDATE_FACT: 'UPDATE_FACT',
    CLEAR_FACT: 'CLEAR_FACT',
    UPDATE_FACT_LIST:'UPDATE_FACT_LIST',
    GET_ALL_GENERATED_FACT_LIST:'GET_ALL_GENERATED_FACT_LIST',
    GET_ALL_GENERATED_FACT_LIST_SUCCESS:'GET_ALL_GENERATED_FACT_LIST_SUCCESS',
    GET_ALL_GENERATED_FACT_LIST_FAILURE:'GET_ALL_GENERATED_FACT_LIST_FAILURE'

}

export default actionType