import actionType from "src/actions/ManageRTBRequestActionTypes";
import { IManageRTBRequest } from "src/types/ManageRTBRequest";

const initialState: IManageRTBRequest = {
    extractRequestStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentExtractRequest: {
        query: '',
        isPivotEnabled: false,
        l1ColumnName: '',
        l2ColumnName: '',
        fixedColumns: '',
        uniqueKey: '',
        valueColumn: '',
        itsdUrl: '',
        fileName: '',
        parameters: [],
        userId: '',
        sheetSplitColumn: '',
        isConsolidated: false,
        sendEmailToUser:false,
        isScheduled:false,
        scheduledTime:0
    },
    allExtractRequestsList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    requestError: {
        queryError: false,
        firstLevelError: false,
        fixedColumnsError: false,
        valueColumnError: false,
        uniqueKeyError: false,
        sheetSplitColumnError: false,
        itsdError: false,
        fileNameError: false,
        parametersError: [],
        userId: false
    },
    updateExtractRequestStatus: {
        loading: false,
        success: false,
        error: false
    }

}

const manageRTBRequestReducer = (state = initialState, action: any): IManageRTBRequest => {
    switch (action.type) {
        case actionType.GET_EXTRACT_REQUEST_LIST: {
            return {
                ...state,
                allExtractRequestsList: Object.assign({}, {
                    success: false,
                    error: false,
                    data: []
                }, { loading: true })
            }
        }
        case actionType.GET_EXTRACT_REQUEST_LIST_SUCCESS: {
            return {
                ...state,
                allExtractRequestsList: Object.assign({}, state.allExtractRequestsList, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_EXTRACT_REQUEST_LIST_ERROR: {
            return {
                ...state,
                allExtractRequestsList: Object.assign({}, state.allExtractRequestsList, { loading: false, error: true, success: false })

            }
        }
        case actionType.ADD_EXTRACT_REQUEST: {
            return {
                ...state,
                extractRequestStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_EXTRACT_REQUEST_SUCCESS: {
            return {
                ...state,
                extractRequestStatus: Object.assign({}, state.extractRequestStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_EXTRACT_REQUEST_ERROR: {
            return {
                ...state,
                extractRequestStatus: Object.assign({}, state.extractRequestStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.UPDATE_EXTRACT_REQUEST_STATUS: {
            return {
                ...state,
                updateExtractRequestStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.UPDATE_EXTRACT_REQUEST_STATUS_SUCCESS: {
            return {
                ...state,
                updateExtractRequestStatus: Object.assign({}, state.updateExtractRequestStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.UPDATE_EXTRACT_REQUEST_STATUS_FAILURE: {
            return {
                ...state,
                updateExtractRequestStatus: Object.assign({}, state.updateExtractRequestStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.UPDATE_EXTRACT_REQUEST: {
            return {
                ...state,
                currentExtractRequest: Object.assign({}, state.currentExtractRequest, { ...action.payload })
            }
        }

        case actionType.UPDATE_EXTRACT_REQUEST_ERROR: {
            return {
                ...state,
                requestError: Object.assign({}, state.requestError, { ...action.payload })
            }
        }
        case actionType.CLEAR_EXTRACT_REQUEST: {
            return {
                ...state,
                extractRequestStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                updateExtractRequestStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                currentExtractRequest: {
                    query: '',
                    isPivotEnabled: false,
                    l1ColumnName: '',
                    l2ColumnName: '',
                    fixedColumns:'',
                    uniqueKey:'',
                    valueColumn:'',
                    itsdUrl:'',
                    fileName:'',
                    parameters:[],
                    userId:'',
                    sheetSplitColumn:'',
                    sendEmailToUser:false,
                    isScheduled:false,
                    scheduledTime:0,
                    isConsolidated: false
                },
                requestError: {
                    queryError: false,
                    firstLevelError: false,
                    fixedColumnsError: false,
                    valueColumnError: false,
                    uniqueKeyError: false,
                    sheetSplitColumnError: false,
                    itsdError: false,
                    fileNameError: false,
                    parametersError: [],
                    userId: false
                },
                allExtractRequestsList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                }
            }
        }
        default:
            return state
    }
}

export default manageRTBRequestReducer