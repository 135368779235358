import { connect } from "react-redux";
import { addUserJiraInfo, clearJiraInfo, updateUserJiraInfo } from "../../actions/JiraAction";
import { IStatus, IUserJiraInfo, IUserJiraInfoError } from "../../types/Jira";
import { IStoreState } from "../../types/StoreState";
import { useEffect, useState } from "react";
import TextArea from "../shared/Fields/TextArea";
import { INotification } from "../../types/notification";
import { showNotification } from "../../actions/notificationAction";
import { clearBreadCrumb, updateBreadCrumb } from "../../actions/BreadCrumbAction";
import Boolean from "../shared/Fields/Boolean";

interface IProps {
    formData:IUserJiraInfo,
    addUserJiraInfoStatus:IStatus,
    clearJiraInfo:() => void,
    addUserJiraInfo:(payload:IUserJiraInfo) => void,
    updateUserJiraInfo:(payload:any) => void,
    updateBreadCrumb:(data:any) => void,
    clearBreadCrumb:() => void,
    showNotification:(notification:INotification) => void
}

const Jira = ({formData,addUserJiraInfoStatus , updateUserJiraInfo , addUserJiraInfo , clearJiraInfo, updateBreadCrumb}:IProps) => {
     const [formError,setFormError]=useState<IUserJiraInfoError>({
        userJiraTokenError:false,
        jqlError:false
     })

     useEffect(()=> {
        const breadCrumb=[{label:'Home' , url:'/'},{label:'Jira' , url:'/'}] 
        updateBreadCrumb(breadCrumb)
        return () => {
            clearBreadCrumb()
            clearJiraInfo()
        }
     },[])

     useEffect(() => {
         if(addUserJiraInfoStatus && !addUserJiraInfoStatus.loading && addUserJiraInfoStatus.success) {
            const notification:INotification = {
                severity:'success',
                summary:'InfoFormation Saved Successfully',
                detail:'Your InfoFormation Is Saved Successfully',
                life:2000
            }
            showNotification(notification)
            onReset()
         }
     },[addUserJiraInfoStatus])

     const onUpdate = (name:string , value:any) => {
        let newValue:any={}
        newValue[name]=value
        updateUserJiraInfo(newValue)
     }

     const onReset = () => {
        let newValue:IUserJiraInfo={
            token:'',
            query:'',
            isRtbQuery:false
        }
        updateUserJiraInfo(newValue)
     }

     const validate = () => {
        let isValid=true
        let formErr:IUserJiraInfoError= {
            userJiraTokenError:false,
            jqlError:false
        }
        if(formData && !formData.token) {
             formErr.userJiraTokenError=true
             isValid=false
        }
        if(formData && !formData.query) {
            formErr.jqlError=true
            isValid=false
        }
        setFormError(formErr)
        return isValid  
     }

     const save = (e:any) => {
         if(!validate()) return 
         addUserJiraInfo(formData)
     }

     return <div className="jira w-100 p-4 d-flex justify-content-center">
            <div className="jira-container w-50 p-3 shadow-sm  flex-column justify-content-center align-item-center" >
            <div className='row mb-3 '>
                <label className='col-3 feild-label'>User Token</label>
                <div className='col-9 text-start'>
                    <TextArea size={3} value={formData.token} name='token' label='token' update={(name, value) => onUpdate(name, value)} error={formError?.userJiraTokenError} ></TextArea>
                    {formError && formError.userJiraTokenError ? <small className="text-danger">Please enter token</small> : null}
                </div>
            </div>
            <div className='row mb-3 '>
                <label className='col-3 feild-label'>JQL</label>
                <div className='col-9 text-start'>
                    <TextArea size={3} value={formData.query} name='query' label='jql' update={(name, value) => onUpdate(name, value)} error={formError?.jqlError} ></TextArea>
                    {formError && formError.jqlError ? <small className="text-danger">Please enter jql</small> : null}
                </div>
            </div>
            <div className='row mb-3'>
                <label className='col-3 feild-label'>Is RTB Query ?</label>
                <div className='col-9 text-start'>
                    <Boolean  value={formData.isRtbQuery ? 'true' : ''} name='isRtbQuery' update={(name, value) => onUpdate(name, value)} ></Boolean>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button className="btn btn-success" onClick={save} >Save<span className="mdi mdi-arrow-right ms-1" ></span></button>
                <button className="btn btn-secondary ms-4" onClick={onReset} > <span className="mdi mdi-refresh me-1"></span> Reset</button>
            </div>
            </div>
     </div>
 
}

const mapStateToProps = (state:IStoreState) => ({
    formData:state.jira.currentUserJiraInfo,
    addUserJiraInfoStatus:state.jira.addUserJiraInfoStatus
})
const mapDispatchToProps =(dispatch:any) => ({
    addUserJiraInfo:(payload:IUserJiraInfo) => {
        return dispatch(addUserJiraInfo(payload))
    },
    clearJiraInfo:() => {
        return dispatch(clearJiraInfo())
    },
    updateUserJiraInfo:(payload:any) => {
        return dispatch(updateUserJiraInfo(payload))
    },
    showNotification: (notification: INotification) => {
        dispatch(showNotification(notification));
    },
    updateBreadCrumb: (data: any) => {
        dispatch(updateBreadCrumb(data));
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    }

})

export default connect(mapStateToProps , mapDispatchToProps)(Jira);