
import './Help.scss'
interface IProps {
    appName:string
}
const Help = ({appName}:IProps) => {
        
    return (
        <div className="w-100  help-container mt-2">
            <div className='w-100 d-flex justify-content-center p-2 mb-4'>
            <div className="row  w-100 mx-2">
                <div className="col-6 d-flex align-items-end justify-content-center flex-column mx-4">
                    <div className='content-div text-start'>
                        <h1 className='fw-bold'>Sorry if you are facing any trouble while using {appName} , please reach out to A&IM.</h1>
                            <a className="my-4 btn text-white shadow-none" href="mailto:analytics@shl.com">Mail to A&IM</a>
                        <p className='l3-font-size'>If you are not redirected to the e-mail page, please reach out to analytics@shl.com</p>
                    </div>
                </div>

                <div className="col-5 ">
                    <img className=" mx-4 help_image w-100 rounded-circle" src={process.env.PUBLIC_URL + '/asset/help-page-image.png'} alt="" />
                </div>

            </div>
            </div>
        
        </div>
    )
}

export default Help;