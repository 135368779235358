const actionType = {
    GET_USER_GROUP_LIST: 'GET_USER_GROUP_LIST',
    GET_USER_GROUP_LIST_SUCCESS: 'GET_USER_GROUP_LIST_SUCCESS',
    GET_USER_GROUP_LIST_ERROR: 'GET_USER_GROUP_LIST_ERROR',
    ADD_USER_GROUP: 'ADD_USER_GROUP',
    ADD_USER_GROUP_SUCCESS: 'ADD_USER_GROUP_SUCCESS',
    ADD_USER_GROUP_ERROR: 'ADD_USER_GROUP_ERROR',
    EDIT_USER_GROUP: 'EDIT_USER_GROUP',
    EDIT_USER_GROUP_SUCCESS: 'EDIT_USER_GROUP_SUCCESS',
    EDIT_USER_GROUP_ERROR: 'EDIT_USER_GROUP_ERROR',
    DELETE_USER_GROUP: 'DELETE_USER_GROUP',
    DELETE_USER_GROUP_SUCCESS: 'DELETE_USER_GROUP_SUCCESS',
    DELETE_USER_GROUP_ERROR: 'DELETE_USER_GROUP_ERROR',
    SELECT_ADD_USER_GROUP: 'SELECT_ADD_USER_GROUP',
    SELECT_EDIT_USER_GROUP: 'SELECT_EDIT_USER_GROUP',
    UPDATE_USER_GROUP: 'UPDATE_USER_GROUP',
    CLEAR_SELECTED_USER_GROUP: 'CLEAR_SELECTED_USER_GROUP',
    CLEAR_USER_GROUP: 'CLEAR_USER_GROUP',
    UPDATE_USER_GROUP_LIST:'UPDATE_USER_GROUP_LIST'
}

export default actionType