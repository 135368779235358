import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { clearBreadCrumb, updateBreadCrumb } from "../../../actions/BreadCrumbAction";
import { clearGroupEmail, getAllEmailGroups, sendEmailToGroups, updateGroupEmailInfo } from "../../../actions/GroupEmailAction";
import { showNotification } from "../../../actions/notificationAction";
import { IGroupEmailError, IGroupList, ISendEmailPayload, IStatus } from "../../../types/GroupEmail";
import { INotification } from "../../../types/notification";
import { IStoreState } from "../../../types/StoreState";
import TextEditor from "../../shared/Fields/Editor";
import GroupedMultiSelect from "../../shared/Fields/GroupedMultiSelect";
import Text from "../../shared/Fields/Text";
import TextArea from "../../shared/Fields/TextArea";
import './GroupEmail.scss'

interface IProps {
    allGroupList: IGroupList,
    formData: ISendEmailPayload,
    sendEmailStatus: IStatus,
    updateGroupEmailInfo: (data: any) => void,
    getAllEmailGroups: () => void,
    sendEmailToGroups: (data: ISendEmailPayload) => void,
    clearGroupEmail: () => void,
    updateBreadCrumb: (breadcrumb: any) => void,
    clearBreadCrumb: () => void,
    showNotification: (notification: INotification) => void
}


const GroupEmail = ({
    allGroupList,
    sendEmailStatus,
    sendEmailToGroups,
    clearBreadCrumb,
    clearGroupEmail,
    updateBreadCrumb,
    showNotification,
    formData,
    updateGroupEmailInfo,
    getAllEmailGroups
}: IProps) => {
    const [formError, setFormError] = useState<IGroupEmailError>();
    const [displayEmailResetDialog, setDisplayEmailResetDialog] = useState<boolean>(false);
    const editorRef = useRef<any>(null);



    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'Send Email', url: '/' }]
        updateBreadCrumb(breadCrumb);
        getAllEmailGroups();
        return () => {
            clearBreadCrumb();
            clearGroupEmail()
        };
    }, [])

    useEffect(() => {
        if (formError && (formError.bodyError || formError.groupError || formError.subjectError)) {
            const notification: INotification = {
                severity: 'error',
                summary: 'Error',
                content: (
                    <div className='d-flex flex-column mt-2 text-start' style={{ flex: '1' }}>
                        <span>Please enter the following</span>
                        <span>{formError && formError.bodyError ? 'Email Body' : ''}</span>
                        <span>{formError && formError.subjectError ? 'Subject' : ''}</span>
                        <span>{formError && formError.groupError ? 'Recipient' : ''}</span>
                    </div>
                ),
                life: 2000
            }
            showNotification(notification)
        }
    }, [formError])

    useEffect(() => {
        if (sendEmailStatus && !sendEmailStatus.loading && sendEmailStatus.success) {
            const notification: INotification = {
                severity: 'success',
                summary: 'Sent',
                content:(
                    <div className='d-flex flex-column mt-2 text-start' style={{ flex: '1' }}>
                        <span className="mb-2">Sent</span>
                       <span>Email sent successfully</span>
                    </div>
                ),
                life: 2000
            }
            showNotification(notification)
            clearGroupEmail();
        }

    }, [sendEmailStatus])



    const scrollHidden = () => {
        document.body.style.overflow = 'hidden'
    }

    const scrollAuto = () => {
        document.body.style.overflow = 'auto'
    }

    const onUpdate = (name: any, value: any) => {
        let newValue: any = {};
        newValue[name] = value;
        updateGroupEmailInfo(newValue);
    }
    const validForm = () => {
        let formErr: IGroupEmailError = {
            groupError: false,
            subjectError: false,
            bodyError: false
        }
        if (!formData.groups)
            formErr.groupError = true;
        if (!formData.subject)
            formErr.subjectError = true;
        if (!formData.body || !editorRef.current.isValid())
            formErr.bodyError = true
        setFormError(formErr);
        for (let item of Object.values(formErr)) {
            if (item)
                return false;
        }
        return true;
    }

    const getMarginZero = (htmlString: string) => {
        return htmlString.replaceAll("<p", "<p style='margin:0px;'");
    }


    const onSave = (e: any) => {
        e.preventDefault();
        if (!validForm()) {

            return
        }
        let payload: ISendEmailPayload = {
            groups: formData.groups,
            subject: formData.subject,
            body: getMarginZero(formData.body),
            storeBody: editorRef.current.getStoreBody()
        }
        sendEmailToGroups(payload)
        scrollAuto();
    }

    const renderDeleteDialogFooter = () => {
        return (
            <div className='d-flex justify-content-center'>
                <button className='me-3 btn btn-danger' onClick={onResetReject}>Cancel</button>
                <button className='btn btn-success' onClick={onResetProceed}>Reset</button>
            </div>
        )
    }
    const onReset = (e: any) => {
        e.preventDefault();
        setDisplayEmailResetDialog(true);
    }
    const onResetReject = () => {
        setDisplayEmailResetDialog(false);
    }

    const onResetProceed = () => {
        clearGroupEmail();
        setDisplayEmailResetDialog(false);
    }

    return (
        <div className="group-email d-flex justify-content-center mt-2 w-100">
            <Dialog resizable={false} draggable={false} header='' visible={displayEmailResetDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={onResetReject} >
                <h4 className='text-danger'>{`You will lose all your content. Do you really want to reset the email?`}</h4>
            </Dialog>
            <form className='group-email-form h-100  w-75 l2-font-size'>
                <div className="upper-div mb-3 d-flex w-100">
                    <div className="">
                        <button onClick={(e) => onSave(e)} className="btn bg-light border shadow-none d-flex flex-column align-items-center" ><span className="mdi mdi-send l3-font-size" ></span> <span>Send</span> </button>
                    </div>
                    <div className="field-container ms-3" >
                        <div className='row mb-3 ms-0 max-width-100'>
                            <label className='btn col-1 bg-light border groups-label'>To</label>
                            <div className='col-11'>
                                <GroupedMultiSelect valueSelectedAll={'allUser'} panelClass='' value={formData.groups} selectOptions={allGroupList.data} update={(name, value) => onUpdate(name, value)} label='Recipients Groups' name='groups' disabled={false} error={false} noJoin={false} noApply={true} ></GroupedMultiSelect>
                            </div>
                        </div>
                        <div className='row ms-0 max-width-100'>
                            <label className='col-1'>Subject</label>
                            <div className='col-11 pe-0'>
                                <Text value={formData.subject} name='subject' label='Subject' update={(name, value) => onUpdate(name, value)} error={false}></Text>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className='h-75 col-12'>
                        <TextEditor ref={editorRef} value={formData.body} update={(name, value) => onUpdate(name, value)} label='Body' name='body'  ></TextEditor>
                    </div>
                </div>
                <div className="d-flex mt-3 justify-content-end" >
                    <button className="btn btn-secondary me-3" onClick={(e) => onReset(e)} >Reset</button>
                    <button onClick={(e) => onSave(e)} className="btn btn-success border shadow-none d-flex align-items-center" ><span className="mdi send-email-icon mdi-send l1-font-size me-1" ></span> <span>Send</span> </button>
                </div>

            </form>
        </div >
    )
}
const mapStateToProps = (state: IStoreState) => ({
    allGroupList: state.groupEmail.allGroupList,
    formData: state.groupEmail.currentGroupEmail,
    sendEmailStatus: state.groupEmail.sendEmailStatus

})
const mapDispatchToProps = (dispatch: any) => ({
    updateBreadCrumb: (data: any) => {
        dispatch(updateBreadCrumb(data));
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    getAllEmailGroups: () => {
        dispatch(getAllEmailGroups())
    },
    sendEmailToGroups: (data: ISendEmailPayload) => {
        dispatch(sendEmailToGroups(data));
    },
    showNotification: (notification: INotification) => {
        dispatch(showNotification(notification));
    },
    clearGroupEmail: () => {
        dispatch(clearGroupEmail());
    },
    updateGroupEmailInfo: (data: any) => {
        dispatch(updateGroupEmailInfo(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupEmail);