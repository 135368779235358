import actionTypes from './loginActionTypes'
import { LoginApi } from '../api/loginApi';
import tokenService from '../api/token.service';

const loginApi = new LoginApi()

export const checkIsAdmin = () => {
    return loginApi.checkIsAdmin({}, _checkIsAdmin, checkIsAdminSuccess, checkIsAdminFailure)
}

const _checkIsAdmin = () => {
    return {
        type: actionTypes.CHECK_IS_ADMIN,
    }
}

export const checkIsAdminSuccess = (data: any) => {
    return {
        type: actionTypes.CHECK_IS_ADMIN_SUCCESS,
        payload: data.admin
    }
}

export const checkIsAdminFailure = (data: any) => {
    return {
        type: actionTypes.CHECK_IS_ADMIN_ERROR,
        payload: data
    }
}

export const getAuthCode = (payload: any) => {
    return loginApi.getAuthCode(payload, _getAuthCode, getAuthCodeSuccess, getAuthCodeFailure)
}

const _getAuthCode = () => {
    return {
        type: actionTypes.GET_AUTH_CODE,
    }
}

export const getAuthCodeSuccess = (data: any) => {
    const user = {
        data: true,
        accessToken: data.access_token || data.accessToken,
        refreshToken: data.refresh_token || data.refreshToken
    }
    tokenService.setUser(user)
    return {
        type: actionTypes.GET_AUTH_CODE_SUCCESS,
        payload: user
    }
}

export const getAuthCodeFailure = (data: any) => {
    return {
        type: actionTypes.GET_AUTH_CODE_ERROR,
        payload: data
    }
}

export const getUserInfo = () => {
    return loginApi.getUserInfo({}, _getUserInfo, getUserInfoSuccess, getUserInfoFailure)
}

const _getUserInfo = () => {
    return {
        type: actionTypes.GET_USER_INFO,
    }
}

export const getUserInfoSuccess = (data: any) => {
    tokenService.setUserInfo(data)
    return {
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload: data
    }
}

export const getUserInfoFailure = (data: any) => {
    return {
        type: actionTypes.GET_USER_INFO_ERROR,
        payload: data
    }
}

export const refreshToken = (data: any) => {
    return {
        type: actionTypes.REFRESH_TOKEN,
        payload: data
    }
}

export const lastAccessURL = (data: string) => {
    return {
        type: actionTypes.LAST_ACCESS_URL,
        payload: data
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
}

export const updateUserRole = (roles: number[]) => {
    tokenService.setUserRoles(roles);
    return {
        type: actionTypes.UPDATE_USER_ROLE,
        payload: roles
    }
}

export const getUserRole = () => {
    return loginApi.getUserRole({}, _getUserRole, getUserRoleSuccess, getUserRoleFailure)
}

const _getUserRole = () => {
    return {
        type: actionTypes.GET_USER_ROLE,
    }
}

export const getUserRoleSuccess = (data: any) => {
    tokenService.setUserRoles(data.data);

    return {
        type: actionTypes.GET_USER_ROLE_SUCCESS,
        payload: data.data
    }
}

export const getUserRoleFailure = (data: any) => {
    return {
        type: actionTypes.GET_USER_ROLE_ERROR,
        payload: data
    }
}

export const getHomeNotification = () => {
    return loginApi.getHomeNotification({}, _getHomeNotification, getHomeNotificationSuccess, getHomeNotificationFailure)
}

const _getHomeNotification = () => {
    return {
        type: actionTypes.GET_HOME_NOTIFICATION,
    }
}

export const getHomeNotificationSuccess = (data: any) => {
    let all = data.data;
    let read = tokenService.getReadNotification()
    let unread = all.filter((item: any) => {
        return !read.some((i: any) => item.notificationId == i)
    })

    return {
        type: actionTypes.GET_HOME_NOTIFICATION_SUCCESS,
        payload: {
            all: all,
            unread: unread
        }
    }
}

export const getHomeNotificationFailure = (data: any) => {
    return {
        type: actionTypes.GET_HOME_NOTIFICATION_ERROR,
        payload: data
    }
}

export const markUnReadNotification = (notifications: any[]) => {
    return {
        type: actionTypes.MARK_READ_NOTIFICATION,
        payload: notifications
    }
}

