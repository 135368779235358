import { config } from "./config";
import { BaseApi } from "./baseApi";

const baseApi = new BaseApi();

export class BiHomePageApi {
     getCandidatesServedPerMonth(payload:any ,requestAction:any , requestSuccessAction:any , requestErrorAction:any ){
        return baseApi.get(config.apiUrl.getCandidatesServedPerMonth ,payload , requestAction , requestSuccessAction , requestErrorAction );
     }

     getDropoutsPerMonth(payload:any , requestAction:any , requestSuccessAction:any , requestErrorAction:any ) {
        return baseApi.get(config.apiUrl.getDropoutsPerMonth , payload , requestAction , requestSuccessAction , requestErrorAction);
     }

     getKpiBiHomeData (payload:any , requestAction:any , requestSuccessAction:any , requestErrorAction:any) {
      return baseApi.get(config.apiUrl.getKpiBiHomeData , payload , requestAction , requestSuccessAction , requestErrorAction )
     }
} 


 