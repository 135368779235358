import { ITimesheetInitiativeReducer } from "../types/Timesheet";
import actionType from 'src/actions/TimesheetInitiativeActionTypes';

// Initial state of the Reducer
/**
 * The initialState holds the default state values for the TimesheetInitiativeReducer
 */
const initialState: ITimesheetInitiativeReducer = {
    // Object to manage the state of initiatives list
    initiatives: {
        loading: false, // Flag to check if data is loading
        success: false, // Flag to check if data loaded successfully
        error: false, // Flag to check if there is an error
        list: [] // Array to store the list of initiatives
    },
    // Object to manage the state of user's initiatives list
    userInitiatives: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    // Object to manage the state of add initiative action
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to manage the state of edit initiative action
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to manage the state of delete initiative action
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to manage the state of mark complete initiative action
    markCompleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to manage the state of bulk upload initiative action
    bulkUploadActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    // Object to store the current initiative details
    currentInitiative: {
        projectId: 0,
        initiative: '',
        squads: ''
    }
}

/**
 * TimesheetInitiativeReducer handles the state of Timesheet Initiative feature
 * @param state - The current state of the Reducer
 * @param action - An action object containing type and payload
 * @returns The new state 
 */
const TimesheetInitiativeReducer = (state = initialState, action: any): ITimesheetInitiativeReducer => {
    switch (action.type) {
        case actionType.GET_INITIATIVE_LIST:
            return {
                ...state,
                initiatives: {
                    ...state.initiatives,
                    loading: true
                }
            };
        case actionType.GET_INITIATIVE_LIST_SUCCESS:
            return {
                ...state,
                initiatives: {
                    ...state.initiatives,
                    loading: false,
                    list: action.payload,
                    success: true,
                    error: false
                }
            };
        case actionType.GET_INITIATIVE_LIST_ERROR:
            return {
                ...state,
                initiatives: {
                    ...state.initiatives,
                    loading: false,
                    error: true,
                    success: false,
                    list: []
                }
            };


        case actionType.GET_USER_INITIATIVE_LIST: {
            return {
                ...state,
                userInitiatives: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_USER_INITIATIVE_LIST_SUCCESS: {
            return {
                ...state,
                userInitiatives: Object.assign({}, state.userInitiatives, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_INITIATIVE_LIST_ERROR: {
            return {
                ...state,
                userInitiatives: Object.assign({}, state.userInitiatives, { loading: false, error: true, success: false, list: [] })

            }
        }
        case actionType.ADD_INITIATIVE: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_INITIATIVE_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_INITIATIVE_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_INITIATIVE: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_INITIATIVE_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_INITIATIVE_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_INITIATIVE: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_INITIATIVE_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_INITIATIVE_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.BULK_UPLOAD_INITIATIVE: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.BULK_UPLOAD_INITIATIVE_SUCCESS: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, state.bulkUploadActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.BULK_UPLOAD_INITIATIVE_ERROR: {
            return {
                ...state,
                bulkUploadActionStatus: Object.assign({}, state.bulkUploadActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.MARK_COMPLETE_INITIATIVE: {
            return {
                ...state,
                markCompleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.MARK_COMPLETE_INITIATIVE_SUCCESS: {
            return {
                ...state,
                markCompleteActionStatus: Object.assign({}, state.markCompleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.MARK_COMPLETE_INITIATIVE_ERROR: {
            return {
                ...state,
                markCompleteActionStatus: Object.assign({}, state.markCompleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_INITIATIVE: {
            return {
                ...state,
                currentInitiative: {
                    projectId: 0,
                    initiative: '',
                    squads: ''
                }
            }
        }

        case actionType.SELECT_EDIT_INITIATIVE: {
            return {
                ...state,
                currentInitiative: Object.assign({}, action.payload),
                editInitiative: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_INITIATIVE: {
            return {
                ...state,
                currentInitiative: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_INITIATIVE: {
            delete state['editInitiative'];
            return {
                ...state,
                currentInitiative: {
                    projectId: 0,
                    initiative: '',
                    squads: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                markCompleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                bulkUploadActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }

        default:
            return state
    }
}

export default TimesheetInitiativeReducer;