import { ILoginReducer } from '../types/login';
import actionTypes from '../actions/loginActionTypes';
import tokenService from 'src/api/token.service';

const intialState: ILoginReducer = {
    isLogin: {
        loading: false,
        success: false,
        error: false,
        data: false,
        accessToken: '',
        refreshToken: ''
    },
    isAdmin: {
        loading: false,
        success: false,
        error: false,
        data: false
    },
    userInfo: {
        loading: false,
        success: false,
        error: false,
        data: null
    },
    lastAccessUrl: '/',
    userRoles: {
        roles: tokenService.getUserRoles(),
        success: false,
        loading: false,
        error: false
    },
    homeNotifications: {
        notifications: [],
        unreadNotifications: [],
        success: false,
        loading: false,
        error: false
    }
}

const loginReducer = (state = intialState, action: any): ILoginReducer => {
    switch (action.type) {
        case actionTypes.CHECK_IS_ADMIN: {
            return {
                ...state,
                isAdmin: Object.assign({}, state.isAdmin, { loading: true })
            }
        }
        case actionTypes.CHECK_IS_ADMIN_SUCCESS: {
            return {
                ...state,
                isAdmin: Object.assign({}, state.isAdmin, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.CHECK_IS_ADMIN_ERROR: {
            return {
                ...state,
                isAdmin: Object.assign({}, state.isAdmin, { loading: false, success: false, data: false, error: true })
            }
        }
        case actionTypes.GET_AUTH_CODE: {
            return {
                ...state,
                isLogin: Object.assign({}, state.isLogin, { loading: true })
            }
        }
        case actionTypes.GET_AUTH_CODE_SUCCESS: {
            return {
                ...state,
                isLogin: Object.assign({}, state.isLogin, { loading: false, success: true, ...action.payload })
            }
        }
        case actionTypes.GET_AUTH_CODE_ERROR: {
            return {
                ...state,
                isLogin: Object.assign({}, state.isLogin, { loading: false, success: false, data: false, error: true })
            }
        }

        case actionTypes.GET_USER_INFO: {
            return {
                ...state,
                userInfo: Object.assign({}, state.userInfo, { loading: true })
            }
        }
        case actionTypes.GET_USER_INFO_SUCCESS: {
            return {
                ...state,
                userInfo: Object.assign({}, state.userInfo, { loading: false, success: true, data: action.payload })
            }
        }
        case actionTypes.GET_USER_INFO_ERROR: {
            return {
                ...state,
                userInfo: Object.assign({}, state.userInfo, { loading: false, success: false, data: false, error: true })
            }
        }

        case actionTypes.LAST_ACCESS_URL: {
            return Object.assign({}, state, { lastAccessUrl: action.payload })
        }

        case actionTypes.LOGOUT: {
            return {
                ...state,
                userInfo: Object.assign({}, {
                    loading: false,
                    success: false,
                    error: false,
                    data: null
                }),
                isLogin: Object.assign({}, {
                    loading: false,
                    success: false,
                    error: false,
                    data: false,
                    accessToken: '',
                    refreshToken: ''
                }),
            }
        }

        case actionTypes.REFRESH_TOKEN: {
            return {
                ...state,
                isLogin: Object.assign({}, state.isLogin, { ...action.payload })
            }
        }

        case actionTypes.UPDATE_USER_ROLE: {
            return {
                ...state,
                userRoles: { roles: action.payload, success: true, loading: false, error: false }
            }
        }

        case actionTypes.GET_USER_ROLE: {
            return {
                ...state,
                userRoles: Object.assign({}, state.userRoles, { loading: true, success: false })
            }
        }
        case actionTypes.GET_USER_ROLE_SUCCESS: {
            return {
                ...state,
                userRoles: Object.assign({}, state.userRoles, { loading: false, success: true, roles: action.payload })
            }
        }
        case actionTypes.GET_USER_ROLE_ERROR: {
            return {
                ...state,
                userRoles: Object.assign({}, state.userRoles, { loading: false, success: false, error: false })
            }
        }

        case actionTypes.GET_HOME_NOTIFICATION: {
            return {
                ...state,
                homeNotifications: Object.assign({}, state.homeNotifications, { loading: true, success: false })
            }
        }
        case actionTypes.GET_HOME_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                homeNotifications: Object.assign({}, state.homeNotifications, { loading: false, success: true, notifications: action.payload.all, unreadNotifications: action.payload.unread })
            }
        }
        case actionTypes.GET_HOME_NOTIFICATION_ERROR: {
            return {
                ...state,
                homeNotifications: Object.assign({}, state.homeNotifications, { loading: false, success: false, error: false })
            }
        }

        case actionTypes.MARK_READ_NOTIFICATION: {
            return {
                ...state,
                homeNotifications: Object.assign({}, state.homeNotifications, { unreadNotifications: action.payload })
            }
        }

        default:
            return state
    }
}

export default loginReducer;