import actionType from '../actions/notificationActionTypes';
import { INotificationState } from '../types/notification'

const intialState: INotificationState = {
    notification: {
        severity: '',
        summary: '',
        detail: '',
        life: 0
    },
    loader: {
        show: 0,
        text: ''
    }
}

const notificationReducer = (state = intialState, action: any): INotificationState => {
    switch (action.type) {
        case actionType.SHOW_NOTIFICATION: {
            return {
                ...state,
                notification: action.payload
            }
        }

        case actionType.SHOW_LOADER: {
            return {
                ...state,
                loader: {
                    show: state.loader.show + 1,
                    text: action.payload
                }
            }
        }

        case actionType.HIDE_LOADER: {
            return {
                ...state,
                loader: {
                    show: state.loader.show > 0 ? state.loader.show - 1 : 0,
                    text: ''
                }
            }
        }

        default:
            return state
    }
}

export default notificationReducer