import { JiraApi } from "../api/jiraApi";
import { IUserJiraInfo } from "../types/Jira";
import actionTypes from "./JiraActionTypes";

const api = new JiraApi()

export const addUserJiraInfo = (payload:IUserJiraInfo) => {
    return api.addUserJiraInfo(payload,_addUserJiraInfo , addUserJiraInfoSuccess,addUserJiraInfoFailure)
}


const _addUserJiraInfo = () => {
    return {
        type:actionTypes.ADD_USER_JIRA_INFO
    }
}

const addUserJiraInfoSuccess = (data:any) => {
    return {
        type:actionTypes.ADD_USER_JIRA_INFO_SUCCESS,
        payload:data
    }
}

const addUserJiraInfoFailure = (data:any) => {
    return {
        type:actionTypes.ADD_USER_JIRA_INFO_FAILURE,
        payload:data
    }
}

export const updateUserJiraInfo = (data:any) => {
     return {
        type:actionTypes.UPDATE_USER_JIRA_INFO,
        payload:data
     }
}

export const clearJiraInfo = () => {
    return {
        type:actionTypes.CLEAR_JIRA_INFO
    }
}