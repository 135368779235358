import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import Text from '../../shared/Fields/Text'
import MultiSelect from '../../shared/Fields/Multiselect/MultiSelect';
import Email from '../../shared/Fields/Email';

import { IStoreState } from '../../../types/StoreState';
import { updateUserInfo } from '../../../actions/ManageUserAction';
import { getRoleList } from '../../../actions/UserRoleAction'
import { IRoles } from '../../../types/UserRole';
import { IUser, IUserError } from '../../../types/ManageUser'
import { IDropdownList } from '../../../types/common';
import { convertDropDownOption } from '../../../util/convertToDropdownOption'
interface IManageUserForm {
  roles: IRoles,
  updateUserInfo: (data: any) => void,
  getRoleList: () => void,
  formData: IUser,
  formError?: IUserError,
  editingUser?: IUser
}

const ManageUserForm = ({ roles, updateUserInfo, getRoleList, formData, formError, editingUser }: IManageUserForm) => {
  const [roleOption, setRoleOption] = useState<IDropdownList[]>([])

  useEffect(() => {
    if (roles && !roles.loading && roles.success) {
      setRoleOption(convertDropDownOption(roles.data, 'id', 'roleName'))
    }

    if (roles && !roles.loading && !roles.success) {
      getRoleList()
    }
  }, [roles])

  const onUpdate = (name: string, value: any) => {
    let newValue: any = {};
    newValue[name] = value
    updateUserInfo(newValue)
  }

  return (
    <form className='ManageUserForm l2-font-size'>
      <div className='row mb-3 '>
        <label className='col-3 '>UserName</label>
        <div className='col-9'>
          <Text value={formData.name} update={(name, value) => onUpdate(name, value)} label='UserName' name='name' error={formError && formError.nameError || false}></Text>
          {formError && formError.nameError ? <small className='l1-font-size text-danger'>Please enter name</small> : null}
        </div>

      </div>

      <div className='row mb-3 '>
        <label className='col-3 '>Email</label>
        <div className='col-9'>
          <Email value={formData.userId} name='userId' label='Email' update={(name, value) => onUpdate(name, value)} error={formError && formError.userIdError || false} disabled={editingUser ? true : false}></Email>
          {formError && formError.userIdError ? <small className='l1-font-size text-danger'>Please enter valid email address</small> : null}
        </div>
      </div>

      <div className='row '>
        <label className='col-3' >Roles</label>
        <div className='col-9'>
          <MultiSelect panelClass='custom-panel-class' selectOptions={roleOption} update={(name, value) => onUpdate(name, value)} label='Roles' name='roleIds' error={formError && formError.roleIdsError || false} disabled={false} noJoin={true} value={formData.roleIds}></MultiSelect>
          {formError && formError.roleIdsError ? <small className='l1-font-size text-danger'>Please select role id</small> : null}

        </div>
      </div>

    </form>
  )

}

const mapStateToProps = (state: IStoreState) => ({
  users: state.manageUser.userList.users,
  roles: state.userRole.roles,
  formData: state.manageUser.currentUser,
  editingUser: state.manageUser.editingUser
})


const mapDispatchToProps = (dispatch: any) => ({

  updateUserInfo: (data: any) => {
    dispatch(updateUserInfo(data))
  },
  getRoleList: () => {
    dispatch(getRoleList())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserForm);