const actionTypes = {
    CLEAR_TABLEAU_REQUEST_DATA: 'CLEAR_TABLEAU_REQUEST_DATA',
    SUBMIT_TABLEAU_REQUEST: 'SUBMIT_TABLEAU_REQUEST',
    SUBMIT_TABLEAU_REQUEST_SUCCESS: 'SUBMIT_TABLEAU_REQUEST_SUCCESS',
    SUBMIT_TABLEAU_REQUEST_ERROR: 'SUBMIT_TABLEAU_REQUEST_ERROR',
    CHECK_TABLEAU_REQUEST_STATUS: 'CHECK_TABLEAU_REQUEST_STATUS',
    CHECK_TABLEAU_REQUEST_STATUS_SUCCESS: 'CHECK_TABLEAU_REQUEST_STATUS_SUCCESS',
    CHECK_TABLEAU_REQUEST_STATUS_ERROR: 'CHECK_TABLEAU_REQUEST_STATUS_ERROR',
    GET_ALL_TABLEAU_REQUEST:'GET_ALL_TABLEAU_REQUEST',
    GET_ALL_TABLEAU_REQUEST_SUCCESS:'GET_ALL_TABLEAU_REQUEST_SUCCESS',
    GET_ALL_TABLEAU_REQUEST_FAILURE:'GET_ALL_TABLEAU_REQUEST_FAILURE',
    CHANGE_TABLEAU_REQUEST_STATUS:'CHANGE_TABLEAU_REQUEST_STATUS',
    CHANGE_TABLEAU_REQUEST_STATUS_SUCCESS:'CHANGE_TABLEAU_REQUEST_STATUS_SUCCESS',
    CHANGE_TABLEAU_REQUEST_STATUS_FAILURE:'CHANGE_TABLEAU_REQUEST_STATUS_FAILURE',
    CLEAR_PENDING_TABLEAU_REQUEST_DATA:'CLEAR_PENDING_TABLEAU_REQUEST_DATA',
    UPDATE_TABLEAU_REQUEST:'UPDATE_TABLEAU_REQUEST'
}

export default actionTypes;