const actionType = {
    GET_EXTRACT_LIST: 'GET_EXTRACT_LIST',
    GET_EXTRACT_LIST_SUCCESS: 'GET_EXTRACT_LIST_SUCCESS',
    GET_EXTRACT_LIST_ERROR: 'GET_EXTRACT_LIST_ERROR',
    GET_EXTRACT:'GET_EXTRACT',
    GET_EXTRACT_SUCCESS:'GET_EXTRACT_SUCCESS',
    GET_EXTRACT_ERROR:'GET_EXTRACT_FAILURE',
    ADD_EXTRACT: 'ADD_EXTRACT',
    ADD_EXTRACT_SUCCESS: 'ADD_EXTRACT_SUCCESS',
    ADD_EXTRACT_ERROR: 'ADD_EXTRACT_ERROR',
    EDIT_EXTRACT: 'EDIT_EXTRACT',
    EDIT_EXTRACT_SUCCESS: 'EDIT_EXTRACT_SUCCESS',
    EDIT_EXTRACT_ERROR: 'EDIT_EXTRACT_ERROR',
    DELETE_EXTRACT: 'DELETE_EXTRACT',
    DELETE_EXTRACT_SUCCESS: 'DELETE_EXTRACT_SUCCESS',
    DELETE_EXTRACT_ERROR: 'DELETE_EXTRACT_ERROR',
    SELECT_ADD_EXTRACT: 'SELECT_ADD_EXTRACT',
    SELECT_EDIT_EXTRACT: 'SELECT_EDIT_EXTRACT',
    UPDATE_EXTRACT: 'UPDATE_EXTRACT',
    CLEAR_EXTRACT: 'CLEAR_EXTRACT',
    UPDATE_EXTRACT_LIST:'UPDATE_EXTRACT_LIST'
}

export default actionType