// import { userRoles } from "../../constants/UserRolesList";
import { connect } from 'react-redux';
import './ManagerRole.scss'
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import { getRoleList, addNewRole } from '../../../actions/UserRoleAction';
import { IPayloadRole, IRole, IAddRole } from '../../../types/UserRole';
import { IStoreState } from '../../../types/StoreState';
import { INotification } from '../../../types/notification';
import { showNotification } from '../../../actions/notificationAction';

interface IManageRole {
  updateBreadCrumb: (breadCrumb: any) => void,
  clearBreadCrumb: () => void,
  getRoleList: () => void,
  addNewRole: (payload: IPayloadRole) => void,
  roles: IRole[],
  addRoleStatus: IAddRole,
  showNotification: (data: INotification) => void
}

const ManageRole = ({ updateBreadCrumb, clearBreadCrumb, getRoleList, addNewRole, roles, addRoleStatus, showNotification }: IManageRole) => {
  const [displayAddUserDialog, setDisplayAddUserDialog] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    const breadcrumb = [{ label: 'Home', url: '/' }, { label: 'ManageRole', url: '/' }]
    updateBreadCrumb(breadcrumb);
    getRoleList();
    return () => {
      clearBreadCrumb()
    }
  }, [])

  useEffect(() => {
    if (addRoleStatus && !addRoleStatus.loading && addRoleStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Success',
        detail: 'New role is added successfully',
        life: 2000
      }
      showNotification(notification)
      getRoleList()
      clear()
    }

    if (addRoleStatus && !addRoleStatus.loading && addRoleStatus.error) {
      clear()
    }
  }, [addRoleStatus])



  const renderData = roles.map((item: IRole, index: any) => (
    <tr className='no-last-border' key={`row${index}`}>
      <td>{item.roleName}</td>
      <td>{item.id}</td>
    </tr>
  ))

  const clear = () => {
    setNewRole('');
    setDisplayAddUserDialog(false);
    setShowWarning(false)
  }

  const addNewRoleClick = (e: any) => {
    setDisplayAddUserDialog(true);
  }

  const validateUserRole = () => {
    return newRole ? true : false;
  }

  const onAddUserProceed = (e: any) => {
    //here will make a api call. 
    e.preventDefault();
    if (validateUserRole()) {
      let payload: IPayloadRole = { role: newRole };
      addNewRole(payload)
    }
    else {
      setShowWarning(true);
    }

  }

  const onAddUserReject = () => {
    setNewRole('');
    setDisplayAddUserDialog(false);
    setShowWarning(false); 
  }

  const roleNameChange = (e: any) => {
    e.preventDefault();
    setNewRole(e.currentTarget.value);
  }

  const renderWarning = () => {
    return showWarning ? <span className="text-danger l2-font-size">Please enter a valid role</span> : null;
  }


  const renderDialogFooter = () => {
    return (
      <div className="">
        <button className="me-3 btn btn-danger" onClick={() => onAddUserReject()}>Cancel</button>
        <button className="btn btn-success" onClick={(e) => onAddUserProceed(e)}>Add Role</button>
      </div>

    )
  }

  return (
    <div className="mt-3 p-2 container d-flex justify-content-center">
      <div className="w-75 shadow-sm p-3">
        <Dialog resizable={false} draggable={false} header={renderWarning()} visible={displayAddUserDialog} footer={renderDialogFooter()} breakpoints={{ '960px': '75vw' }} style={{ width: '30vw' }} onHide={() => onAddUserReject()} >
          <form onSubmit={(e) => onAddUserProceed(e)} className='mt-1 d-flex l2-font-size'>
            <span className='me-2 text-danger'>*</span>
            <input type="text" className="form-control" value={newRole} onChange={(e) => roleNameChange(e)} placeholder='Enter New Role' />
          </form>
        </Dialog>
        <div className="d-flex justify-content-between align-items-center p-2">
          <h3 className="text-start text-global-custom fw-bold">User Roles</h3>
          <button className="btn btn-success" onClick={(e) => addNewRoleClick(e)}> <span className="mdi mdi-plus l3-font-size"></span>Add New Role</button>
        </div>
        <table className="table table-striped mb-0 mt-3">
          <thead>
            <tr>
              <th>User Role</th>
              <th>Role Id</th>
            </tr>
          </thead>
          <tbody>
            {renderData}
          </tbody>
        </table>
        
      </div>
    </div>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  roles: state.userRole.roles.data,
  addRoleStatus: state.userRole.addRole
})

const mapDispatchToProps = (dispatch: any) => ({
  updateBreadCrumb: (payload: any) => {
    dispatch(updateBreadCrumb(payload))
  },
  clearBreadCrumb: () => {
    dispatch(clearBreadCrumb())
  },
  getRoleList: () => {
    dispatch(getRoleList())
  },
  addNewRole: (payload: IPayloadRole) => {
    dispatch(addNewRole(payload))
  },
  showNotification: (data: INotification) => {
    dispatch(showNotification(data))
  }
})



export default connect(mapStateToProps, mapDispatchToProps)(ManageRole);