import { TableauRequestApi } from "../api/tableauRequestApi";
import { ITableauRequest } from "../types/tableauRequest";
import actionTypes from "./tableauRequestActionType";
const api = new TableauRequestApi()


export  const submitTableauRequest = (payload:any) => {
    return api.submitTableauRequest({} , payload , _submitTableauRequest , submitTableauRequestSuccess , submitTableauRequestFailure)
}

const _submitTableauRequest = () => {
      return {
         type:actionTypes.SUBMIT_TABLEAU_REQUEST
      }
}

const submitTableauRequestSuccess = (data:any) => {
      return {
        type:actionTypes.SUBMIT_TABLEAU_REQUEST_SUCCESS,
        payload:data
      }
}

const submitTableauRequestFailure = (data:any) => {
    return {
        type:actionTypes.SUBMIT_TABLEAU_REQUEST_ERROR ,
        payload:data
    }
}

export const checkIsTableauRequestPending = () => {
    return api.checkIsTableauRequestPending({} , _checkTableauRequestStatus , checkTableauReqeustStatusSuccess , checkTableauReqeustStatusFailure)
}

const _checkTableauRequestStatus = () => {
    return {
        type:actionTypes.CHECK_TABLEAU_REQUEST_STATUS
    }
}

const checkTableauReqeustStatusSuccess = (data:any) => {
        return {
            type:actionTypes.CHECK_TABLEAU_REQUEST_STATUS_SUCCESS,
            payload:data.data
        }
}

const checkTableauReqeustStatusFailure = (data:any) => {
    return {
        type:actionTypes.CHECK_TABLEAU_REQUEST_STATUS_ERROR,
        payload:data
    }
}

export const clearTableauRequestData = () => {
    return {
        type:actionTypes.CLEAR_TABLEAU_REQUEST_DATA
    }
}

export const getAllTableauRequest = () => {
    return api.getAllTableauRequest({} , _getAllTableauRequest , getAllTableauRequestSuccess , getAllTableauRequestFailure)
}

const _getAllTableauRequest = () => {
    return {
        type:actionTypes.GET_ALL_TABLEAU_REQUEST
    }
}

const getAllTableauRequestSuccess = (data:any) => {
    return {
        type:actionTypes.GET_ALL_TABLEAU_REQUEST_SUCCESS,
        payload:data
    }
}

const getAllTableauRequestFailure = (data:any) => {
    return {
        type:actionTypes.GET_ALL_TABLEAU_REQUEST_FAILURE,
        payload:data
    }
}

export const approveTableauRequest = (data:ITableauRequest) => {
    const payload = {
        requestId:data.requestId,
        status:'APPROVED'
    }
    return api.changeTableauRequestStatus({} , payload , startAction , actionSuccess , actionFailure)
       
}

export const rejectTableauRequest = (data:ITableauRequest) => {
    const payload = {
        requestId:data.requestId,
        status:'REJECTED'
    }
    return api.changeTableauRequestStatus({} , payload , startAction , actionSuccess , actionFailure)
       
}

const startAction = () => {
    return {
        type:actionTypes.CHANGE_TABLEAU_REQUEST_STATUS
    }
}

const actionSuccess = (data:any) => {
     return {
        type:actionTypes.CHANGE_TABLEAU_REQUEST_STATUS_SUCCESS,
        payload:data
     }
}

const actionFailure = (data:any) => {
    return {
       type:actionTypes.CHANGE_TABLEAU_REQUEST_STATUS_FAILURE,
       payload:data
    }
}

export const clearPendingTableauRequest = () => {
    return {
        type: actionTypes.CLEAR_PENDING_TABLEAU_REQUEST_DATA
    }
}

export const updateTableauRequest = (data:any) => {
    return {
        type:actionTypes.UPDATE_TABLEAU_REQUEST,
        payload:data
    }
}
