import { BaseApi } from "./baseApi";
import { config } from "./config";
const api = new BaseApi();

export class GroupEmailApi {

    getAllEmailGroups(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
        return api.get(config.apiUrl.getAllEmailGroups, parameter, requestAction, requestSuccessAction, errorAction)
    }

    sendEmailToGroups(parameter: any, payload: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
        return api.post(config.apiUrl.sendEmailToGroups, parameter, payload, requestAction, requestSuccessAction, errorAction);
    }


}