const actionTypes = {
        GET_CANDIDATES_SERVED_PER_MONTH:'GET_CANDIDATES_SERVED_PER_MONTH',
        GET_CANDIDATES_SERVED_PER_MONTH_SUCCESS:'GET_CANDIDATES_SERVED_PER_MONTH_SUCCESS',
        GET_CANDIDATES_SERVED_PER_MONTH_FAILURE:'GET_CANDIDATES_SERVED_PER_MONTH_FAILURE',
        GET_DROPOUTS_PER_MONTH:'GET_DROPOUTS_PER_MONTH',
        GET_DROPOUTS_PER_MONTH_SUCCESS:'GET_DROPOUTS_PER_MONTH_SUCCESS',
        GET_DROPOUTS_PER_MONTH_FAILURE:'GET_DROPOUTS_PER_MONTH_FAILURE',
        GET_KPI_BI_HOME_DATA:'GET_KPI_BI_HOME_DATA',
        GET_KPI_BI_HOME_DATA_SUCCESS:'GET_KPI_BI_HOME_DATA_SUCCESS',
        GET_KPI_BI_HOME_DATA_FAILURE:'GET_KPI_BI_HOME_DATA_FAILURE'

}

export default actionTypes