import { useNavigate } from 'react-router';
import './Confirmation.scss';



const Confirmation = () => {
    const navigate = useNavigate();
    const navigateToManageSut = () => {
        navigate(`/manageSut`)
    }

    return (
        <div className="w-100 d-flex justify-content-center p-1 confirmation-container">
            <div className="mt-4 shadow-sm d-flex flex-column align-items-center  p-3 content">
                <span className='mdi mdi-checkbox-marked-circle text-success'></span>
                <h3 className="text-start text-secondary">Information has been saved successfully.</h3>
                <button onClick={navigateToManageSut} className='btn btn-success my-4' >Continue</button>
            </div>
        </div>
    )
}

export default Confirmation;