import actionTypes from './ManageQuizActiontype'
import { ManageQuizApi } from '../api/manageQuizApi'
import { IQuiz, IDeleteQuizPayload } from '../types/ManageQuiz'
const api = new ManageQuizApi()

export const getQuizList = () => {
    return api.getQuizList({}, _getQuizList, getQuizListSuccess, getQuizListFailure)
}

const _getQuizList = () => {
    return {
        type: actionTypes.GET_QUIZ_LIST,
    }
}

export const getQuizListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_QUIZ_LIST_SUCCESS,
        payload: data.data
    }
}

export const getQuizListFailure = (data: any) => {
    return {
        type: actionTypes.GET_QUIZ_LIST_ERROR,
        payload: data
    }
}

export const addQuizList = (payload: IQuiz) => {
    return api.addNewQuiz(payload, _addQuizList, addQuizListSuccess, addQuizListFailure)
}

const _addQuizList = () => {
    return {
        type: actionTypes.ADD_QUIZ,
    }
}

export const addQuizListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_QUIZ_SUCCESS,
        payload: data.users
    }
}

export const addQuizListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_QUIZ_ERROR,
        payload: data
    }
}

export const editQuiz = (payload: IQuiz) => {
    return api.editQuiz(payload, _editQuiz, editQuizSuccess, editQuizFailure)
}

const _editQuiz = () => {
    return {
        type: actionTypes.EDIT_QUIZ,
    }
}

export const editQuizSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_QUIZ_SUCCESS,
        payload: data.users
    }
}

export const editQuizFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_QUIZ_ERROR,
        payload: data
    }
}

export const deleteQuiz = (payload: IDeleteQuizPayload) => {
    return api.deleteQuiz(payload, _deleteQuiz, deleteQuizSuccess, deleteQuizFailure)
}

const _deleteQuiz = () => {
    return {
        type: actionTypes.DELETE_QUIZ,
    }
}

export const deleteQuizSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_QUIZ_SUCCESS,
        payload: data.data
    }
}

export const deleteQuizFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_QUIZ_ERROR,
        payload: data
    }
}

export const selectAddQuiz = () => {
    return {
        type: actionTypes.SELECT_ADD_QUIZ,
    }
}

export const selectEditQuiz = (data: IQuiz) => {
    return {
        type: actionTypes.SELECT_EDIT_QUIZ,
        payload: data
    }
}

export const updateQuizInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_QUIZ,
        payload: data
    }
}

export const clearSelectedQuiz = ()=> {
    return {
        type: actionTypes.CLEAR_QUIZ
    }
}

export const updateQuizList = (data: IQuiz[]) => {
    return {
        type: actionTypes.UPDATE_QUIZ_LIST,
        payload: data
    }
}

export const getAllGeneratedQuizList= () => {
    return api.getAllGeneratedQuizList({} , _getAllGeneratedQuizList , getAllGeneratedQuizListSuccess , getAllGeneratedQuizListFailure)
}

const _getAllGeneratedQuizList = () => {
    return {
        type:actionTypes.GET_ALL_GENERATED_QUIZ_LIST
    }
} 

const getAllGeneratedQuizListSuccess = (data:any) => {
    return {
        type:actionTypes.GET_ALL_GENERATED_QUIZ_LIST_SUCCESS,
        payload:data.data
    }
} 

const getAllGeneratedQuizListFailure = (data:any) => {
    return {
        type:actionTypes.GET_ALL_GENERATED_QUIZ_LIST_FAILURE,
        payload:data
    }
} 

export const updateQuizError = (data:any) => {
    return {
      type:actionTypes.UPDATE_QUIZ_ERROR,
      payload:data
    }
}