import { showNotification } from '../actions/notificationAction';
import { INotification } from '../types/notification'
export const errorNotification = (store: any) => (next: any) => (action: any) => {
    if (action.payload && action.payload.isAxiosError) {
        if (action.payload.response.data && action.payload.response.data.errorList) {
            action.payload.response.data.errorList.forEach((item: any) => {
                const notification: INotification = {
                    severity: 'error',
                    summary: item.error,
                    detail: item.error_description,
                    life: 2000
                }
                store.dispatch(showNotification(notification))
            });
        }
    }

    let result = next(action)
    return result
}