
export const convertDropDownOption = (list: any[], valueKey?: string, labelKey?: string, isString?: boolean) => {
    if (valueKey && labelKey) {
        return list.map((item) => {
            return {
                label: isString ? item[labelKey].toString() : item[labelKey],
                value: isString ? item[valueKey].toString() : item[valueKey]
            }
        })
    } else {
        return list.map(item => { return { label: item, value: item } })
    }
}

export const convertDropDownOptionWithDisabled = (list: any[], enabledValue: any[], valueKey?: string, labelKey?: string, isString?: boolean) => {
    if (valueKey && labelKey) {
        let l: any[] = [];
        list.forEach((item) => {
            if (enabledValue.some((i: any) => i == item[valueKey])) {
                l.push({
                    label: isString ? item[labelKey].toString() : item[labelKey],
                    value: isString ? item[valueKey].toString() : item[valueKey],
                })
            }
        })
        return l;
    } else {
        return list.map(item => { return { label: item, value: item, disabled: enabledValue && !enabledValue.some((i: any) => i == item) } })
    }
}

