import { act } from "react-dom/test-utils";
import { actionTypes } from "../actions/ManageDeActionType";
import { IManageDe } from "../types/ManageDe";
const initialState: IManageDe = {
    allDeList: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    allDeHistoricRequestList: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    currentDeCompanyList: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    companyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    deUserList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    addDeStatus: {
        loading: false,
        success: false,
        error: false,
    },
    editDeStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteDeStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentDe: {
        companyId: null,
        companyName: null,
        source: null,
        userIds: [],
    },
    editingDe: undefined,
    filteredCompanyList: [],
    filteredUserList: [],
    triggerJobStatus: {
        loading: false,
        success: false,
        error: false
    }
}

const ManageDeReducer = (state = initialState, action: any): IManageDe => {
    switch (action.type) {
        case actionTypes.GET_ALL_DE_LIST: {
            return {
                ...state,
                allDeList: Object.assign({}, state.allDeList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_DE_LIST_SUCCESS: {
            return {
                ...state,
                allDeList: Object.assign({}, state.allDeList, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_DE_LIST_FAILURE: {
            return {
                ...state,
                allDeList: Object.assign({}, state.allDeList, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        case actionTypes.GET_DE_REQUEST_HISTORY: {
            return {
                ...state,
                allDeHistoricRequestList: Object.assign({}, state.allDeHistoricRequestList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_DE_REQUEST_HISTORY_SUCCESS: {
            return {
                ...state,
                allDeHistoricRequestList: Object.assign({}, state.allDeHistoricRequestList, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_DE_REQUEST_HISTORY_FAILURE: {
            return {
                ...state,
                allDeHistoricRequestList: Object.assign({}, state.allDeHistoricRequestList, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        case actionTypes.GET_COMPANY_LIST: {
            return {
                ...state,
                companyList: Object.assign({}, state.companyList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                companyList: Object.assign({}, state.companyList, { loading: false, success: true, error: false, data: action.payload }),
                filteredCompanyList: action.payload.slice(0, 100)
            }
        }
        case actionTypes.GET_COMPANY_LIST_FAILURE: {
            return {
                ...state,
                companyList: Object.assign({}, state.companyList, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        case actionTypes.GET_USER_LIST: {
            return {
                ...state,
                deUserList: Object.assign({}, state.deUserList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                deUserList: Object.assign({}, state.deUserList, { loading: false, success: true, error: false, data: action.payload }),
                filteredUserList: action.payload.slice(0, 100)
            }
        }
        case actionTypes.GET_USER_LIST_FAILURE: {
            return {
                ...state,
                deUserList: Object.assign({}, state.deUserList, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        case actionTypes.ADD_DE: {
            return {
                ...state,
                addDeStatus: Object.assign({}, state.addDeStatus, { loading: true, success: false, error: false })
            }
        }
        case actionTypes.ADD_DE_SUCCESS: {
            return {
                ...state,
                addDeStatus: Object.assign({}, state.addDeStatus, { loading: false, success: true, error: false })
            }
        }
        case actionTypes.ADD_DE_FAILURE: {
            return {
                ...state,
                addDeStatus: Object.assign({}, state.addDeStatus, { loading: false, success: false, error: true })
            }
        }
        case actionTypes.EDIT_DE: {
            return {
                ...state,
                editDeStatus: Object.assign({}, state.editDeStatus, { loading: true, success: false, error: false })
            }
        }
        case actionTypes.EDIT_DE_SUCCESS: {
            return {
                ...state,
                editDeStatus: Object.assign({}, state.editDeStatus, { loading: false, success: true, error: false })
            }
        }
        case actionTypes.EDIT_DE_FAILURE: {
            return {
                ...state,
                editDeStatus: Object.assign({}, state.editDeStatus, { loading: false, success: false, error: true })
            }
        }
        case actionTypes.DELETE_DE: {
            return {
                ...state,
                deleteDeStatus: Object.assign({}, state.deleteDeStatus, { loading: true, success: false, error: false })
            }
        }
        case actionTypes.DELETE_DE_SUCCESS: {
            return {
                ...state,
                deleteDeStatus: Object.assign({}, state.deleteDeStatus, { loading: false, success: true, error: false })
            }
        }
        case actionTypes.DELETE_DE_FAILURE: {
            return {
                ...state,
                deleteDeStatus: Object.assign({}, state.deleteDeStatus, { loading: false, success: false, error: true })
            }
        }
        case actionTypes.UPDATE_DE_INFO: {
            return {
                ...state,
                currentDe: Object.assign({}, state.currentDe, action.payload)
            }
        }
        case actionTypes.SELECT_ADD_DE: {
            return {
                ...state,
                currentDe: {
                    companyId: null,
                    companyName: null,
                    source: null,
                    userIds: []
                },
                editingDe: undefined
            }
        }
        case actionTypes.SELECT_EDIT_DE: {
            return {
                ...state,
                currentDe: Object.assign({}, action.payload),
                editingDe: Object.assign({}, action.payload)
            }
        }
        case actionTypes.CLEAR_SELECTED_DE: {
            return {
                ...state,
                currentDe: undefined,
                editingDe: undefined
            }
        }
        case actionTypes.FILTER_COMPANY: {
            return {
                ...state,
                filteredCompanyList: action.payload
            }
        }

        case actionTypes.FILTER_USER: {
            return {
                ...state,
                filteredUserList: action.payload
            }
        }
        case actionTypes.UPDATE_DE_LIST: {
            return {
                ...state,
                allDeList: Object.assign({}, state.allDeList, { loading: false, success: true, error: false, data: action.payload })
            }
        }

        case actionTypes.PROCESS_JOB: {
            return {
                ...state,
                triggerJobStatus: { loading: true, success: false, error: false }
            }
        }
        case actionTypes.PROCESS_JOB_SUCCESS: {
            return {
                ...state,
                triggerJobStatus: { loading: false, success: true, error: false }
            }
        }
        case actionTypes.PROCESS_JOB_FAILURE: {
            return {
                ...state,
                triggerJobStatus: { loading: false, success: false, error: true }
            }
        }

        case actionTypes.GET_CURRENT_DE_COMPANY_LIST: {
            return {
                ...state,
                currentDeCompanyList: Object.assign({}, state.currentDeCompanyList, { loading: true, success: false, error: false, data: [] })
            }
        }

        case actionTypes.GET_CURRENT_DE_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                currentDeCompanyList: Object.assign({}, state.currentDeCompanyList, { loading: false, success: true, error: false, data: action.payload })
            }
        }

        case actionTypes.GET_CURRENT_DE_COMPANY_LIST_FAILURE: {
            return {
                ...state,
                currentDeCompanyList: Object.assign({}, state.currentDeCompanyList, { loading: false, success: false, error: true, data: [] })
            }
        }

        case actionTypes.CLEAR_DE_REQUEST: {
            return {
                ...state,
                allDeHistoricRequestList: {
                    loading: false,
                    error: false,
                    success: false,
                    data: []
                },
                triggerJobStatus: { loading: false, success: false, error: false },
                addDeStatus: {
                    loading: false,
                    success: false,
                    error: false,
                },
                editDeStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteDeStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }
        default:
            return state
    }
}

export default ManageDeReducer;