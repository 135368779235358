import './NoAccessPage.scss'
import { connect } from 'react-redux';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const NoAccessPage = (props: any) => {
    const navigate = useNavigate();
    const goback = () => {
        navigate('/')
    }


    return (<div className='m-4 d-flex justify-content-center align-items-center flex-column no-access width-60 mx-auto shadow-box p-3 l2-font-size'>
        <div className='d-flex w-50 justify-content-center align-items-center'>
            <span className="mdi mdi-door-sliding-lock door-icon"></span>
        </div>
        <h3 className='title'>Restricted Access</h3>
        {/* <p className='title'>We are Sorry...</p> */}
        <p className='desc'>The page you're trying to access has restricted access.</p>
        <p className='desc'>Please refer to your system administrator.</p>
        <p className='desc'>If you want to access this page. Please send mail to A&IM team.</p>
        <button className='btn btn-back' onClick={goback}>Go Back</button>
    </div>)
}
export default NoAccessPage;