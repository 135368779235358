import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import MultiSelect from '../shared/Fields/Multiselect/MultiSelect';
import Select from '../shared/Fields/SingleSelect/Select';
import { IDeError, IDe,  ICompanyList, IDeUserList } from '../../types/ManageDe';
import { IStoreState } from '../../types/StoreState';
import { getDeUserList, updateDeInfo, filterCompany, filterUser, getCompanyList } from '../../actions/ManageDeAction';


interface IManageDeForm {
  formError?: IDeError,
  formData?: IDe,
  editingDe?: IDe,
  companyList: ICompanyList,
  deUserList: IDeUserList,
  getDeUserList: (param: any) => void,
  updateDeInfo: (data: any) => void,
  getCompanyList: (param: any) => void
}


const ManageDeForm = ({
  formError,
  formData,
  editingDe,
  companyList,
  deUserList,
  getDeUserList,
  updateDeInfo,
  getCompanyList
}: IManageDeForm) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const sourceOptions = [
    {
      label: "TC",
      value: "1"
    },
    {
      label: "TC+",
      value: "2"
    }

  ]

  useEffect(() => {
    if (formData && formData.source) {
      let source;
      source = formData.source;
      let param = {
        source: source
      }
      getDeUserList(param);
      getCompanyList(param);
      setIsDisabled(false);
    }
    else setIsDisabled(true);

  }, [formData?.source])

  useEffect(() => {


  }, [formData?.userIds])

  useEffect(() => {
    if (deUserList && !deUserList.loading && deUserList.success) {
      filterUser('')
    }

  }, [deUserList])

  useEffect(() => {
    if (companyList && !companyList.loading && companyList.success) {
      filterCompany('')
    }
  }, [companyList])


  const onUpdate = (name: any, value: any) => {
    console.log(typeof value)
    let newValue: any = {};
    newValue[name] = value;
    updateDeInfo(newValue);
  }

  return (
    <form className='ManageDeForm l2-font-size'>

      <div className='row mb-3 '>
        <label className='col-3 ' >Source</label>
        <div className='col-9'>
          <Select value={formData?.source} selectOptions={sourceOptions} update={(name, value) => onUpdate(name, value)} label='source' name='source' error={formError && formError.sourceError} disabled={editingDe && editingDe.source}></Select>
          {formError && formError.sourceError ? <small className='l1-font-size text-danger' >Please select a source</small> : null}
        </div>
      </div>

      <div className='row mb-3 '>
        <label className='col-3 '>Company Name</label>
        <div className='col-9'>
          <Select panelClass='panel-width-850' selectOptions={companyList.data} update={(name, value) => onUpdate(name, value)} label='company' name='companyId' error={formError && formError.companyError} disabled={(editingDe && editingDe.companyId) || isDisabled} value={formData && formData.companyId && formData.companyId.toString()}  ></Select>
          {formError && formError.companyError ? <small className='l1-font-size text-danger'>Please select company name</small> : null}
        </div>
      </div>

      <div className='row '>
        <label className='col-3 '>User</label>
        <div className='col-9'>
          <MultiSelect panelClass='panel-width-850' value={formData?.userIds} selectOptions={deUserList.data} update={(name, value) => onUpdate(name, value)} label='name' name='userIds' disabled={isDisabled} error={formError && formError.userError} noJoin={true}  noApply={true}></MultiSelect>
          {formError && formError.userError ? <small className='l1-font-size text-danger'>Please select a user </small> : null}
        </div>

      </div>


    </form>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  formData: state.manageDe.currentDe,
  companyList: state.manageDe.companyList,
  deUserList: state.manageDe.deUserList,
  editingDe: state.manageDe.editingDe
})

const mapDispatchToProps = (dispatch: any) => ({
  getDeUserList: (param: any) => {
    dispatch(getDeUserList(param))
  },
  updateDeInfo: (data: any) => {
    dispatch(updateDeInfo(data))
  },
  getCompanyList: (param: any) => {
    dispatch(getCompanyList(param))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDeForm);