import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ReportRequest {
    submitReportRequest(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.submitReportRequest, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    checkAccess(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.reportHasAccess, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    isPIRequestPending(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.isPIAccessRequestPending, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}
