import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class LoginApi {
  checkIsAdmin(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.checkIsAdmin, payload, requestAction, requestSuccessAction, requestErrorAction);
  }

  getAuthCode(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getAuthCode, payload, requestAction, requestSuccessAction, requestErrorAction);
  }

  getUserInfo(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getUserInfo, payload, requestAction, requestSuccessAction, requestErrorAction);
  }

  getUserRole({ }, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getUserSpecificRole, { noLoader: true }, requestAction, requestSuccessAction, requestErrorAction);
  }

  getHomeNotification({ }, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getHomeNotification, {}, requestAction, requestSuccessAction, requestErrorAction);
  }

}