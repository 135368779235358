import {UPDATE_STATUS , UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAILURE} from './DataRefreshingActionTypes';
import { DataRefreshing } from '../api/dataRefreshing';
const api = new DataRefreshing();

export const updateStatus = () => {
      return api.checkIsUpdating({},  _updateStatus , updateStatusSuccess , updateStatusFailure)
}

export const _updateStatus = () => {
     return {
           type:UPDATE_STATUS
     }
}

export const updateStatusSuccess = (data:any) => {
          return {
              type:UPDATE_STATUS_SUCCESS,
              payload:data
          }
}

export const updateStatusFailure = (data:any) => {
    return {
        type:UPDATE_STATUS_FAILURE,
        payload:data
    }
}