import { config } from './config'
import { BaseApi } from './baseApi'
const api = new BaseApi();

export class CaReportApi {
    getCategoryReports(requestParam: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
        return api.get(config.apiUrl.getCategoryReports, requestParam, requestAction, requestSuccessAction, errorAction)
    }
}

