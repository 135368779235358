import actionType from "src/actions/ManageSMARTDEActionType";
import { IManageSMARTDE } from "src/types/ManageSMARTDE";
import { flattenDiagnosticMessageText } from "typescript";

const initialState: IManageSMARTDE = {
    userAllCompanyList: {
        loading: false,
        success: false,
        error: false,
        data: {
            allCompanyAccess: false,
            mappings: [],
            justification: '',
            status: '',
            date: '',
            approvedBy: ''
        }
    },
    userAllPendingComapnyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    addNewCompaniesStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentAccessRequest: {
        justification: '',
        allCompanyAccess: false,
        companyList: []
    },
    isSuperDepartmentStatus: {
        loading: false,
        success: false,
        error: false,
        data: false
    },
    pendingSuperUser: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    approvedSuperUser: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    updateSuperUserRequestStatus: {
        loading: false,
        success: false,
        error: false
    },
    pendingCompanyUser: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    approvedCompanyUser: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    updateCompanyUserRequestStatus: {
        loading: false,
        success: false,
        error: false
    }
}


const manageSmartDeReducer = (state = initialState, action: any): IManageSMARTDE => {
    switch (action.type) {
        case actionType.GET_USER_ALL_COMPANY_LIST: {
            return {
                ...state,
                userAllCompanyList: Object.assign({}, state.userAllCompanyList, { loading: true })
            }
        }
        case actionType.GET_USER_ALL_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                userAllCompanyList: Object.assign({}, state.userAllCompanyList, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_ALL_COMPANY_LIST_ERROR: {
            return {
                ...state,
                userAllCompanyList: Object.assign({}, state.userAllCompanyList, { loading: false, error: true, success: false })

            }
        }
        case actionType.GET_USER_ALL_PENDING_COMPANY_LIST: {
            return {
                ...state,
                userAllPendingComapnyList: Object.assign({}, {
                    success: false,
                    error: false,
                    data: []
                }, { loading: true })
            }
        }
        case actionType.GET_USER_ALL_PENDING_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                userAllPendingComapnyList: Object.assign({}, state.userAllPendingComapnyList, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_ALL_PENDING_COMPANY_LIST_ERROR: {
            return {
                ...state,
                userAllPendingComapnyList: Object.assign({}, state.userAllPendingComapnyList, { loading: false, error: true, success: false })

            }
        }
        case actionType.ADD_NEW_COMPANY_STATUS: {
            return {
                ...state,
                addNewCompaniesStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_NEW_COMPANY_STATUS_SUCCESS: {
            return {
                ...state,
                addNewCompaniesStatus: Object.assign({}, state.addNewCompaniesStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_NEW_COMPANY_STATUS_ERROR: {
            return {
                ...state,
                addNewCompaniesStatus: Object.assign({}, state.addNewCompaniesStatus, { loading: false, error: true, success: false })

            }
        }
        case actionType.IS_SUPER_DEPARTMENT_STATUS: {
            return {
                ...state,
                isSuperDepartmentStatus: Object.assign({}, state.isSuperDepartmentStatus, {
                    loading: true,
                    success: false,
                    error: false
                })
            }
        }
        case actionType.IS_SUPER_DEPARTMENT_STATUS_SUCCESS: {
            return {
                ...state,
                isSuperDepartmentStatus: Object.assign({}, state.isSuperDepartmentStatus, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionType.IS_SUPER_DEPARTMENT_STATUS_ERROR: {
            return {
                ...state,
                isSuperDepartmentStatus: Object.assign({}, state.isSuperDepartmentStatus, { loading: false, error: true, success: false })

            }
        }
        case actionType.UPDATE_CURRENT_REQUEST: {
            return {
                ...state,
                currentAccessRequest: Object.assign({}, state.currentAccessRequest, { ...action.payload })
            }
        }
        case actionType.CLEAR_SMART_DE_STATE: {
            return {
                ...state,
                currentAccessRequest: {
                    justification: '',
                    allCompanyAccess: false,
                    companyList: []
                },
                addNewCompaniesStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }

        case actionType.GET_PENDING_SUPER_USER: {
            return {
                ...state,
                pendingSuperUser: Object.assign({}, state.pendingSuperUser, { loading: true })
            }
        }
        case actionType.GET_PENDING_SUPER_USER_SUCCESS: {
            return {
                ...state,
                pendingSuperUser: Object.assign({}, state.pendingSuperUser, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_PENDING_SUPER_USER_ERROR: {
            return {
                ...state,
                pendingSuperUser: Object.assign({}, state.pendingSuperUser, { loading: false, error: true, success: false, list: [] })

            }
        }

        case actionType.GET_APPROVED_SUPER_USER: {
            return {
                ...state,
                approvedSuperUser: Object.assign({}, state.approvedSuperUser, { loading: true })
            }
        }
        case actionType.GET_APPROVED_SUPER_USER_SUCCESS: {
            return {
                ...state,
                approvedSuperUser: Object.assign({}, state.approvedSuperUser, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_APPROVED_SUPER_USER_ERROR: {
            return {
                ...state,
                approvedSuperUser: Object.assign({}, state.approvedSuperUser, { loading: false, error: true, success: false, list: [] })
            }
        }

        case actionType.UPDATE_SUPER_USER_REQUEST: {
            return {
                ...state,
                updateSuperUserRequestStatus: { loading: true, success: false, error: false }
            }
        }

        case actionType.UPDATE_SUPER_USER_REQUEST_SUCCESS: {
            return {
                ...state,
                updateSuperUserRequestStatus: { loading: false, success: true, error: false }
            }
        }

        case actionType.UPDATE_SUPER_USER_REQUEST_ERROR: {
            return {
                ...state,
                updateSuperUserRequestStatus: { loading: false, success: false, error: true }
            }
        }

        case actionType.GET_PENDING_COMPANY_USER: {
            return {
                ...state,
                pendingCompanyUser: Object.assign({}, state.pendingCompanyUser, { loading: true })
            }
        }
        case actionType.GET_PENDING_COMPANY_USER_SUCCESS: {
            return {
                ...state,
                pendingCompanyUser: Object.assign({}, state.pendingCompanyUser, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_PENDING_COMPANY_USER_ERROR: {
            return {
                ...state,
                pendingCompanyUser: Object.assign({}, state.pendingCompanyUser, { loading: false, error: true, success: false, list: [] })

            }
        }

        case actionType.UPDATE_PENDING_COMPANY_USER: {
            return {
                ...state,
                pendingCompanyUser: Object.assign({}, state.pendingCompanyUser, { list: action.payload })
            }
        }

        case actionType.GET_APPROVED_COMPANY_USER: {
            return {
                ...state,
                approvedCompanyUser: Object.assign({}, state.approvedCompanyUser, { loading: true })
            }
        }
        case actionType.GET_APPROVED_COMPANY_USER_SUCCESS: {
            return {
                ...state,
                approvedCompanyUser: Object.assign({}, state.approvedCompanyUser, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_APPROVED_COMPANY_USER_ERROR: {
            return {
                ...state,
                approvedCompanyUser: Object.assign({}, state.approvedCompanyUser, { loading: false, error: true, success: false, list: [] })
            }
        }

        case actionType.UPDATE_APPROVED_COMPANY_USER: {
            return {
                ...state,
                approvedCompanyUser: Object.assign({}, state.approvedCompanyUser, { list: action.payload })
            }
        }

        case actionType.UPDATE_COMPANY_USER_REQUEST: {
            return {
                ...state,
                updateCompanyUserRequestStatus: { loading: true, success: false, error: false }
            }
        }

        case actionType.UPDATE_COMPANY_USER_REQUEST_SUCCESS: {
            return {
                ...state,
                updateCompanyUserRequestStatus: { loading: false, success: true, error: false }
            }
        }

        case actionType.UPDATE_COMPANY_USER_REQUEST_ERROR: {
            return {
                ...state,
                updateCompanyUserRequestStatus: { loading: false, success: false, error: true }
            }
        }

        case actionType.CLEAR_ACTION_STATUS: {
            return {
                ...state,
                updateSuperUserRequestStatus: { loading: false, success: false, error: false },
                updateCompanyUserRequestStatus: { loading: false, success: false, error: false }
            }
        }
        default: return state
    }
}

export default manageSmartDeReducer