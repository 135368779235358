import { actionTypes } from "./GroupEmailActionTypes";
import { GroupEmailApi } from "../api/groupEmailApi";
import { ISendEmailPayload } from "../types/GroupEmail";

const api = new GroupEmailApi();

export const getAllEmailGroups = () => {
    return api.getAllEmailGroups({}, _getAllEmailGroups, getAllEmailGroupsSuccess, getAllEmailGroupsFailure);
}

export const sendEmailToGroups = (payload: ISendEmailPayload) => {
    return api.sendEmailToGroups({}, payload, _sendEmailToGroups, sendEmailToGroupsSuccess, sendEmailToGroupsFailure);
}

const _getAllEmailGroups = () => {
    return {
        type: actionTypes.GET_ALL_EMAIL_GROUP_LIST
    }
}

const getAllEmailGroupsSuccess = (data: any) => {
    return {
        type: actionTypes.GET_ALL_EMAIL_GROUP_LIST_SUCCESS,
        payload: data.data
    }
}

const getAllEmailGroupsFailure = (data: any) => {
    return {
        type: actionTypes.GET_ALL_EMAIL_GROUP_LIST_FAILURE,
        payload: data
    }
}

const _sendEmailToGroups = () => {
    return {
        type: actionTypes.SEND_EMAIL_TO_GROUPS
    }
}

const sendEmailToGroupsSuccess = (data: any) => {
    return {
        type: actionTypes.SEND_EMAIL_TO_GROUPS_SUCCESS,
        payload: data
    }
}

const sendEmailToGroupsFailure = (data: any) => {
    return {
        type: actionTypes.SEND_EMAIL_TO_GROUPS_FAILURE,
        payload: data
    }
}


export const clearGroupEmail = () => {
    return {
        type: actionTypes.CLEAR_EMAIL_GROUP
    }
}

export const updateGroupEmailInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_GROUP_EMAIL_INFO,
        payload: data
    }
}
