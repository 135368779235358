import { useCallback, useEffect, useState } from 'react'

import Table, { IColumn } from 'src/components/shared/Table/Table';
import { data } from 'src/constants/dataDictionary';
import './DataDictionary.scss';
import { debounceFunction } from 'src/util/debounce';
interface IProps {

}


const DataDictionary = ({ }: IProps) => {
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    const [searchKey, setSearchKey] = useState('');
    const [searchResult, setSearchResult] = useState<any[]>([])
    const debouceSearch = useCallback(debounceFunction((nextValue: string) => updateList(nextValue), 400), []);

    useEffect(() => {
        setSearchResult(data);
    }, [])

    const column: IColumn[] = [
        {
            field: 'tableName',
            header: 'Table Name'
        },
        {
            field: 'description',
            header: 'About Table'
        }
    ];

    const userRequestTableColumns: IColumn[] = [
        {
            field: 'name',
            header: 'Column Name'
        },
        {
            field: 'columnDescription',
            header: 'About Column',
        }
    ]

    const handleSearchInput = (e: any) => {
        setSearchKey(e.target.value);
        debouceSearch(e.target.value)
    }


    const onRowToggle = (data: any) => {
        setExpandedRows(data)
    }

    const updateList = (searchKey: string) => {
        let d = [];
        for (let i of data) {
            if (i.tableName.toLowerCase().includes(searchKey.toLowerCase())) {
                d.push(i);
                continue;
            }
            // if (i.description && i.description.toLowerCase().includes(searchKey.toLowerCase())) {
            //     d.push(i);
            //     continue;
            // }
            let filterItems = [];
            for (let j of i.items) {
                if (j.name.toLowerCase().includes(searchKey.toLowerCase())) {
                    filterItems.push(j);
                    continue;
                }
                // if (j.columnDescription && j.columnDescription.toLowerCase().includes(searchKey.toLowerCase())) {
                //     filterItems.push(j);
                //     continue;
                // }
            }
            if (filterItems.length > 0) {
                d.push({
                    tableName: i.tableName,
                    description: i.description,
                    items: filterItems
                })
            }
        }
        setSearchResult(d);
        if (searchKey) {
            setExpandedRows(d);
        } else {
            setExpandedRows([])
        }
    }

    return (<div className="m-3 dictionary-container">
        <div className='d-flex shadow-box bg-light-green p-2 mb-4 justify-content-between align-items-center'>
            <span className='l3-font-size fw-600 text-white'>Flavours of Data</span>
            <div className='bg-white d-flex w-50'>
                <button className="btn search-btn btn-custom shadow-none border-0" ><span className="mdi mdi-magnify"></span></button>
                <input className="search-input shadow-none border-0 l2-font-size fw-bold " placeholder="Enter search key ( ex. Table Name ) " value={searchKey} onChange={(e) => handleSearchInput(e)}></input>
            </div>
        </div >
        <div className='mt-2 w-100'>
            <Table rows={16} expandedRows={expandedRows} onRowToggle={onRowToggle} expandedTableColumns={userRequestTableColumns} expandedTableDataColumn='items' data={searchResult} column={column} paginator={true}></Table>
        </div>
    </div >)
}

export default DataDictionary