import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ReportApi {
    getKpiReportList(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getReportAndKpiList, { noLoader: true }, requestAction, requestSuccessAction, requestErrorAction);
    }
    getAllKpiReportList(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.getFromJson(config.apiUrl.getAllKpi, { noLoader: true }, requestAction, requestSuccessAction, requestErrorAction);
    }
    getPublishedKpiReportList(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.getFromJson(config.apiUrl.getPublishedKpi, { noLoader: true }, requestAction, requestSuccessAction, requestErrorAction);
    }
    getFavouriteReport(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getFavouriteReport, {noLoader: true}, requestAction, requestSuccessAction, requestErrorAction)
    }
    setFavouriteReport(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.setFavouriteReport, {}, payload, requestAction, requestSuccessAction, requestErrorAction)
    }
    removeFromFavourite(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.removeFromFavourite, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
    getRecentReports(requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getRecentReports, {}, requestAction, requestSuccessAction, requestErrorAction);
    }
    getHomeKpiData(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getHomeKpiData, {...payload, noLoader: true}, requestAction, requestSuccessAction, requestErrorAction)
    }
}