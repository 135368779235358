import { BaseApi } from "./baseApi";
import { config } from './config'

const baseApi = new BaseApi();
export class CandidateUniquenesApi {
  getAllCompany(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getAllCandidateCompany, payload, requestAction, requestSuccessAction, requestErrorAction)
  }


  getAllSolutionTiming(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.get(config.apiUrl.getAllSolutionTiming, payload, requestAction, requestSuccessAction, requestErrorAction)
  }

  addSolutionTimings(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
    return baseApi.post(config.apiUrl.addSolutionTiming, {}, payload, requestAction, requestSuccessAction, requestErrorAction)
  }
} 