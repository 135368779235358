const actionType = {
    GET_PENDING_SECURE_REQUEST: 'GET_PENDING_SECURE_REQUEST',
    GET_PENDING_SECURE_REQUEST_SUCCESS: 'GET_PENDING_SECURE_REQUEST_SUCCESS',
    GET_PENDING_SECURE_REQUEST_ERROR: 'GET_PENDING_SECURE_REQUEST_ERROR',
    GET_APPROVED_SECURE_REQUEST: 'GET_APPROVED_SECURE_REQUEST',
    GET_APPROVED_SECURE_REQUEST_SUCCESS: 'GET_APPROVED_SECURE_REQUEST_SUCCESS',
    GET_APPROVED_SECURE_REQUEST_ERROR: 'GET_APPROVED_SECURE_REQUEST_ERROR',
    APPROVE_SECURE_REQUEST_REQUEST: 'APPROVE_SECURE_REQUEST_REQUEST',
    APPROVE_SECURE_REQUEST_REQUEST_SUCCESS: 'APPROVE_SECURE_REQUEST_REQUEST_SUCCESS',
    APPROVE_SECURE_REQUEST_REQUEST_ERROR: 'APPROVE_SECURE_REQUEST_REQUEST_ERROR',
    REJECT_SECURE_REQUEST_REQUEST: 'REJECT_SECURE_REQUEST_REQUEST',
    REJECT_SECURE_REQUEST_REQUEST_SUCCESS: 'REJECT_SECURE_REQUEST_REQUEST_SUCCESS',
    REJECT_SECURE_REQUEST_REQUEST_ERROR: 'REJECT_SECURE_REQUEST_REQUEST_ERROR',
    CLEAR_SECURE_REQUEST_ACTION_STATUS: 'CLEAR_SECURE_REQUEST_ACTION_STATUS',
}
export default actionType;