import './CandidateUniquenes.scss'
import Select from "../../shared/Fields/SingleSelect/Select";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Table, { IColumn } from "../../shared/Table/Table";
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { IStoreState } from '../../../types/StoreState';
import { Dialog } from 'primereact/dialog'
import { getAllCompany, getAllSolutionTiming, updateSolutionTimings, filterSutCompany, resetAllSolutionTimings, addSolutionTimings, clear, addMoreCompany, updateoriginalTimings } from '../../../actions/candidateUniquenesAction';
import { ICommonType, IPair, ISolutionTiming, ICandidateUniquenessPayload, ICandidateUniquenessError, IStatus } from '../../../types/candidateUniqueness';

import { clearBreadCrumb, updateBreadCrumb } from '../../../actions/BreadCrumbAction';
import { showNotification } from '../../../actions/notificationAction';
import { INotification } from '../../../types/notification';


interface IProps {
    updateBreadCrumb: (data: any) => void,
    clearBreadCrumb: () => void,
    allSolutionTimingList: ISolutionTiming[],
    allCompanyList: ICommonType,
    getAllCompany: () => void,
    getAllSolutionTiming: (payload: any) => void,
    updateSolutionTimings: (payload: any) => void,
    addSolutionTimings: (payload: ICandidateUniquenessPayload) => void,
    resetAllSolutionTimings: () => void,
    showNotification: (notification: INotification) => void,
    updateOriginalTimings:() => void,
    actionStatus: IStatus,
    clear: () => void,
    addMoreCompany: () => void
}


const CandidateUniqueness = ({ addMoreCompany, clear, allSolutionTimingList, getAllCompany,updateOriginalTimings, getAllSolutionTiming, updateSolutionTimings, addSolutionTimings, allCompanyList, resetAllSolutionTimings, updateBreadCrumb, clearBreadCrumb, showNotification, actionStatus }: IProps) => {
    const tableRef = useRef<any>(null);
    const [companyValue, setCompanyValue] = useState<any>();
    const [formError, setFormError] = useState<ICandidateUniquenessError>();
    const [displayResetWarning, setDisplayResetWarning] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)

    const allTimingOption = [{
        label: '6',
        value: '6'
    }, {
        label: '12',
        value: '12'
    }, {
        label: '18',
        value: '18'
    }, {
        label: '24',
        value: '24'
    }, {
        label: '30',
        value: '30'
    }, {
        label: '36',
        value: '36'
    }

    ]

    useEffect(() => {
        if (companyValue) {
            getAllSolutionTiming({ companyId: companyValue })
        }

    }, [companyValue])

    useEffect(() => {
        if (allCompanyList && !allCompanyList.loading && allCompanyList.success) {
            filterSutCompany('', null);
        }

    }, [allCompanyList])

    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'Define Candidate Uniqueness', url: '/' }]
        updateBreadCrumb(breadCrumb);
        if (allCompanyList && !allCompanyList.loading && !allCompanyList.success) {
            getAllCompany()
        }
        return () => {
            clearBreadCrumb();
            clear()
        }
    }, [])

    useEffect(() => {
        if (actionStatus && !actionStatus.loading && actionStatus.success) {
            const notification: INotification = {
                severity: 'success',
                detail: 'Uniqueness definition has been updated successfully.',
                summary: 'Success',
                life: 2000
              }
            updateOriginalTimings()
            setDisabled(true)
            showNotification(notification)
            
        }
    }, [actionStatus])

    useEffect(() => {
        if (getAddedOrDeletedMappings().length < 1) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [allSolutionTimingList])


    const filterObject = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    }
    const mappingEditor = (options: any) => {
        return <div className="custom-background-multiselect"><Select panelClass='mapping-editor-panel' selectOptions={allTimingOption} name="" label={'value in month'} value={options.value} update={(name, value) => options.editorCallback(value)} /></div>

    }

    const onRowEditComplete = (e: any) => {
        let _tableData = [...allSolutionTimingList];
        let { newData, index, data } = e;

        newData.lastTiming = data.timing
        _tableData[index] = newData;

        updateSolutionTimings(_tableData);
    }


    const validate = () => {
        let _formError: ICandidateUniquenessError = {
            companyError: false
        }

        if (!companyValue)
            _formError.companyError = true

        setFormError(_formError)

        if (tableRef.current && tableRef.current.editingRows && tableRef.current.editingRows.length > 0) {

            const notification: INotification = {
                severity: 'error',
                summary: `You have incomplete edit action for some rows. Please complete those.`,
                detail: ``, life: 3000
            }

            showNotification(notification)
            return false;

        }

        for (const item of Object.values(_formError)) {
            if (item) {
                return false
            }
        }
        return true;

    }
    const onCompanyUpdate = (value: any) => {
        setCompanyValue(value)
    }


    const getAddedOrDeletedMappings = () => {
        let solutions: ISolutionTiming[] = []
        allSolutionTimingList.forEach((item: ISolutionTiming) => {
            if (item.originalTiming !== item.timing) {
                let i = { ...item }
                delete i.originalTiming
                solutions.push(i)
            }
        })
        return solutions

    }

    const onSave = () => {
        if (!validate()) return
        let payload: ICandidateUniquenessPayload
        let solutions: ISolutionTiming[] = getAddedOrDeletedMappings()
        if (solutions.length !== 0) {
            payload = {
                companyId: companyValue,
                timings: solutions
            }
            addSolutionTimings(payload)
        }
    }

    const columns: IColumn[] = [
        {
            header: "Solution",
            field: "solution",
            applyFilter: false
        },
        {
            header: 'Original Uniqueness Definition',
            field: "lastTiming",
            applyFilter: false
        },
        {
            header: 'Updated Uniqueness Definition',
            field: "timing",
            applyFilter: false,
            cellEditor: mappingEditor
        }

    ]

    const onReset = () => {
        setDisplayResetWarning(true)
    }

    const onResetProceed = () => {
        setDisabled(true)
        resetAllSolutionTimings();
        setDisplayResetWarning(false)
    }

    const onResetReject = () => {
        setDisplayResetWarning(false)
    }


    const renderResetWarningDialogFooter = () => {
        return (
            <div className='d-flex justify-content-center'>
                <button className='me-5 btn btn-danger' onClick={onResetReject}> Cancel </button>
                <button className='btn btn-success' onClick={onResetProceed}> Ok </button>
            </div>
        )
    }

    const getSutBody = () => {
        if (!companyValue)
            return null;
        else if (allSolutionTimingList.length > 0) return <Table onRowEditComplete={onRowEditComplete} filterObject={filterObject} data={allSolutionTimingList} column={columns} tableRef={tableRef} editMode={'row'} paginator={true} keyColumn={'solution'}></Table>

    }

    const onCompanyBlur = () => {
        addMoreCompany()
    }


    return (
        <div className="manage-content-package-form w-100 p-3">
            <div className="d-flex justify-content-between align-items-end w-100 border-bottom p-2 mb-3">
                <h4 className="text-start fw-bold text-global-custom custom-title w-50">Update Candidate Uniqueness</h4>
                {companyValue ? <div className='w-50 d-flex justify-content-end me-4'>
                    <button disabled={disabled} onClick={onReset} className="btn btn-danger  action-btn me-3 "><span className='mdi mdi-restore l3-font-size me-1'></span>Reset</button>
                    <button disabled={disabled} onClick={onSave} className="btn btn-success action-btn ">Save<span className='mdi mdi-arrow-right l3-font-size ms-1 '></span></button>
                </div> : null}
            </div>

            <div className="form-container mt-3 shadow-sm px-4 pb-4 w-100 ">

                <div className='d-flex my-4 justify-content-start'>
                    <div className="w-25 ">
                        <div className='me-3 text-start'>
                            <label className='form-label l2-font-size'>Company </label>
                            <span className='text-danger'>*</span> <span>:</span>
                        </div>
                        <div className='w-100 text-start'>
                            <Select selectOptions={allCompanyList.data} update={(name, value) => onCompanyUpdate(value)} name="companyName" label="Company Name" value={companyValue} error={formError && formError.companyError ? true : false} onBlur={onCompanyBlur}></Select>
                            {formError && formError.companyError ? <small className='l0-font-size'>Please select company name</small> : null}
                        </div>

                    </div>
                </div>
                {getSutBody()}
                <div className="d-flex justify-content-end mt-4 w-100 ">
                    {companyValue && <div className='w-50 d-flex justify-content-end me-4'>
                        <button disabled={disabled} onClick={onReset} className="btn btn-danger action-btn me-3"><span className='mdi mdi-restore l3-font-size me-1'></span>Reset</button>
                        <button disabled={disabled} onClick={onSave} className="btn btn-success action-btn ">Save<span className='mdi mdi-arrow-right l3-font-size ms-1 '></span></button>
                    </div>}
                </div>
                <Dialog resizable={false} draggable={false} header='' visible={displayResetWarning} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderResetWarningDialogFooter()} onHide={onResetReject} >
                    <div className='dialog-heading text-center d-flex justify-content-center'>
                        <h4 className='text-danger w-75'>{`You Will Lose All Your Unsaved Changes After This Operation.`}</h4>
                    </div>
                </Dialog>
            </div>
        </div>
    )


}

const mapStateToProps = (state: IStoreState) => ({
    allCompanyList: state.candidateUniquenes.allCompanyList,
    allSolutionTimingList: state.candidateUniquenes.allSolutionTimingList.data,
    actionStatus: state.candidateUniquenes.addSolutionTimingsStatus
})

const mapDispatchToProps = (dispatch: any) => ({
    updateBreadCrumb: (data: any) => {
        dispatch(updateBreadCrumb(data));
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    getAllCompany: () => {
        dispatch(getAllCompany())
    },
    getAllSolutionTiming: (payload: any) => {
        dispatch(getAllSolutionTiming(payload))
    },
    updateSolutionTimings: (payload: any) => {
        dispatch(updateSolutionTimings(payload))
    },
    addSolutionTimings: (payload: ICandidateUniquenessPayload) => {
        dispatch(addSolutionTimings(payload))
    },
    resetAllSolutionTimings: () => {
        dispatch(resetAllSolutionTimings())
    },
    showNotification: (notification: INotification) => {
        dispatch(showNotification(notification))
    },
    clear: () => {
        dispatch(clear())
    },
    addMoreCompany: () => {
        dispatch(addMoreCompany())
    },
    updateOriginalTimings:() => {
        dispatch(updateoriginalTimings())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CandidateUniqueness);