import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageNotificationsApi {

    getNotificationsList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getNotificationList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewNotifications(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.curdNotification, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editNotifications(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.curdNotification, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteNotifications(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.curdNotification, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

}