import { IManageUserGroup } from "../types/ManageUserGroup";
import actionType from '../actions/ManageUserGroupActionTypes'

const initialState: IManageUserGroup = {
    userGroupList: {
        loading: false,
        success: false,
        error: false,
        userGroups: []
    },
    addUserGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    editUserGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteUserGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentUserGroup: {
        groupId:'',
        groupName:'',
        users:''
    },
    editingUserGroup: {
        groupId:'',
        groupName:'',
        users:''
    }

}

const manageUserGroupReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_USER_GROUP_LIST: {
            return {
                ...state,
                userGroupList: Object.assign({}, {
                    success: false,
                    error: false,
                    userGroups: []
                }, { loading: true })
            }
        }
        case actionType.GET_USER_GROUP_LIST_SUCCESS: {
            return {
                ...state,
                userGroupList: Object.assign({}, state.userGroupList, { loading: false, userGroups: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_GROUP_LIST_ERROR: {
            return {
                ...state,
                userGroupList: Object.assign({}, state.userGroupList, { loading: false, error: true, success: false })

            }
        }
        case actionType.ADD_USER_GROUP: {
            return {
                ...state,
                addUserGroupStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_USER_GROUP_SUCCESS: {
            return {
                ...state,
                addUserGroupStatus: Object.assign({}, state.addUserGroupStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_USER_GROUP_ERROR: {
            return {
                ...state,
                addUserGroupStatus: Object.assign({}, state.addUserGroupStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_USER_GROUP: {
            return {
                ...state,
                editUserGroupStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_USER_GROUP_SUCCESS: {
            return {
                ...state,
                editUserGroupStatus: Object.assign({}, state.editUserGroupStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_USER_GROUP_ERROR: {
            return {
                ...state,
                editUserGroupStatus: Object.assign({}, state.editUserGroupStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_USER_GROUP: {
            return {
                ...state,
                deleteUserGroupStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_USER_GROUP_SUCCESS: {
            return {
                ...state,
                deleteUserGroupStatus: Object.assign({}, state.deleteUserGroupStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_USER_GROUP_ERROR: {
            return {
                ...state,
                deleteUserGroupStatus: Object.assign({}, state.deleteUserGroupStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_USER_GROUP: {
            return {
                ...state,
                currentUserGroup: Object.assign({}, {
                    groupId:'',
                    groupName:'',
                    users:''
                }),
                editingUserGroup: null
            }
        }

        case actionType.SELECT_EDIT_USER_GROUP: {
            return {
                ...state,
                currentUserGroup: Object.assign({}, action.payload),
                editingUserGroup: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_USER_GROUP: {
            return {
                ...state,
                currentUserGroup: Object.assign({}, state.currentUserGroup, { ...action.payload })
            }
        }

        case actionType.CLEAR_USER_GROUP: {
            return {
                ...state,
                currentUserGroup: null,
                editingUserGroup: null,
                addUserGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editUserGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteUserGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                }

            }
        }

        case actionType.CLEAR_SELECTED_USER_GROUP: {
            return {
                ...state,
                currentUserGroup: null,
                editingUserGroup: null
            }
        }


        case actionType.UPDATE_USER_GROUP_LIST: {
            return {
                ...state,
                userGroupList: Object.assign({}, state.userGroupList, { loading: false, userGroups: action.payload, success: true, error: false })
            }
        }
        default:
            return state
    }
}

export default manageUserGroupReducer