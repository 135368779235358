const actionType = {
    GET_INITIATIVE_LIST: 'GET_INITIATIVE_LIST',
    GET_INITIATIVE_LIST_SUCCESS: 'GET_INITIATIVE_LIST_SUCCESS',
    GET_INITIATIVE_LIST_ERROR: 'GET_INITIATIVE_LIST_ERROR',
    ADD_INITIATIVE: 'ADD_INITIATIVE',
    ADD_INITIATIVE_SUCCESS: 'ADD_INITIATIVE_SUCCESS',
    ADD_INITIATIVE_ERROR: 'ADD_INITIATIVE_ERROR',
    EDIT_INITIATIVE: 'EDIT_INITIATIVE',
    EDIT_INITIATIVE_SUCCESS: 'EDIT_INITIATIVE_SUCCESS',
    EDIT_INITIATIVE_ERROR: 'EDIT_INITIATIVE_ERROR',
    DELETE_INITIATIVE: 'DELETE_INITIATIVE',
    DELETE_INITIATIVE_SUCCESS: 'DELETE_INITIATIVE_SUCCESS',
    DELETE_INITIATIVE_ERROR: 'DELETE_INITIATIVE_ERROR',
    MARK_COMPLETE_INITIATIVE: 'MARK_COMPLETE_INITIATIVE',
    MARK_COMPLETE_INITIATIVE_SUCCESS: 'MARK_COMPLETE_INITIATIVE_SUCCESS',
    MARK_COMPLETE_INITIATIVE_ERROR: 'MARK_COMPLETE_INITIATIVE_ERROR',
    BULK_UPLOAD_INITIATIVE: 'BULK_UPLOAD_INITIATIVE',
    BULK_UPLOAD_INITIATIVE_SUCCESS: 'BULK_UPLOAD_INITIATIVE_SUCCESS',
    BULK_UPLOAD_INITIATIVE_ERROR: 'BULK_UPLOAD_INITIATIVE_ERROR',
    SELECT_ADD_INITIATIVE: 'SELECT_ADD_INITIATIVE',
    SELECT_EDIT_INITIATIVE: 'SELECT_EDIT_INITIATIVE',
    UPDATE_INITIATIVE: 'UPDATE_INITIATIVE',
    CLEAR_INITIATIVE: 'CLEAR_MANAGE_INITIATIVE',
    GET_USER_INITIATIVE_LIST: 'GET_USER_INITIATIVE_LIST',
    GET_USER_INITIATIVE_LIST_SUCCESS: 'GET_USER_INITIATIVE_LIST_SUCCESS',
    GET_USER_INITIATIVE_LIST_ERROR: 'GET_USER_INITIATIVE_LIST_ERROR',
}

export default actionType