import { showNotification } from "../../../actions/notificationAction"
import { checkIsTableauRequestPending, clearTableauRequestData, submitTableauRequest } from "../../../actions/tableauRequestAction"
import { INotification } from "../../../types/notification"
import { IStoreState } from "../../../types/StoreState"
import { connect } from 'react-redux';
import { IStatus } from "../../../types/tableauRequest";
import { useEffect, useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate } from 'react-router-dom'
import ProcessComponent from "../../shared/ProcessComponent/ProcessComponent";
import './requestTableauAccess.scss'

interface ITableauRequestProps {
    tableauRequestStatus: IStatus,
    clearTableauRequestData: () => void,
    submitTableauRequest: (payload: any) => void,
    checkIsTableauRequestPending: () => void,
    raisedTableauRequestStatus: string,
    showNotification: (payload: INotification) => void
}


const RequestTableauAccess = ({ tableauRequestStatus, raisedTableauRequestStatus, clearTableauRequestData, checkIsTableauRequestPending, submitTableauRequest, showNotification }: ITableauRequestProps) => {
    const [requestSubmitted, setRequestSubmitted] = useState(false)
    const [justification, setJustification] = useState<string>('');
    const navigate = useNavigate()

    useEffect(() => {
        checkIsTableauRequestPending();
        return () => {
            clearTableauRequestData();
        }
    }, [])

    useEffect(() => {
        if (tableauRequestStatus && !tableauRequestStatus.loading && tableauRequestStatus.success) {
            setRequestSubmitted(true)
        }
    }, [tableauRequestStatus])

    const valid = () => {
        return justification.trim().length
    }
    const tableRequestRequestProcess = {
        processName: 'Tableau Access Request Status',
        processDesc: 'You have requested access for Tableau Dashboards. Current status of your request is below.',
        steps: [
            {
                title: 'Request',
                desc: 'A Tableau request has been submitted by the user.'
            },
            {
                title: 'Pending',
                desc: 'Your request is pending for A&IM Team approval.'
            },
            {
                title: raisedTableauRequestStatus != 'pending' ? 'Approved' : 'Approve/Reject',
                desc: raisedTableauRequestStatus != 'pending' ? 'Your request has been approved by A&IM Team' : 'After considering the request, A&IM Team may approve or reject the request.'
            },
            {
                title: 'Access Granted',
                desc: 'You have access to Tableau Dashboards.'
            }
        ]
    }

    const submit = () => {
        if (!valid()) {
            showNotification({
                severity: 'error',
                summary: 'Justification is required.',
                detail: '',
                life: 2000
            })
            return
        };
        const payload = {
            justification: justification
        }

        submitTableauRequest(payload)
    }

    const onFieldChange = (event: any) => {
        setJustification(event.currentTarget.value)
    }

    const getRequestDom = () => {

        if (requestSubmitted || raisedTableauRequestStatus === 'pending') {
            return (
                <div className="mx-auto mb-5 shadow process-wrapper"> <ProcessComponent {...tableRequestRequestProcess} processIndex={1} status={1}  ></ProcessComponent> </div>
            )
        }
        else if (raisedTableauRequestStatus === 'approved') {
            return (
                <div className="mx-auto mb-5 shadow process-wrapper"> <ProcessComponent {...tableRequestRequestProcess} processIndex={1} status={2}  ></ProcessComponent> </div>
            )
        }
        else if (raisedTableauRequestStatus === 'created') {
            return (
                <div className="mx-auto mb-5 shadow process-wrapper"> <ProcessComponent {...tableRequestRequestProcess} processIndex={1} status={3}  ></ProcessComponent> </div>
            )
        }
        else return (
            <div>
                <h4>You don't have viewer license, Please request access to view reports.</h4>
                <h6>This is a one time request. Access will be granted after admin approval.</h6>
                <div className="w-100 mt-3">
                    <InputTextarea autoFocus={true} placeholder="Please Enter Justification" rows={4} value={justification} onChange={onFieldChange} autoResize className="form-control p-1 w-100 border border-info"></InputTextarea>
                </div>
                <div className="mt-3">
                    <button className="btn btn-success" onClick={submit} disabled={tableauRequestStatus && tableauRequestStatus.loading}>Submit Request</button>
                </div>
            </div>
        )
    }

    return (<div className="h-100 p-4 container mx-auto">
        {
            getRequestDom()
        }
    </div>

    )
}


const mapStateToProps = (state: IStoreState) => ({
    tableauRequestStatus: state.tableauRequest.tableauRequestStatus,
    raisedTableauRequestStatus: state.tableauRequest.raisedTableauRequestStatus.data
})


const mapDispatchToProps = (dispatch: any) => ({
    clearTableauRequestData: () => {
        dispatch(clearTableauRequestData())
    },
    submitTableauRequest: (payload: any) => {
        dispatch(submitTableauRequest(payload))
    },
    checkIsTableauRequestPending: () => {
        dispatch(checkIsTableauRequestPending())
    },
    showNotification: (payload: INotification) => {
        dispatch(showNotification(payload))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestTableauAccess);