export const deepCopy = (value) => {
    let newValue;
    if (Array.isArray(value)) {
        newValue = []
        value.forEach(item => {
            if (typeof item === 'object' && item !== null) {
                newValue.push(deepCopy(item))
            } else {
                newValue.push(item)
            }
        }
        )
    } else {
        newValue = {}
        for (let key in value) {
            if (typeof value[key] === 'object' && value[key] !== null) {
                newValue[key] = deepCopy(value[key])
            } else {
                newValue[key] = value[key]
            }
        }
    }

    return newValue
}

export const isEqual = (first, second) => {
    if ((first === null && second !== null) || (second === null && first !== null)) return false;
    if (first === null && second === null) return true;
    let firstKey = Object.keys(first);
    let secondKey = Object.keys(second);
    if (firstKey.length !== secondKey.length) return false
    for (let key of Object.keys(first)) {
        if ((typeof first[key]) === 'object' || (typeof second[key]) === 'object') {

            if ((typeof first[key]) !== 'object')
                return false;
            if ((typeof second[key]) !== 'object')
                return false;
            if (Array.isArray(first[key])) {
                if (!isArrayEqual(first[key], second[key])) {
                    return false
                }
            }
            if (!isEqual(first[key], second[key])) {
                return false
            }
        }
        else if (first[key] !== second[key]) return false
    }
    return true;

}

export const isArrayEqual = (_arr1, _arr2) => {
    if (
        !Array.isArray(_arr1)
        || !Array.isArray(_arr2)
        || _arr1.length !== _arr2.length
    ) {
        return false;
    }

    // .concat() to not mutate arguments
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}