import { ISearchFilter } from "../types/reportList";
import actionType from "../actions/reportListActionType";
import tokenService from "src/api/token.service";

const initialState: ISearchFilter = {
    filteredReports: {
        data: []
    },
    suggestionList: {
        data: []
    },
    nonFilteredReports: {
        loading: false,
        error: false,
        success: false,
        data: tokenService.getUserReportsList()
    },
    homeKpiData: {
        loading: false,
        error: false,
        success: false,
        data: {
            candidates: '',
            dropouts: '',
            assessments: ''
        }
    },
    recentReportList: {
        loading: false,
        error: false,
        success: false,
        data: tokenService.getUserReportsList()
    },
    favouriteReportList: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    setFavouriteStatus: {
        loading: false,
        error: false,
        success: false
    },
    removeFromFavouriteStatus: {
        loading: false,
        error: false,
        success: false
    }

}

const reportSearchReducer = (state = initialState, action: any): ISearchFilter => {
    switch (action.type) {
        case actionType.UPDATE_SUGGESTION_LIST: {
            return {
                ...state,
                suggestionList: Object.assign({}, { data: action.payload })
            }
        }

        case actionType.FILTER_REPORTS: {
            return {
                ...state,
                filteredReports: Object.assign({}, { data: action.payload })
            }
        }

        case actionType.CLEAR_FILTER: {
            return {
                ...state,
                filteredReports: Object.assign({}, { data: state.nonFilteredReports.data })
            }
        }
        case actionType.FETCH_REPORTS: {
            return {
                ...state,
                nonFilteredReports: Object.assign({}, state.nonFilteredReports, { loading: true })
            }
        }
        case actionType.FETCH_REPORTS_SUCCESS: {
            return {
                ...state,
                filteredReports: Object.assign({}, { data: action.payload }),
                nonFilteredReports: Object.assign({}, state.nonFilteredReports, { loading: false, error: false, success: true, data: action.payload })
            }
        }
        case actionType.FETCH_REPORTS_FAILURE: {
            return {
                ...state,
                filteredReports: Object.assign({}, { data: [] }),
                nonFilteredReports: Object.assign({}, { loading: false, error: true, success: false, data: [] })
            }
        }

        case actionType.UPDATE_CURRENT_REPORT: {
            return {
                ...state,
                currentReport: action.payload
            }
        }

        case actionType.UPDATE_CURRENT_KPI: {
            return {
                ...state,
                currentKpi: action.payload
            }
        }
        case actionType.GET_FAVOURITE_REPORT: {
            return {
                ...state,
                favouriteReportList: Object.assign({}, state.favouriteReportList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionType.GET_FAVOURITE_REPORT_SUCCESS: {
            return {
                ...state,
                favouriteReportList: Object.assign({}, state.favouriteReportList, { loading: false, success: true, error: false, data: action.payload.data })
            }
        }
        case actionType.GET_FAVOURITE_REPORT_FAILURE: {
            return {
                ...state,
                favouriteReportList: Object.assign({}, state.favouriteReportList, { loading: false, success: false, error: true, data: action.payload.data })
            }
        }
        case actionType.SET_FAVOURITE_REPORT: {
            return {
                ...state,
                setFavouriteStatus: Object.assign({}, state.setFavouriteStatus, { loading: true, success: false, error: false })
            }
        }
        case actionType.SET_FAVOURITE_REPORT_SUCCESS: {
            return {
                ...state,
                setFavouriteStatus: Object.assign({}, state.setFavouriteStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.SET_FAVOURITE_REPORT_FAILURE: {
            return {
                ...state,
                setFavouriteStatus: Object.assign({}, state.setFavouriteStatus, { loading: false, success: false, error: true })
            }
        }
        case actionType.REMOVE_FROM_FAVOURTIE: {
            return {
                ...state,
                removeFromFavouriteStatus: Object.assign({}, state.removeFromFavouriteStatus, { loading: true, success: false, error: false })
            }
        }

        case actionType.REMOVE_FROM_FAVOURTIE_SUCCESS: {
            return {
                ...state,
                removeFromFavouriteStatus: Object.assign({}, state.removeFromFavouriteStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.REMOVE_FROM_FAVOURTIE_FAILURE: {
            return {
                ...state,
                removeFromFavouriteStatus: Object.assign({}, state.removeFromFavouriteStatus, { loading: false, success: false, error: true })
            }
        }
        case actionType.GET_RECENT_REPORTS: {
            return {
                ...state,
                recentReportList: Object.assign({}, state.recentReportList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionType.GET_RECENT_REPORTS_SUCCESS: {
            return {
                ...state,
                recentReportList: Object.assign({}, state.recentReportList, { loading: false, success: true, error: false, data: action.payload.data })
            }
        }
        case actionType.GET_RECENT_REPORTS_FAILURE: {
            return {
                ...state,
                recentReportList: Object.assign({}, state.recentReportList, { loading: false, success: false, error: true, data: action.payload.data })
            }
        }
        case actionType.GET_HOME_KPI_DATA: {
            return {
                ...state,
                homeKpiData: Object.assign({}, state.homeKpiData, { loading: true, success: false, error: false })
            }
        }
        case actionType.GET_HOME_KPI_DATA_SUCCESS: {
            return {
                ...state,
                homeKpiData: Object.assign({}, state.homeKpiData, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionType.GET_HOME_KPI_DATA_FAILURE: {
            return {
                ...state,
                homeKpiData: Object.assign({}, state.homeKpiData, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        default:
            return state
    }
}

export default reportSearchReducer