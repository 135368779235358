const actionType = {
    GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
    GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
    GET_CATEGORY_LIST_ERROR: 'GET_CATEGORY_LIST_ERROR',
    ADD_CATEGORY: 'ADD_CATEGORY',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',
    EDIT_CATEGORY: 'EDIT_CATEGORY',
    EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',  
    EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
    SELECT_ADD_CATEGORY: 'SELECT_ADD_CATEGORY',
    SELECT_EDIT_CATEGORY: 'SELECT_EDIT_CATEGORY',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    CLEAR_CATEGORY: 'CLEAR_CATEGORY',
    PUBLISH_CATEGORY:'PUBLISH_CATEGORY',
    PUBLISH_CATEGORY_SUCCESS:'PUBLISH_CATEGORY_SUCCESS',
    PUBLISH_CATEGORY_FAILURE:'PUBLISH_CATEGORY_FAILURE',
    UPDATE_CATEGORY_DATA: 'UPDATE_CATEGORY_DATA'
}

export default actionType