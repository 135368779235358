import actionType from "../actions/ManageTableauSuperUserActionType";
import { IManageTableauSuperUser } from "../types/ManageTableauSuperUser";

const initialState: IManageTableauSuperUser = {
    tableauSuperUserList: {
        loading: false,
        success: false,
        error: false,
        superUsers: []
    },
    addTableauSuperUserStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteTableauSuperUserStatus: {
        loading: false,
        success: false,
        error: false
    },
    processNonAggregatedDataStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentTableauSuperUser: {
        name: '',
        email: ''
    },

}

const manageTableauSuperUserReducer = (state = initialState, action: any):IManageTableauSuperUser => {
    switch (action.type) {
        case actionType.GET_TABLEAU_SUPER_USER_LIST: {
            return {
                ...state,
                tableauSuperUserList: {
                    success: false,
                    error: false,
                    loading: true,
                    superUsers: []
                }
            }
        }
        case actionType.GET_TABLEAU_SUPER_USER_LIST_SUCCESS: {
            return {
                ...state,
                tableauSuperUserList:{ loading: false, success: true, error: false,superUsers: action.payload }
            }
        }
        case actionType.GET_TABLEAU_SUPER_USER_LIST_ERROR: {
            return {
                ...state,
                tableauSuperUserList: { loading: false, error: true, success: false , superUsers:[] }

            }
        }
        case actionType.ADD_TABLEAU_SUPER_USER: {
            return {
                ...state,
                addTableauSuperUserStatus:{
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.ADD_TABLEAU_SUPER_USER_SUCCESS: {
            return {
                ...state,
                addTableauSuperUserStatus:{ loading: false, success: true, error: false }
            }
        }
        case actionType.ADD_TABLEAU_SUPER_USER_ERROR: {
            return {
                ...state,
                addTableauSuperUserStatus:{ loading: false, error: true, success: false }
            }
        }

        case actionType.DELETE_TABLEAU_SUPER_USER: {
            return {
                ...state,
                deleteTableauSuperUserStatus:{
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.DELETE_TABLEAU_SUPER_USER_SUCCESS: {
            return {
                ...state,
                deleteTableauSuperUserStatus: { loading: false, success: true, error: false }
            }
        }
        case actionType.DELETE_TABLEAU_SUPER_USER_ERROR: {
            return {
                ...state,
                deleteTableauSuperUserStatus: { loading: false, error: true, success: false }

            }
        }

        case actionType.PROCESS_NON_AGGREGATED_DATA : {
            return {
                ...state,
                processNonAggregatedDataStatus:{loading:true , success:false , error:false}
            }
        }

        case actionType.PROCESS_NON_AGGREGATED_DATA_SUCCESS : {
            return {
                ...state,
                processNonAggregatedDataStatus:{loading:false , success:true , error:false}
            }
        }

        case actionType.PROCESS_NON_AGGREGATED_DATA_FAILURE : {
            return {
                ...state,
                processNonAggregatedDataStatus:{loading:false , success:false , error:true }
            }
        }

        case actionType.SELECT_ADD_TABLEAU_SUPER_USER: {
            return {
                ...state,
                currentTableauSuperUser: {
                    name: '',
                    email: ''
                }
            }
        }

        case actionType.UPDATE_TABLEAU_SUPER_USER: {
            return {
                ...state,
                currentTableauSuperUser: Object.assign({}, state.currentTableauSuperUser, { ...action.payload })
            }
        }

        case actionType.CLEAR_TABLEAU_SUPER_USER: {
            return {
                ...state,
                currentTableauSuperUser: {
                    name: '',
                    email: ''
                }
            }
        }

        case actionType.UPDATE_TABLEAU_SUPER_USER_LIST: {
            return {
                ...state,
                tableauSuperUserList:{ loading: false, superUsers: action.payload, success: true, error: false }
            }
        }

        case actionType.SELECT_DELETE_TABLEAU_SUPER_USER: {
            return {
                ...state,
                currentTableauSuperUser:action.payload
            }
        }
        case actionType.CLEAR_STATE : {
            return {
                tableauSuperUserList: {
                    loading: false,
                    success: false,
                    error: false,
                    superUsers: []
                },
                addTableauSuperUserStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteTableauSuperUserStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                processNonAggregatedDataStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                currentTableauSuperUser: {
                    name: '',
                    email: ''
                }
                
            }
        }

        default:
            return state
    }
}

export default manageTableauSuperUserReducer