import { IManageReport } from "../types/ManageReport";
import actionType from '../actions/ManageReportActionTypes'

const initialState: IManageReport = {
    reportList: {
        loading: false,
        success: false,
        error: false,
        reports: []
    },
    addReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    editReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentReport: {
        name: '',
        id: 0,
        icon: '',
        description: '',
        url: '',
        shortDescription: '',
        piData: false,
        kpiId: '',
        legacyReport: '',
        isUtility: false,
        noDownloadButton: false,
        downloadUtilText: ''
    },
    editingReport: {
        name: '',
        id: 0,
        icon: '',
        description: '',
        url: '',
        shortDescription: '',
        piData: false,
        kpiId: '',
        legacyReport: '',
        isUtility: false,
        noDownloadButton: false,
        downloadUtilText: ''
    },
    copyStatus: {
        loading: false,
        success: false,
        error: false
    },
    publishReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    historyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    currentHistoryFormData: {
        kpis: [],
        reports: [],
        company: '',
        startDate: '',
        endDate: ''
    }

}

const manageReportReducer = (state = initialState, action: any): IManageReport => {
    switch (action.type) {
        case actionType.GET_REPORT_LIST: {
            return {
                ...state,
                reportList: Object.assign({}, {
                    success: false,
                    error: false,
                    reports: []
                }, { loading: true })
            }
        }
        case actionType.GET_REPORT_LIST_SUCCESS: {
            return {
                ...state,
                reportList: Object.assign({}, state.reportList, { loading: false, reports: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_REPORT_LIST_ERROR: {
            return {
                ...state,
                reportList: Object.assign({}, state.reportList, { loading: false, error: true, success: false, reports: [] })

            }
        }
        case actionType.GET_HISTORY: {
            return {
                ...state,
                historyList: Object.assign({}, {
                    success: false,
                    error: false,
                    data: []
                }, { loading: true })
            }
        }
        case actionType.GET_HISTORY_SUCCESS: {
            return {
                ...state,
                historyList: Object.assign({}, state.historyList, { loading: false, data: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_HISTORY_FAILURE: {
            return {
                ...state,
                historyList: Object.assign({}, state.historyList, { loading: false, error: true, success: false, data: [] })

            }
        }
        case actionType.ADD_REPORT: {
            return {
                ...state,
                addReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_REPORT_SUCCESS: {
            return {
                ...state,
                addReportStatus: Object.assign({}, state.addReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_REPORT_ERROR: {
            return {
                ...state,
                addReportStatus: Object.assign({}, state.addReportStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_REPORT: {
            return {
                ...state,
                editReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_REPORT_SUCCESS: {
            return {
                ...state,
                editReportStatus: Object.assign({}, state.editReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_REPORT_ERROR: {
            return {
                ...state,
                editReportStatus: Object.assign({}, state.editReportStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_REPORT: {
            return {
                ...state,
                deleteReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_REPORT_SUCCESS: {
            return {
                ...state,
                deleteReportStatus: Object.assign({}, state.deleteReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_REPORT_ERROR: {
            return {
                ...state,
                deleteReportStatus: Object.assign({}, state.deleteReportStatus, { loading: false, error: true, success: false })

            }
        }
        case actionType.UPDATE_REPORT_LIST: {
            return {
                ...state,
                reportList: Object.assign({}, state.reportList, { loading: false, reports: action.payload, success: true, error: false })
            }
        }
        case actionType.UPDATE_HISTORY_INFO: {
            return {
                ...state,
                currentHistoryFormData: Object.assign({}, state.currentHistoryFormData, action.payload)
            }
        }

        case actionType.SELECT_ADD_REPORT: {
            return {
                ...state,
                currentReport: {
                    name: '',
                    id: 0,
                    icon: '',
                    description: '',
                    url: '',
                    shortDescription: '',
                    piData: false,
                    kpiId: '',
                    legacyReport: '',
                    isUtility: false,
                    noDownloadButton: false,
                    downloadUtilText: ''
                },
                editingReport: {
                    name: '',
                    id: 0,
                    icon: '',
                    description: '',
                    url: '',
                    shortDescription: '',
                    piData: false,
                    kpiId: '',
                    legacyReport: '',
                    isUtility: false,
                    noDownloadButton: false,
                    downloadUtilText: ''
                }
            }
        }

        case actionType.SELECT_EDIT_REPORT: {
            return {
                ...state,
                currentReport: Object.assign({}, action.payload),
                editingReport: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_REPORT: {
            return {
                ...state,
                currentReport: Object.assign({}, state.currentReport, { ...action.payload })
            }
        }

        case actionType.CLEAR_REPORT: {
            return { ...initialState, reportList: state.reportList }
        }

        case actionType.COPY_FILTER: {
            return {
                ...state,
                copyStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.COPY_FILTER_SUCCESS: {
            return {
                ...state,
                copyStatus: { loading: false, success: true, error: false }
            }
        }
        case actionType.COPY_FILTER_ERROR: {
            return {
                ...state,
                copyStatus: { loading: false, error: true, success: false }

            }
        }
        case actionType.UPDATE_COPY_DATA: {
            return {
                ...state,
                copyData: Object.assign({}, state.copyData, { ...action.payload })
            }
        }

        case actionType.CLEAR_COPY_DATA: {
            return {
                ...state,
                copyData: undefined
            }
        }
        case actionType.PUBLISH_REPORT: {
            return {
                ...state,
                publishReportStatus: Object.assign({}, { loading: true, success: false, error: false })
            }
        }
        case actionType.PUBLISH_REPORT_SUCCESS: {
            return {
                ...state,
                publishReportStatus: Object.assign({}, { loading: false, success: true, error: false })
            }
        }
        case actionType.PUBLISH_REPORT_FAILURE: {
            return {
                ...state,
                publishReportStatus: Object.assign({}, { loading: false, success: false, error: true })
            }
        }

        case actionType.UPDATE_REPORT_NOTIFICATION: {
            return {
                ...state,
                notificationUpdateStatus: Object.assign({}, { loading: true, success: false, error: false })
            }
        }
        case actionType.UPDATE_REPORT_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notificationUpdateStatus: Object.assign({}, { loading: false, success: true, error: false })
            }
        }
        case actionType.UPDATE_REPORT_NOTIFICATION_ERROR: {
            return {
                ...state,
                notificationUpdateStatus: Object.assign({}, { loading: false, success: false, error: true })
            }
        }

        case actionType.CLEAR_UPDATE_NOTIFICATION_STATUS: {
            return {
                ...state,
                notificationUpdateStatus: Object.assign({}, { loading: false, success: false, error: false })
            }
        }

        default:
            return state
    }
}

export default manageReportReducer