import actionType from "../actions/piDisclaimerActionType";
import { IQueryResponse } from "../types/piDisclaimer";

const intialstate: IQueryResponse = {
    disclaimer: {
        reportId: '',
        name: '',
        piReasonKey: 0,
        piConsentChecked: false
    },
    requestSubmitStatus: {
        loading: false,
        error: false,
        success: false,
        data: null
    },
    diclaimedReportId: ''
}

const piDisclaimerReducer = (state = intialstate, action: any): IQueryResponse => {
    switch (action.type) {
        case actionType.SUBMITQUERYRESPONSE: {
            return {
                ...state,
                requestSubmitStatus: Object.assign({}, state.requestSubmitStatus, { loading: true })
            }
        }
        case actionType.SUBMITQUERYRESPONSESUCCESS: {
            return {
                ...state,
                requestSubmitStatus: Object.assign({}, state.requestSubmitStatus, { loading: false, error: false, success: true, data: action.payload })
            }
        }
        case actionType.SUBMITQUERYRESPONSEFAILURE: {
            return {
                ...state,
                requestSubmitStatus: Object.assign({}, state.requestSubmitStatus, { loading: false, error: true, success: false, data: action.payload })
            }
        }

        case actionType.UPDATE_DISCLAIMER_DATA: {
            return {
                ...state,
                disclaimer: Object.assign({}, state.disclaimer, { ...action.payload })
            }
        }

        case actionType.CLEAR_DISCLAIMER_DATA: {
            return {
                ...state,
                disclaimer: {
                    reportId: '',
                    name: '',
                    piReasonKey: 0,
                    piConsentChecked: false
                },
                requestSubmitStatus: {
                    loading: false,
                    error: false,
                    success: false,
                    data: null
                }
            }
        }

        case actionType.UPDATE_DISCLAIMER_FOR_REPORT: {
            return {
                ...state,
                diclaimedReportId: action.payload
            }
        }
        default:
            return state
    }
}

export default piDisclaimerReducer;