import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Table.scss'


interface ITableProps {
    data?: any[],
    column: IColumn[],
    actions?: IAction[],
    filterObject?: any
    globalFilterField?: any[],
    tableRef?: any,
    paginator?: boolean,
    reorderable?: boolean,
    actionFunction?: (item: any) => IAction[]
    selectedRow?: any[]
    onSelectionChange?: (e: any) => void
    selectable?: boolean,
    onRowReorder?: (e: any) => void
    autoLayout?: boolean
    rows?: number
    selectOnEdit?: boolean,
    onRowEditComplete?: any,
    editMode?: any,
    keyColumn?: any,
    expandedTableHeaderTemplate?: (item: any) => void,
    expandedTableDataColumn?: any,
    expandedRows?: any
    onRowToggle?: (data: any) => void,
    expandedTableColumns?: IColumn[],
    expandedTableClass?: string,
    onRowClick?: any,
    headerAction?: IAction[],
    isExpandSelectable?: boolean,
    onExpandTableSelect?: (e: any, data: any) => void
}

export interface IColumn {
    field?: any,
    header: any,
    bodyTemplate?: (item: any) => void,
    filterTemplate?: (item: any) => void,
    applyFilter?: boolean,
    matchMode?: any,
    cellEditor?: any,
    onCellEditCompleted?: any,
    expander?: boolean,
    width?: string
}

export interface IAction {
    cssClass: string,
    action: (item: any) => void,
    headerTemplate?: (item: any) => any,
    header: string,
    title?: string,
    nonCrud?: boolean,
    btnClass?: string,
    isMenu?: boolean,
    menuAction?: { name: string, actionFunc: (item: any) => void, isView?: (item: any) => boolean }[]
}
const Table = ({ data,
    column,
    actions,
    filterObject,
    paginator,
    globalFilterField,
    tableRef,
    reorderable,
    actionFunction,
    selectable,
    selectedRow,
    onSelectionChange,
    onRowReorder,
    autoLayout,
    selectOnEdit,
    onRowEditComplete,
    editMode,
    rows,
    keyColumn,
    expandedRows,
    onRowToggle,
    expandedTableHeaderTemplate,
    expandedTableDataColumn,
    expandedTableColumns,
    expandedTableClass,
    onRowClick,
    headerAction,
    isExpandSelectable,
    onExpandTableSelect
}: ITableProps) => {
    const [tableFilter, setTableFilter] = useState<any>(null)
    const [tableGlobalFilter, setTableGlobalFilter] = useState<string>('')
    const [editingRow, setEditingRow] = useState<any>([]);
    const [first, setFirst] = useState<number>(0)
    const [tableRows, setTableRows] = useState<number>()


    useEffect(() => {
        initTableFilters();
    }, [])

    useEffect(() => {
        if (rows)
            setTableRows(rows)
    }, [rows])

    const initTableFilters = () => {
        setTableFilter(filterObject)

    }
    const clearGlobalFilter = () => {
        initTableFilters()
        setTableGlobalFilter('');
    }

    const changeTableGlobalFilter = (e: any) => {
        const value = e.target.value
        let _tableFilters = { ...tableFilter };
        _tableFilters['global'].value = value
        setTableFilter(_tableFilters);
        setTableGlobalFilter(value);

    }

    const renderHeader = () => {
        return (
            <div className='d-flex table-header justify-content-between'>
                <span className='search-input  bg-white p-2'>
                    <span className='mdi mdi-magnify me-1 l3-font-size '></span>
                    <input value={tableGlobalFilter} onChange={(e) => changeTableGlobalFilter(e)} className='shadow-none l2-font-size border-0' placeholder='Search Keyword' />
                </span>
                <div className='ms-auto'>
                    {headerAction && headerAction.map((item: IAction) => <button onClick={item.action} className={item.cssClass}>{item.header}</button>)}
                </div>
            </div>
        )
    }
    const menuTemplate = (menuAction: { name: string, actionFunc: (item: any) => void, isView?: (item: any) => boolean }[], rowData: any) => {
        return (
            <div className="btn-group ms-auto">
                <button className="btn btn-primary mdi mdi-dots-vertical" data-bs-toggle="dropdown" aria-expanded="false"></button>

                <ul className="dropdown-menu">
                    {menuAction.map((item) => {
                        if (!item.isView || item.isView(rowData)) return <li className=' dropdown-item' onClick={() => item.actionFunc(rowData)}>{item.name}</li>
                        return null
                    })}
                </ul>
            </div>
        )
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className='w-100 d-flex'>
                {actions && actions?.map((item: IAction) =>
                    item.isMenu && item.menuAction ? menuTemplate(item.menuAction, rowData) :
                        item.nonCrud ?
                            <button className={item.cssClass} onClick={() => item.action(rowData)} >{item.header === '' ? item.headerTemplate?.(rowData) : item.header}</button>
                            : <button className={`btn ${item.btnClass}`} onClick={() => item.action(rowData)} title={item.title}><span className={item.cssClass}></span><span>{item.header === '' ? item.headerTemplate?.(rowData) : item.header}</span></button>
                )}

                {actionFunction && actionFunction(rowData).map((item: IAction) =>
                    item.nonCrud ?
                        <button className={item.cssClass} onClick={() => item.action(rowData)} >{item.header === '' ? item.headerTemplate?.(rowData) : item.header}</button>
                        : <button className={`btn ${item.btnClass}`} onClick={() => item.action(rowData)} title={item.title}><span className={item.cssClass}></span><span>{item.header === '' ? item.headerTemplate?.(rowData) : item.header}</span></button>
                )}
            </div>
        )
    }

    const rowExpansionTemplate = (rowData: any) => {
        return (
            <div className={expandedTableClass}>
                {expandedTableHeaderTemplate?.(rowData)}
                <DataTable selection={isExpandSelectable && rowData.selectedRows}
                    onSelectionChange={e => onExpandTableSelect && onExpandTableSelect(e, rowData)} showSelectAll={expandedTableDataColumn && rowData[expandedTableDataColumn] && rowData[expandedTableDataColumn].length > 1} value={expandedTableDataColumn && rowData[expandedTableDataColumn]}  >
                    {isExpandSelectable && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
                    {expandedTableColumns?.map((item: any) => <Column
                        field={item.field} header={item.header} showFilterMenuOptions={false} body={item.bodyTemplate} filterElement={item.filterTemplate} filterMatchModeOptions={item.matchMode} ></Column>
                    )}
                </DataTable>
            </div>
        )
    }

    const onRowEditInit = (e: any) => {
        let edit = [...editingRow]
        edit.push(e.data)
        setEditingRow(edit)
    }

    const onRowEditCancel = (e: any) => {
        let data = e.data;
        let edit = editingRow.filter((item: any) => item[keyColumn] !== data[keyColumn])
        setEditingRow(edit)
    }

    const onRowEditDone = (e: any) => {
        onRowEditComplete(e)
        let data = e.data;
        let edit = editingRow.filter((item: any) => item[keyColumn] !== data[keyColumn])
        setEditingRow(edit)
    }

    const onPageChange = (e: any) => {
        setFirst(e.first)
        setTableRows(e.rows)
    }

    return (
        <DataTable
            onRowClick={onRowClick}
            ref={tableRef}
            globalFilterFields={globalFilterField}
            autoLayout={autoLayout}
            size='small'
            paginator={!paginator ? paginator : true}
            filters={tableFilter}
            filterDisplay="menu"
            value={data}
            className='table-container w-100 mb-0'
            stripedRows
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            rows={tableRows || 8}
            rowsPerPageOptions={[8, 16, 32]}
            emptyMessage="No record found."
            header={filterObject && renderHeader()}
            tableClassName="width-100"
            onRowReorder={e => onRowReorder && onRowReorder(e)}
            selection={selectable && selectedRow}
            onSelectionChange={e => onSelectionChange && onSelectionChange(e)}
            reorderableRows={reorderable}
            selectionMode="checkbox"
            pageLinkSize={3}
            editMode={editMode}
            onRowEditComplete={onRowEditDone}
            editingRows={editingRow}
            onRowEditInit={onRowEditInit}
            onRowEditCancel={onRowEditCancel}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={e => onRowToggle && onRowToggle(e.data)}
            onPage={onPageChange}
            first={first}
            showSelectAll={data && data.length > 1}
        >
            {selectable && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
            {expandedTableColumns?.length ? <Column expander={true} headerStyle={{ width: '5%' }} style={{ width: '5%' }} /> : null}
            {reorderable ? <Column rowReorder rowReorderIcon='pi pi-sort-alt' style={{ width: '3em' }} /> : null
            }
            {/* {rowGroupMode && <Column  ></Column>  } */}
            {
                column.map((item: any) => <Column expander={item.expander}
                    editor={item.cellEditor} onCellEditComplete={item.onCellEditCompleted}
                    filter={item.applyFilter} filterPlaceholder="search keyword" field={item.field} header={item.header} showFilterMenuOptions={false} body={item.bodyTemplate} filterElement={item.filterTemplate} filterMatchModeOptions={item.matchMode} style={{ width: item.width }} filterMenuStyle={{ width: '220px' }} ></Column>
                )
            }

            {actions || actionFunction ? <Column body={actionBodyTemplate}  ></Column> : null}
            {editMode ? <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{}}></Column> : null}

        </DataTable >

    )
}

export default Table;