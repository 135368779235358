/* This is guard to prevent unauthorised access from frontend.
 It will check user permissions and redirect to no access page if user is not authorised. */


import React from "react";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { config } from '../api/config'
import { connect } from 'react-redux';
import { IStoreState } from '../types/StoreState';
import { updateUserRole } from '../actions/loginAction';

interface IRoleAuthProps {
    children: any,
    userRoles?: {
        roles: number[],
        success: boolean
    },
    updateUserRole?: (roles: number[]) => void,
    requireRole: number[],
    noAccessUrl?: string
}


const RoleAuthGuard = ({ children, userRoles, updateUserRole, requireRole, noAccessUrl }: IRoleAuthProps) => {
    const [authorised, setAuthorised] = useState(false);
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        checkPermission()
    }, [])

    const checkPermission = async () => {
        //User is already logged in and roles are already in redux state
        if (userRoles && userRoles.success) {
            for(let i of requireRole) {
                if(userRoles.roles.some(item => item === i)) {
                    setAuthorised(true)
                    setChecked(true)
                    return;
                }
            }
        } else {
            //user login using this link, so in some case role will not be available 
            const roles = await axios.get(config.baseUrl + config.apiUrl.getUserSpecificRole)
            if (roles && roles.data && roles.data.status === 'OK' && roles.data.data) {
                let data: number[] = roles.data.data
                updateUserRole && updateUserRole(data)
                for(let i of requireRole) {
                    if(data.some(item => item === i)) {
                        setAuthorised(true)
                        setChecked(true)
                        return;
                    }
                }
            }
        }
        setAuthorised(false)
        setChecked(true)
    }
    return !checked ? <div>Checking your access..</div> : authorised ? children : <Navigate to={noAccessUrl || "/smart/noaccess"} />;
}


const mapStateToProps = (state: IStoreState) => ({
    userRoles: state.login.userRoles
})

const mapDispatchToProps = (dispatch: any) => ({
    updateUserRole: (roles: number[]) => {
        dispatch(updateUserRole(roles))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(RoleAuthGuard);