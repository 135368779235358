import actionType from "./reportListActionType";
import { IKpi, IReports } from '../types/reportList';
import { deepCopy } from '../util/arrayFunction';
import store from '../store/store'
import { ReportApi } from '../api/reportApi';
import { rolesList } from "src/constants/RoleList";
import tokenService from "src/api/token.service";

const api = new ReportApi()
export const getKpiReportList = (all: boolean) => {
    if (all) {
        return api.getAllKpiReportList(_getKpiReportList, getKpiReportListSuccess, getKpiReportListFailure)
    } else {
        return api.getPublishedKpiReportList(_getKpiReportList, getKpiReportListSuccess, getKpiReportListFailure)
    }
}

export const getFavouriteReport = () => {
    return api.getFavouriteReport(_getFavouriteReport, getFavouriteReportSuccess, getFavouriteReportFailure);
}

export const setFavouriteReport = (payload: any) => {
    return api.setFavouriteReport(payload, _setFavouriteReport, setFavouriteReportSuccess, setFavouriteReportFailure);
}

export const removefromFavourite = (payload: any) => {
    return api.removeFromFavourite(payload, _removeFromFavourite, removeFromFavouriteSuccess, removeFromFavouriteFailure)
}

const _getKpiReportList = () => {
    return {
        type: actionType.FETCH_REPORTS,
    }
}

export const getKpiReportListSuccess = (data: any) => {
    let kpiList: IKpi[] = data;
    kpiList = kpiList.sort((kpiA, kpiB) => kpiA.kpi < kpiB.kpi ? -1 : 1)
    let roles = store.getState().login.userRoles.roles;
    kpiList.forEach((item: IKpi) => {
        if (!roles.some(item => item === rolesList.REPORT_DEVELOPER)) {
            item.reports = item.reports.filter((item: IReports) => {
                if (!item.roles) return true;
                if (item.roles.split(',').some((i) => {
                    return roles.some(r => parseInt(i) === r)
                })) return true

                return false
            })
        }
        item.reports.sort((a: IReports, b: IReports) => {
            if (!a.seq) {
                return -1
            } else if (!b.seq) {
                return 1
            }
            return a.seq - b.seq;
        })
    })
    tokenService.setUserReportsList(kpiList);
    return {
        type: actionType.FETCH_REPORTS_SUCCESS,
        payload: kpiList
    }
}

export const getKpiReportListFailure = (data: any) => {
    return {
        type: actionType.FETCH_REPORTS_FAILURE,
        payload: data
    }
}

export const getRecentReports = () => {
    return api.getRecentReports(_getRecentReports, getRecentReportsSuccess, getRecentReportsFailure);
}

const _getRecentReports = () => {
    return {
        type: actionType.GET_RECENT_REPORTS
    }
}

const getRecentReportsSuccess = (data: any) => {
    return {
        type: actionType.GET_RECENT_REPORTS_SUCCESS,
        payload: data
    }
}

const getRecentReportsFailure = (data: any) => {
    return {
        type: actionType.GET_RECENT_REPORTS_FAILURE,
        payload: data
    }
}

const _getFavouriteReport = () => {
    return {
        type: actionType.GET_FAVOURITE_REPORT
    }
}

const getFavouriteReportSuccess = (data: any) => {
    return {
        type: actionType.GET_FAVOURITE_REPORT_SUCCESS,
        payload: data
    }
}

const getFavouriteReportFailure = (data: any) => {
    return {
        type: actionType.GET_FAVOURITE_REPORT_FAILURE,
        payload: data
    }
}

const _setFavouriteReport = () => {
    return {
        type: actionType.SET_FAVOURITE_REPORT
    }
}

const setFavouriteReportSuccess = (data: any) => {
    return {
        type: actionType.SET_FAVOURITE_REPORT_SUCCESS,
        payload: data
    }
}

const setFavouriteReportFailure = (data: any) => {
    return {
        type: actionType.SET_FAVOURITE_REPORT_FAILURE,
        payload: data
    }
}

const _removeFromFavourite = () => {
    return {
        type: actionType.REMOVE_FROM_FAVOURTIE
    }
}

const removeFromFavouriteSuccess = (data: any) => {
    return {
        type: actionType.REMOVE_FROM_FAVOURTIE_SUCCESS,
        payload: data
    }
}

const removeFromFavouriteFailure = (data: any) => {
    return {
        type: actionType.REMOVE_FROM_FAVOURTIE_FAILURE,
        payload: data
    }

}

export const updateSuggestionList = (searchKey: string) => {
    searchKey = searchKey.toLowerCase();
    const state = store.getState();
    let suggetionLIst: string[] = [];
    const nonFilteredReports: any = deepCopy(state.reportSearch.nonFilteredReports.data);
    nonFilteredReports.forEach((item: IKpi) => {
        item.reports.forEach(report => {
            if (report.name.toLowerCase().includes(searchKey))
                suggetionLIst.push(report.name);
            if (report.legacyReport) {
                report.legacyReport.split(',').forEach(item => {
                    if (item.toLowerCase().includes(searchKey))
                        suggetionLIst.push(item);
                })
            }
        });
    })
    return {
        type: actionType.UPDATE_SUGGESTION_LIST,
        payload: suggetionLIst
    }
}

export const filterReports = (searchKey: string) => {
    const state = store.getState()
    searchKey = searchKey.toLowerCase()
    const nonFilteredReports: any = deepCopy(state.reportSearch.nonFilteredReports.data);
    let filteredReports = nonFilteredReports.filter((item: IKpi) => {
        item.reports = item.reports.filter((report: IReports) => {
            if (report.name.toLowerCase().includes(searchKey) || report.description.toLowerCase().includes(searchKey) || isLegacyInclude(report, searchKey)) {
                return true
            } else {
                return false
            }
        })
        return item.reports.length > 0
    })
    return {
        type: actionType.FILTER_REPORTS,
        payload: filteredReports
    }
}

const isLegacyInclude = (report: IReports, searchKey: string) => {
    if (report.legacyReport) {
        if (report.legacyReport.toLowerCase().includes(searchKey)) {
            return true
        }
    }
    return false
}

export const clearReportFilter = () => {
    return {
        type: actionType.CLEAR_FILTER
    }
}

export const updateCurrentReport = (report: IReports) => {
    return {
        type: actionType.UPDATE_CURRENT_REPORT,
        payload: report
    }
}

export const updateCurrentKpi = (kpi: IKpi) => {
    return {
        type: actionType.UPDATE_CURRENT_KPI,
        payload: kpi
    }
}

export const getHomeKpiData = (payload: any) => {
    return api.getHomeKpiData(payload, _getHomeKpiData, getHomeKpiDataSuccess, getHomeKpiDataFailure)
}

const _getHomeKpiData = () => {
    return {
        type: actionType.GET_HOME_KPI_DATA
    }
}
const getHomeKpiDataSuccess = (data: any) => {
    if (!data)
        return {
            type: actionType.GET_HOME_KPI_DATA_SUCCESS,
            payload: {}
        }
    data.assessments = Number(data.assessments)
    data.candidates = Number(data.candidates)
    return {
        type: actionType.GET_HOME_KPI_DATA_SUCCESS,
        payload: data
    }
}
const getHomeKpiDataFailure = (data: any) => {
    return {
        type: actionType.GET_HOME_KPI_DATA_FAILURE,
        payload: data
    }
}