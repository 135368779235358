 const actionTypes = {
    CHECK_IS_ADMIN: 'CHECK_IS_ADMIN', 
    CHECK_IS_ADMIN_SUCCESS: 'CHECK_IS_ADMIN_SUCCESS', 
    CHECK_IS_ADMIN_ERROR: 'CHECK_IS_ADMIN_ERROR',
    CHECK_LOGIN: 'CHECK_LOGIN',
    GET_AUTH_CODE: 'GET_AUTH_CODE',
    GET_AUTH_CODE_SUCCESS: 'GET_AUTH_CODE_SUCCESS',
    GET_AUTH_CODE_ERROR: 'GET_AUTH_CODE_ERROR',
    GET_USER_INFO: 'GET_USER_INFO',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    LAST_ACCESS_URL: 'LAST_ACCESS_URL',
    LOGOUT: 'LOGOUT',
    UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
    GET_USER_ROLE: 'GET_USER_ROLE',
    GET_USER_ROLE_SUCCESS: 'GET_USER_ROLE_SUCCESS',
    GET_USER_ROLE_ERROR: 'GET_USER_ROLE_ERROR',
    GET_HOME_NOTIFICATION: 'GET_HOME_NOTIFICATION',
    GET_HOME_NOTIFICATION_SUCCESS: 'GET_HOME_NOTIFICATION_SUCCESS',
    GET_HOME_NOTIFICATION_ERROR: 'GET_HOME_NOTIFICATION_ERROR',
    MARK_READ_NOTIFICATION: 'MARK_READ_NOTIFICATION'
}

export default actionTypes;