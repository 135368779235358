const actionType = {
    GET_FILTER_LIST: 'GET_FILTER_LIST',
    GET_FILTER_LIST_SUCCESS: 'GET_FILTER_LIST_SUCCESS',
    GET_FILTER_LIST_ERROR: 'GET_FILTER_LIST_ERROR',
    GET_PARENT_FILTER_LIST: 'GET_PARENT_FILTER_LIST',
    GET_PARENT_FILTER_LIST_SUCCESS: 'GET_PARENT_FILTER_LIST_SUCCESS',
    GET_PARENT_FILTER_LIST_ERROR: 'GET_PARENT_FILTER_LIST_ERROR',
    ADD_FILTER: 'ADD_FILTER',
    ADD_FILTER_SUCCESS: 'ADD_FILTER_SUCCESS',
    ADD_FILTER_ERROR: 'ADD_FILTER_ERROR',
    EDIT_FILTER: 'EDIT_FILTER',
    EDIT_FILTER_SUCCESS: 'EDIT_FILTER_SUCCESS',
    EDIT_FILTER_ERROR: 'EDIT_FILTER_ERROR',
    DELETE_FILTER: 'DELETE_FILTER',
    DELETE_FILTER_SUCCESS: 'DELETE_FILTER_SUCCESS',
    DELETE_FILTER_ERROR: 'DELETE_FILTER_ERROR',
    SELECT_ADD_FILTER: 'SELECT_ADD_FILTER',
    SELECT_EDIT_FILTER: 'SELECT_EDIT_FILTER',
    UPDATE_FILTER: 'UPDATE_FILTER',
    CLEAR_FILTER: 'CLEAR_MANAGE_FILTER',
    UPDATE_FILTER_LIST:'UPDATE_FILTER_LIST'
}

export default actionType