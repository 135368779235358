import { useState, useEffect } from 'react';
import './QuizField.scss'

interface IProps {
    quizIndex: number,
    question: string,
    update: (question: string, value: string, quizIndex: number) => void,
    max?: number,
    type?: string,
    options: string[],
    answer: string

}

const QuizField = ({ quizIndex, question, update, max, options, type, answer }: IProps) => {
    const [value, setValue] = useState<any>('');
    const [multiValue, setMultiValue] = useState<string[]>([]);
    const [maxExceeded, setMaxExceed] = useState<boolean>(false);

    useEffect(() => {
        update(question, value, quizIndex)
       
    }, [value])

    useEffect(() => {
        update(question, multiValue.join(), quizIndex)

    }, [multiValue])


    const onChange = (event: any) => {
        const eventValue = event && event.currentTarget.value;
        if (event.currentTarget.checked) {
            if (max && multiValue.length < max)
                setMultiValue([...multiValue, eventValue])
            else {
                setMaxExceed(true)
                setTimeout(() => { setMaxExceed(false) }, 2000);
                event.currentTarget.checked = false
            }
        } else {
            const quizindex = multiValue.findIndex((item: any) => item === eventValue);
            const values = [...multiValue];
            if (quizindex > -1) {
                values.splice(quizindex, 1)
            }
            setMultiValue(values)
        }
    }

    const onRadioChange = (event: any) => {
        if(value) return ;
        setValue(event && event.currentTarget.value);
       
    }


    const createRadioButton = () => {
        return (
            <div className='d-flex flex-column  field shadow-sm rounded m-4 p-4' id={'q' + quizIndex}>
                <div className='text-start  mb-3'>
                    <h4>{`${quizIndex + 1}. `}{question}</h4>
                </div>
                {options.map((item: any , index:any) => <div className={`question  d-flex mb-2 ${value === item && item === answer ? 'correct' : ''}${value === item && item !== answer ? 'incorrect' : ''}`} key={`${quizIndex}-${item}-${index}`} >
                    <input  type='radio' className='form-check-input d-none' id={`${quizIndex}-${item}-${index}`} value={item} onChange={onRadioChange} checked={value === item}></input>
                    <label htmlFor={`${quizIndex}-${item}-${index}`}  className={`p-checkbox-label l2-font-size ms-3 flex-grow-1 text-start`}>{item}</label>
                    {value === item && item === answer && <span className=' ms-auto mdi mdi-check-bold'></span>}
                    {value === item && item !== answer && <span className=' ms-auto mdi mdi-close-thick'></span>}
                </div>)}
            </div>)
    }

    const createCheckbox = () => {
        return (
            <div className='d-flex flex-column field row shadow-sm rounded m-4 p-4' id={'q' + quizIndex}>
                {maxExceeded ? <div>
                    <p className="text-danger">You can select only response</p>
                </div> : null}
                <div className='col-12 mb-3'>
                    <h4>{`${quizIndex + 1}. `}{question}</h4>
                </div>
                {options.map((item: any) => <div className='col-12 d-flex mb-3' key={item}>
                    <input type="checkbox" className='form-check-input' id={item} value={item} onChange={onChange} checked={multiValue.includes(item)}></input>
                    <label htmlFor={item} className="p-checkbox-label l2-font-size ms-3">{item}</label>
                </div>)}

            </div>)
    }

    const createInputField = () => {
        switch (type) {
            case 'single':
                return createRadioButton();
            case 'multi':
                return createCheckbox();
            default:
                return createRadioButton();
                ;
        }
    }
    return (<div>
        {createInputField()}
    </div>)
}

export default QuizField;