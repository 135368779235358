import { rolesList } from 'src/constants/RoleList';
import { CaReportApi } from '../api/caReportApi'
import store from '../store/store';
import { ICaReport, ICategoryReports } from '../types/CaReport'
import actionType from './CaReportActionType'
const api = new CaReportApi();


export const getCategoryReports = () => {
    return api.getCategoryReports({ appName: 'client-analytics' }, _getCategoryReports, getCategoryReportsSuccess, getCategoryReportsFailure)
}

const _getCategoryReports = () => {
    return {
        type: actionType.GET_CATEGORY_REPORTS
    }
}

const getCategoryReportsSuccess = (data: any) => {
    let category: ICategoryReports[] = data.data;
    category = category.sort((kpiA, kpiB) => kpiA.categoryName < kpiB.categoryName ? -1 : 1)
    let roles = store.getState().login.userRoles.roles;
    category.forEach((item: ICategoryReports) => {
        if (!roles.some(item => item === rolesList.CA_REPORT_DEVELOPER)) {
            item.reports = item.reports.filter((item: ICaReport) => {
                if (!item.roles) return true;
                if (item.roles.split(',').some((i) => {
                    return roles.some(r => parseInt(i) === r)
                })) return true

                return false
            })
        }
    })
    return {
        type: actionType.GET_CATEGORY_REPORTS_SUCCESS,
        payload: category
    }
}

const getCategoryReportsFailure = (data: any) => {
    return {
        type: actionType.GET_CATEGORY_REPORTS_FAILURE,
        payload: data
    }
}


export const updateCurrentCaReport = (data: ICaReport) => {
    return {
        type: actionType.UPDATE_CURRENT_CA_REPORT,
        payload: data
    }
}

export const updateCurrentCategory = (data: ICategoryReports) => {
    return {
        type: actionType.UPDATE_CURRENT_CATEGORY,
        payload: data
    }
}


export const clearCaReportState = () => {
    return {
        type: actionType.CLEAR_CA_REPORT_STATE
    }
}