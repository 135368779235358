export const extractTypes = {
    HIGH: 'high',
    LOW: 'low'
};

export const extractTypeOptions = [{
    label: 'High Volume Pool Extract',
    value: extractTypes.HIGH
}, {
    label: 'Low Volume Pool Extract',
    value: extractTypes.LOW
}];