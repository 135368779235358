import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageKpiApi {

    getKpiList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getKPIList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewKpi(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addKpi, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editKpi(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.editKpi, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteKpi(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteKpi, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
    publishKpi(payload:any , requestAction:any , requestSuccessAction:any , requestErrorAction:any){
        return baseApi.put(config.apiUrl.publishKpi , {} , payload , requestAction , requestSuccessAction , requestErrorAction)
    }
}