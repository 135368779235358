import { UPDATE_STATUS , UPDATE_STATUS_FAILURE, UPDATE_STATUS_SUCCESS } from "../actions/DataRefreshingActionTypes";
import { IDataRefreshing } from "../types/DataRefreshing";
const initialstate:IDataRefreshing = {
    isUpdating: {
        loading:false,
        error:false,
        success:false,
        data:false
    }
    
}

const DataRefreshingReducer = (state=initialstate , action:any) => {
          switch(action.type)
          {
              case UPDATE_STATUS: {
                return {
                    isUpdating: Object.assign({} , state.isUpdating , { loading:true})
                }
              }
              case UPDATE_STATUS_SUCCESS: {
                  return {
                      isUpdating: Object.assign({} , state.isUpdating , {loading:false, error:false , success: true , data:action.payload.isUpdating})
                  }
              }
              case UPDATE_STATUS_FAILURE : {
                  return {
                      isUpdating : Object.assign({} , state.isUpdating , { loading:false, error:true , success: false , data:false })
                  }
              }
              default :
                  return state
            
          }
}

export default DataRefreshingReducer