import "./BiLoader.scss"

const BiLoader = () => {
   return <div className="biLoader-container flex-column d-flex justify-content-center align-items-center">
      
      <div className="biLoader d-flex align-items-end mb-3 p-1">
         <div style={{ animationDelay: "-0.2s" }} ></div>
         <div style={{ animationDelay: "-0.4s" }} ></div>
         <div style={{ animationDelay: "-0.8s" }} ></div>
         <div style={{ animationDelay: "-1.2s" }} ></div>
         <div style={{ animationDelay: "-1.6s" }} ></div>
         <div style={{ animationDelay: "-2s" }} ></div>
         <div style={{ animationDelay: "-2.4s" }} ></div>
         <div style={{ animationDelay: "-2.8s" }} ></div>
      </div>
      <h1 className="mt-4 text-white">Welcome To SHL Analytics</h1>
      
   </div>
}

export default BiLoader