import actionTypes from './ManageKpiActionTypes'
import { ManageKpiApi } from '../api/manageKpiApi';
import { IKpi, IKpiAddPayload, IKpiEditPayload, IDeleteKpiPayload } from '../types/ManageKpi'
const api = new ManageKpiApi()

export const getKpiList = () => {
    return api.getKpiList({}, _getKpiList, getKpiListSuccess, getKpiListFailure)
}

const _getKpiList = () => {
    return {
        type: actionTypes.GET_KPI_LIST,
    }
}

export const getKpiListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_KPI_LIST_SUCCESS,
        payload: data.data
    }
}

export const getKpiListFailure = (data: any) => {
    return {
        type: actionTypes.GET_KPI_LIST_ERROR,
        payload: data
    }
}

export const addKpiList = (payload: IKpiAddPayload) => {
    return api.addNewKpi(payload, _addKpiList, addKpiListSuccess, addKpiListFailure)
}

const _addKpiList = () => {
    return {
        type: actionTypes.ADD_KPI,
    }
}

export const addKpiListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_KPI_SUCCESS,
        payload: data.kpis
    }
}

export const addKpiListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_KPI_ERROR,
        payload: data
    }
}

export const editKpi = (payload: IKpiEditPayload) => {
    return api.editKpi(payload, _editKpi, editKpiSuccess, editKpiFailure)
}

const _editKpi = () => {
    return {
        type: actionTypes.EDIT_KPI,
    }
}

export const editKpiSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_KPI_SUCCESS,
        payload: data.users
    }
}

export const editKpiFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_KPI_ERROR,
        payload: data
    }
}

export const deleteKpi = (payload: IDeleteKpiPayload) => {
    return api.deleteKpi(payload, _deleteKpi, deleteKpiSuccess, deleteKpiFailure)
}

const _deleteKpi = () => {
    return {
        type: actionTypes.DELETE_KPI,
    }
}

export const deleteKpiSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_KPI_SUCCESS,
        payload: data
    }
}

export const deleteKpiFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_KPI_ERROR,
        payload: data
    }
}

export const selectAddKpi = () => {
    return {
        type: actionTypes.SELECT_ADD_KPI,
    }
}

export const selectEditKpi = (data: IKpi) => {
    return {
        type: actionTypes.SELECT_EDIT_KPI,
        payload: data
    }
}

export const updateKpiInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_KPI,
        payload: data
    }
}

export const clearSelectedKpi = () => {
    return {
        type: actionTypes.CLEAR_KPI
    }
}

export const publishKpi = (data: any) => {
    return api.publishKpi(data, _publishKpi, publishKpiSuccess, publishKpiFailure)
}

const _publishKpi = () => {
    return {
        type: actionTypes.PUBLISH_KPI,
    }
}

const publishKpiSuccess = (data: any) => {
    return {
        type: actionTypes.PUBLISH_KPI_SUCCESS,
        payload: data
    }
}

const publishKpiFailure = (data: any) => {
    return {
        type: actionTypes.PUBLISH_KPI_FAILURE,
        payload: data
    }
}

export const updateData = (data: IKpi[]) => {
    return {
        type: actionTypes.UPDATE_KPI_DATA,
        payload: data
    }
}
