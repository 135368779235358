import actionTypes from './notificationActionTypes'
import { INotification } from '../types/notification'

export const showNotification = (payload: INotification) => {
    return {
        type: actionTypes.SHOW_NOTIFICATION,
        payload
    }
}

export const showLoader = (message?: string) => {
    return {
        type: actionTypes.SHOW_LOADER,
        payload: message
    }
}

export const hideLoader = () => {
    return {
        type: actionTypes.HIDE_LOADER
    }
}