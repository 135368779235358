import actionType from "../actions/candidateUniquenesActionType";
import { ICandidateUniqueness } from "../types/candidateUniqueness";

const intialState: ICandidateUniqueness = {
    allCompanyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allSolutionTimingList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    originalTimings: [],
    addSolutionTimingsStatus: {
        loading: false,
        success: false,
        error: false
    },
    filteredSutTimingsCompanyList: []
}


const candidateUniquenesReducer = (state = intialState, action: any): ICandidateUniqueness => {
    switch (action.type) {
        case actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE: {
            return {
                ...state,
                allCompanyList: { loading: true, success: false, error: false, data: [] }
            }
        }
        case actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE_SUCCESS: {
            return {
                ...state,
                allCompanyList: { loading: false, success: true, error: false, data: action.payload }
            }
        }
        case actionType.GET_ALL_COMPANY_CANDIDATE_UNIQUE_FAILURE: {
            return {
                ...state,
                allCompanyList: { loading: false, success: false, error: true, data: [] }
            }
        }

        case actionType.GET_ALL_SULUTION_TIMELINES: {
            return {
                ...state,
                allSolutionTimingList: { loading: true, success: false, error: false, data: [] }
            }
        }

        case actionType.GET_ALL_SULUTION_TIMELINES_SUCCESS: {
            return {
                ...state,
                allSolutionTimingList: { loading: false, success: true, error: false, data: action.payload },
                originalTimings: action.payload
            }
        }

        case actionType.GET_ALL_SULUTION_TIMELINES_FAILURE: {
            return {
                ...state,
                allSolutionTimingList: { loading: false, success: false, error: true, data: [] }
            }
        }
        case actionType.UPDATE_TIMELINE: {
            return {
                ...state,
                allSolutionTimingList: Object.assign({ loading: false, success: true, error: false, data: action.payload })
            }
        }

        case actionType.ADD_SOLUTION_TIMINGS: {
            return {
                ...state,
                addSolutionTimingsStatus: { loading: true, success: false, error: false }
            }
        }

        case actionType.ADD_SOLUTION_TIMINGS_SUCCESS: {
            return {
                ...state,
                addSolutionTimingsStatus: { loading: false, success: true, error: false }
            }
        }

        case actionType.ADD_SOLUTION_TIMINGS_FAILURE: {
            return {
                ...state,
                addSolutionTimingsStatus: { loading: false, success: false, error: true }
            }
        }

        case actionType.FILTER_SUT_TIMING_COMPANY: {
            return {
                ...state,
                filteredSutTimingsCompanyList: action.payload
            }
        }

        case actionType.RESET_ALL_SOLUTION_TIMINGS: {
            return {
                ...state,
                allSolutionTimingList: Object.assign(state.allSolutionTimingList, { data: action.payload })
            }
        }
        case actionType.UPDATE_ORIGINAL_TIMINGS : {
            return {
                ...state,
                originalTimings:action.payload
            }
        }

        case actionType.CLEAR_SUT_TIMING_STATE: {
            return {
                allCompanyList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allSolutionTimingList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                addSolutionTimingsStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                originalTimings: [],
                filteredSutTimingsCompanyList: []
            }
        }

        default: {
            return state
        }



    }
}

export default candidateUniquenesReducer