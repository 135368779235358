const actionType = {
    GET_CA_REPORT_LIST: 'GET_CA_REPORT_LIST',
    GET_CA_REPORT_LIST_SUCCESS: 'GET_CA_REPORT_LIST_SUCCESS',
    GET_CA_REPORT_LIST_ERROR: 'GET_CA_REPORT_LIST_ERROR',
    ADD_CA_REPORT: 'ADD_CA_REPORT',
    ADD_CA_REPORT_SUCCESS: 'ADD_CA_REPORT_SUCCESS',
    ADD_CA_REPORT_ERROR: 'ADD_CA_REPORT_ERROR',
    EDIT_CA_REPORT: 'EDIT_CA_REPORT',
    EDIT_CA_REPORT_SUCCESS: 'EDIT_CA_REPORT_SUCCESS',
    EDIT_CA_REPORT_ERROR: 'EDIT_CA_REPORT_ERROR',
    DELETE_CA_REPORT: 'DELETE_CA_REPORT',
    DELETE_CA_REPORT_SUCCESS: 'DELETE_CA_REPORT_SUCCESS',
    DELETE_CA_REPORT_ERROR: 'DELETE_CA_REPORT_ERROR',
    SELECT_ADD_CA_REPORT: 'SELECT_ADD_CA_REPORT',
    SELECT_EDIT_CA_REPORT: 'SELECT_EDIT_CA_REPORT',
    UPDATE_CA_REPORT: 'UPDATE_CA_REPORT',
    CLEAR_CA_REPORT: 'CLEAR_CA_REPORT',
    PUBLISH_CA_REPORT:'PUBLISH_CA_REPORT',
    PUBLISH_CA_REPORT_SUCCESS:'PUBLISH_CA_REPORT_SUCCESS',
    PUBLISH_CA_REPORT_FAILURE:'PUBLISH_CA_REPORT_FAILURE',
    UPDATE_CA_REPORT_LIST:'UPDATE_CA_REPORT_LIST'
}

export default actionType