import { useEffect, useState } from 'react';
import './ManageNotification.scss';

import { connect } from 'react-redux'
import { Dialog } from 'primereact/dialog';
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import ManageNotificationForm from './ManageNotificationForm';
import {
  getNotificationsList,
  selectAddNotifications,
  selectEditNotifications,
  updateNotificationsInfo,
  addNotificationsList,
  editNotifications,
  deleteNotifications,
  clearSelectedNotifications,
  updateNotificationsList
} from '../../../actions/ManageNotificationsAction';
import { showNotification } from '../../../actions/notificationAction'
import { IUserNotification, INotificationError, IStatus } from '../../../types/ManageNotification';
import { IStoreState } from '../../../types/StoreState'
import { INotification } from '../../../types/notification';
import { getKpiList } from '../../../actions/ManageKpiAction'
import { isEqual } from '../../../util/arrayFunction'
import Table, { IAction, IColumn } from '../../shared/Table/Table';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment';


interface IProps {
  updateBreadCrumb: (breadcrumb: any) => void,
  clearBreadCrumb: () => void,
  getNotificationList: () => void,
  notificationsList: IUserNotification[],
  selectAddNotification: () => void,
  selectEditNotification: (data: IUserNotification) => void,
  addNotification: (payload: any) => void,
  editNotification: (payload: any) => void,
  deleteNotification: (payload: { id: any }) => void,
  clearSelectedNotification: () => void,
  currentNotification: IUserNotification,
  editingNotification?: IUserNotification,
  addNotificationStatus: IStatus,
  editNotificationStatus: IStatus,
  deleteNotificationStatus: IStatus,
  showNotification: (notification: INotification) => void,
  updateNotificationsList: (data: any) => void
}

const ManageNotification = ({ updateBreadCrumb,
  notificationsList,
  clearBreadCrumb,
  getNotificationList,
  selectAddNotification,
  selectEditNotification,
  addNotification,
  editNotification,
  deleteNotification,
  clearSelectedNotification,
  currentNotification,
  editingNotification,
  addNotificationStatus,
  editNotificationStatus,
  deleteNotificationStatus,
  updateNotificationsList,
  showNotification }: IProps) => {
  const [displayManageNotificationAddDialog, setDisplayManageNotificationAddDialog] = useState<boolean>(false);
  const [displayManageNotificationDeleteDialog, setDisplayManageNotificationDeleteDialog] = useState<boolean>(false);
  const [formError, setFormError] = useState<INotificationError>()
  const [isEdit, setIsEdit] = useState<boolean>(false)


  const filterObject = {
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }


  useEffect(() => {
    const breadCrumb = [{ label: 'Home', url: '' }, { label: 'Manage Notifications', url: '/' }]
    updateBreadCrumb(breadCrumb);
    getNotificationList()
    getKpiList()
    return () => {
      clearBreadCrumb();
      scrollAuto()
    };
  }, [])


  useEffect(() => {
    if (deleteNotificationStatus && !deleteNotificationStatus.loading && deleteNotificationStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Deleted Successfully',
        detail: `${currentNotification.title} is deleted successfully.`, life: 2000
      }
      showNotification(notification)
      const newNotifications = notificationsList.filter((item: IUserNotification) => item.notificationId !== currentNotification.notificationId)
      clearSelectedNotification();
      updateNotificationsList(newNotifications)
    }
  }, [deleteNotificationStatus])

  useEffect(() => {
    if (editNotificationStatus && !editNotificationStatus.loading && editNotificationStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Updated Successfully',
        detail: `Notification is updated successfully.`, life: 2000
      }
      showNotification(notification)
      const newNotifications = notificationsList.map((item: IUserNotification) => item.notificationId === currentNotification.notificationId ? currentNotification : item)
      clearSelectedNotification()
      updateNotificationsList(newNotifications)
      setDisplayManageNotificationAddDialog(false)
    }
  }, [editNotificationStatus])

  useEffect(() => {
    if (addNotificationStatus && !addNotificationStatus.loading && addNotificationStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Added Successfully',
        detail: `Notification is added successfully.`, life: 2000
      }
      showNotification(notification)
      clearSelectedNotification()
      getNotificationList()
      setDisplayManageNotificationAddDialog(false)
    }
  }, [addNotificationStatus])

  const scrollHidden = () => {
    document.body.style.overflow = 'hidden'
  }

  const scrollAuto = () => {
    document.body.style.overflow = 'auto'
  }

  const validForm = () => {
    let formErr: INotificationError = {
      title: false,
      notification: false,
      startDate: false,
      endDate: false
    }

    if (!currentNotification.title || !currentNotification.title.trim()) {
      formErr.title = true
    }
    if (!currentNotification.notification || !currentNotification.notification.trim()) {
      formErr.notification = true
    }
    if (!currentNotification.startDate) {
      formErr.startDate = 'Please enter start date'
    } else {
      if (moment(currentNotification.startDate).isAfter(currentNotification.endDate)) {
        formErr.startDate = 'Start date should be less than end date'
      }
      if (!isEdit && moment(currentNotification.startDate).isBefore(moment().subtract(1, 'days'))) {
        formErr.startDate = 'Start date can not be less than current date.'
      }
    }

    if (!currentNotification.endDate) {
      formErr.endDate = 'Please enter end date'
    } else {
      if (moment(currentNotification.endDate).isBefore(moment().subtract(1, 'days'))) {
        formErr.endDate = 'End date can not be less than current date.'
      }
    }

    if (currentNotification.file) {
      let value = currentNotification.file;
      if (value && value.type && !value.type.includes('image/')) {
        formErr.file = 'Please upload only image file.';
      }
    }

    setFormError(formErr)

    for (let item of Object.values(formErr)) {
      if (item) {
        return false
      }
    }

    return true
  }

  const addNewNotification = () => {
    selectAddNotification();
    setDisplayManageNotificationAddDialog(true)
    scrollHidden();
    setIsEdit(false)
  }


  const save = () => {
    if (!validForm()) return
    if (editingNotification && editingNotification.notificationId) {
      if (isEqual(editingNotification, currentNotification)) {
        clearSelectedNotification()
        setDisplayManageNotificationAddDialog(false)
        scrollAuto()
        return
      }
      const payload = new FormData();
      payload.append('title', currentNotification.title);
      payload.append('notificationId', currentNotification.notificationId || null);
      payload.append('notification', currentNotification.notification);
      payload.append('roles', currentNotification.roles || '');
      payload.append('uiEndDate', currentNotification.endDate);
      payload.append('uiStartDate', currentNotification.startDate);
      if (currentNotification.file) {
        payload.append('file', currentNotification.file || null);
      }

      editNotification(payload)
      scrollAuto();
      return
    }
    const payload = new FormData();
    payload.append('title', currentNotification.title);
    payload.append('notification', currentNotification.notification);
    payload.append('roles', currentNotification.roles || '');
    payload.append('uiEndDate', currentNotification.endDate);
    payload.append('uiStartDate', currentNotification.startDate);
    if (currentNotification.file) {
      payload.append('file', currentNotification.file || null);
    }
    addNotification(payload)
    // setDisplayManageNotificationAddDialog(false);
    scrollAuto();
  }

  const cancel = () => {
    clearSelectedNotification()
    setDisplayManageNotificationAddDialog(false);
    let formErr: INotificationError = {
      title: false,
      notification: false,
      startDate: false,
      endDate: false
    }
    setFormError(formErr);
    scrollAuto();
  }

  //for delete feature


  const onDeleteNotification = (user: any) => {
    selectEditNotification(user)
    setDisplayManageNotificationDeleteDialog(true);
    scrollHidden();
  }

  const onDeleteNotificationReject = () => {
    clearSelectedNotification()
    setDisplayManageNotificationDeleteDialog(false);
    scrollAuto();
  }

  const onDeleteNotificationProceed = () => {
    setDisplayManageNotificationDeleteDialog(false);
    deleteNotification({ id: currentNotification.notificationId || 0 })
    scrollAuto();
  }

  //for edit feature 
  const onEditNotification = (userNotification: IUserNotification) => {
    selectEditNotification(userNotification)
    setDisplayManageNotificationAddDialog(true)
    scrollHidden();
    setIsEdit(true)
  }




  const renderDialogFooter = () => {
    return (
      <div className=''>
        <button className='me-3 btn btn-danger' onClick={cancel}> <span className='mdi mdi-close'></span> Cancel</button>
        <button className='btn btn-success' onClick={save}>Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
      </div>
    )

  }


  const renderDeleteDialogFooter = () => {
    return (
      <div className='d-flex justify-content-center'>
        <button className='me-3 btn btn-danger' onClick={onDeleteNotificationReject}>  No </button>
        <button className='btn btn-success' onClick={onDeleteNotificationProceed}> Yes </button>
      </div>
    )
  }




  const action: IAction[] = [

    {
      cssClass: 'text-secondary mdi mdi-pencil',
      action: onEditNotification,
      header: '',
      title: 'Edit'
    },
    {
      cssClass: 'text-danger mdi mdi-delete',
      action: onDeleteNotification,
      header: '',
      title: 'Delete'
    }
  ]


  const columns: IColumn[] = [
    {
      field: 'title',
      header: 'Title',
      applyFilter: true
    },
    {
      field: 'startDate',
      header: 'Start Date'
    },
    {
      field: 'endDate',
      header: 'End Date'
    }
  ]

  const globalFilterField = ['title'];

  const updateError = (error: any) => {
    setFormError({ ...formError, ...error });

  }


  return (
    <div className='mx-3 manage-report mt-3 d-flex justify-content-center'>
      <div className='w-100'>
        <Dialog resizable={false} draggable={false} header={<h4 className='dialog-heading'>Please Fill The Below Details To {isEdit ? 'Edit Notification' : 'Create New Notification'}</h4>} visible={displayManageNotificationAddDialog} breakpoints={{ '960px': '75vw' }} style={{ width: '45vw' }} footer={renderDialogFooter()} onHide={() => cancel()} >
          {currentNotification ? <ManageNotificationForm updateError={updateError} isEdit={isEdit} formError={formError}></ManageNotificationForm> : null}
        </Dialog>

        <Dialog resizable={false} draggable={false} header='' visible={displayManageNotificationDeleteDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={() => onDeleteNotificationReject()} >
          <div className='dialog-heading text-center'>
            <h6 className='text-danger'>{`Do you really want to delete the notification ${currentNotification?.title} ? `}</h6>
          </div>
        </Dialog>
        <div className='d-flex justify-content-between align-items-center p-2'>
          <h3 className='text-start text-global-custom fw-bold'>All Notification</h3>
          <button className='btn btn-success' onClick={() => addNewNotification()}><span className='mdi mdi-plus l3-font-size'></span> Add Notification</button>
        </div>

        <Table filterObject={filterObject} data={notificationsList} column={columns} actions={action} globalFilterField={globalFilterField} paginator={true}></Table>

      </div>
    </div>

  )
}

const mapStateToProps = (state: IStoreState) => ({
  notificationsList: state.manageNotification.notificationsList.notifications,
  roles: state.userRole.roles.data,
  currentNotification: state.manageNotification.currentNotifications,
  editingNotification: state.manageNotification.editingNotifications,
  addNotificationStatus: state.manageNotification.addNotificationsStatus,
  editNotificationStatus: state.manageNotification.editNotificationsStatus,
  deleteNotificationStatus: state.manageNotification.deleteNotificationsStatus,
})


const mapDispatchToProps = (dispatch: any) => ({
  updateBreadCrumb: (data: any) => {
    dispatch(updateBreadCrumb(data));
  },
  clearBreadCrumb: () => {
    dispatch(clearBreadCrumb())
  },
  getNotificationList: () => {
    dispatch(getNotificationsList())
  },
  selectAddNotification: () => {
    dispatch(selectAddNotifications())
  },
  selectEditNotification: (data: IUserNotification) => {
    dispatch(selectEditNotifications(data))
  },
  updateNotificationInfo: (data: any) => {
    dispatch(updateNotificationsInfo(data))
  },
  addNotification: (payload: IUserNotification) => {
    dispatch(addNotificationsList(payload))
  },
  editNotification: (payload: IUserNotification) => {
    dispatch(editNotifications(payload))
  },
  deleteNotification: (payload: { id: any }) => {
    dispatch(deleteNotifications(payload))
  },
  clearSelectedNotification: () => {
    dispatch(clearSelectedNotifications())
  },
  showNotification: (notification: INotification) => {
    dispatch(showNotification(notification))
  },
  getKpiList: () => {
    dispatch(getKpiList())
  },
  updateNotificationsList: (data: IUserNotification[]) => {
    dispatch(updateNotificationsList(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageNotification);