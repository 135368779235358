import { ITimesheetMyEntryReducer } from "../types/Timesheet";
import actionType from '../actions/TimesheetMyEntryActionTypes'

const initialState: ITimesheetMyEntryReducer = {
    entry: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    hourRequired: {
        loading: false,
        success: false,
        error: false,
        hours: 45
    },
    self: {
        loading: false,
        success: false,
        error: false,
        user: undefined
    }
}

const TimesheetMyEntryReducer = (state = initialState, action: any): ITimesheetMyEntryReducer => {
    const getSquadId = () => {
        if (!state.self.user) return 0;
        let userSquads = state.self.user.squadId?.split(',') || [];
        if (userSquads.length === 1) {
            return parseInt(userSquads[0])
        }
        return 0
    }
    switch (action.type) {
        case actionType.GET_MYENTRY_LIST: {
            return {
                ...state,
                entry: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_MYENTRY_LIST_SUCCESS: {
            return {
                ...state,
                entry: Object.assign({}, state.entry, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_MYENTRY_LIST_ERROR: {
            return {
                ...state,
                entry: Object.assign({}, state.entry, { loading: false, error: true, success: false, list: [] })

            }
        }

        case actionType.UPDATE_MY_ENTRY_LIST: {
            return {
                ...state,
                entry: Object.assign({}, state.entry, { list: action.payload })
            }
        }
        case actionType.ADD_MYENTRY: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_MYENTRY_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_MYENTRY_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_MYENTRY: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_MYENTRY_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_MYENTRY_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_MYENTRY: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_MYENTRY_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_MYENTRY_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_MYENTRY: {
            return {
                ...state,
                currentEntry: {
                    projectId: 0,
                    projectName: '',
                    squadId: getSquadId(),
                    squadName: '',
                    weekCommencing: '',
                    businessPlanningConcept: 0,
                    projectManagement: 0,
                    analysis: 0,
                    designBuild: 0,
                    newDevelopment: 0,
                    testing: 0,
                    support: 0,
                    leave: 0,
                    sickLeave: 0,
                    generalAdmin: 0,
                    overtime: 0,
                    overtimeType: '',
                    initiatives: ''
                }
            }
        }

        case actionType.SELECT_EDIT_MYENTRY: {
            return {
                ...state,
                currentEntry: Object.assign({}, action.payload),
                editEntry: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_MYENTRY: {
            return {
                ...state,
                currentEntry: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_MYENTRY: {
            delete state['editEntry'];
            return {
                ...state,
                currentEntry: {
                    projectId: 0,
                    projectName: '',
                    squadId: 0,
                    squadName: '',
                    weekCommencing: '',
                    businessPlanningConcept: 0,
                    projectManagement: 0,
                    analysis: 0,
                    designBuild: 0,
                    newDevelopment: 0,
                    testing: 0,
                    support: 0,
                    leave: 0,
                    sickLeave: 0,
                    generalAdmin: 0,
                    overtime: 0,
                    overtimeType: '',
                    initiatives: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
            }
        }

        case actionType.GET_MY_HOUR_REQUIRED: {
            return {
                ...state,
                hourRequired: Object.assign({}, {
                    success: false,
                    error: false,
                    hours: 45
                }, { loading: true })
            }
        }
        case actionType.GET_MY_HOUR_REQUIRED_SUCCESS: {
            return {
                ...state,
                hourRequired: Object.assign({}, { loading: false, hours: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_MY_HOUR_REQUIRED_ERROR: {
            return {
                ...state,
                hourRequired: Object.assign({}, { loading: false, error: true, success: false, hours: 45 })
            }
        }

        case actionType.GET_SELF: {
            return {
                ...state,
                self: Object.assign({}, {
                    success: false,
                    error: false,
                }, { loading: true })
            }
        }
        case actionType.GET_SELF_SUCCESS: {
            return {
                ...state,
                self: Object.assign({}, { loading: false, user: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_SELF_ERROR: {
            return {
                ...state,
                self: Object.assign({}, { loading: false, error: true, success: false, user: undefined })
            }
        }
        default:
            return state
    }
}

export default TimesheetMyEntryReducer;