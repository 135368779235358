import actionTypes from './ManageUserActionTypes'
import { ManageUserApi } from '../api/manageUserApi';
import { IUser, IUserAddPayload, IUserEditPayload, IDeleteUserPayload } from '../types/ManageUser'
const api = new ManageUserApi()

export const getUserList = () => {
    return api.getUserList({}, _getUserList, getUserListSuccess, getUserListFailure)
}

const _getUserList = () => {
    return {
        type: actionTypes.GET_USER_LIST,
    }
}

export const getUserListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_USER_LIST_SUCCESS,
        payload: data.data
    }
}

export const getUserListFailure = (data: any) => {
    return {
        type: actionTypes.GET_USER_LIST_ERROR,
        payload: data
    }
}

export const addUserList = (payload: IUserAddPayload) => {
    return api.addNewUser(payload, _addUserList, addUserListSuccess, addUserListFailure)
}

const _addUserList = () => {
    return {
        type: actionTypes.ADD_USER,
    }
}

export const addUserListSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_USER_SUCCESS,
        payload: data.users
    }
}

export const addUserListFailure = (data: any) => {
    return {
        type: actionTypes.ADD_USER_ERROR,
        payload: data
    }
}

export const editUser = (payload: IUserEditPayload) => {
    return api.editUser(payload, _editUser, editUserSuccess, editUserFailure)
}

const _editUser = () => {
    return {
        type: actionTypes.EDIT_USER,
    }
}

export const editUserSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_USER_SUCCESS,
        payload: data.users
    }
}

export const editUserFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_USER_ERROR,
        payload: data
    }
}

export const deleteUser = (payload: IDeleteUserPayload) => {
    return api.deleteUser(payload, _deleteUser, deleteUserSuccess, deleteUserFailure)
}

const _deleteUser = () => {
    return {
        type: actionTypes.DELETE_USER,
    }
}

export const deleteUserSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        payload: data.data
    }
}

export const deleteUserFailure = (data: any) => {
    return {
        type: actionTypes.DELETE_USER_ERROR,
        payload: data
    }
}

export const selectAddUser = () => {
    return {
        type: actionTypes.SELECT_ADD_USER,
    }
}

export const selectEditUser = (data: IUser) => {
    return {
        type: actionTypes.SELECT_EDIT_USER,
        payload: data
    }
}

export const updateUserInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_USER,
        payload: data
    }
}

export const clearSelectedUser = ()=> {
    return {
        type: actionTypes.CLEAR_USER
    }
}

export const updateUserList = (data: IUser[]) => {
    return {
        type: actionTypes.UPDATE_USER_LIST,
        payload: data
    }
}
