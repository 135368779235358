import { BiHomePageApi } from "../api/biHomePageApi";
import actionTypes from "./BiHomePageActionTypes";
import { IDropdownList } from '../types/common'
import { IBarChartData, IBarChartRace, IBarRaceChartData, IDropoutApiData, IPolarChartData, ICategoryData, ISeriesData, ILineChartData } from "../types/BiHomePage";
import { number } from "yargs";
const api = new BiHomePageApi();



export const getCandidatesServedPerMonth = () => {
    return api.getCandidatesServedPerMonth({}, _getCandidatesServedPerMonth, getCandidatesServedPerMonthSuccess, getCandidatesServedPerMonthFailure);
}

const _getCandidatesServedPerMonth = () => {
    return {
        type: actionTypes.GET_CANDIDATES_SERVED_PER_MONTH
    }
}

const getCandidatesServedPerMonthSuccess = (data: any) => {
    let max = data["maxValue"];
    delete data["maxValue"];

    let payloadData = {
        max: max,
        months: Object.keys(data),
        data: Object.values(data)
    }

    return {
        type: actionTypes.GET_CANDIDATES_SERVED_PER_MONTH_SUCCESS,
        payload: payloadData
    }
}

const getCandidatesServedPerMonthFailure = (data: any) => {
    return {
        type: actionTypes.GET_CANDIDATES_SERVED_PER_MONTH_FAILURE,
        payload: data
    }
}

export const getDropoutsPerMonth = () => {
    return api.getDropoutsPerMonth({}, _getDropoutsPerMonth, getDropoutsPerMonthSuccess, getDropoutsPerMonthFailure)
}

const _getDropoutsPerMonth = () => {
    return {
        type: actionTypes.GET_DROPOUTS_PER_MONTH
    }
}

const getDropoutsPerMonthSuccess = (data: any) => {
    let max = Math.round(data["maxValue"]);
    delete data["maxValue"];
    let payloadData = {
        max: max,
        months: Object.keys(data),
        data: Object.values(data)
    }
    return {
        type: actionTypes.GET_DROPOUTS_PER_MONTH_SUCCESS,
        payload: payloadData
    }
}

const getDropoutsPerMonthFailure = (data: any) => {
    return {
        type: actionTypes.GET_DROPOUTS_PER_MONTH_FAILURE,
        payload: data
    }
}

export const getKpiBiHomeData = () => {
    return api.getKpiBiHomeData({}, _getKpiBiHomeData, getKpiBiHomeDataSuccess, getKpiBiHomeDataFailure)
}

const _getKpiBiHomeData = () => {
    return {
        type: actionTypes.GET_KPI_BI_HOME_DATA
    }
}

const getKpiBiHomeDataSuccess = (apiData: any) => {
    let payload: any = {};
    if (!apiData) {
        return {
            type: actionTypes.GET_KPI_BI_HOME_DATA_FAILURE,
            payload: payload
        }
    }
    apiData.forEach((item: IDropdownList) => {
        switch (item.label) {
            case 'dropouts': {
                payload['dropoutByIndustry'] = processDropoutData(JSON.parse(item.value));
                break;
            }

            // case 'packageTrend': {
            //     payload['packageTrend'] = processTrendData(JSON.parse(item.value));
            //     break;
            // }

            case 'topAssessments': {
                payload['topAssessments'] = processRaceChartData(JSON.parse(item.value));
                break;
            }

            default: {
                break;
            }
        }
    })

    return {
        type: actionTypes.GET_KPI_BI_HOME_DATA_SUCCESS,
        payload: payload
    }
}

const getKpiBiHomeDataFailure = (data: any) => {
    return {
        type: actionTypes.GET_KPI_BI_HOME_DATA_FAILURE,
        payload: data
    }
}

const processDropoutData = (data: IDropoutApiData) => {
    let dropout: IPolarChartData = {
        category: {
            data: []
        },
        series: []
    }

    let assessments: any = {}

    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        dropout.category.data.push(keys[i]);
        for (let item of data[keys[i]]) {
            if (!assessments[item.label]) {
                assessments[item.label] = new Array(keys.length).fill(0)
            }
            assessments[item.label][i] = Number(item.value)
        }
    }
    for (let key in assessments) {
        // if (key === 'Other') continue;
        dropout.series.push({
            name: key,
            stack: 'a',
            data: assessments[key]
        })
    }

    return dropout;
}

// const processTrendData = (data: any) => {
//     let trends: ILineChartData[] = [];
//     let ageGroup: any = { '18-20': 1, '21-24': 2, '25-29': 3, '30–34': 4, '35–39': 5, '40-44': 6, '45-49': 7, '50-54': 8, '55-59': 9, '60-64': 10 };
//     let color = [
//         '#0b84a5',
//         '#f6c85f',
//         '#6f4e7c',
//         '#9dd866',
//         '#ca472f',
//         '#ffa056',
//         '#8dddd0'];
//     let keys = Object.keys(data)
//     let min = 10;
//     let max = 0;
//     for (let index in keys) {
//         let i: ILineChartData = {
//             label: keys[index],
//             data: [],
//             fill: false,
//             borderColor: color[index],
//             tension: .4
//         }

//         data[keys[index]].forEach((innerItem: IDropdownList) => {
//             let index: number = Number(ageGroup[innerItem.label]);
//             if (!Number.isNaN(index)) {
//                 i.data[index] = Number(innerItem.value);
//                 min = min > Number(innerItem.value) ? Number(innerItem.value) : min;
//                 max = max < Number(innerItem.value) ? Number(innerItem.value) : max;
//             }
//         })
//         trends.push(i)
//     }
//     return { data: trends, min: min - 0.3, max: max + 0.3 }
// }

const processRaceChartData = (data: any) => {
    const color = ['#0b84a5',
        '#f6c85f',
        '#6f4e7c',
        '#9dd866',
        '#ca472f',
        '#ffa056',
        '#8dddd0'];

    let keys = Object.keys(data);
    let raceData = keys.reduce((res, item) => {
        if (data[item] && data[item].length > 0) {
            return {
                ...res,
                ...{
                    [item]: data[item].map((innerItem: IDropdownList, index: number) => {
                        let i: IBarRaceChartData = {
                            title: innerItem.label,
                            value: Number(innerItem.value),
                            color: color[index],
                            id: innerItem.label
                        }
                        return i;
                    })
                }
            };
        }
        return res
    }, {});

    return raceData;
}


