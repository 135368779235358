const actionType = {
    GET_REPORT_LIST: 'GET_REPORT_LIST',
    GET_REPORT_LIST_SUCCESS: 'GET_REPORT_LIST_SUCCESS',
    GET_REPORT_LIST_ERROR: 'GET_REPORT_LIST_ERROR',
    ADD_REPORT: 'ADD_REPORT',
    ADD_REPORT_SUCCESS: 'ADD_REPORT_SUCCESS',
    ADD_REPORT_ERROR: 'ADD_REPORT_ERROR',
    EDIT_REPORT: 'EDIT_REPORT',
    EDIT_REPORT_SUCCESS: 'EDIT_REPORT_SUCCESS',
    EDIT_REPORT_ERROR: 'EDIT_REPORT_ERROR',
    DELETE_REPORT: 'DELETE_REPORT',
    DELETE_REPORT_SUCCESS: 'DELETE_REPORT_SUCCESS',
    DELETE_REPORT_ERROR: 'DELETE_REPORT_ERROR',
    SELECT_ADD_REPORT: 'SELECT_ADD_REPORT',
    SELECT_EDIT_REPORT: 'SELECT_EDIT_REPORT',
    UPDATE_REPORT: 'UPDATE_REPORT',
    CLEAR_REPORT: 'CLEAR_REPORT',
    COPY_FILTER: 'COPY_FILTER',
    COPY_FILTER_SUCCESS: 'COPY_FILTER_SUCCESS',
    COPY_FILTER_ERROR: 'COPY_FILTER_ERROR',
    UPDATE_COPY_DATA: 'UPDATE_COPY_DATA',
    CLEAR_COPY_DATA: 'CLEAR_COPY_DATA',
    PUBLISH_REPORT:'PUBLISH_REPORT',
    PUBLISH_REPORT_SUCCESS:'PUBLISH_REPORT_SUCCESS',
    PUBLISH_REPORT_FAILURE:'PUBLISH_REPORT_FAILURE',
    UPDATE_REPORT_LIST:'UPDATE_REPORT_LIST',
    GET_HISTORY:'GET_HISTORY',
    GET_HISTORY_SUCCESS:'GET_HISTORY_SUCCESS',
    GET_HISTORY_FAILURE:'GET_HISTORY_FAILURE',
    UPDATE_HISTORY_INFO:'UPDATE_HISTORY_INFO',
    UPDATE_REPORT_NOTIFICATION:'UPDATE_REPORT_NOTIFICATION',
    UPDATE_REPORT_NOTIFICATION_SUCCESS:'UPDATE_REPORT_NOTIFICATION_SUCCESS',
    UPDATE_REPORT_NOTIFICATION_ERROR:'UPDATE_REPORT_NOTIFICATION_ERROR',
    CLEAR_UPDATE_NOTIFICATION_STATUS: 'CLEAR_UPDATE_NOTIFICATION_STATUS'
}

export default actionType