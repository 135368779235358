import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../FieldCommon.scss';
import './Multiselect.scss';
import { debounceFunction } from 'src/util/debounce';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';
interface IOptions {
    label: string,
    value: string,
    disabled?: boolean
}

interface ISelect {
    update: (name: string, value: any, displayLabel: string, valueLabels?: any) => void,
    selectOptions?: IOptions[],
    label: string,
    name: string,
    disabled?: boolean,
    error?: boolean,
    noJoin?: boolean,
    value?: any,
    maxSelected?: number,
    onFilter?: (event: any) => void,
    onShow?: () => void,
    onHide?: () => void,
    panelClass?: string,
    noApply?: boolean,
    onBlur?: (e: any) => void,
    optionLabelField?: string,
    optionValueField?: string,
    disabledItemText?: string,
    noValueErrorText?: string
    noValueErrorIcon?: string
}

const Select = ({ update, selectOptions, label, name, disabled, error, noJoin, value, maxSelected, onHide, noApply, onBlur, disabledItemText, noValueErrorIcon, noValueErrorText }: ISelect) => {
    const [innerValue, setValue] = useState<any[]>([]);
    const staleClosureInnerValue = useRef(innerValue);
    const [labels, setLabels] = useState<any[]>([]);
    const staleClosureLabels = useRef(labels);

    const [searchString, setSearchString] = useState<string>('');
    const debounceSearch = useCallback(debounceFunction((nextValue: string, list: IOptions[], innerValue: any) => updateList(nextValue, list, innerValue), 400), [])

    // const debounceCompanyFilter = useCallback(debounceFunction((nextValue: string, list: IOptions[], innerValue: any) => updateList(nextValue, list, innerValue), 400), [])
    const [list, setList] = useState<any[]>([]);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [waitForSelectOptions, setWaitForSelectOptions] = useState<boolean>(false);
    const showSelectAll = useMemo(() => {
        if (!selectOptions || selectOptions.length < 0) return false;
        if (!maxSelected) return true;
        if (selectOptions.length > maxSelected) return false;
        return true
    }, [selectOptions, maxSelected]);
    const ref = useRef<any>();
    const searchInput = useRef<any>();


    const selectAll = useMemo(() => {
        let enableCount = 0;
        if (searchString) {
            for (let item of list) {
                if (item.disabled) continue;
                enableCount = 1;
                if (!innerValue.some((i: any) => i === item.value)) return false
            }
            if (enableCount === 1) {
                return true;
            }
        } else if (selectOptions) {
            for (let item of selectOptions) {
                if (item.disabled) continue;
                enableCount = 1;
                if (!innerValue.some((i: any) => i === item.value)) return false
            }
            if (enableCount === 1) {
                return true;
            }
        }
        return false
    }, [list, innerValue, selectOptions, searchString]);


    const width = useMemo(() => {
        if (ref && ref.current) {
            return ref.current.offsetWidth
        } else {
            return '200px'
        }
    }, [ref.current])

    const handler = (e: any) => {
        if (e.currentTarget.id === `${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`) {
            console.log(innerValue);
            hide()
        }
    }

    const onDropdownOpen = (e: any) => {
        if (e.currentTarget.id === `${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`) {
            window.sessionStorage.setItem('opendropdown', `${name}_dropdown`);
            searchInput.current.focus()
        }
    }

    useEffect(() => {
        staleClosureLabels.current = labels
    }, [labels]);

    useEffect(() => {
        staleClosureInnerValue.current = innerValue
    }, [innerValue]);

    useEffect(() => {
        let dropdown = ref.current;
        if (dropdown) {
            dropdown.addEventListener('hide.bs.dropdown', handler);
            dropdown.addEventListener('shown.bs.dropdown', onDropdownOpen);
            return () => {
                dropdown.removeEventListener('hide.bs.dropdown', handler);
                dropdown.removeEventListener('shown.bs.dropdown', onDropdownOpen);
            }
        }
    }, [])

    useEffect(() => {
        updateValue(value);
    }, [value])

    useEffect(() => {
        if (selectOptions) {
            debounceSearch('', selectOptions, innerValue)
            if (waitForSelectOptions) {
                setWaitForSelectOptions(false);
                updateValue(value)
            }
        }
    }, [selectOptions]);

    const updateValue = (value: any) => {
        if (value) {
            if (!noJoin) {
                if (innerValue.join(',') === value) return;
                let l = getLabelsValues(value.split(','));
                if (l && l.length > 0) {
                    setValue(value.split(','));
                    setLabels(l)
                }
            } else {
                if (innerValue.join(',') === value.join(',')) return;
                let l = getLabelsValues(value);
                if (l && l.length > 0) {
                    setValue(value)
                    setLabels(l)
                }
            }
        } else {
            setValue([])
            setLabels([]);
        }
    }

    const getLabelsValues = (value: any[]) => {
        let labels: string[] = [];
        if (!selectOptions || selectOptions.length === 0) {
            setWaitForSelectOptions(true);
            return labels;
        }
        value.forEach((item: any) => {
            let f = selectOptions.find((i: IOptions) => {
                return i.value.toString() === item.toString()
            })

            if (f) labels.push(f.label);
        })

        return labels;
    }



    const updateList = (value: any, selectOptions: IOptions[], innerValue: any) => {
        if (selectOptions) {
            let fItems: IOptions[] = [];
            if (value) {
                for (let item of selectOptions) {
                    if (item && item.label.toLowerCase().includes(value.toLowerCase())) {
                        fItems.push(item);
                    }
                }
            } else {
                fItems = selectOptions.slice(0, 100);
            }
            // if (fItems.length === 0) {
            //     fItems.push({
            //         label: `No ${label} is available.`,
            //         value: 'na',
            //         disabled: true
            //     })
            // }
            setList(fItems)
        }
    }

    const hide = () => {
        onHide && onHide();
        if (noJoin) {
            update(name, staleClosureInnerValue.current, label, staleClosureLabels.current.join(','));
            window.sessionStorage.removeItem('opendropdown');
            return
        }
        update(name, staleClosureInnerValue.current.join(','), label, staleClosureLabels.current.join(','));
        window.sessionStorage.removeItem('opendropdown');
    }


    const onChange = (event: any, value: any, label: any) => {
        const checked = event && event.currentTarget && event.currentTarget.checked;
        if (checked) {
            if (maxSelected && selectedCount === maxSelected && maxSelected > 0) {
                return;
            }
            let v = [...innerValue, value];
            setValue(v);
            let l = [...labels, label];
            setLabels(l);
            setSelectedCount((prevState) => {
                return prevState + 1;
            });
        } else {
            let v = [...innerValue];
            let i = v.findIndex(item => item === value)
            v.splice(i, 1);
            setValue(v);
            let l = [...labels];
            l.splice(i, 1);
            setLabels(l);
            setSelectedCount((prevState) => {
                return prevState - 1;
            });
        }
    }

    const isChecked = (value: any) => {
        return innerValue.some((item: any) => {
            return value === item
        })
    }

    const onSelectAll = (event: any) => {
        if (!selectOptions) return;
        const checked = event && event.currentTarget && event.currentTarget.checked;
        if (checked) {
            // setSelectAll(true);
            let v = [...innerValue];
            let l = [...labels];
            if (searchString) {
                list.forEach((item: IOptions) => {
                    if (item.disabled) return;
                    let i = v.findIndex(vi => vi === item.value);
                    if (i === -1) {
                        v.push(item.value);
                        l.push(item.label);
                    }
                })
            } else {
                selectOptions.forEach((item: IOptions) => {
                    if (item.disabled) return;
                    let i = v.findIndex(vi => vi === item.value);
                    if (i === -1) {
                        v.push(item.value);
                        l.push(item.label);
                    }
                })
            }
            setValue(v);
            setLabels(l);
        } else {
            // setSelectAll(false)
            let v = [...innerValue];
            let l = [...labels];
            if (searchString) {
                list.forEach((item: IOptions) => {
                    let i = v.findIndex(vi => vi === item.value)
                    v.splice(i, 1);
                    l.splice(i, 1);
                })
            } else {
                selectOptions.forEach((item: IOptions) => {
                    let i = v.findIndex(vi => vi === item.value)
                    v.splice(i, 1);
                    l.splice(i, 1);
                })
            }
            setValue(v);
            setLabels(l);
        }
    }


    const onSearch = (event: any) => {
        setSearchString(event.currentTarget.value);
        debounceSearch(event.currentTarget.value, selectOptions, innerValue)
    }
    return (
        // <MultiSelect showSelectAll={!maxSelected || (selectOptions && selectOptions.length <= maxSelected)} onShow={onShow} onHide={hide} panelClassName={panelClass} className={"form-control shadow-none max-height-38" + `${error ? ' invalid' : ''}`} resetFilterOnHide={true} optionLabel="label" value={innerValue} options={selectOptions} onChange={change} placeholder={`Select ${label}`} filter disabled={disabled} selectionLimit={maxSelected} onFilter={checkFilter} onBlur={blurred} panelFooterTemplate={!noApply && panelFooterTemplate} />
        <div className='multiselect_container'>
            <div ref={ref} className='value-container form-control cursor-pointer' style={{ pointerEvents: disabled ? 'none' : 'auto', borderColor: error ? 'rgb(220, 53, 69)' : '', opacity: disabled ? '0.6' : '1' }} id={`${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`} data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                {!innerValue || innerValue.length === 0 ? <span className='placeholder-text'>{`Select ${label}`}</span> : <>
                    {/* <CustomTooltip text={labels.join(', ')} target={`.${name.replace(/[^a-zA-Z0-9]/g, '')}-value`} /> */}
                    <span className={` value`} >
                        {labels.join(',')}
                    </span>
                </>}
                <span className='pi pi-chevron-down down-icon ms-auto'></span>
            </div>
            <div className='suggestion-box dropdown-menu p-0' style={{ width: width }} aria-labelledby={`${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`}>
                <div className='d-flex align-items-center border-bottom p-2 px-3 search-container'>
                    {showSelectAll && <input type="checkbox" className={"form-check-input shadow-none me-3 p-2"} value={'selectAll'} onChange={onSelectAll} checked={selectAll} ></input>}
                    <input ref={searchInput} type='text' className='form-control' placeholder='Type to search' onChange={onSearch} value={searchString} />
                </div>
                <ul className=''>
                    {selectOptions && selectOptions.length === 0 ?
                        <li style={{ pointerEvents: 'none' }} className='w-100 py-3 px-3 d-flex align-items-center text-danger'><span className={`error-icon me-2 mdi ${noValueErrorIcon ? noValueErrorIcon : 'mdi-alert'}`}></span> {noValueErrorText ? noValueErrorText : `No ${label.toLowerCase()} is available`}</li> :
                        list && list.length === 0 ?
                            <li style={{ pointerEvents: 'none' }} className='w-100 py-3 px-3 d-flex align-items-center text-danger'><span className={`error-icon me-2 mdi mdi-alert`}></span>{`No ${label.toLowerCase()} is available as per search`}</li> : null}
                    {list?.map(item => <li key={item.value} className={item.disabled ? 'disabled' : ''}>
                        <label htmlFor={`${name.replace(/[^a-zA-Z0-9]/g, '')}_${item.value}`} className='w-100 py-2 px-3'>
                            <input key={item.value} type="checkbox" className={"form-check-input shadow-none p-2 me-3"} id={`${name.replace(/[^a-zA-Z0-9]/g, '')}_${item.value}`} value={item.value} onChange={(event) => onChange(event, item.value, item.label)} checked={isChecked(item.value)} disabled={item.disabled}></input>
                            {item.disabled && <>
                                <CustomTooltip text={disabledItemText ? disabledItemText : 'This option is disabled not available for selection'} target={`.value-${item.value.toString().replace(/[^a-zA-Z0-9]/g, '')}`} />
                            </>}
                            <span className={`value-${item.value.toString().replace(/[^a-zA-Z0-9]/g, '')}  fw-normal l2-font-size`} >{item.label}</span>
                        </label>
                    </li>)}
                </ul>
            </div>
        </div>
    )
}

export default Select