import './CustomTooltip.scss';
import { Tooltip } from 'primereact/tooltip';

interface IProps {
    target: string,
    text: string
}
const CustomTooltip = ({ target, text }: IProps) => {
    return (<Tooltip target={target} mouseTrack autoHide={false} hideDelay={100} >
        <div className='tool-tip-text-container'>
            {text}
        </div>
    </Tooltip>)
}

export default CustomTooltip;