const viewRequestActionType = {
    FETCH_REQUESTS: 'FETCH_REQUESTS',
    FETCH_REQUESTS_SUCCESS: 'FETCH_REQUESTS_SUCCESS',
    FETCH_REQUESTS_ERROR: 'FETCH_REQUESTS_ERROR',
    START_ACTION: 'START_ACTION',
    ACTION_SUCCESS: 'ACTION_SUCCESS',
    ACTION_ERROR: 'ACTION_ERROR',
    CLEAR_PI_REQUEST: 'CLEAR_PI_REQUEST',
    UPDATE_REQUEST: 'UPDATE_REQUEST'
}

export default viewRequestActionType