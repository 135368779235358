import { IManageFact } from "../types/ManageFact";
import actionType from '../actions/ManageFactActionType'

const initialState: IManageFact = {
    factList: {
        loading: false,
        success: false,
        error: false,
        facts: []
    },
    generatedFactList: {
        loading: false,
        success: false,
        error: false,
        generatedFacts: []
    },
    addFactStatus: {
        loading: false,
        success: false,
        error: false
    },
    editFactStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteFactStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentFact: {
        id: '',
        question: '',
        query: '',
        isDynamic: false,
        value: ''
    },
    editingFact: {
        id: '',
        question: '',
        query: '',
        isDynamic: false,
        value: ''
    }

}

const manageFactReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_FACT_LIST: {
            return {
                ...state,
                factList: Object.assign({}, {
                    success: false,
                    error: false,
                    facts: []
                }, { loading: true })
            }
        }
        case actionType.GET_FACT_LIST_SUCCESS: {
            return {
                ...state,
                factList: Object.assign({}, state.factList, { loading: false, facts: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_FACT_LIST_ERROR: {
            return {
                ...state,
                factList: Object.assign({}, state.factList, { loading: false, error: true, success: false })

            }
        }
        case actionType.GET_ALL_GENERATED_FACT_LIST: {
            return {
                ...state,
                generatedFactList: Object.assign({}, {
                    success: false,
                    error: false,
                    generatedFacts: []
                }, { loading: true })
            }
        }

        case actionType.GET_ALL_GENERATED_FACT_LIST_SUCCESS: {
            return {
                ...state,
                generatedFactList: Object.assign({}, state.generatedFactList, { loading: false, generatedFacts: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_ALL_GENERATED_FACT_LIST_FAILURE: {
            return {
                ...state,
                generatedFactList: Object.assign({}, state.generatedFactList, { loading: false, error: true, success: false })

            }
        }

        case actionType.ADD_FACT: {
            return {
                ...state,
                addFactStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_FACT_SUCCESS: {
            return {
                ...state,
                addFactStatus: Object.assign({}, state.addFactStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_FACT_ERROR: {
            return {
                ...state,
                addFactStatus: Object.assign({}, state.addFactStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_FACT: {
            return {
                ...state,
                editFactStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_FACT_SUCCESS: {
            return {
                ...state,
                editFactStatus: Object.assign({}, state.editFactStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_FACT_ERROR: {
            return {
                ...state,
                editFactStatus: Object.assign({}, state.editFactStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_FACT: {
            return {
                ...state,
                deleteFactStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_FACT_SUCCESS: {
            return {
                ...state,
                deleteFactStatus: Object.assign({}, state.deleteFactStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_FACT_ERROR: {
            return {
                ...state,
                deleteFactStatus: Object.assign({}, state.deleteFactStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_FACT: {
            return {
                ...state,
                currentFact: Object.assign({}, {
                    question: '',
                    query: '',
                    isDynamic: false,
                    value: ''
                }),
                editingFact: null
            }
        }

        case actionType.SELECT_EDIT_FACT: {
            return {
                ...state,
                currentFact: Object.assign({}, action.payload),
                editingFact: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_FACT: {
            return {
                ...state,
                currentFact: Object.assign({}, state.currentFact, { ...action.payload })
            }
        }

        case actionType.CLEAR_FACT: {
            return {
                ...state,
                currentFact: null,
                editingFact: null,
                addFactStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editFactStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteFactStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }

        case actionType.UPDATE_FACT_LIST: {
            return {
                ...state,
                factList: Object.assign({}, state.factList, { loading: false, facts: action.payload, success: true, error: false })
            }
        }
        default:
            return state
    }
}

export default manageFactReducer