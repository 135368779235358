import { IManageKpi } from "../types/ManageKpi";
import actionType from '../actions/ManageKpiActionTypes'

const initialState: IManageKpi = {
    kpiList: {
        loading: false,
        success: false,
        error: false,
        kpis: []
    },
    addKpiStatus: {
        loading: false,
        success: false,
        error: false
    },
    editKpiStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteKpiStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentKpi: {
        kpi: '',
        kpiId: '',
        icon: '',
        description: '',
        color: ''
    },
    editingKpi: {
        kpi: '',
        kpiId: '',
        icon: '',
        description: '',
        color: ''
    },
    publishKpiStatus: {
        loading: false,
        success: false,
        error: false
    }

}

const manageKpiReducer = (state = initialState, action: any): IManageKpi => {
    switch (action.type) {
        case actionType.GET_KPI_LIST: {
            return {
                ...state,
                kpiList: Object.assign({}, {
                    success: false,
                    error: false,
                    kpis: []
                }, { loading: true })
            }
        }
        case actionType.GET_KPI_LIST_SUCCESS: {
            return {
                ...state,
                kpiList: Object.assign({}, state.kpiList, { loading: false, kpis: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_KPI_LIST_ERROR: {
            return {
                ...state,
                kpiList: Object.assign({}, state.kpiList, { loading: false, error: true, success: false, kpis: [] })

            }
        }
        case actionType.ADD_KPI: {
            return {
                ...state,
                addKpiStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_KPI_SUCCESS: {
            return {
                ...state,
                addKpiStatus: Object.assign({}, state.addKpiStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_KPI_ERROR: {
            return {
                ...state,
                addKpiStatus: Object.assign({}, state.addKpiStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_KPI: {
            return {
                ...state,
                editKpiStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_KPI_SUCCESS: {
            return {
                ...state,
                editKpiStatus: Object.assign({}, state.editKpiStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_KPI_ERROR: {
            return {
                ...state,
                editKpiStatus: Object.assign({}, state.editKpiStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_KPI: {
            return {
                ...state,
                deleteKpiStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_KPI_SUCCESS: {
            return {
                ...state,
                deleteKpiStatus: Object.assign({}, state.deleteKpiStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_KPI_ERROR: {
            return {
                ...state,
                deleteKpiStatus: Object.assign({}, state.deleteKpiStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_KPI: {
            return {
                ...state,
                currentKpi: Object.assign({}, {
                    kpi: '',
                    kpiId: '',
                    icon: '',
                    description: '',
                    color: ''
                }),
                editingKpi: {
                    kpi: '',
                    kpiId: '',
                    icon: '',
                    description: '',
                    color: ''
                }
            }
        }

        case actionType.SELECT_EDIT_KPI: {
            return {
                ...state,
                currentKpi: Object.assign({}, action.payload),
                editingKpi: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_KPI: {
            return {
                ...state,
                currentKpi: Object.assign({}, state.currentKpi, { ...action.payload })
            }
        }

        case actionType.CLEAR_KPI: {
            return {
                ...state,
                currentKpi: {
                    kpi: '',
                    kpiId: '',
                    icon: '',
                    description: '',
                    color: ''
                },
                editingKpi: {
                    kpi: '',
                    kpiId: '',
                    icon: '',
                    description: '',
                    color: ''
                }
            }
        }
        case actionType.PUBLISH_KPI: {
            return {
                ...state,
                publishKpiStatus: Object.assign({}, { loading: true, success: false, error: false })
            }
        }
        case actionType.PUBLISH_KPI_SUCCESS: {
            return {
                ...state,
                publishKpiStatus: Object.assign({}, { loading: false, success: true, error: false })
            }
        }
        case actionType.PUBLISH_KPI_FAILURE: {
            return {
                ...state,
                publishKpiStatus: Object.assign({}, { loading: false, success: false, error: true })
            }
        }

        case actionType.UPDATE_KPI_DATA: {
            return {
                ...state,
                kpiList: { loading: false, kpis: action.payload, success: true, error: false }
            }
        }
        default:
            return state
    }
}

export default manageKpiReducer