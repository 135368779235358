const actionType = {
   
    GET_TEAMENTRY_LIST: 'GET_TEAMENTRY_LIST',
    GET_TEAMENTRY_LIST_SUCCESS: 'GET_TEAMENTRY_LIST_SUCCESS',
    GET_TEAMENTRY_LIST_ERROR: 'GET_TEAMENTRY_LIST_ERROR',
    ADD_TEAMENTRY: 'ADD_TEAMENTRY',
    ADD_TEAMENTRY_SUCCESS: 'ADD_TEAMENTRY_SUCCESS',
    ADD_TEAMENTRY_ERROR: 'ADD_TEAMENTRY_ERROR',
    EDIT_TEAMENTRY: 'EDIT_TEAMENTRY',
    EDIT_TEAMENTRY_SUCCESS: 'EDIT_TEAMENTRY_SUCCESS',
    EDIT_TEAMENTRY_ERROR: 'EDIT_TEAMENTRY_ERROR',
    DELETE_TEAMENTRY: 'DELETE_TEAMENTRY',
    DELETE_TEAMENTRY_SUCCESS: 'DELETE_TEAMENTRY_SUCCESS',
    DELETE_TEAMENTRY_ERROR: 'DELETE_TEAMENTRY_ERROR',
    UPDATE_TEAMENTRY: 'UPDATE_TEAMENTRY',
    SELECT_ADD_TEAMENTRY: 'SELECT_ADD_TEAMENTRY',
    SELECT_EDIT_TEAMENTRY: 'SELECT_EDIT_TEAMENTRY',
    CLEAR_TEAMENTRY: 'CLEAR_MANAGE_TEAMENTRY',
}

export default actionType