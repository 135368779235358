import './ManageSolutionMapping.scss'
import { IDropdownList } from '../../../types/common';
import Select from "../../shared/Fields/SingleSelect/Select";
import MultiSelect from "../../shared/Fields/Multiselect/MultiSelect";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Table, { IColumn } from "../../shared/Table/Table";
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { IStoreState } from '../../../types/StoreState';
import { Dialog } from 'primereact/dialog'
import { addSolutionMappings, clear, filterSutCompany, getAllCompany, getAllMappings, getAllSolutionMapping, resetAllSolutionMappings, updateSolutionMappings, addMoreCompany, clearFilterCompany } from '../../../actions/ManageSolutionMappingAction';
import { ICommonType, IManageContentPackageError, IManageContentPackagPayload, IPair, ISolutionMapping, ISolutionMappingPayload, IStatus } from '../../../types/manageSolutionMapping';

import { useNavigate} from 'react-router';
import { clearBreadCrumb, updateBreadCrumb } from '../../../actions/BreadCrumbAction';
import { showNotification } from '../../../actions/notificationAction';
import { INotification } from '../../../types/notification';


interface IProps {
    updateBreadCrumb: (data: any) => void,
    clearBreadCrumb: () => void,
    allMappingsList: IPair[],
    allModifiedSolutionMappingList: ISolutionMapping[],
    allSolutionMappingList: ISolutionMapping[],
    allCompanyList: ICommonType,
    getAllCompany: (payload: any) => void,
    getAllMappings: (payload: any) => void,
    getAllSolutionMapping: (payload: any) => void,
    updateSolutionMappings: (payload: any) => void,
    addSolutionMappings: (payload: IManageContentPackagPayload) => void,
    resetAllSolutionMappings: () => void,
    showNotification: (notification: INotification) => void
    actionStatus: IStatus,
    clear: () => void,
    addMoreCompany: () => void,
    clearFilterCompany: () => void
}


const ManageSolutionMapping = ({ addMoreCompany, allMappingsList, allModifiedSolutionMappingList, allSolutionMappingList, getAllCompany, getAllMappings, getAllSolutionMapping, updateSolutionMappings, addSolutionMappings, allCompanyList, resetAllSolutionMappings, updateBreadCrumb, clearBreadCrumb, showNotification, actionStatus, clear, clearFilterCompany }: IProps) => {
    const [packageType, setPackageType] = useState<string>('');
    const tableRef = useRef<any>(null);
    const [companyValue, setCompanyValue] = useState<any>();
    const [formError, setFormError] = useState<IManageContentPackageError>();
    const navigate = useNavigate();
    const [displayResetWarning, setDisplayResetWarning] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (packageType) {
            let isIntegrated = packageType === 'Integrated Client' ? true : false
            setCompanyValue(null)
            getAllCompany({ isIntegrated: isIntegrated })
            clearFilterCompany()
        }

    }, [packageType])

    useEffect(() => {
        if (companyValue) {
            let isIntegrated = packageType === 'Integrated Client' ? true : false
            getAllMappings({ companyId: companyValue, isIntegrated: isIntegrated })
            getAllSolutionMapping({ companyId: companyValue, isIntegrated: isIntegrated })
        }

    }, [companyValue])

    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '' }, { label: 'SUT Configurations', url: '/' }]
        updateBreadCrumb(breadCrumb);
        return () => {
            clearBreadCrumb();
            clear()
        }
    }, [])

    useEffect(() => {
        if (getAddedOrDeletedMappings().length < 1) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [allModifiedSolutionMappingList])

    const packageOptions: IDropdownList[] = [
        { label: "Integrated Client", value: "Integrated Client" },
        { label: "Non Integrated Client", value: "Non Integrated Client" }
    ]

    const filterObject = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    }



    const mappingBodyTemplate = (rowData: ISolutionMapping) => {

        return rowData.mappingIds.length === 0 ? <span>&mdash;</span> : rowData.mappingIds.map((item: any) => allMappingsList.find((obj: IPair) => obj.value === item)?.label).join(' , ');
    }

    const mappingEditor = (options: any) => {
        return <div className="custom-background-multiselect"><MultiSelect panelClass='mapping-editor-panel panel-width-850' selectOptions={allMappingsList} name="" label={`${packageType === 'Integrated Client' ? 'ICP' : 'Project'}`} value={options.value} update={(name, value) => options.editorCallback(value)} noJoin={true} noApply={true} /></div>

    }

    const onRowEditComplete = (e: any) => {
        let _tableData = [...allModifiedSolutionMappingList];
        let { newData, index } = e;

        _tableData[index] = newData;

        updateSolutionMappings(_tableData);
    }

    const onPackageUpdate = (value: any) => {
        setPackageType(value)
    }

    const validate = () => {
        let _formError: IManageContentPackageError = {
            packageTypeError: false,
            companyError: false
        }

        if (!packageType)
            _formError.packageTypeError = true
        if (!companyValue)
            _formError.companyError = true

        setFormError(_formError)

        if (tableRef.current && tableRef.current.editingRows && tableRef.current.editingRows.length > 0) {
            const notification: INotification = {
                severity: 'error',
                summary: `You have incomplete edit action for some rows. Please complete those.`,
                detail: ``, life: 3000
            }

            showNotification(notification)
            return false;

        }

        for (const item of Object.values(_formError)) {
            if (item) {
                return false
            }
        }
        return true;

    }

    const onCompanyUpdate = (value: any) => {
        setCompanyValue(value)
    }

    const navigateToConfirmation = () => {
        navigate('/confirmation/manageSut')
    }

    const getAddedOrDeletedMappings = () => {
        let solutions: ISolutionMappingPayload[] = []
        allSolutionMappingList.forEach((olditem: ISolutionMapping, index: any) => {
            let solutionObj: ISolutionMappingPayload = {
                name: olditem.solution,
                addedMappings: [],
                deletedMappings: []

            }
            let newItem = allModifiedSolutionMappingList[index]
            newItem.mappingIds.forEach((newId: any) => {
                if (!olditem.mappingIds.find((oldId: any) => newId === oldId)) {
                    let obj = allMappingsList.find((item: IPair) => item.value === newId)
                    if (obj)
                        solutionObj.addedMappings.push(obj)
                }

            })

            olditem.mappingIds.forEach((oldId: any) => {
                if (!newItem.mappingIds.find((newId: any) => newId === oldId)) {
                    let obj = allMappingsList.find((item: IPair) => item.value === oldId)
                    if (obj)
                        solutionObj.deletedMappings.push(obj)
                }
            })

            if (solutionObj.addedMappings.length != 0 || solutionObj.deletedMappings.length != 0)
                solutions.push(solutionObj)

        })

        return solutions

    }

    const onSave = () => {
        if (!validate()) return
        let payload: IManageContentPackagPayload
        let solutions: ISolutionMappingPayload[] = getAddedOrDeletedMappings()
        if (solutions.length !== 0) {
            let isIntegrated = packageType === 'Integrated Client' ? true : false
            payload = {
                isIntegrated: isIntegrated,
                companyId: companyValue,
                solutions: solutions
            }
            addSolutionMappings(payload)
            navigateToConfirmation()
        }
        navigateToConfirmation()

    }

    const columns: IColumn[] = [
        {
            header: "Solution",
            field: "solution",
            applyFilter: false
        },
        {
            header: packageType === 'Integrated Client' ? "ICP" : "Project",
            field: "mappingIds",
            applyFilter: false,
            bodyTemplate: mappingBodyTemplate,
            cellEditor: mappingEditor

        }

    ]

    const onReset = () => {
        setDisplayResetWarning(true)
    }

    const onResetProceed = () => {
        resetAllSolutionMappings();
        setDisabled(true)
        setDisplayResetWarning(false)
    }

    const onResetReject = () => {
        setDisplayResetWarning(false)
    }


    const renderResetWarningDialogFooter = () => {
        return (
            <div className='d-flex justify-content-center'>
                <button className='me-5 btn btn-danger' onClick={onResetReject}> Cancel </button>
                <button className='btn btn-success' onClick={onResetProceed}> Ok </button>
            </div>
        )
    }

    const getSutBody = () => {
        if (!companyValue)
            return null;
        else if (companyValue && !allMappingsList.length)
            return <div className='text-danger text-start mt-5 '>No {packageType === 'Integrated Client' ? 'ICP' : 'Project'} Requires Solution Mapping For Current Company </div>
        else if (companyValue && allMappingsList.length > 0) return <Table onRowEditComplete={onRowEditComplete} filterObject={filterObject} data={allModifiedSolutionMappingList} column={columns} tableRef={tableRef} editMode={'row'} paginator={true} keyColumn={'solution'}></Table>

    }

    const onCompanyBlur = () => {
        addMoreCompany()
    }

    return (
        <div className="manage-solution-mapping w-100 p-3">
            <div className="d-flex justify-content-between align-items-end w-100 border-bottom p-2 mb-3">
                <h3 className="text-start text-global-custom w-50 fw-bold">Add SUT Configurations</h3>
                {companyValue && allMappingsList.length ? <div className='w-50 d-flex justify-content-end me-4'>
                    <button disabled={disabled} onClick={onReset} className="btn btn-danger  action-btn me-3 "><span className='mdi mdi-restore me-1'></span>Reset</button>
                    <button disabled={disabled} onClick={onSave} className="btn btn-success action-btn ">Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
                </div> : null}
            </div>

            <div className="form-container mt-3 shadow-sm p-4 pt-2 w-100 l2-font-size ">

                <div className='d-flex my-4 justify-content-start'>
                    <div className="w-50 me-5">
                        <div className='me-3 text-start'>
                            <label >Client Type</label>
                            <span className='text-danger'>*</span> <span>:</span>
                        </div>
                        <div className='w-100 text-start' >
                            <Select selectOptions={packageOptions} update={(name, value) => onPackageUpdate(value)} value={packageType} label="Client Type" name="client type" error={formError && formError.packageTypeError ? true : false}></Select>
                            {formError && formError.packageTypeError ? <small className='l1-font-size text-danger'>Please select client type</small> : null}
                        </div>

                    </div>
                    <div className="w-50 ">
                        <div className='me-3 text-start'>
                            <label >Company </label>
                            <span className='text-danger'>*</span> <span>:</span>
                        </div>
                        <div className='w-100 text-start'>
                            <Select disabled={!packageType} selectOptions={allCompanyList.data} update={(name, value) => onCompanyUpdate(value)} name="companyName" label="Company Name" value={companyValue} error={formError && formError.companyError ? true : false} onBlur={onCompanyBlur}></Select>
                            {formError && formError.companyError ? <small className='l1-font-size text-danger'>Please select company name</small> : null}
                        </div>

                    </div>
                </div>
                {getSutBody()}
                <div className="d-flex justify-content-end mt-4 w-100 ">
                    {companyValue && allMappingsList.length ? <div className='w-50 d-flex justify-content-end me-4'>
                        <button disabled={disabled} onClick={onReset} className="btn btn-danger action-btn me-3"><span className='mdi mdi-restore me-1 l3-font-size'></span>Reset</button>
                        <button disabled={disabled} onClick={onSave} className="btn btn-success action-btn ">Save<span className='mdi mdi-arrow-right ms-1 l3-font-size '></span></button>
                    </div> : null}
                </div>
                <Dialog resizable={false} draggable={false} header='' visible={displayResetWarning} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderResetWarningDialogFooter()} onHide={onResetReject} >
                    <div className='dialog-heading text-center d-flex justify-content-center'>
                        <h4 className='text-danger w-75'>{`You Will Lose All Your Unsaved Changes After This Operation.`}</h4>
                    </div>
                </Dialog>
            </div>



        </div>
    )


}

const mapStateToProps = (state: IStoreState) => ({
    allCompanyList: state.manageSolutionMapping.allCompanyList,
    allMappingsList: state.manageSolutionMapping.allMappingsList.data,
    allSolutionMappingList: state.manageSolutionMapping.allSolutionMappingList.data,
    allModifiedSolutionMappingList: state.manageSolutionMapping.allModifiedSolutionMappingList.data,
    actionStatus: state.manageSolutionMapping.addSolutionMappingsStatus
})

const mapDispatchToProps = (dispatch: any) => ({

    updateBreadCrumb: (data: any) => {
        dispatch(updateBreadCrumb(data));
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    getAllCompany: (payload: any) => {

        dispatch(getAllCompany(payload))
    },
    getAllMappings: (payload: any) => {
        dispatch(getAllMappings(payload))
    },
    getAllSolutionMapping: (payload: any) => {
        dispatch(getAllSolutionMapping(payload))
    },
    updateSolutionMappings: (payload: any) => {
        dispatch(updateSolutionMappings(payload))
    },
    addSolutionMappings: (payload: IManageContentPackagPayload) => {
        dispatch(addSolutionMappings(payload))
    },
    resetAllSolutionMappings: () => {
        dispatch(resetAllSolutionMappings())
    },
    showNotification: (notification: INotification) => {
        dispatch(showNotification(notification))
    },
    clear: () => {
        dispatch(clear())
    },
    addMoreCompany: () => {
        dispatch(addMoreCompany())
    },
    clearFilterCompany: () => {
        dispatch(clearFilterCompany())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageSolutionMapping);