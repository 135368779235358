const actionTypes = {
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    DELETE_TOKEN: 'DELETE_TOKEN',
    DELETE_TOKEN_SUCCESS: 'DELETE_TOKEN_SUCCESS',
    DELETE_TOKEN_ERROR: 'DELETE_TOKEN_ERROR',
    LOG_VIEW_HOSTORY: 'LOG_VIEW_HISTORY',
    LOG_VIEW_HOSTORY_SUCCESS: 'LOG_VIEW_HISTORY_SUCCESS',
    LOG_VIEW_HOSTORY_ERROR: 'LOG_VIEW_HISTORY_ERROR',
    LOG_APPLIED_FILTER: 'LOG_APPLIED_FILTER',
    LOG_APPLIED_FILTER_SUCCESS: 'LOG_APPLIED_FILTER_SUCCESS',
    LOG_APPLIED_FILTER_ERROR: 'LOG_APPLIED_FILTER_ERROR',
    CHECK_TOKEN_STATUS: 'CHECK_TOKEN_STATUS',
    CHECK_TOKEN_STATUS_SUCCESS: 'CHECK_TOKEN_STATUS_SUCCESS',
    CHECK_TOKEN_STATUS_ERROR: 'CHECK_TOKEN_STATUS_ERROR',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
    DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
    DOWNLOAD_REPORT_ERROR: 'DOWNLOAD_REPORT_ERROR',
    CLEAR_DOWNLOAD_STATUS: 'CLEAR_DOWNLOAD_STATUS',
    CLEAR_LOG_VIEW_HISTORY: 'CLEAR_LOG_VIEW_HISTORY',
    SECURE_DOWNLOAD_REQUEST: 'SECURE_DOWNLOAD_REQUEST',
    SECURE_DOWNLOAD_REQUEST_SUCCESS: 'SECURE_DOWNLOAD_REQUEST_SUCCESS',
    SECURE_DOWNLOAD_REQUEST_ERROR: 'SECURE_DOWNLOAD_REQUEST_ERROR',
    CLEAR_SECURE_DOWNLOAD_REQUEST: 'CLEAR_SECURE_DOWNLOAD_REQUEST'
}

export default actionTypes