import { IManageCaReport } from "../types/ManageCaReport";
import actionType from '../actions/ManageCaReportActionType'

const initialState: IManageCaReport = {
    caReportList: {
        loading: false,
        success: false,
        error: false,
        reports: []
    },
    addCaReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    editCaReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteCaReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentCaReport: {
        name: '',
        id: 0,
        description: '',
        internalUrl:'',
        externalUrl:'',
        categoryId:''
    },
    editingCaReport: {
        name: '',
        id: 0,
        description: '',
        internalUrl:'',
        externalUrl:'',
        categoryId:''
    },
    publishCaReportStatus: {
        loading:false,
        success:false,
        error:false
    }

}

const manageCaReportReducer = (state = initialState, action: any): IManageCaReport => {
    switch (action.type) {
        case actionType.GET_CA_REPORT_LIST: {
            return {
                ...state,
                caReportList: Object.assign({}, {
                    success: false,
                    error: false,
                    reports: []
                }, { loading: true })
            }
        }
        case actionType.GET_CA_REPORT_LIST_SUCCESS: {
            return {
                ...state,
                caReportList: Object.assign({}, state.caReportList, { loading: false, reports: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_CA_REPORT_LIST_ERROR: {
            return {
                ...state,
                caReportList: Object.assign({}, state.caReportList, { loading: false, error: true, success: false, reports: [] })

            }
        }
        case actionType.ADD_CA_REPORT: {
            return {
                ...state,
                addCaReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_CA_REPORT_SUCCESS: {
            return {
                ...state,
                addCaReportStatus: Object.assign({}, state.addCaReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_CA_REPORT_ERROR: {
            return {
                ...state,
                addCaReportStatus: Object.assign({}, state.addCaReportStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_CA_REPORT: {
            return {
                ...state,
                editCaReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_CA_REPORT_SUCCESS: {
            return {
                ...state,
                editCaReportStatus: Object.assign({}, state.editCaReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_CA_REPORT_ERROR: {
            return {
                ...state,
                editCaReportStatus: Object.assign({}, state.editCaReportStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_CA_REPORT: {
            return {
                ...state,
                deleteCaReportStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_CA_REPORT_SUCCESS: {
            return {
                ...state,
                deleteCaReportStatus: Object.assign({}, state.deleteCaReportStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_CA_REPORT_ERROR: {
            return {
                ...state,
                deleteCaReportStatus: Object.assign({}, state.deleteCaReportStatus, { loading: false, error: true, success: false })

            }
        }
        case actionType.UPDATE_CA_REPORT_LIST: {
            return {
                ...state,
                caReportList: Object.assign({}, state.caReportList, { loading: false, reports: action.payload, success: true, error: false })
            }
        }

        case actionType.SELECT_ADD_CA_REPORT: {
            return {
                ...state,
                currentCaReport: {
                    name: '',
                    id: 0,
                    description: '',
                    internalUrl:'',
                    externalUrl:'',
                    categoryId:''
                },
                editingCaReport: {
                    name: '',
                    id: 0,
                    description: '',
                    internalUrl:'',
                    externalUrl:'',
                    categoryId:''
                }
            }
        }

        case actionType.SELECT_EDIT_CA_REPORT: {
            return {
                ...state,
                currentCaReport: Object.assign({}, action.payload),
                editingCaReport: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_CA_REPORT: {
            return {
                ...state,
                currentCaReport: Object.assign({}, state.currentCaReport, { ...action.payload })
            }
        }

        case actionType.CLEAR_CA_REPORT: {
            return { ...initialState, caReportList: state.caReportList }
        }

        case actionType.PUBLISH_CA_REPORT : {
             return {
                ...state,
                publishCaReportStatus:Object.assign({} , {loading:true , success:false , error: false})
             }
        }
        case actionType.PUBLISH_CA_REPORT_SUCCESS : {
            return {
               ...state,
               publishCaReportStatus:Object.assign({} , {loading:false , success:true , error: false})
            }
       }
       case actionType.PUBLISH_CA_REPORT_FAILURE : {
        return {
           ...state,
           publishCaReportStatus:Object.assign({} , {loading:false , success:false , error:true})
        }
     }
         
        default:
            return state
    }
}

export default manageCaReportReducer