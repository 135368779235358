import actionTypes from "../actions/ManageScoreGroupActionType";
import { IManageScoreGroup } from "../types/ManageScoreGroup";

const initialState: IManageScoreGroup = {
    allCompanyCustomScoreTagList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allCompanyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allTCScoreTagList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allSelectedTCScoreTagList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allContentPackages: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    addCustomScoreGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteCustomScoreGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    editCustomScoreGroupStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentScoreGroup: {
        companyId: null,
        companyName: '',
        customScoreGroupName: '',
        customScoreGroupId: null,
        customScoreGroupDescription: '',
        owner: '',
        contentPackages: [],
    },
    editingScoreGroup: undefined,
    editingSelectedTCScoreTagList: [],
    viewOnly: false
}


const manageScoreGroupReducer = (state = initialState, action: any): IManageScoreGroup => {
    switch (action.type) {
        case actionTypes.GET_ALL_CUSTOM_SCORE_GROUP: {
            return {
                ...state,
                allCompanyCustomScoreTagList: Object.assign({}, state.allCompanyCustomScoreTagList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_CUSTOM_SCORE_GROUP_SUCCESS: {
            return {
                ...state,
                allCompanyCustomScoreTagList: Object.assign({}, state.allCompanyCustomScoreTagList, { loading: false, success: true, error: false, data: action.payload.data })
            }
        }
        case actionTypes.UPDATE_ALL_CUSTOM_SCORE_GROUP: {
            return {
                ...state,
                allCompanyCustomScoreTagList: Object.assign({}, state.allCompanyCustomScoreTagList, { data: action.payload })
            }
        }
        case actionTypes.GET_ALL_CUSTOM_SCORE_GROUP_FAILURE: {
            return {
                ...state,
                allCompanyCustomScoreTagList: Object.assign({}, state.allCompanyCustomScoreTagList, { loading: false, success: false, error: true, data: [] })
            }
        }
        case actionTypes.GET_ALL_COMPANY: {
            return {
                ...state,
                allCompanyList: Object.assign({}, state.allCompanyList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_COMPANY_SUCCESS: {
            return {
                ...state,
                allCompanyList: Object.assign({}, state.allCompanyList, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_COMPANY_FAILURE: {
            return {
                ...state,
                allCompanyList: Object.assign({}, state.allCompanyList, { loading: false, success: false, error: true, data: [] })
            }
        }
        case actionTypes.GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP: {
            return {
                ...state,
                allSelectedTCScoreTagList: Object.assign({}, state.allSelectedTCScoreTagList, { loading: true, success: false, error: false })
            }
        }
        case actionTypes.GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_SUCCESS: {
            return {
                ...state,
                allSelectedTCScoreTagList: Object.assign({}, state.allSelectedTCScoreTagList, { loading: false, success: true, error: false, data: action.payload }),
                editingSelectedTCScoreTagList: action.payload
            }
        }
        case actionTypes.GET_ALL_SCORE_TAG_IN_CUSTOM_GROUP_FAILURE: {
            return {
                ...state,
                allSelectedTCScoreTagList: Object.assign({}, state.allSelectedTCScoreTagList, { loading: false, success: false, error: true, data: [] })
            }
        }
        case actionTypes.GET_ALL_TC_SCORE_TAG: {
            return {
                ...state,
                allTCScoreTagList: Object.assign({}, state.allTCScoreTagList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_TC_SCORE_TAG_SUCCESS: {
            return {
                ...state,
                allTCScoreTagList: Object.assign({}, state.allTCScoreTagList, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_TC_SCORE_TAG_FAILURE: {
            return {
                ...state,
                allTCScoreTagList: Object.assign({}, state.allTCScoreTagList, { loading: false, success: false, error: true, data: [] })
            }
        }
        case actionTypes.GET_ALL_CONTENT_PACKAGE: {
            return {
                ...state,
                allContentPackages: Object.assign({}, state.allContentPackages, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_CONTENT_PACKAGE_SUCCESS: {
            return {
                ...state,
                allContentPackages: Object.assign({}, state.allContentPackages, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_CONTENT_PACKAGE_FAILURE: {
            return {
                ...state,
                allContentPackages: Object.assign({}, state.allContentPackages, { loading: false, success: false, error: true, data: [] })
            }
        }
        case actionTypes.ADD_CUSTOM_SCORE_GROUP: {
            return {
                ...state,
                addCustomScoreGroupStatus: { loading: true, success: false, error: false }
            }
        }
        case actionTypes.ADD_CUSTOM_SCORE_GROUP_SUCCESS: {
            return {
                ...state,
                addCustomScoreGroupStatus: { loading: false, success: true, error: false }
            }
        }
        case actionTypes.ADD_CUSTOM_SCORE_GROUP_FAILURE: {
            return {
                ...state,
                addCustomScoreGroupStatus: { loading: false, success: false, error: true }
            }
        }
        case actionTypes.DELETE_CUSTOM_SCORE_GROUP: {
            return {
                ...state,
                deleteCustomScoreGroupStatus: { loading: true, success: false, error: false }
            }
        }
        case actionTypes.DELETE_CUSTOM_SCORE_GROUP_SUCCESS: {
            return {
                ...state,
                deleteCustomScoreGroupStatus: { loading: false, success: true, error: false }
            }
        }
        case actionTypes.DELETE_CUSTOM_SCORE_GROUP_FAILURE: {
            return {
                ...state,
                deleteCustomScoreGroupStatus: { loading: false, success: false, error: true }
            }
        }
        case actionTypes.EDIT_CUSTOM_SCORE_GROUP: {
            return {
                ...state,
                editCustomScoreGroupStatus: { loading: true, success: false, error: false }
            }
        }
        case actionTypes.EDIT_CUSTOM_SCORE_GROUP_SUCCESS: {
            return {
                ...state,
                editCustomScoreGroupStatus: { loading: false, success: true, error: false }
            }
        }
        case actionTypes.EDIT_CUSTOM_SCORE_GROUP_FAILURE: {
            return {
                ...state,
                editCustomScoreGroupStatus: { loading: false, success: false, error: true }
            }
        }
        case actionTypes.SELECT_ADD_SCORE_GROUP: {
            return {
                ...state,
                currentScoreGroup: {
                    companyId: null,
                    companyName: '',
                    customScoreGroupDescription: '',
                    customScoreGroupName: '',
                    customScoreGroupId: null,
                    contentPackages: [],
                    owner: ''
                },
                editingScoreGroup: undefined
            }
        }
        case actionTypes.SELECT_EDIT_SCORE_GROUP: {
            return {
                ...state,
                currentScoreGroup: Object.assign({}, state.currentScoreGroup, action.payload),
                editingScoreGroup: Object.assign({}, state.currentScoreGroup, action.payload)
            }
        }
        case actionTypes.UPDATE_SCORE_GROUP_INFO: {
            return {
                ...state,
                currentScoreGroup: Object.assign({}, state.currentScoreGroup, action.payload)
            }
        }
        case actionTypes.UPDATE_TC_TAG_LIST: {
            return {
                ...state,
                allTCScoreTagList: { loading: false, success: true, error: false, data: action.payload }
            }
        }
        case actionTypes.UPDATE_SELECTED_TC_TAGS: {
            return {
                ...state,
                allSelectedTCScoreTagList: { loading: false, success: true, error: false, data: action.payload }
            }
        }
        case actionTypes.SET_VIEW_ONLY: {
            return {
                ...state,
                viewOnly: true
            }
        }
        case actionTypes.CLEAR_SELECTED_SCORE_GROUP: {
            return {
                ...state,
                allCompanyList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allTCScoreTagList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allSelectedTCScoreTagList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allContentPackages: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                addCustomScoreGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteCustomScoreGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editCustomScoreGroupStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                currentScoreGroup: {
                    companyId: null,
                    companyName: '',
                    customScoreGroupName: '',
                    customScoreGroupId: null,
                    customScoreGroupDescription: '',
                    owner: '',
                    contentPackages: [],
                },
                editingScoreGroup: undefined,
                editingSelectedTCScoreTagList: [],
                viewOnly: false
            }
        }

        default:
            return state
    }

}

export default manageScoreGroupReducer;