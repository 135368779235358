import { updateSearchKey } from "../actions/SearchKeyAction";
import { UPDATE_SEARCH_KEY } from "../actions/SearchKeyActionType";
import { IsearchKey } from "../types/SearchKey";
const initialState:IsearchKey = {
     searchKey:{
         data:''
     }
}

const searchKeyReducers = (state: IsearchKey=initialState , action:any):IsearchKey=> {
         switch(action.type) {
             case UPDATE_SEARCH_KEY: {
                 return {
                    searchKey: Object.assign({},state.searchKey, {data:action.payload} )
                 }
             }
             default:
                 return state
         }
}



export default searchKeyReducers;