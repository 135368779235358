import axios from "axios";
import TokenService from "./token.service";
import { refreshToken } from '../actions/loginAction';
import { showLoader, hideLoader } from '../actions/notificationAction'
import { config } from './config'
import { debug } from "console";

// const instance = axios.create({
//   baseURL: "http://localhost:8080/reportviewer",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const setup = (store: any) => {
  const { dispatch } = store;

  axios.interceptors.request.use(
    (newconfig: any) => {
      if ((!newconfig.params || !newconfig.params.noLoader) && !newconfig.url?.includes(config.baseUrl + config.apiUrl.isSessionActive) && newconfig.url !== config.baseUrl + config.apiUrl.logFilter) {
        dispatch(showLoader('Loading...'));
      }

      const token = TokenService.getLocalAccessToken();
      if ((!newconfig.params || !newconfig.params.noBearer) && token && newconfig.url !== config.baseUrl + config.apiUrl.getAuthCode) {
        newconfig.headers["Authorization"] = 'Bearer ' + token;
      }
      return newconfig;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      if ((!res.config.params || !res.config.params.noLoader) && !res.config.url?.includes(config.baseUrl + config.apiUrl.isSessionActive) && res.config.url !== config.baseUrl + config.apiUrl.logFilter) {
        dispatch(hideLoader());
      }
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== config.baseUrl + config.apiUrl.getAuthCode && err.response) {
        // Access Token was expired
        if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
          originalConfig._retry = true;

          try {

            const rs = await axios.get(config.baseUrl + "/auth/refreshToken", {
              params: {
                refresh_token: TokenService.getLocalRefreshToken()
              }
            });
            const accessToken = rs.data && rs.data.access_token;

            dispatch(refreshToken({ accessToken }));
            dispatch(hideLoader())
            TokenService.updateLocalAccessToken(accessToken);

            return axios(originalConfig);
          } catch (_error) {
            dispatch(hideLoader());
            return Promise.reject(_error);
          }
        }
      }
      dispatch(hideLoader());
      return Promise.reject(err);
    }
  );
};

export default setup;