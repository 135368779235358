import { addSolutionMappings } from "../actions/ManageSolutionMappingAction";
import actionType from "../actions/ManageSolutionMappingActionType";
import { IManageSolutionMapping } from "../types/manageSolutionMapping";

const intialState: IManageSolutionMapping = {
    allCompanyList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allMappingsList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allSolutionMappingList: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    allModifiedSolutionMappingList: {
        data: []
    },
    addSolutionMappingsStatus: {
        loading: false,
        success: false,
        error: false
    },
    filteredSutCompanyList: []

}


const manageSolutionMappingReducer = (state = intialState, action: any): IManageSolutionMapping => {
    switch (action.type) {
        case actionType.GET_ALL_COMPANY: {

            return {
                ...state,
                allCompanyList: { loading: true, success: false, error: false, data: [] }
            }
        }
        case actionType.GET_ALL_COMPANY_SUCCESS: {

            return {
                ...state,
                allCompanyList: { loading: false, success: true, error: false, data: action.payload.data }
            }
        }
        case actionType.GET_ALL_COMPANY_FAILURE: {
            return {
                ...state,
                allCompanyList: { loading: false, success: false, error: true, data: [] }
            }
        }

        case actionType.GET_ALL_MAPPINGS: {
            return {
                ...state,
                allMappingsList: { loading: true, success: false, error: false, data: [] }
            }
        }

        case actionType.GET_ALL_MAPPINGS_SUCCESS: {
            return {
                ...state,
                allMappingsList: { loading: false, success: true, error: false, data: action.payload.data }
            }
        }

        case actionType.GET_ALL_MAPPINGS_FAILURE: {
            return {
                ...state,
                allMappingsList: { loading: false, success: false, error: true, data: [] }
            }
        }

        case actionType.GET_ALL_SULUTION_MAPPING: {
            return {
                ...state,
                allSolutionMappingList: { loading: true, success: false, error: false, data: [] }
            }
        }

        case actionType.GET_ALL_SULUTION_MAPPING_SUCCESS: {
            return {
                ...state,
                allSolutionMappingList: { loading: false, success: true, error: false, data: action.payload.data },
                allModifiedSolutionMappingList: { data: action.payload.data }
            }
        }

        case actionType.GET_ALL_SULUTION_MAPPING_FAILURE: {
            return {
                ...state,
                allSolutionMappingList: { loading: false, success: false, error: true, data: [] }
            }
        }
        case actionType.UPDATE_SOLUTION_MAPPINGS: {
            return {
                ...state,
                allModifiedSolutionMappingList: { data: action.payload }
            }
        }
        case actionType.ADD_SOLUTION_MAPPINGS: {
            return {
                ...state,
                addSolutionMappingsStatus: { loading: true, success: false, error: false }
            }
        }

        case actionType.ADD_SOLUTION_MAPPINGS_SUCCESS: {
            return {
                ...state,
                addSolutionMappingsStatus: { loading: false, success: true, error: false }
            }
        }

        case actionType.ADD_SOLUTION_MAPPINGS_FAILURE: {
            return {
                ...state,
                addSolutionMappingsStatus: { loading: false, success: false, error: true }
            }
        }

        case actionType.FILTER_SUT_COMPANY: {
            return {
                ...state,
                filteredSutCompanyList: action.payload
            }
        }

        case actionType.RESET_ALL_SOLUTION_MAPPINGS: {
            return {
                ...state,
                allModifiedSolutionMappingList: { data: state.allSolutionMappingList.data }
            }
        }

        case actionType.CLEAR_SOLUTION_MAPPING_STATE: {
            return {
                allCompanyList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allMappingsList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allSolutionMappingList: {
                    loading: false,
                    success: false,
                    error: false,
                    data: []
                },
                allModifiedSolutionMappingList: {
                    data: []
                },
                addSolutionMappingsStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                filteredSutCompanyList: []
            }
        }

        case actionType.CLEAR_FILTER_SUT_COMPANY: {
            return {
                ...state,
                filteredSutCompanyList: []
            }
        }

        default: {
            return state
        }



    }
}

export default manageSolutionMappingReducer