import { useState, useEffect } from 'react'
import './FieldCommon.scss'

interface IBoolean {
    value: any,
    update: (name: string, value: any, displayLabel?: string, valueLabels?: any) => void,
    name: string,
    disabled?: boolean,
    error?: boolean,
    isBooleanVal?: boolean,
    label?: string
}
const Boolean = ({ value, name, update, disabled, error, label }: IBoolean) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (!value) {
            // update(name, false, label)
            setChecked(false)
        } else {
            setChecked(true)
        }
    }, [value])

    const onChange = (event: any) => {
        const v = event && event.currentTarget && event.currentTarget.checked;
        setChecked(v)
        update(name, v, label)
    }

    return (
        <input type="checkbox" className={"form-check-input shadow-none p-2" + `${error ? ' invalid' : ''}`} id={name} value={'true'} onChange={onChange} checked={checked} disabled={disabled}></input>

    )

}

export default Boolean