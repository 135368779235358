import { BaseApi } from "./baseApi";
import { config } from './config';

const api = new BaseApi()

export  class TableauRequestApi {
     submitTableauRequest( parameter:any , payload:any , requestAction:any , requestSuccessAction:any , errorAction:any ) {
      return api.post(config.apiUrl.submitTableauRequest , parameter , payload , requestAction , requestSuccessAction , errorAction)
     }
     checkIsTableauRequestPending(parameter:any ,requestAction:any , requestSuccessAction:any , errorAction:any ) {
       return  api.get(config.apiUrl.checkTableauRequestPending , parameter , requestAction , requestSuccessAction , errorAction)
     }
     getAllTableauRequest(parameter:any , requestAction:any , requestSuccessAction:any , errorAction:any) {
      return api.get(config.apiUrl.getAllTableauRequest , parameter , requestAction , requestSuccessAction, errorAction )
     }
     changeTableauRequestStatus(parameter:any , payload:any , requestAction:any , requestSuccessAction:any , errorAction:any) {
      return api.put(config.apiUrl.changeTableauRequestStatus , parameter , payload ,requestAction , requestSuccessAction , errorAction)
     }
}
