import './ProcessFlow.scss';
import { processList } from "src/constants/ProcessFlow";
import { IProcess } from "../../../constants/ProcessFlowType";
import ProcessComponent from '../../shared/ProcessComponent/ProcessComponent';
import { useLocation } from 'react-router';
import { useEffect } from 'react';


const ProcessFlow = () => {
    const location = useLocation()

    useEffect(() => {
        if (location && location.hash) {
            window.location.replace(location.hash)
        }
    }, [location])

    return (
        <div className="process-flow-container">
            <h3 className='p-2 fw-bold border-bottom text-start mb-3 mx-3'>
                Process Flow
            </h3>
            {/* <a href="#Tableau Access">Process</a> */}
            {processList.map((item: IProcess, index: number) => (
                <div id={`${item.processName}`} className="mx-auto mb-5 shadow process-wrapper">
                    <ProcessComponent {...item} processIndex={index} status={10} ></ProcessComponent>
                </div>
            ))

            }
            

        </div>
    )
}

export default ProcessFlow;