import { IBreadCrumb } from "../types/BreadCrumb"
import actionType from "../actions/BreadCrumbActionTypes"

const intialstate: IBreadCrumb = {
    breadCrumb: []
}

const BreadCrumbReducer = (state = intialstate, action: any) => {
    switch (action.type) {
        case actionType.UPDATE_BREADCRUMB: {
            return {
                breadCrumb: action.payload
            }
        }

        case actionType.CLEAR_BREADCRUMB: {
            return {
                breadCrumb: []
            }
        }
        default:
            return state
    }

}

export default BreadCrumbReducer