import { IManageExtract } from "../types/ManageExtract";
import actionType from '../actions/ManageExtractActionType'
import { extractTypes } from "src/constants/extractType";

const initialState: IManageExtract = {
    extractList: {
        loading: false,
        success: false,
        error: false,
        extracts: []
    },
    addExtractStatus: {
        loading: false,
        success: false,
        error: false
    },
    editExtractStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteExtractStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentExtract: {
        extractName: '',
        description: '',
        reportId: "",
        query: '',
        isPivotEnabled: false,
        l1ColumnName: '',
        l2ColumnName: '',
        fixedColumns: '',
        uniqueKey: '',
        valueColumn: '',
        roles: "",
        dataVolume: extractTypes.LOW,
        isSecureReport: false
    },
    editingExtract: {
        extractName: '',
        description: '',
        reportId: "",
        query: '',
        isPivotEnabled: false,
        l1ColumnName: '',
        l2ColumnName: '',
        fixedColumns: '',
        uniqueKey: '',
        valueColumn: '',
        roles: "",
        dataVolume: extractTypes.LOW,
        isSecureReport: false
    },
    reportExtract: {
        loading: false,
        success: false,
        error: false,
        extract: {
            extractName: '',
            description: '',
            reportId: "",
            query: '',
            isPivotEnabled: false,
            l1ColumnName: '',
            l2ColumnName: '',
            fixedColumns: '',
            uniqueKey: '',
            valueColumn: '',
            roles: "",
            dataVolume: extractTypes.LOW,
            isSecureReport: false
        }
    }

}

const manageExtractReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_EXTRACT_LIST: {
            return {
                ...state,
                extractList: Object.assign({}, {
                    success: false,
                    error: false,
                    extracts: []
                }, { loading: true })
            }
        }
        case actionType.GET_EXTRACT_LIST_SUCCESS: {
            return {
                ...state,
                extractList: Object.assign({}, state.extractList, { loading: false, extracts: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_EXTRACT_LIST_ERROR: {
            return {
                ...state,
                extractList: Object.assign({}, state.extractList, { loading: false, error: true, success: false })

            }
        }

        case actionType.GET_EXTRACT: {
            return {
                ...state,
                reportExtract: Object.assign({}, state.reportExtract, { loading: true })
            }
        }
        case actionType.GET_EXTRACT_SUCCESS: {
            return {
                ...state,
                reportExtract: Object.assign({}, state.reportExtract, { loading: false, extract: action.payload, success: true, error: false }),
                currentExtract: action.payload
            }
        }
        case actionType.GET_EXTRACT_ERROR: {
            return {
                ...state,
                reportExtract: Object.assign({}, state.reportExtract, { loading: false, error: true, success: false })

            }
        }

        case actionType.ADD_EXTRACT: {
            return {
                ...state,
                addExtractStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_EXTRACT_SUCCESS: {
            return {
                ...state,
                addExtractStatus: Object.assign({}, state.addExtractStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_EXTRACT_ERROR: {
            return {
                ...state,
                addExtractStatus: Object.assign({}, state.addExtractStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_EXTRACT: {
            return {
                ...state,
                editExtractStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_EXTRACT_SUCCESS: {
            return {
                ...state,
                editExtractStatus: Object.assign({}, state.editExtractStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_EXTRACT_ERROR: {
            return {
                ...state,
                editExtractStatus: Object.assign({}, state.editExtractStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_EXTRACT: {
            return {
                ...state,
                deleteExtractStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_EXTRACT_SUCCESS: {
            return {
                ...state,
                deleteExtractStatus: Object.assign({}, state.deleteExtractStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_EXTRACT_ERROR: {
            return {
                ...state,
                deleteExtractStatus: Object.assign({}, state.deleteExtractStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_EXTRACT: {
            return {
                ...state,
                currentExtract: Object.assign({}, {
                    reportId: "",
                    query: '',
                    isPivotEnabled: false,
                    l1ColumnName: '',
                    l2ColumnName: '',
                    fixedColumns: '',
                    uniqueKey: '',
                    valueColumn: '',
                    roles: "",
                    dataVolume: extractTypes.LOW
                }),
                editingExtract: null
            }
        }

        case actionType.SELECT_EDIT_EXTRACT: {
            return {
                ...state,
                currentExtract: Object.assign({}, action.payload),
                editingExtract: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_EXTRACT: {
            return {
                ...state,
                currentExtract: Object.assign({}, state.currentExtract, { ...action.payload })
            }
        }

        case actionType.CLEAR_EXTRACT: {
            return { ...initialState, extractList: state.extractList }
        }

        case actionType.UPDATE_EXTRACT_LIST: {
            return {
                ...state,
                extractList: Object.assign({}, state.extractList, { loading: false, extracts: action.payload, success: true, error: false })
            }
        }
        default:
            return state
    }
}

export default manageExtractReducer