export const rolesList = {
    PIDATA:0,
    ADMIN:1,
    REPORT_DEVELOPER:2,
    DPO:3,
    CSIT:4,
    QA:5,
    TABLEAU_ADMIN:6,
    SCORE_TAG_ADMIN:7,
    SUT:101,
    CA_REPORT_DEVELOPER:102,
    EMAIL_SERVICE:110,
    RTB:9,
    TIMESHEETADMIN: 10,
    CAUSER: 12,
    CUSTOMDASHBOARDUSER: 13,
    CREATEYOUROWNDASHBOARDUSER: 14,
    LEADERBOARDADMIN: 11
}