
import { connect } from 'react-redux'
import { getHomeNotification, markUnReadNotification } from 'src/actions/loginAction'
import { IStoreState } from 'src/types/StoreState'
import { useEffect } from 'react'
import './AllNotification.scss'
import tokenService from 'src/api/token.service'
import moment from 'moment'

interface IProps {
    homeNotification: {
        notifications: any[],
        unreadNotifications: any[],
        success: boolean,
        error: boolean,
        loading: boolean
    },
    getHomeNotification: () => void,
    markUnReadNotification: (notification: any[]) => void
}

const AllNotification = ({ homeNotification, getHomeNotification, markUnReadNotification }: IProps) => {
    useEffect(() => {
        if (homeNotification && !homeNotification.loading && !homeNotification.success) {
            getHomeNotification()
        }
    }, [homeNotification])

    const markRead = (item: any) => {
        tokenService.setReadNotification(item)
        let unread = homeNotification.unreadNotifications.filter((i: any) => item !== i.notificationId);
        markUnReadNotification(unread)
    }

    return (
        <div className=' p-4 w-100  notification-container d-flex flex-column  align-items-center'>
            <h3 className='border-bottom text-start mb-4 p-1 fw-bold w-100'>My Notifications</h3>
            {homeNotification.notifications && homeNotification.notifications.length > 0 ? <div className='shadow-sm p-3 content-wrapper'>
                {homeNotification.notifications.map((item: any, index: number) => {
                    return <div key={item.notificationId} className={`l2-font-size  text-start p-1 py-3 d-flex border-bottom content-container `} onClick={() => markRead(item.notificationId)}>
                        <div className={homeNotification.unreadNotifications.some((i: any) => i.notificationId === item.notificationId) ? 'unread' : 'read'}>

                        </div>
                        <div className='ps-4'>
                            <div className='text-end'>
                                <span className='l1-font-size'>{moment(item.startDate).format('YYYY-MM-DD')}</span>
                            </div>
                            {/* <h4 className='fw-bold'>{item.title}</h4> */}
                            <p className='mt-1 notification-desc  ' dangerouslySetInnerHTML={{ __html: item.notification }}></p>
                        </div>
                    </div>
                })}
            </div>
                : <div className='shadow-sm p-3 content-wrapper'><h3 className='w-100'>There are no notifications to show at this time.</h3></div>}
        </div>
    )
}

const mapStateToProps = (state: IStoreState) => ({
    homeNotification: state.login.homeNotifications
})

const mapDispatchToProps = (dispatch: any) => ({
    getHomeNotification: () => {
        dispatch(getHomeNotification())
    },
    markUnReadNotification: (notifications: any[]) => {
        dispatch(markUnReadNotification(notifications))
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(AllNotification)