import { useNavigate } from "react-router";
import "./ErrorHandler.scss";
import { useEffect } from "react";

interface IProps {
    title: string, description: string, emoji: string,
    showButton?: boolean
}
const PageNotFound = ({ title, description, emoji = 'mdi-emoticon-sad-outline', showButton = true }: IProps) => {

    const Navigate = useNavigate();
    const redirectToHome = () => {
        Navigate('/');
    }

    return (
        <div className=" d-flex justify-content-center my-5 ErrorHandler-container text-white">
            <div className=" row Errorhandler-wrapper py-4 rounded shadow-sm d-flex justify-content-center l2-font-size ">
             { emoji.length > 0 ? <div className="col-3 "><span className={"mdi text-grey " + emoji}></span></div> : null }   
                <div className="text-start col-9 d-flex justify-content-center flex-column">
                    <h2 className="mb-4">{title}</h2>
                    <p className="text-grey">{description}</p>
                    {showButton && <p className="text-grey">Please go to the SHL-Analytics home page by clicking the below link</p>}
                    {showButton && <button className="btn btn-secondary shadow-none" onClick={redirectToHome}>Visit Main Page</button>}
                </div>
            </div>
        </div>
    )


}

export default PageNotFound;