const actionType = {
    GET_KPI_LIST: 'GET_KPI_LIST',
    GET_KPI_LIST_SUCCESS: 'GET_KPI_LIST_SUCCESS',
    GET_KPI_LIST_ERROR: 'GET_KPI_LIST_ERROR',
    ADD_KPI: 'ADD_KPI',
    ADD_KPI_SUCCESS: 'ADD_KPI_SUCCESS',
    ADD_KPI_ERROR: 'ADD_KPI_ERROR',
    EDIT_KPI: 'EDIT_KPI',
    EDIT_KPI_SUCCESS: 'EDIT_KPI_SUCCESS',
    EDIT_KPI_ERROR: 'EDIT_KPI_ERROR',
    DELETE_KPI: 'DELETE_KPI',
    DELETE_KPI_SUCCESS: 'DELETE_KPI_SUCCESS',
    DELETE_KPI_ERROR: 'DELETE_KPI_ERROR',
    SELECT_ADD_KPI: 'SELECT_ADD_KPI',
    SELECT_EDIT_KPI: 'SELECT_EDIT_KPI',
    UPDATE_KPI: 'UPDATE_KPI',
    CLEAR_KPI: 'CLEAR_KPI',
    PUBLISH_KPI:'PUBLISH_KPI',
    PUBLISH_KPI_SUCCESS:'PUBLISH_KPI_SUCCESS',
    PUBLISH_KPI_FAILURE:'PUBLISH_KPI_FAILURE',
    UPDATE_KPI_DATA: 'UPDATE_KPI_DATA'
}

export default actionType