import actionTypes from "../actions/BiHomePageActionTypes";
import { IBiHomePage } from "../types/BiHomePage";

const initialState:IBiHomePage = {
    dropoutsPerMonth:{
        loading:false,
        success:false,
        error:false,
        data:Object.create(null)
    },
    candidatesPerMonth:{
        loading:false,
        success:false,
        error:false,
        data:Object.create(null)
    },
    kpiBiHomeData : {
        loading:false,
        success:false,
        error:false,
        data:Object.create(null)
    }
}



const biHomePageReducer = (state=initialState , action:any):IBiHomePage => {
          switch(action.type) {
            case actionTypes.GET_CANDIDATES_SERVED_PER_MONTH : {
                return {
                    ...state,
                    candidatesPerMonth:{loading:true , success:false , error:false , data:Object.create(null)}   
                }
            }

            case actionTypes.GET_CANDIDATES_SERVED_PER_MONTH_SUCCESS : {
                return {
                    ...state,
                    candidatesPerMonth:{loading:false , success:true , error:false , data:action.payload }    
                }
            }
            
            case actionTypes.GET_CANDIDATES_SERVED_PER_MONTH_FAILURE : {
                return {
                    ...state,
                    candidatesPerMonth:{loading:false , success:false ,error:true , data:Object.create(null) }  
                }
            }

            case actionTypes.GET_DROPOUTS_PER_MONTH : {
                return {
                    ...state,
                    dropoutsPerMonth:{loading:true , success:false , error:false , data:Object.create(null)}
                }
            }

            case actionTypes.GET_DROPOUTS_PER_MONTH_SUCCESS : {
                return {
                    ...state,
                    dropoutsPerMonth:{loading:false , success:true , error:false , data:action.payload }
                }
            }

            case actionTypes.GET_DROPOUTS_PER_MONTH_FAILURE : {
                return {
                    ...state,
                    dropoutsPerMonth:{loading:false , success:false , error:true , data:Object.create(null)}
                }
            }

            case actionTypes.GET_KPI_BI_HOME_DATA : {
                return {
                    ...state,
                    kpiBiHomeData:{loading:true , success:false , error:false , data:Object.create(null) }
                }
            }

            case actionTypes.GET_KPI_BI_HOME_DATA_SUCCESS : {
                return {
                    ...state,
                    kpiBiHomeData:{loading:false , success:true , error:false , data:action.payload }
                }
            }

            case actionTypes.GET_KPI_BI_HOME_DATA_FAILURE : {
                return {
                    ...state,
                    kpiBiHomeData:{loading:false , success:false , error:true , data:Object.create(null) }
                }
            }

            default : {
                return state
            }

          }
}

export default biHomePageReducer;



