import './FieldCommon.scss'

interface IEmail {
    value: string,
    update: (name: string, value: string) => void,
    label: string,
    name: string,
    error?: boolean,
    disabled?: boolean
}

const Email = ({ value, update, label, name, error, disabled }: IEmail) => {
    return (<input type='email' className={"form-control input-text shadow-none" + `${error ? ' invalid' : ''}`} aria-invalid={error} value={value} onChange={(e) => update(name, e.currentTarget.value)} placeholder={`Enter ${label}`} disabled={disabled} />)
}

export default Email