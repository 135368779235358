import './BiHeader.scss';
import { connect } from 'react-redux';
import { IDropdownList } from '../../../types/common'
import { Dropdown } from 'primereact/dropdown';
import { list } from '../../../constants/environment';
import { Link } from 'react-router-dom'
import { IStoreState } from 'src/types/StoreState';
import { useEffect } from 'react';
import { rolesList } from 'src/constants/RoleList';

interface IProps {
    userInfo?: any,
    isLogin?: any,
    userRoles?: any,
    homeNotification?: any[]
}

const BiHeader = ({ userInfo, userRoles, homeNotification, isLogin }: IProps) => {
    let allList: any = list
    let environment: string = process.env.REACT_APP_ENVIRONMENT_NAME || 'AU'
    const regionOptions: any = allList[environment]

    const onRegionChange = (value: any) => {
        window.open(`https://${value}`, '_blank');
    }

    // const closeSideNave = () => {
    //      const sidenav = document.getElementById('bi-sidenav')
    //      const home = document.getElementById('bi-home');
    //      sidenav?.classList.toggle('close');
    //      home?.classList.toggle('width-100');
    // }


    return (
        <nav id='bi-navbar' className="bi-navbar text-light sticky-top w-100 py-2   z-index-1001 d-flex justify-content-center ">
            <div className='navbar-item-container d-flex justify-content-between align-content-center mx-4'>
                {/* <span onClick={() => closeSideNave()} className='mdi mdi-menu fs-3 me-4'></span> */}
                {/* <Link to={'/'}><div className='brand-logo d-flex align-items-center h-100'><img className='logo-img' src={process.env.PUBLIC_URL + './asset/logo.png'} alt="" /> </div></Link> */}
                <div className='d-flex align-items-center'>
                    <span>SHL - Analytics</span>
                </div>
                <div className='d-flex align-items-center'>
                    <Link to='/notification'>
                        <div className='text-white me-4 d-flex'>
                            <span className='mdi mdi-bell fs-4 '></span>
                            <span className='l1-font-size' >{(homeNotification && homeNotification.length) ? homeNotification?.length : null}</span>
                        </div>
                    </Link>
                    {userRoles && userRoles.some((item: any) => item === rolesList.EMAIL_SERVICE)
                        ? <Link to={'/sendEmail'} className='text-white me-4 ms-1'><span className='mdi mdi-email-plus-outline fs-4 mail-icon'></span>Compose Email</Link>
                        : null}
                    <div className="rounded-circle  user-container d-flex  justify-content-center align-items-center">
                        {userInfo && userInfo.data && `${userInfo.data.fname[0]} ${userInfo.data.lname[0]}`}
                    </div>
                    <span className="text-light ms-2">{userInfo && userInfo.data && `${userInfo.data.fname} ${userInfo.data.lname}`}</span>

                    {/* {(environment !== 'UAT' && environment !== 'LOCAL' && environment !== 'DEV') ? <div className="d-flex w-auto  align-items-center">
                        
                        <Dropdown panelClassName='region-panel' className='ms-3 region-dropdown shadow-none border-0 text-light ' value={window.location.hostname} onChange={(e) => onRegionChange(e.value)} options={regionOptions}  ></Dropdown>
                        <span className='mdi mdi-chevron-double-right icon-chev'></span>
                    </div> : null} */}
                </div>
            </div>

        </nav>
    )
}

const mapStateToProps = (state: IStoreState) => ({
    isLogin: state.login.isLogin,
    userInfo: state.login.userInfo,
    userRoles: state.login.userRoles.roles,
    homeNotification: state.login.homeNotifications.unreadNotifications
})

export default connect(mapStateToProps, null)(BiHeader);