import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageQuizApi {

    getQuizList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getAllQuizList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    getAllGeneratedQuizList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getAllGeneratedQuizList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewQuiz(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addQuiz, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editQuiz(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.editQuiz, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteQuiz(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteQuiz, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}