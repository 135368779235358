import './FieldCommon.scss'
import { useCallback, useEffect, useState } from 'react';
import { debounceFunction } from '../../../util/debounce'
import { InputTextarea } from 'primereact/inputtextarea';
interface ITextArea {
    value: string,
    update: (name: string, value: string, displayLabel: string, valueLabels?: any) => void,
    label: string,
    name: string,
    error?: boolean,
    size: number,
    notResizable?: boolean,
    maxLimit?: number,
    hint?: string
}

const TextArea = ({ value, update, label, name, error, size, notResizable, maxLimit, hint }: ITextArea) => {
    const [val, setVal] = useState('')
    const [remainingChar, setRemainingChar] = useState<number>(100);
    // const debounceUpdate = useCallback(debounceFunction((nextValue: string) => update(name, nextValue, label), 400), [])

    const onUpdate = (e: any) => {
        if (maxLimit) {
            setRemainingChar(maxLimit - (e.currentTarget.value.length))
        }
        setVal(e.currentTarget.value)
        // debounceUpdate(e.currentTarget.value)
        update(name, e.currentTarget.value, label)
    }

    useEffect(() => {
        if (maxLimit) {
            setRemainingChar(maxLimit - (value.length))
        }
        setVal(value)
    }, [value]);

    return (
        <div className='d-flex flex-column align-items-end'>
            <InputTextarea maxLength={maxLimit} placeholder={`Enter ${label}`} className={"form-control textarea shadow-none input-text w-100" + `${error ? ' invalid' : ''}`} aria-invalid={error} rows={size} value={val} onChange={(e: any) => onUpdate(e)} autoResize={!notResizable} />
            {maxLimit && <small className='fs-small text-primary'>{remainingChar}/{maxLimit} characters left </small>}
            <small className=' text-primary l1-font-size'>{hint}</small>
        </div>

    )
}

export default TextArea;