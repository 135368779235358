import { connect } from 'react-redux'
import Text from '../../shared/Fields/Text'

import { IStoreState } from '../../../types/StoreState';
import { updateUserGroupInfo } from '../../../actions/ManageUserGroupAction';
import { IUserGroup, IUserGroupError } from '../../../types/ManageUserGroup'
import TextArea from '../../shared/Fields/TextArea';
interface IManageUserGroupForm {
  updateUserGroupInfo: (data: any) => void,
  formData: IUserGroup,
  formError?: IUserGroupError,
  editingUserGroup?: IUserGroup,
  isEdit: boolean
}

const ManageUserGroupForm = ({ updateUserGroupInfo, formData, formError, editingUserGroup, isEdit }: IManageUserGroupForm) => {

  const onUpdate = (name: string, value: any) => {
    let newValue: any = {};
    newValue[name] = value
    updateUserGroupInfo(newValue)
  }

  return (
    <form className='ManageUserGroupForm l2-font-size'>
      <div className='row mb-3 '>
        <label className='col-3 feild-label '>Group Name<span className='text-danger'>*</span></label>
        <div className='col-9'>
          <Text value={formData.groupName} update={(name, value) => onUpdate(name, value)} label='Group Name' name='groupName' error={formError && formError.groupNameError || false}></Text>
          {formError && formError.groupNameError ? <small className='text-danger l1-font-size'>Please enter group name</small> : null}
        </div>

      </div>

      <div className='row mb-3 '>
        <label className='col-3 feild-label'>User Emails<span className='text-danger'>*</span></label>
        <div className='col-9'>
          <TextArea size={5} value={formData.users} name='users' label='User Emails' update={(name, value) => onUpdate(name, value)} error={formError && formError.usersError || false} ></TextArea>
          <div><small className='text-primary l1-font-size'>Please enter comma separated emails</small></div>
          {formError && formError.usersError ? <small className='text-danger l1-font-size'>Please enter valid email address</small> : null}
        </div>
      </div>
    </form>
  )

}

const mapStateToProps = (state: IStoreState) => ({
  userGroups: state.manageUserGroup.userGroupList.userGroups,
  formData: state.manageUserGroup.currentUserGroup,
  editingUserGroup: state.manageUserGroup.editingUserGroup
})


const mapDispatchToProps = (dispatch: any) => ({
  updateUserGroupInfo: (data: any) => {
    dispatch(updateUserGroupInfo(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserGroupForm);