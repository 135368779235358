import { Dialog } from "primereact/dialog";
import moment from "moment";
import { useEffect, useState } from "react";
import { updateBreadCrumb, clearBreadCrumb } from "../../actions/BreadCrumbAction";
import { IDe, IDeError, IAllDeList, IEditDePayload, IStatus, ICompanyList, ICompany, IDeList, IGetCurrentDeCompaniesPayload, ICurrentDeCompanies, IAllCurrentDeCompanies } from "../../types/ManageDe";
import { connect } from "react-redux";
import ManageDeForm from "./ManageDeForm";
import { getAllDeList, addDe, editDe, deleteDe, selectAddDe, selectEditDe, clearSelectedDe, updateDeList, triggerJob, clearDe, getCurrentDeCompanyList } from '../../actions/ManageDeAction'
import { IStoreState } from "../../types/StoreState";
import { INotification } from '../../types/notification';
import { showNotification } from "../../actions/notificationAction";
import './ManageDe.scss'
import { isEqual } from "../../util/arrayFunction";
import { IDropdownList } from "../../types/common";
import { rolesList } from "src/constants/RoleList";
import Table, { IColumn } from "../shared/Table/Table";


interface IProps {
    allDeList: IAllDeList,
    companyList: ICompanyList,
    editingDe?: IDe,
    currentDe?: IDe,
    addDeStatus: IStatus,
    editDeStatus: IStatus,
    deleteDeStatus: IStatus,
    getAllDeList: () => void,
    addDe: (data: any) => void,
    editDe: (data: any) => void,
    deleteDe: (data: any) => void,
    selectAddDe: () => void,
    selectEditDe: (data: any) => void,
    clearSelectedDe: () => void,
    updateDeList: (data: any) => void
    triggerJob: () => void,
    clearDe: () => void,
    updateBreadCrumb: (breadcrumb: any) => void,
    clearBreadCrumb: () => void,
    triggerJobStatus: IStatus,
    roles: number[],
    showNotification: (notification: INotification) => void,
    getCurrentDeCompanyList:(param:IGetCurrentDeCompaniesPayload) => void,
    currentDeCompanyList:IAllCurrentDeCompanies
}


const ManageDe = ({
    allDeList,
    companyList,
    getAllDeList,
    editingDe,
    currentDe,
    addDe,
    editDe,
    deleteDe,
    addDeStatus,
    editDeStatus,
    deleteDeStatus,
    selectAddDe,
    selectEditDe,
    updateDeList,
    clearSelectedDe,
    triggerJob,
    clearDe,
    updateBreadCrumb,
    clearBreadCrumb,
    triggerJobStatus,
    roles,
    showNotification,
    getCurrentDeCompanyList,
    currentDeCompanyList
    }: IProps) => {
    const [displayManageDeAddDialog, setDisplayManageDeAddDialog] = useState<boolean>(false);
    const [displayManageDeDeleteDialog, setDisplayManageDeDeleteDialog] = useState<boolean>(false);
    const [displayManageDeWarningDialog, setDisplayManageDeWarningDialog] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState(null);

    const [formError, setFormError] = useState<IDeError>();
    let sourceObj: any = {
        1: 'TC',
        2: 'TC+'
    }

    const filterObject = {}

    const scrollHidden = () => {
        document.body.style.overflow = 'hidden'
    }

    const scrollAuto = () => {
        document.body.style.overflow = 'auto'
    }



    useEffect(() => {
        const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'Pending/New DE Requests', url: '/' }]
        updateBreadCrumb(breadCrumb);
        getAllDeList();
        return () => {
            clearBreadCrumb();
            scrollAuto()
            clearDe()
        };
    }, [])

    useEffect(() => {
        if (addDeStatus && !addDeStatus.loading && addDeStatus.success) {
            clearSelectedDe();
            getAllDeList();
            setDisplayManageDeAddDialog(false);

        }

    }, [addDeStatus])

    useEffect(() =>{
        if(currentDeCompanyList && !currentDeCompanyList.loading && currentDeCompanyList.success) {
            if(currentDeCompanyList.data.length==0) {
                save()
            }
            else {
                setDisplayManageDeWarningDialog(true)
            }
        }

    },[currentDeCompanyList])

    useEffect(() => {
        if (editDeStatus && !editDeStatus.loading && editDeStatus.success) {
            const newDeList = allDeList.data.map((item: IDeList) => {
                if (item.companyId === currentDe?.companyId && item.source === currentDe?.source) {
                    const editedItem = { ...item, ...currentDe }
                    return editedItem
                }
                else return item;
            })
            clearSelectedDe();
            updateDeList(newDeList)
            setDisplayManageDeAddDialog(false);

        }

    }, [editDeStatus])

    useEffect(() => {
        if (triggerJobStatus && !triggerJobStatus.loading && triggerJobStatus.success) {
            const notification: INotification = {
                severity: 'success',
                summary: 'Job Triggered Successfully',
                detail: `Job is triggered successfully.`, life: 2000
            }
            showNotification(notification)
            clearDe()
        }

    }, [triggerJobStatus])

    useEffect(() => {
        if (deleteDeStatus && !deleteDeStatus.loading && deleteDeStatus.success) {
            const notification: INotification = {
                severity: 'success',
                summary: 'Deleted Successfully',
                detail: `${currentDe && currentDe.companyId} is deleted successfully.`, life: 2000
            }
            showNotification(notification)
            const newDeList = allDeList.data.filter((item: IDeList) => {
                if (item.companyId === currentDe?.companyId && item.source === currentDe?.source) {
                    return false
                }
                return true
            })
            clearSelectedDe();
            updateDeList(newDeList)
            setDisplayManageDeDeleteDialog(false);

        }

    }, [deleteDeStatus])

    const getUsers = (item: IDe) => {
        return item.userIds.join(' , ');
    }


    const renderAllUser = () => {
        if (allDeList && allDeList.data && allDeList.data.length > 0) {
            return allDeList.data.map((item: IDeList) => {

                const valueItem: IDe = {
                    companyId: item.companyId,
                    companyName: item.companyName,
                    userIds: item.userIds,
                    source: item.source
                }

                return (
                    <tr className='last-border-none' key={`De${item.companyId}`}>
                        <td>{sourceObj[item.source]}</td>
                        <td>{item.companyName}</td>
                        <td>
                            {getUsers(item)}
                        </td>
                        <td>{item.creationDate}</td>
                        <td title={item.createdBy} className="text-truncate">{item.createdBy}</td>
                        <td>{item.updationDate}</td>
                        <td title={item.updatedBy} className="text-truncate" >{item.updatedBy}</td>
                        <td>
                            <button className='btn' onClick={() => onEditUser(valueItem)}><span className='text-secondary mdi mdi-pencil'></span></button>
                            <button className='btn' onClick={() => onDeleteUser(valueItem)} ><span className='text-danger mdi mdi-delete'></span></button>
                        </td>
                    </tr>
                )
            })
        } else {
            return <tr>
                <td className="text-center p-3" colSpan={8}>No pending DE request.</td>
            </tr>
        }
    }

    const renderDialogFooter = () => {
        return (
            <div className=''>
                <button className='me-3 btn btn-danger' onClick={cancel}> <span className='mdi mdi-close'></span> Cancel</button>
                <button className='btn btn-success' onClick={onSubmit}>Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
            </div>
        )

    }

    const renderDeleteDialogFooter = () => {
        return (
            <div className='d-flex justify-content-center'>
                <button className='me-3 btn btn-danger' onClick={onDeleteUserReject}>  No </button>
                <button className='btn btn-success' onClick={onDeleteUserProceed}> Yes </button>
            </div>
        )
    }

    const onWarningReject = () => {
        setDisplayManageDeWarningDialog(false)
    }

    const onWarningProceed = () => {
        setDisplayManageDeWarningDialog(false)
        save(); 
    }

    const renderWarningDialogFooter = () => {
        return (
            <div className='d-flex justify-content-end'>
                <button className='me-3 btn btn-danger' onClick={onWarningReject}>Edit</button>
                <button className='btn btn-success' onClick={onWarningProceed}>Skip the warning and continue</button>
            </div>
        )
    }

    const getAddedOrDeletedUser = (current: IDe, old: IDe) => {
        let addedUserIds = current.userIds.filter(user => {
            return !old.userIds.find(oldUser => oldUser === user)
        })

        let deletedUserIds = old.userIds.filter(oldUser => {
            return !current.userIds.find(user => user === oldUser);
        })

        return { addedUserIds, deletedUserIds }
    }

    const validForm = () => {
        let formErr: IDeError = {
            sourceError: false,
            companyError: false,
            userError: false
        }
        if (currentDe && !currentDe.companyId)
            formErr.companyError = true;
        if (currentDe && !currentDe.source)
            formErr.sourceError = true;
        if (currentDe && currentDe.userIds.length < 1)
            formErr.userError = true
        setFormError(formErr);
        for (let item of Object.values(formErr)) {
            if (item)
                return false;
        }
        return true;

    }


    const addNewUser = () => {
        selectAddDe();
        setDisplayManageDeAddDialog(true)
        scrollHidden();
    }

    const onSubmit = () => {
        if (!validForm()) return
        if(currentDe?.userIds.some((item:string)  => !item.endsWith("@shl.com") )) {
            getCurrentDeCompanyList({userList:currentDe.userIds.filter((item:string) => !item.endsWith("@shl.com") ).join(',')})
        }
        else save();
    } 

    const save = () => {
        if (editingDe && currentDe) {
            if (isEqual(currentDe, editingDe)) {
                clearSelectedDe()
                setDisplayManageDeAddDialog(false);
                scrollAuto()
                return
            }

            let payload: IEditDePayload = {
                companyId: currentDe && currentDe.companyId,
                companyName: currentDe && currentDe.companyName,
                source: currentDe && currentDe.source,
                ...getAddedOrDeletedUser(currentDe, editingDe)
            }
            editDe(payload);
            //    setDisplayManageUserAddDialog(false)
            scrollAuto();
            return;
        }

        let companyName = currentDe && companyList.data.find((item: IDropdownList) => item.value === currentDe.companyId)?.label;
        let payload = {
            companyId: currentDe && currentDe.companyId,
            companyName: companyName?.substring(0, companyName?.indexOf(' (')),
            source: currentDe && currentDe.source,
            userIds: currentDe && currentDe.userIds
        }
        addDe(payload);
        scrollAuto();
    }

    const cancel = () => {
        clearSelectedDe();
        setDisplayManageDeAddDialog(false);
        let formErr: IDeError = {
            sourceError: false,
            companyError: false,
            userError: false
        }
        setFormError(formErr);
        scrollAuto();
    }

    const onEditUser = (item: IDe) => {
        selectEditDe(item)
        setDisplayManageDeAddDialog(true)
        scrollHidden();
    }

    const onDeleteUser = (item: IDe) => {
        selectEditDe(item);
        setDisplayManageDeDeleteDialog(true);
    }

    const onDeleteUserReject = () => {
        clearSelectedDe();
        setDisplayManageDeDeleteDialog(false);
    }

    const onDeleteUserProceed = () => {
        deleteDe({ companyId: currentDe && currentDe.companyId, source: currentDe && currentDe.source })
        setDisplayManageDeDeleteDialog(false);
    }

    const triggerDEJob = () => {
        triggerJob();
    }

    
    const onRowToggle = (data: any) => {
        setExpandedRows(data)
    }

    const currentCountBodyTemplate = (rowData:ICurrentDeCompanies) => {
        return rowData.companies.length
    }


    const columns: IColumn[] = [
        {
            field: 'userId',
            header: 'User Id'
        },
        {
            header: 'Current Accessed Companies Count',
            bodyTemplate:currentCountBodyTemplate
        }

    ]


    const companiesTableColumns: IColumn[] = [
        {
            field: 'label',
            header: 'Company Name'
        },
        {
            field: 'value',
            header: 'Company Id'
        }
    ]
    
    return (
        <div className="manage-de manage-de d-flex justify-content-center ">
            <div className="shadow-sm p-3">
                <Dialog resizable={false} draggable={false} visible={displayManageDeAddDialog} onHide={cancel} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} header={<h6 className="dialog-heading">Please Fill The Below Details To Create New User</h6>} footer={renderDialogFooter()}   >
                    <ManageDeForm formError={formError} ></ManageDeForm>
                </Dialog>

                <Dialog className="warning-dialog" resizable={false} draggable={false} visible={displayManageDeWarningDialog} onHide={onWarningReject} breakpoints={{ '960px': '85vw' }} style={{ width: '60vw' }} header={<h6 className="dialog-heading">Please check the current access of the following external users</h6>} footer={renderWarningDialogFooter()}   >
                <Table expandedTableClass='company-table' expandedRows={expandedRows} onRowToggle={onRowToggle} data={currentDeCompanyList.data} column={columns} paginator={true} expandedTableColumns={companiesTableColumns} expandedTableDataColumn='companies'  ></Table>
                </Dialog>

                <Dialog resizable={false} draggable={false} header='' visible={displayManageDeDeleteDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={onDeleteUserReject} >
                    <div className='dialog-heading text-center'>
                        <h4 className='text-danger'>{`Do You Really Want To Delete the User ${currentDe?.companyId} ? `}</h4>
                    </div>
                </Dialog>
                <div className="d-flex align-items-end w-100 border-bottom p-2 mb-3">
                    <h3 className="text-start text-global-custom fw-bold ">All Pending DE Requests</h3>
                    <button className='btn btn-success ms-auto' onClick={() => addNewUser()}><span className='mdi mdi-plus l3-font-size'></span> Add User</button>
                    {roles && roles.find(item => item === rolesList.TABLEAU_ADMIN) && < button className='btn btn-secondary ms-3' onClick={triggerDEJob}><span className='mdi mdi-cog-refresh l3-font-size'></span> Process Manually</button>}
                </div>
                <table className='table table-striped mb-0'>
                    <thead>
                        <tr>
                            <th className="width-10">Source</th>
                            <th>Company Name</th>
                            <th>Users</th>
                            <th >Created on</th>
                            <th >Created by</th>
                            <th >Updated on</th>
                            <th >Updated by</th>
                            <th className="width-10">Actions</th>
                        </tr>
                    </thead>
                    <tbody >
                        {renderAllUser()}
                    </tbody>
                </table>
            </div>
        </div >

    )
}

const mapStateToProps = (state: IStoreState) => ({
    allDeList: state.manageDe.allDeList,
    companyList: state.manageDe.companyList,
    currentDe: state.manageDe.currentDe,
    editingDe: state.manageDe.editingDe,
    addDeStatus: state.manageDe.addDeStatus,
    editDeStatus: state.manageDe.editDeStatus,
    deleteDeStatus: state.manageDe.deleteDeStatus,
    triggerJobStatus: state.manageDe.triggerJobStatus,
    roles: state.login.userRoles.roles,
    currentDeCompanyList:state.manageDe.currentDeCompanyList


})
const mapDispatchToProps = (dispatch: any) => ({
    updateBreadCrumb: (data: any) => {
        dispatch(updateBreadCrumb(data));
    },
    clearBreadCrumb: () => {
        dispatch(clearBreadCrumb())
    },
    getAllDeList: () => {
        dispatch(getAllDeList())
    },
    addDe: (data: any) => {
        dispatch(addDe(data));
    },
    editDe: (data: any) => {
        dispatch(editDe(data));
    },
    deleteDe: (data: any) => {
        dispatch(deleteDe(data));
    },
    selectAddDe: () => {
        dispatch(selectAddDe());
    },
    selectEditDe: (data: any) => {
        dispatch(selectEditDe(data))
    },
    clearSelectedDe: () => {
        dispatch(clearSelectedDe());
    },
    showNotification: (notification: INotification) => {
        dispatch(showNotification(notification));
    },
    updateDeList: (data: any) => {
        dispatch(updateDeList(data))
    },
    triggerJob: () => {
        dispatch(triggerJob())
    },
    clearDe: () => {
        dispatch(clearDe());
    },
    getCurrentDeCompanyList: (param:IGetCurrentDeCompaniesPayload) => {
        dispatch(getCurrentDeCompanyList(param))
    }

})
export default connect(mapStateToProps, mapDispatchToProps)(ManageDe);