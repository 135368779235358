// Import necessary dependencies
import './UserNotification.scss'
import { useEffect, useRef, useState } from "react"
import { IUserNotification } from "../../types/ManageNotification"
import { Dialog } from 'primereact/dialog';
import { getHomeNotification, markUnReadNotification } from '../../actions/loginAction'
import { connect } from 'react-redux';
import { IStoreState } from '../../types/StoreState';
import Carousel from '../shared/Caraousel/Caraousel'
import tokenService from 'src/api/token.service';
import { Link } from 'react-router-dom';

// Define the shape of the component's props
interface IProps {
    homeNotification: {
        notifications: any[],
        unreadNotifications: any[],
        success: boolean,
        error: boolean,
        loading: boolean
    },
    getHomeNotification: () => void,
    isLogin: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: boolean;
        accessToken: string;
        refreshToken: string;
    },
    markUnReadNotification: (notification: any[]) => void,
    navigate: () => void
}

// Define the UserNotification component
const UserNotification = ({ homeNotification, isLogin, getHomeNotification, markUnReadNotification, navigate }: IProps) => {
    // State variables to manage component state
    const [show, setShow] = useState<boolean>(false);
    const [viewed, setViewed] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<any>(null)
    const [index, setIndex] = useState(0);

    // Use the useEffect hook to fetch notifications when the component mounts or when dependencies change
    useEffect(() => {
        if (tokenService.getNotificationClosed() !== 'closed' && !viewed && isLogin && !isLogin.loading && isLogin.success && isLogin.data) {
            if (homeNotification && !homeNotification.loading && !homeNotification.success) {
                getHomeNotification()
            }
            if (homeNotification && !homeNotification.loading && homeNotification.success) {
                if (homeNotification.unreadNotifications && homeNotification.unreadNotifications.length > 0) {
                    setNotifications(homeNotification.unreadNotifications);
                    setTimeout(() => { setShow(true); setViewed(true) }, 1000)
                } else {
                    setShow(false)
                }
            }
        }
    }, [isLogin, homeNotification])

    // Event handler for carousel rotation
    const onRotate = (index: number) => {
        setIndex(index)
    }

    // Event handler for closing the notification dialog
    const onClose = () => {
        tokenService.setNotificationClosed()
        setShow(false)
        let all = [...homeNotification.unreadNotifications];
        let read = all.splice(0, index + 1);
        read.forEach((item: any) => {
            tokenService.setReadNotification(item.notificationId)
        })
        markUnReadNotification(all);
    }

    // Event handler for navigating to a detail page
    const viewMore = () => {
        onClose();
        navigate()
    }

    // Function to render the content of each notification item
    const itemTemp = (item: IUserNotification) => {
        return (
            <div key={item.notificationId} className={`mb-3 item`}>
                {/* <div className='title'>{item.title}</div> */}
                <div className='desc' title={item.notification} dangerouslySetInnerHTML={{ __html: item.notification }}></div>
            </div>)
    }

    // Render the component
    return (
        <Dialog resizable={false} className='user-notification' showHeader={false} visible={show} onHide={() => setShow(false)} breakpoints={{ '960px': '90vw' }} style={{ maxWidth: '1080px' }} closable={true}>
            {show ? <div>
                <div>
                    <button className='btn close-btn' onClick={onClose}> <span className='mdi mdi-close'> </span></button>
                    <Carousel name={'notification'} inputMaxHeight={400} height={800} item_width={300} numberOfItem={2} rotated={onRotate}>
                        {notifications.map((item: IUserNotification) =>
                            <div >
                                <div className='d-flex'>
                                    <div className='fix-width-250px d-flex'>
                                        <div className='my-auto' style={{ height: '200px', width: '220px' }}>
                                            {item.filePath ? <img className='w-100' src={item.filePath} alt="" /> :
                                                <img className='w-100' src={process.env.PUBLIC_URL + '/asset/help-page-image.png'} alt="" />}
                                        </div>
                                    </div>
                                    <div className='max-width-750px'>
                                        {itemTemp(item)}
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button className='btn btn-success mt-2 mx-auto' onClick={viewMore}>View Detail</button>
                                </div>

                            </div>
                        )}
                    </Carousel>
                </div>
            </div> : null}
        </Dialog>)
}

// Redux integration
const mapStateToProps = (state: IStoreState) => ({
    isLogin: state.login.isLogin,
    homeNotification: state.login.homeNotifications
})

const mapDispatchToProps = (dispatch: any) => ({
    getHomeNotification: () => {
        dispatch(getHomeNotification())
    },
    markUnReadNotification: (notifications: any[]) => {
        dispatch(markUnReadNotification(notifications))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(UserNotification);
