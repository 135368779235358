const viewRequestActionType = {
    FETCH_REQUESTS: 'FETCH_APPROVE_REQUESTS',
    FETCH_REQUESTS_SUCCESS: 'FETCH_APPROVE_REQUESTS_SUCCESS',
    FETCH_REQUESTS_ERROR: 'FETCH_APPROVE_REQUESTS_ERROR',
    START_ACTION: 'START_APPROVE_ACTION',
    ACTION_SUCCESS: 'ACTION_APPROVE_SUCCESS',
    ACTION_ERROR: 'ACTION_APPROVE_ERROR',
    CLEAR_PI_REQUEST: 'CLEAR_PI_APPROVE_REQUEST',
    UPDATE_APPROVE_DATA: 'UPDATE_APPROVE_DATA'
}

export default viewRequestActionType