import { actionTypes } from "../actions/GroupEmailActionTypes";
import { IGroupEmail } from "../types/GroupEmail";
const initialState: IGroupEmail = {
    allGroupList: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    sendEmailStatus: {
        loading: false,
        success: false,
        error: false,
    },
    currentGroupEmail: {
        groups: '',
        subject: '',
        body: '',
        storeBody: ''
    }
}

const GroupEmailReducer = (state = initialState, action: any): IGroupEmail => {
    switch (action.type) {
        case actionTypes.GET_ALL_EMAIL_GROUP_LIST: {
            return {
                ...state,
                allGroupList: Object.assign({}, state.allGroupList, { loading: true, success: false, error: false, data: [] })
            }
        }
        case actionTypes.GET_ALL_EMAIL_GROUP_LIST_SUCCESS: {
            return {
                ...state,
                allGroupList: Object.assign({}, state.allGroupList, { loading: false, success: true, error: false, data: action.payload })
            }
        }
        case actionTypes.GET_ALL_EMAIL_GROUP_LIST_FAILURE: {
            return {
                ...state,
                allGroupList: Object.assign({}, state.allGroupList, { loading: false, success: false, error: true, data: action.payload })
            }
        }
        case actionTypes.SEND_EMAIL_TO_GROUPS: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, state.sendEmailStatus, { loading: true, success: false, error: false })
            }
        }
        case actionTypes.SEND_EMAIL_TO_GROUPS_SUCCESS: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, state.sendEmailStatus, { loading: false, success: true, error: false })
            }
        }
        case actionTypes.SEND_EMAIL_TO_GROUPS_FAILURE: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, state.sendEmailStatus, { loading: false, success: false, error: true })
            }
        }
        case actionTypes.UPDATE_GROUP_EMAIL_INFO: {
            return {
                ...state,
                currentGroupEmail: Object.assign({}, state.currentGroupEmail, action.payload)
            }
        }
        case actionTypes.CLEAR_EMAIL_GROUP: {
            return {
                ...state,
                currentGroupEmail: {
                    groups: '',
                    subject: '',
                    body: '',
                    storeBody: ''
                },
                sendEmailStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }
        default:
            return state
    }
}

export default GroupEmailReducer;