import { useState, useEffect, useRef } from 'react'
import './FieldCommon.scss'
import { Tooltip } from 'primereact/tooltip'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

interface IFile {
    value: any,
    update: (name: string, value: File, displayLabel: string, valueLabels?: any) => void,
    label: string,
    name: string,
    error?: boolean,
    disabled?: boolean,
    hint?: string,
    acceptType?: string
}

const File = ({ value, update, label, name, error, disabled, hint, acceptType }: IFile) => {
    const [fileName, setFileNme] = useState<string>('');
    const fileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (value) {
            setFileNme(value.name);
        } else {
            setFileNme('')
        }
    }, [value])

    const onUpdate = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setFileNme(file.name);
            update(name, file, file.name);
        }
    }

    const onFileOpen = (event: any) => {
        event.preventDefault();
        if (fileInput && fileInput.current) {
            fileInput.current.value = '';
            fileInput.current.click();
        }
    }

    return (
        <div className='d-flex flex-column align-items-end '>
            {fileName && <CustomTooltip text={fileName} target={`.${name.replace(/[^a-zA-Z]/g, 'a')}-value`} />}

            {/* <span className={`${name.replace(/[^a-zA-Z0-9]/g, '')}-value value`} data-pr-tooltip={value} >
                        {value}
                    </span> */}
            <div className='d-flex w-100'>
                <input
                    className={"form-control input-text shadow-none" + `${error ? ' invalid' : ''} ${name.replace(/[^a-zA-Z]/g, 'a')}-value`}
                    aria-invalid={error} value={fileName}
                    placeholder={`Enter ${label}`} readOnly={true}
                    style={{ borderColor: error ? 'rgb(220, 53, 69)' : '', opacity: disabled ? '0.6' : '1' }}
                    onClick={onFileOpen}
                />
                <button className='btn btn-primary ms-2' onClick={onFileOpen}>Upload</button>
            </div>
            <input accept={acceptType} type='file' onInput={onUpdate} className='d-none' ref={fileInput}></input>
            <small className=' text-primary l1-font-size'>{hint}</small>
        </div>)
}

export default File;