import { ITimesheetSquadReducer } from "../types/Timesheet";
import actionType from '../actions/TimesheetSquadActionTypes'

const initialState: ITimesheetSquadReducer = {
    squads: {
        loading: false,
        success: false,
        error: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentSquad: {
        squadName: '',
        members: '',
        id: 0,
        squadDL: ''
    }
}

const TimesheetSquadReducer = (state = initialState, action: any): ITimesheetSquadReducer => {
    switch (action.type) {
        case actionType.GET_SQUAD_LIST: {
            return {
                ...state,
                squads: Object.assign({}, {
                    success: false,
                    error: false,
                    list: []
                }, { loading: true })
            }
        }
        case actionType.GET_SQUAD_LIST_SUCCESS: {
            return {
                ...state,
                squads: Object.assign({}, state.squads, { loading: false, list: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_SQUAD_LIST_ERROR: {
            return {
                ...state,
                squads: Object.assign({}, state.squads, { loading: false, error: true, success: false, list: [] })

            }
        }
        case actionType.ADD_SQUAD: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_SQUAD_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_SQUAD_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_SQUAD: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_SQUAD_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_SQUAD_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_SQUAD: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_SQUAD_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_SQUAD_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_SQUAD: {
            return {
                ...state,
                currentSquad: {
                    squadName: '',
                    members: '',
                    id: 0,
                    squadDL: ''
                }
            }
        }

        case actionType.SELECT_EDIT_SQUAD: {
            return {
                ...state,
                currentSquad: Object.assign({}, action.payload),
                editSquad: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_SQUAD: {
            return {
                ...state,
                currentSquad: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_SQUAD: {
            delete state['editSquad'];
            return {
                ...state,
                currentSquad: {
                    squadName: '',
                    members: '',
                    id: 0,
                    squadDL: ''
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
            }
        }
        default:
            return state
    }
}

export default TimesheetSquadReducer;