import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageUserApi {

    getUserList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getUserList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewUser(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addUser, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editUser(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.editUser, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteUser(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteUser, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}