const actionType = {
    ADD_EXTRACT_REQUEST: 'ADD_EXTRACT_REQUEST',
    ADD_EXTRACT_REQUEST_SUCCESS: 'ADD_EXTRACT_REQUEST_SUCCESS',
    ADD_EXTRACT_REQUEST_ERROR: 'ADD_EXTRACT_REQUEST_ERROR',
    GET_EXTRACT_REQUEST_LIST: 'GET_EXTRACT_REQUEST_LIST',
    GET_EXTRACT_REQUEST_LIST_SUCCESS: 'GET_EXTRACT_REQUEST_LIST_SUCCESS',
    GET_EXTRACT_REQUEST_LIST_ERROR: 'GET_EXTRACT_REQUEST_LIST_ERROR',
    UPDATE_EXTRACT_REQUEST: 'UPDATE_EXTRACT_REQUEST',
    CLEAR_EXTRACT_REQUEST:'CLEAR_EXTRACT_REQUEST',
    UPDATE_EXTRACT_REQUEST_ERROR:'UPDATE_EXTRACT_REQUEST_ERROR',
    UPDATE_EXTRACT_REQUEST_STATUS:'UPDATE_EXTRACT_REQUEST_STATUS',
    UPDATE_EXTRACT_REQUEST_STATUS_SUCCESS:'UPDATE_EXTRACT_REQUEST_STATUS_SUCCESS',
    UPDATE_EXTRACT_REQUEST_STATUS_FAILURE:'UPDATE_EXTRACT_REQUEST_STATUS_FAILURE'
}

export default actionType