import actionTypes from './UserRoleActionTypes'
import { UserRoleApi } from '../api/userRoleApi';
import { IPayloadRole } from '../types/UserRole'
const api = new UserRoleApi()

export const getRoleList = () => {
    return api.getRoleList({}, _getRoleList, getRoleListSuccess, getRoleListFailure)
}

const _getRoleList = () => {
    return {
        type: actionTypes.GET_ROLE_LIST,
    }
}

export const getRoleListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_ROLE_LIST_SUCCESS,
        payload: data.data
    }
}

export const getRoleListFailure = (data: any) => {
    return {
        type: actionTypes.GET_ROLE_LIST_ERROR,
        payload: data
    }
}

export const addNewRole = (payload: IPayloadRole) => {
    return api.addNewRole(payload, _addNewRole, addNewRoleSuccess, addNewRoleFailure)
}

const _addNewRole = () => {
    return {
        type: actionTypes.ADD_NEW_ROLE,
    }
}

export const addNewRoleSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_NEW_ROLE_SUCCESS,
        payload: data
    }
}

export const addNewRoleFailure = (data: any) => {
    return {
        type: actionTypes.ADD_NEW_ROLE_ERROR,
        payload: data
    }
}