import { useState, useEffect } from 'react';
import Text from '../../shared/Fields/Text';
import GroupedMultiSelect from '../../shared/Fields/GroupedMultiSelect';
import TextArea from '../../shared/Fields/TextArea';
import TextEditor from 'src/components/shared/Fields/Editor';
import DateField from '../../shared/Fields/Date';
import File from 'src/components/shared/Fields/File';
import './ManageNotification.scss'
import { connect } from 'react-redux'
import { IStoreState } from '../../../types/StoreState';
import { updateNotificationsInfo } from '../../../actions/ManageNotificationsAction';
import { IUserNotification, INotificationError } from '../../../types/ManageNotification'
import { getAllEmailGroups } from '../../../actions/GroupEmailAction';
import { IGroupList } from '../../../types/GroupEmail'
import moment from 'moment';

interface IManageNotificationForm {
    updateNotificationsInfo: (data: any) => void,
    formData: IUserNotification,
    formError?: INotificationError,
    groups: IGroupList,
    getAllEmailGroups: () => void,
    isEdit: boolean,
    updateError: (error: any) => void
}


const ManageNotificationForm = ({ isEdit, updateNotificationsInfo, formData, formError, groups, getAllEmailGroups, updateError }: IManageNotificationForm) => {

    const currentDate = new Date()

    useEffect(() => {
        if (groups && !groups.loading && !groups.success) {
            getAllEmailGroups()
        }

    }, [groups])

    const onUpdate = (name: string, value: any) => {
        if (name === 'file') {
            if (value && value.type && !value.type.includes('image/')) {
                updateError({ file: 'Please upload only image file.' });

                let newValue: any = {};
                newValue[name] = null;
                updateNotificationsInfo(newValue);
                return;
            }
        }
        let newValue: any = {};
        newValue[name] = value;
        updateNotificationsInfo(newValue);
    }



    return (
        <form className='ManageNotification l2-font-size'>
            <div className='row mb-3 '>
                <label className='col-3 feild-label '>Title<span className='text-danger'>*</span></label>
                <div className='col-9'>
                    <Text value={formData.title} update={(name, value) => onUpdate(name, value)} label='Title' name='title' error={formError && formError?.title} maxLimit={50}></Text>
                    {formError && formError.title ? <small className='text-danger l1-font-size'>Please enter title</small> : null}
                </div>

            </div>

            <div className='row mb-3 '>
                <label className='col-3 feild-label'>Description<span className='text-danger'>*</span></label>
                <div className='col-9'>
                    <TextEditor noImage={true} value={formData.notification} name='notification' label='Description' update={(name, value) => onUpdate(name, value)} error={formError?.notification} ></TextEditor>
                    {formError && formError.notification ? <small className='text-danger l1-font-size'>Please enter description</small> : null}
                </div>
            </div>

            <div className='row mb-3 '>
                <label className='col-3 feild-label' >Groups (Who will get this?)</label>
                <div className='col-9  icon-field'>
                    {/* <MultiSelect panelClass='custom-panel-class' selectOptions={roleOption} update={(name, value) => onUpdate(name, value)} label='Roles' name='roles' disabled={false} noJoin={false} value={formData.roles}></MultiSelect> */}
                    <GroupedMultiSelect valueSelectedAll={'allUser'} panelClass='' value={formData.roles} selectOptions={groups.data} update={(name, value) => onUpdate(name, value)} label='User Group' name='roles' disabled={false} error={false} noJoin={false} noApply={true} ></GroupedMultiSelect>
                </div>
            </div>


            <div className='row mb-3 '>
                <label className='col-3 feild-label'>Start Date<span className='text-danger'>*</span></label>
                <div className='col-9'>
                    <DateField value={formData.startDate} name='startDate' label='Start Date' update={(name, value) => onUpdate(name, value)} error={formError?.startDate} minDate={isEdit && moment(formData.startDate).isBefore(moment().subtract(1, 'days')) ? formData.startDate : currentDate}></DateField>
                    {formError && formError.startDate ? <small className='fs-small'>{formError.startDate}</small> : null}
                </div>
            </div>

            <div className='row mb-3 '>
                <label className='col-3 feild-label'>End Date<span className='text-danger'>*</span></label>
                <div className='col-9'>
                    <DateField value={formData.endDate} name='endDate' label='End Date' update={(name, value) => onUpdate(name, value)} error={formError?.startDate} minDate={formData.startDate || currentDate}></DateField>
                    {formError && formError.endDate ? <small className='text-danger l1-font-size'>{formError.endDate}</small> : null}
                </div>
            </div>

            <div className='row mb-3 '>
                <label className='col-3 feild-label'>Upload Thumbnail</label>
                <div className='col-9'>
                    <File acceptType='image/*' value={formData.file} name='file' label='Thumbnail' update={(name, value) => onUpdate(name, value)} ></File>
                    {formError && formError.file ? <small className='text-danger l1-font-size'>{formError.file}</small> : null}
                </div>
            </div>

        </form>
    )

}
const mapStateToProps = (state: IStoreState) => ({
    formData: state.manageNotification.currentNotifications,
    groups: state.groupEmail.allGroupList,
})


const mapDispatchToProps = (dispatch: any) => ({

    updateNotificationsInfo: (data: any) => {
        dispatch(updateNotificationsInfo(data))
    },
    getAllEmailGroups: () => {
        dispatch(getAllEmailGroups())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageNotificationForm);