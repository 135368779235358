import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAuthCode } from '../../actions/loginAction'
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom'

const LoginRedirect = (props: any) => {
    const [queryParam] = useSearchParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (props.isLogin && !props.isLogin.data && !props.isLogin.loading && !props.isLogin.error) {
            const payload = {
                code: queryParam.get('code')
            }
            props.getAuthCode(payload)
        }

        if (props.isLogin && props.isLogin.data && props.isLogin.accessToken) {
            const url = sessionStorage.getItem('lastUrl')
            // localStorage.removeItem('lastUrl')
            navigate(url ? url : '/')
        }
    }, [props.isLogin])

    if (props.isLogin && props.isLogin.data && props.isLogin.accessToken) {
        return null
    }
    return (<div>Please wait, we are signing you in.</div>)
}

const mapStateToProps = (state: any) => ({
    isLogin: state.login.isLogin,
})

const mapDispatchToProps = (dispatch: any) => ({
    getAuthCode: (payload: any) => {
        dispatch(getAuthCode(payload))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(LoginRedirect);