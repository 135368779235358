import { ILeaderboardState } from "src/types/Leaderboard";
import actionType from "src/actions/LeaderboardActionTypes";

const intialState: ILeaderboardState = {
    awards: {
        loading: false,
        error: false,
        success: false,
        list: []
    },
    userAwards: {
        loading: false,
        error: false,
        success: false,
        list: []
    },
    leaderboardData: {
        loading: false,
        error: false,
        success: false,
        list: []
    },
    addActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    editActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteActionStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentAward: {
        userName: '',
        cycleId: 0,
        userId: '',
        awardType: '',
        points: 0,
        squadId: 0
    },
    digitalCycle: {
        loading: false,
        error: false,
        success: false,
        cycles: []
    },
    currentSprintList: [],
    sendEmailStatus: {
        loading: false,
        success: false,
        error: false
    },
    awardType: {
        loading: false,
        error: false,
        success: false,
        list: []
    }
}

const LeaderboardReducer = (state = intialState, action: any): ILeaderboardState => {

    switch (action.type) {
        case actionType.GET_AWARD_LIST:
            return {
                ...state,
                awards: {
                    ...state.awards,
                    loading: true
                }
            }

        case actionType.GET_AWARD_LIST_SUCCESS:
            return {
                ...state,
                awards: {
                    error: false,
                    success: true,
                    loading: false,
                    list: action.payload
                }
            }

        case actionType.GET_AWARD_LIST_ERROR:
            return {
                ...state,
                awards: {
                    error: true,
                    success: false,
                    loading: false,
                    list: []
                }
            }

        case actionType.GET_AWARD_BY_TYPE_LIST:
            return {
                ...state,
                awardType: {
                    success: false,
                    error: false,
                    loading: true,
                    list: []
                }
            }

        case actionType.GET_AWARD_BY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                awardType: {
                    error: false,
                    success: true,
                    loading: false,
                    list: action.payload
                }
            }

        case actionType.GET_AWARD_BY_TYPE_LIST_ERROR:
            return {
                ...state,
                awardType: {
                    error: true,
                    success: false,
                    loading: false,
                    list: []
                }
            }

        case actionType.GET_USERAWARD_LIST:
            return {
                ...state,
                userAwards: {
                    ...state.userAwards,
                    loading: true
                }
            }

        case actionType.GET_USERAWARD_LIST_SUCCESS:
            return {
                ...state,
                userAwards: {
                    error: false,
                    success: true,
                    loading: false,
                    list: action.payload
                }
            }

        case actionType.GET_USERAWARD_LIST_ERROR:
            return {
                ...state,
                userAwards: {
                    error: true,
                    success: false,
                    loading: false,
                    list: []
                }
            }

        case actionType.GET_LEADERBOARD_DATA:
            return {
                ...state,
                leaderboardData: {
                    ...state.leaderboardData,
                    loading: true
                }
            }

        case actionType.GET_LEADERBOARD_DATA_SUCCESS:
            return {
                ...state,
                leaderboardData: {
                    error: false,
                    success: true,
                    loading: false,
                    list: action.payload
                }
            }

        case actionType.GET_LEADERBOARD_DATA_ERROR:
            return {
                ...state,
                leaderboardData: {
                    error: true,
                    success: false,
                    loading: false,
                    list: []
                }
            }

        case actionType.GET_DIGITAL_CYCLE: {
            return {
                ...state,
                digitalCycle: Object.assign({}, {
                    success: false,
                    error: false,
                    cycles: []
                }, { loading: true })
            }
        }
        case actionType.GET_DIGITAL_CYCLE_SUCCESS: {
            return {
                ...state,
                digitalCycle: Object.assign({}, state.digitalCycle, { loading: false, cycles: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_DIGITAL_CYCLE_ERROR: {
            return {
                ...state,
                digitalCycle: Object.assign({}, state.digitalCycle, { loading: false, error: true, success: false, cycles: [] })

            }
        }
        case actionType.ADD_AWARD: {
            return {
                ...state,
                addActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_AWARD_SUCCESS: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_AWARD_ERROR: {
            return {
                ...state,
                addActionStatus: Object.assign({}, state.addActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_AWARD: {
            return {
                ...state,
                editActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_AWARD_SUCCESS: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_AWARD_ERROR: {
            return {
                ...state,
                editActionStatus: Object.assign({}, state.editActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_AWARD: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_AWARD_SUCCESS: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_AWARD_ERROR: {
            return {
                ...state,
                deleteActionStatus: Object.assign({}, state.deleteActionStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_AWARD: {
            return {
                ...state,
                currentAward: {
                    userName: '',
                    cycleId: 0,
                    userId: '',
                    awardType: '',
                    points: 0,
                    squadId: 0
                }
            }
        }

        case actionType.SELECT_EDIT_AWARD: {
            return {
                ...state,
                currentAward: Object.assign({}, action.payload),
                editAward: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_AWARD: {
            return {
                ...state,
                currentAward: Object.assign({}, action.payload)
            }
        }
        case actionType.CLEAR_AWARD: {
            return {
                ...state,
                currentAward: {
                    userName: '',
                    cycleId: 0,
                    userId: '',
                    awardType: '',
                    points: 0,
                    squadId: 0
                },
                addActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteActionStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                currentSprintList: [],
                editAward: undefined,
                sendEmailStatus: {
                    loading: false,
                    success: false,
                    error: false
                }
            }
        }

        case actionType.UPDATE_CURRENT_SPRINT_LIST: {
            return {
                ...state,
                currentSprintList: Object.assign({}, action.payload)
            }
        }

        case actionType.SEND_EMAIL: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.SEND_EMAIL_SUCCESS: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, state.sendEmailStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.SEND_EMAIL_ERROR: {
            return {
                ...state,
                sendEmailStatus: Object.assign({}, state.sendEmailStatus, { loading: false, error: true, success: false })
            }
        }


        default:
            return state;
    }

}

export default LeaderboardReducer;