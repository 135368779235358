import { BaseApi } from "./baseApi";
import {config} from './config'

const baseApi = new BaseApi();
export class ManageSolutionMappingApi {
      getAllSutCompany(payload:any ,requestAction:any , requestSuccessAction:any ,requestErrorAction:any ) {
           return baseApi.get(config.apiUrl.getAllSutCompany , payload , requestAction , requestSuccessAction , requestErrorAction)
      }  

      getAllMappings(payload:any ,requestAction:any , requestSuccessAction:any ,requestErrorAction:any ) {
        return baseApi.get(config.apiUrl.getAllMappings , payload , requestAction , requestSuccessAction , requestErrorAction)
      }

      getAllSolutionMapping(payload:any ,requestAction:any , requestSuccessAction:any ,requestErrorAction:any ) {
        return baseApi.get(config.apiUrl.getAllSolutionMapping , payload , requestAction , requestSuccessAction , requestErrorAction)
      }

      addSolutionMappings(payload:any , requestAction:any , requestSuccessAction:any , requestErrorAction:any){
        return baseApi.post(config.apiUrl.addSolutionMappings,{} , payload,requestAction,requestSuccessAction,requestErrorAction)
      }
} 