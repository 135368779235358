import { IManageUser } from "../types/ManageUser";
import actionType from '../actions/ManageUserActionTypes'

const initialState: IManageUser = {
    userList: {
        loading: false,
        success: false,
        error: false,
        users: []
    },
    addUserStatus: {
        loading: false,
        success: false,
        error: false
    },
    editUserStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteUserStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentUser: {
        roleIds: [],
        name: '',
        userId: ''
    },
    editingUser: {
        roleIds: [],
        name: '',
        userId: ''
    },

}

const manageUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_USER_LIST: {
            return {
                ...state,
                userList: Object.assign({}, {
                    success: false,
                    error: false,
                    users: []
                }, { loading: true })
            }
        }
        case actionType.GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                userList: Object.assign({}, state.userList, { loading: false, users: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_USER_LIST_ERROR: {
            return {
                ...state,
                userList: Object.assign({}, state.userList, { loading: false, error: true, success: false })

            }
        }
        case actionType.ADD_USER: {
            return {
                ...state,
                addUserStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_USER_SUCCESS: {
            return {
                ...state,
                addUserStatus: Object.assign({}, state.addUserStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_USER_ERROR: {
            return {
                ...state,
                addUserStatus: Object.assign({}, state.addUserStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_USER: {
            return {
                ...state,
                editUserStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_USER_SUCCESS: {
            return {
                ...state,
                editUserStatus: Object.assign({}, state.editUserStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_USER_ERROR: {
            return {
                ...state,
                editUserStatus: Object.assign({}, state.editUserStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_USER: {
            return {
                ...state,
                deleteUserStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_USER_SUCCESS: {
            return {
                ...state,
                deleteUserStatus: Object.assign({}, state.deleteUserStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_USER_ERROR: {
            return {
                ...state,
                deleteUserStatus: Object.assign({}, state.deleteUserStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_USER: {
            return {
                ...state,
                currentUser: Object.assign({}, {
                    roleIds: [],
                    name: '',
                    userId: ''
                }),
                editingUser: null
            }
        }

        case actionType.SELECT_EDIT_USER: {
            return {
                ...state,
                currentUser: Object.assign({}, action.payload),
                editingUser: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_USER: {
            return {
                ...state,
                currentUser: Object.assign({}, state.currentUser, { ...action.payload })
            }
        }

        case actionType.CLEAR_USER: {
            return {
                ...state,
                currentUser: null,
                editingUser: null
            }
        }

        case actionType.UPDATE_USER_LIST: {
            return {
                ...state,
                userList: Object.assign({}, state.userList, { loading: false, users: action.payload, success: true, error: false })
            }
        }
        default:
            return state
    }
}

export default manageUserReducer