import { IIntialState } from '../types/filter';
import actionTypes from '../actions/filterActionTypes';
import { debug } from 'console';

const intialState: IIntialState = {
    options: {},
    filters: {},
    filterList: {
        loading: false,
        success: false,
        error: false,
        filter: []
    },
    currentUpdatedFilter: { name: '' },
    filterLabels: {},
    extractfilters: {},
    extractFilterList: {
        loading: false,
        success: false,
        error: false,
        filter: []
    },
    extractFilterLabels: {}
}

const filterReducer = (state = intialState, action: any): IIntialState => {
    switch (action.type) {
        case actionTypes.FETCH_OPTION: {
            return {
                ...state,
                options: Object.assign({}, state.options, { loading: true, success: false })
            }
        }
        case actionTypes.FETCH_OPTION_SUCCESS: {
            return {
                ...state,
                options: Object.assign({}, state.options, { loading: false, success: true, ...action.payload })
            }
        }
        case actionTypes.FETCH_OPTION_ERROR: {
            return {
                ...state,
                options: Object.assign({}, state.options, { loading: false, success: false, error: true })
            }
        }
        case actionTypes.UPDATE_FILTER: {
            return {
                ...state,
                filters: Object.assign({}, state.filters, { ...action.payload.filters }),
                filterLabels: Object.assign({}, state.filterLabels, { ...action.payload.filterLabels })
            }
        }

        case actionTypes.CLEAR_FILTER: {
            return {
                ...state,
                filters: {},
                filterList: {
                    loading: false,
                    success: false,
                    error: false,
                    filter: []
                },
                filterLabels: {}
            }
        }

        case actionTypes.UPDATE_EXTRACT_FILTER: {
            return {
                ...state,
                extractfilters: Object.assign({}, state.extractfilters, { ...action.payload.extractfilters }),
                extractFilterLabels: Object.assign({}, state.extractFilterLabels, { ...action.payload.extractFilterLabels })
            }
        }

        case actionTypes.CLEAR_EXTRCAT_FILTER: {
            return {
                ...state,
                extractfilters: {},
                extractFilterList: {
                    loading: false,
                    success: false,
                    error: false,
                    filter: []
                },
                extractFilterLabels: {}
            }
        }

        case actionTypes.GET_FILTER_LIST_FOR_REPORT: {
            return {
                ...state,
                filterList: Object.assign({}, {
                    success: false,
                    error: false,
                    filter: []
                }, { loading: true })
            }
        }
        case actionTypes.GET_FILTER_LIST_FOR_REPORT_SUCCESS: {
            return {
                ...state,
                filterList: Object.assign({}, state.filterList, { loading: false, filter: action.payload, success: true, error: false })
            }
        }
        case actionTypes.GET_FILTER_LIST_FOR_REPORT_ERROR: {
            return {
                ...state,
                filterList: Object.assign({}, state.filterList, { loading: false, error: true, success: false, filter: [] })

            }
        }

        case actionTypes.GET_FILTER_LIST_FOR_EXTRACT: {
            return {
                ...state,
                extractFilterList: Object.assign({}, {
                    success: false,
                    error: false,
                    filter: []
                }, { loading: true })
            }
        }
        case actionTypes.GET_FILTER_LIST_FOR_EXTRACT_SUCCESS: {
            return {
                ...state,
                extractFilterList: Object.assign({}, state.extractFilterList, { loading: false, filter: action.payload, success: true, error: false })
            }
        }
        case actionTypes.GET_FILTER_LIST_FOR_EXTRACT_ERROR: {
            return {
                ...state,
                extractFilterList: Object.assign({}, state.extractFilterList, { loading: false, error: true, success: false, filter: [] })

            }
        }

        case actionTypes.UPDATE_CURRENT_UPDATED_FILTER_NAME: {
            return {
                ...state,
                currentUpdatedFilter: { name: action.payload }

            }
        }

        default:
            return state
    }
}

export default filterReducer;