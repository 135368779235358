import { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getAllGeneratedFactList } from "src/actions/ManageFactAction"
import { IGeneratedFact } from "src/types/ManageFact"
import { IStoreState } from "src/types/StoreState"
import Carousel from '../../../shared/Caraousel/Caraousel'


interface IProps {
    generatedFactList: any,
    getAllGeneratedFactList: () => void,
}

const TopFactBanner = ({ generatedFactList, getAllGeneratedFactList }: IProps) => {

    useEffect(() => { getAllGeneratedFactList() }, [])

    const itemTemp = (item: IGeneratedFact) => {
        return (
            <div className='bi-app w-100'>
                <div className='d-flex  align-items-center h-100' >
                    <div className='d-flex h-100 align-items-center flex-grow-1'>
                        <div className='h-75  mx-3 d-flex justify-content-start'>
                            <img className='h-100' src={process.env.PUBLIC_URL + `/asset/bulb_icon.svg`} alt="" />
                        </div>
                        <div className='fact-intro d-flex align-items-center justify-content-start'>
                            <span className='fw-bold text-start l3-font-size me-3' >Do You Know?</span>
                        </div>
                        <div className=' app-info d-flex align-items-center justify-content-start flex-grow-1'>
                            <span className='text-start l2-font-size'>{item.fact}</span>
                        </div>
                    </div>

                    {/* <div className='ms-auto d-flex justify-content-end me-3'>
                        <Link to='/quiz' >
                            <button className='mail-btn shadow-none py-2 px-4 border-0 ms-3'>Start Quiz<span className='arrow-icon mdi mdi-arrow-right ms-1'></span></button>
                        </Link>
                    </div> */}

                </div>
            </div>)
    }

    return (
        <div className='shadow-sm bi-content-container w-100'>
            <div id='carouselContainer' className='w-100 carousel-top-container' data-bs-ride="ride" >
                <Carousel name={'fun_facts'} inputMaxHeight={80} height={60} item_width={300} numberOfItem={2}>
                    {generatedFactList.map((item: any) => itemTemp(item))}
                </Carousel>
            </div>
        </div>)
}

const mapStateToProps = (state: IStoreState) => ({
    generatedFactList: state.manageFact.generatedFactList.generatedFacts

})

const mapDispatchToProps = (dispatch: any) => ({
    getAllGeneratedFactList: () => {
        return dispatch(getAllGeneratedFactList());
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(TopFactBanner);