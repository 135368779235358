const actionType = {
      GET_ALL_COMPANY_CANDIDATE_UNIQUE: 'GET_ALL_COMPANY_CANDIDATE_UNIQUE',
      GET_ALL_COMPANY_CANDIDATE_UNIQUE_SUCCESS: 'GET_ALL_COMPANY_CANDIDATE_UNIQUE_SUCCESS',
      GET_ALL_COMPANY_CANDIDATE_UNIQUE_FAILURE: 'GET_ALL_COMPANY_CANDIDATE_UNIQUE_FAILURE',

      GET_ALL_SULUTION_TIMELINES: 'GET_ALL_SULUTION_TIMELINES',
      GET_ALL_SULUTION_TIMELINES_SUCCESS: 'GET_ALL_SULUTION_TIMELINES_SUCCESS',
      GET_ALL_SULUTION_TIMELINES_FAILURE: 'GET_ALL_SULUTION_TIMELINES_FAILURE',
      UPDATE_TIMELINE: 'UPDATE_TIMELINE',
      ADD_SOLUTION_TIMINGS: 'ADD_SOLUTION_TIMINGS',
      ADD_SOLUTION_TIMINGS_SUCCESS: 'ADD_SOLUTION_TIMINGS_SUCCESS',
      ADD_SOLUTION_TIMINGS_FAILURE: 'ADD_SOLUTION_TIMINGS_FAILURE',
      FILTER_SUT_TIMING_COMPANY: 'FILTER_SUT_TIMING_COMPANY',
      RESET_ALL_SOLUTION_TIMINGS: 'RESET_ALL_SOLUTION_TIMINGS',
      CLEAR_SUT_TIMING_STATE: 'CLEAR_SUT_TIMING_STATE',
      UPDATE_ORIGINAL_TIMINGS:'UPDATE_ORIGINAL_TIMINGS'
}

export default actionType