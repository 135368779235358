const reportRequestActionType = {
    UPDATE_REQUEST_DATA: 'UPDATE_REQUEST_DATA',
    CLEAR_DATA: 'CLEAR_DATA',
    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    SUBMIT_REQUEST_SUCCESS: 'SUBMIT_REQUEST_SUCCESS',
    SUBMIT_REQUEST_ERROR: 'SUBMIT_REQUEST_ERROR',
    CHECK_HAS_ACCESS: 'CHECK_HAS_ACCESS',
    CHECK_HAS_ACCESS_ERROR: 'CHECK_HAS_ACCESS_ERROR',
    CHECK_HAS_ACCESS_SUCCUESS: 'CHECK_HAS_ACCESS_SUCCESS',
    CLEAR_HAS_ACCESS: 'CLEAR_HAS_ACCESS',
    CHECK_REQUEST_STATUS: 'CHECK_REQUEST_STATUS',
    CHECK_REQUEST_STATUS_SUCCESS: 'CHECK_REQUEST_STATUS_SUCCESS',
    CHECK_REQUEST_STATUS_ERROR: 'CHECK_REQUEST_STATUS_ERROR',
}

export default reportRequestActionType