const actionType = {
   
    GET_USERS_LIST: 'GET_USERS_LIST',
    GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
    GET_USERS_LIST_ERROR: 'GET_USERS_LIST_ERROR',
    ADD_USERS: 'ADD_USERS',
    ADD_USERS_SUCCESS: 'ADD_USERS_SUCCESS',
    ADD_USERS_ERROR: 'ADD_USERS_ERROR',
    EDIT_USERS: 'EDIT_USERS',
    EDIT_USERS_SUCCESS: 'EDIT_USERS_SUCCESS',
    EDIT_USERS_ERROR: 'EDIT_USERS_ERROR',
    DELETE_USERS: 'DELETE_USERS',
    DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR: 'DELETE_USERS_ERROR',
    UPDATE_USERS: 'UPDATE_USERS',
    SELECT_ADD_USERS: 'SELECT_ADD_USERS',
    SELECT_EDIT_USERS: 'SELECT_EDIT_USERS',
    CLEAR_USERS: 'CLEAR_MANAGE_USERS',
    GET_MISSING_USERS_LIST: 'GET_MISSING_USERS_LIST',
    GET_MISSING_USERS_LIST_SUCCESS: 'GET_MISSING_USERS_LIST_SUCCESS',
    GET_MISSING_USERS_LIST_ERROR: 'GET_MISSING_USERS_LIST_ERROR',
    MARK_RESIGNED_USER: 'MARK_RESIGNED_USER',
    MARK_RESIGNED_USER_SUCCESS: 'MARK_RESIGNED_USER_SUCCESS',
    MARK_RESIGNED_USER_ERROR: 'MARK_RESIGNED_USER_ERROR',
    BULK_UPLOAD_USER: 'BULK_UPLOAD_USER',
    BULK_UPLOAD_USER_SUCCESS: 'BULK_UPLOAD_USER_SUCCESS',
    BULK_UPLOAD_USER_ERROR: 'BULK_UPLOAD_USER_ERROR',
}

export default actionType