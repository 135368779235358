const actionType = {
    GET_AWARD_LIST: 'GET_AWARD_LIST',
    GET_AWARD_LIST_SUCCESS: 'GET_AWARD_LIST_SUCCESS',
    GET_AWARD_LIST_ERROR: 'GET_AWARD_LIST_ERROR',
    GET_AWARD_BY_TYPE_LIST: 'GET_AWARD_BY_TYPE_LIST',
    GET_AWARD_BY_TYPE_LIST_SUCCESS: 'GET_AWARD_BY_TYPE_LIST_SUCCESS',
    GET_AWARD_BY_TYPE_LIST_ERROR: 'GET_AWARD_BY_TYPE_LIST_ERROR',
    ADD_AWARD: 'ADD_AWARD',
    ADD_AWARD_SUCCESS: 'ADD_AWARD_SUCCESS',
    ADD_AWARD_ERROR: 'ADD_AWARD_ERROR',
    EDIT_AWARD: 'EDIT_AWARD',
    EDIT_AWARD_SUCCESS: 'EDIT_AWARD_SUCCESS',
    EDIT_AWARD_ERROR: 'EDIT_AWARD_ERROR',
    DELETE_AWARD: 'DELETE_AWARD',
    DELETE_AWARD_SUCCESS: 'DELETE_AWARD_SUCCESS',
    DELETE_AWARD_ERROR: 'DELETE_AWARD_ERROR',
    SELECT_ADD_AWARD: 'SELECT_ADD_AWARD',
    SELECT_EDIT_AWARD: 'SELECT_EDIT_AWARD',
    UPDATE_AWARD: 'UPDATE_AWARD',
    CLEAR_AWARD: 'CLEAR_MANAGE_AWARD',
    GET_USERAWARD_LIST: 'GET_USERAWARD_LIST',
    GET_USERAWARD_LIST_SUCCESS: 'GET_USERAWARD_LIST_SUCCESS',
    GET_USERAWARD_LIST_ERROR: 'GET_USERAWARD_LIST_ERROR',
    GET_DIGITAL_CYCLE: 'GET_DIGITAL_CYCLE',
    GET_DIGITAL_CYCLE_SUCCESS: 'GET_DIGITAL_CYCLE_SUCCESS',
    GET_DIGITAL_CYCLE_ERROR: 'GET_DIGITAL_CYCLE_ERROR',
    
    GET_LEADERBOARD_DATA: 'GET_LEADERBOARD_DATA',
    GET_LEADERBOARD_DATA_SUCCESS: 'GET_LEADERBOARD_DATA_SUCCESS',
    GET_LEADERBOARD_DATA_ERROR: 'GET_LEADERBOARD_DATA_ERROR',
    UPDATE_CURRENT_SPRINT_LIST: 'UPDATE_CURRENT_SPRINT_LIST',
    SEND_EMAIL: 'SEND_EMAIL',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',
}

export default actionType