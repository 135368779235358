import React, { useEffect, useState } from 'react'
import './ManageUser.scss'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux'
import { updateBreadCrumb, clearBreadCrumb } from '../../../actions/BreadCrumbAction';
import { getUserList, selectAddUser, selectEditUser, updateUserInfo, addUserList, editUser, deleteUser, clearSelectedUser, updateUserList } from '../../../actions/ManageUserAction';
import { showNotification } from '../../../actions/notificationAction'
import { getRoleList } from '../../../actions/UserRoleAction'
import { IUser, IUserAddPayload, IUserEditPayload, IDeleteUserPayload, IUserError, IStatus } from '../../../types/ManageUser'
import { IStoreState } from '../../../types/StoreState'
import { IRole } from '../../../types/UserRole'
import ManageUserForm from './ManageUserForm';
import { INotification } from '../../../types/notification';
import Table, { IAction, IColumn } from '../../shared/Table/Table';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Select from '../../shared/Fields/Multiselect/MultiSelect';
import { IDropdownList } from '../../../types/common';
import { convertDropDownOption } from '../../../util/convertToDropdownOption';
import { FilterService } from 'primereact/api';
import { isEqual } from '../../../util/arrayFunction';

interface IProps {
  updateBreadCrumb: (breadcrumb: any) => void,
  clearBreadCrumb: () => void,
  getUserList: () => void,
  users: IUser[],
  getRoleList: () => void,
  roles: IRole[],
  selectAddUser: () => void,
  selectEditUser: (data: IUser) => void,
  addUser: (payload: IUserAddPayload) => void,
  editUser: (payload: IUserEditPayload) => void,
  deleteUser: (payload: IDeleteUserPayload) => void,
  clearSelectedUser: () => void,
  updateUserList: (data: any) => void
  currentUser: IUser,
  editingUser?: IUser,
  addUserStatus: IStatus,
  editUSerStatus: IStatus,
  deleteUserStatus: IStatus,
  showNotification: (notification: INotification) => void
}

const ManageUser = ({ updateBreadCrumb,
  users,
  clearBreadCrumb,
  getUserList,
  getRoleList,
  roles,
  selectAddUser,
  selectEditUser,
  addUser,
  editUser,
  deleteUser,
  clearSelectedUser,
  currentUser,
  updateUserList,
  editingUser,
  addUserStatus,
  editUSerStatus,
  deleteUserStatus,
  showNotification }: IProps) => {
  const [displayManageUserAddDialog, setDisplayManageUserAddDialog] = useState<boolean>(false);
  const [displayManageUserDeleteDialog, setDisplayManageUserDeleteDialog] = useState<boolean>(false);
  const [formError, setFormError] = useState<IUserError>()
  const [roleOptions, setRoleOption] = useState<IDropdownList[]>([]);
  FilterService.register('myFilter', (a: any[], b: any[]) => {
    if (a && b) {
      return b.some((aItem: any) => a.some(bItem => bItem == aItem))
    } else {
      return true
    }
  })

  const matchModes = [
    { label: 'my_Filter', value: 'myFilter' }
  ]

  const filterObject = {
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'userId': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'roleIds': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: 'myFilter' }] }

  }

  useEffect(() => {
    if (roles) {
      setRoleOption(convertDropDownOption(roles, 'id', 'roleName'))
    }
  }, [roles])

  useEffect(() => {
    const breadCrumb = [{ label: 'Home', url: '/' }, { label: 'AllUser', url: '/' }]
    updateBreadCrumb(breadCrumb);
    getUserList();
    getRoleList()
    return () => {
      clearBreadCrumb();
      scrollAuto()
    };
  }, [])

  useEffect(() => {
    if (deleteUserStatus && !deleteUserStatus.loading && deleteUserStatus.success) {
      const notification: INotification = {
        severity: 'success',
        summary: 'Deleted Successfully',
        detail: `${currentUser.name} is deleted successfully.`, life: 2000
      }
      showNotification(notification)
      const newUserList = users.filter((item: IUser) => item.userId !== currentUser.userId)
      clearSelectedUser();
      updateUserList(newUserList)
      setDisplayManageUserDeleteDialog(false);
    }
  }, [deleteUserStatus])

  useEffect(() => {
    if (editUSerStatus && !editUSerStatus.loading && editUSerStatus.success) {
      const newUserList = users.map((item: IUser) => item.userId === currentUser.userId ? currentUser : item)
      clearSelectedUser()
      updateUserList(newUserList)
      setDisplayManageUserAddDialog(false)
    }
  }, [editUSerStatus])

  useEffect(() => {
    if (addUserStatus && !addUserStatus.loading && addUserStatus.success) {
      clearSelectedUser()
      getUserList()
      setDisplayManageUserAddDialog(false)
    }
  }, [addUserStatus])

  const getRoles = (item: IUser) => {
    if (roles && roles.length > 0) {
      return item.roleIds.map(roleId => {
        return roles.find(item => item.id === roleId)?.roleName
      }).join(' , ')
    }
  }

  const rolesGlobalFilterValue = (item: IUser) => {
    if (roles && roles.length > 0) {
      return item.roleIds.map(roleId => {
        return roles.find(item => item.id === roleId)?.roleName
      })
    }
  }

  const scrollHidden = () => {
    document.body.style.overflow = 'hidden'
  }

  const scrollAuto = () => {
    document.body.style.overflow = 'auto'
  }

  const validForm = () => {
    let formErr: IUserError = {
      nameError: false,
      userIdError: false,
      roleIdsError: false
    }
    if (!currentUser.name) {
      formErr.nameError = true
    }
    if (!currentUser.userId) {
      formErr.userIdError = true
    } else {
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(shl.com)$/i;
      if (!re.test(currentUser.userId)) {
        formErr.userIdError = true
      }
    }

    if (!currentUser.roleIds || currentUser.roleIds.length === 0) {
      formErr.roleIdsError = true
    }
    setFormError(formErr)

    for (let item of Object.values(formErr)) {
      if (item) {
        return false
      }
    }

    return true
  }

  const addNewUser = () => {
    selectAddUser();
    setDisplayManageUserAddDialog(true)
    scrollHidden();
  }

  const getAddedOrDeletedRole = (current: IUser, old: IUser) => {
    let addedRoleIds = current.roleIds.filter(item => {
      return !old.roleIds.find(oldItem => oldItem === item)
    })

    let deletedRoleIds = old.roleIds.filter(item => {
      return !current.roleIds.find(oldItem => oldItem === item)
    })
    return { addedRoleIds, deletedRoleIds }
  }

  const save = () => {
    if (!validForm()) return
    if (editingUser) {
      if (isEqual(currentUser, editingUser)) {
        clearSelectedUser();
        setDisplayManageUserAddDialog(false)
        scrollAuto()
        return
      }
      const payload: IUserEditPayload = {
        name: currentUser.name,
        userId: currentUser.userId,
        ...getAddedOrDeletedRole(currentUser, editingUser)
      }
      editUser(payload)
      scrollAuto();
      return
    }
    addUser(currentUser)
    scrollAuto();
  }

  const cancel = () => {
    clearSelectedUser()
    setDisplayManageUserAddDialog(false);
    let formErr: IUserError = {
      nameError: false,
      userIdError: false,
      roleIdsError: false
    }
    setFormError(formErr);
    scrollAuto();
  }

  //for delete feature


  const onDeleteUser = (user: any) => {
    selectEditUser(user)
    setDisplayManageUserDeleteDialog(true);
  }

  const onDeleteUserReject = () => {
    clearSelectedUser()
    setDisplayManageUserDeleteDialog(false);
  }

  const onDeleteUserProceed = () => {
    deleteUser({ id: currentUser.userId })
  }

  //for edit feature 
  const onEditUser = (user: IUser) => {
    selectEditUser(user)
    setDisplayManageUserAddDialog(true)
    scrollHidden();
  }


  const renderDialogFooter = () => {
    return (
      <div className=''>
        <button className='me-3 btn btn-danger' onClick={cancel}> <span className='mdi mdi-close'></span> Cancel</button>
        <button className='btn btn-success' onClick={save}>Save<span className='mdi mdi-arrow-right ms-1 '></span></button>
      </div>
    )

  }

  const renderDeleteDialogFooter = () => {
    return (
      <div className='d-flex justify-content-center'>
        <button className='me-3 btn btn-danger' onClick={onDeleteUserReject}>  No </button>
        <button className='btn btn-success' onClick={onDeleteUserProceed}> Yes </button>
      </div>
    )
  }

  const rolesFilterTemplate = (options: any) => {
    return <Select panelClass='custom-panel-class' value={options.value} noJoin={true} selectOptions={roleOptions} update={(name, value) => options.filterCallback(value)} label='Roles' name='userIds' maxSelected={6} disabled={false} ></Select>
  }

  const globalFilterField = ['name', 'userId', rolesGlobalFilterValue]

  const columns: IColumn[] = [{
    field: 'name',
    header: 'Name',
    applyFilter: true
  },
  {
    field: 'userId',
    header: 'Email',
    applyFilter: true
  },
  {
    field: 'roleIds',
    header: 'Roles',
    bodyTemplate: getRoles,
    applyFilter: true,
    filterTemplate: rolesFilterTemplate,
    matchMode: matchModes
  }]

  const action: IAction[] = [{
    action: onEditUser,
    cssClass: 'text-secondary mdi mdi-pencil',
    title: 'Edit',
    header: ''
  }, {
    action: onDeleteUser,
    cssClass: 'text-danger mdi mdi-delete',
    title: 'Delete',
    header: ''
  }]


  return (
    <div className=' manage-user container mt-3 d-flex justify-content-center'>
      <div className='shadow-sm p-2'>
        <Dialog resizable={false} draggable={false} header={<h4 className='dialog-heading'>Please Fill The Below Details To Create New User</h4>} visible={displayManageUserAddDialog} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} footer={renderDialogFooter()} onHide={cancel} >
          {currentUser ? <ManageUserForm formError={formError}></ManageUserForm> : null}
        </Dialog>

        <Dialog resizable={false} draggable={false} header='' visible={displayManageUserDeleteDialog} breakpoints={{ '960px': '60vw' }} style={{ width: '30vw' }} footer={renderDeleteDialogFooter()} onHide={onDeleteUserReject} >
          <div className='dialog-heading text-center'>
            <h4 className='text-danger'>{`Do You Really Want To Delete the User ${currentUser?.name} ? `}</h4>
          </div>
        </Dialog>
        <div className='d-flex justify-content-between align-items-center p-2'>
          <h3 className='text-global-custom fw-bold'>All User</h3>
          <button className='btn btn-success' onClick={() => addNewUser()}><span className='mdi mdi-plus l3-font-size'></span> Add User</button>
        </div>
        <Table data={users} filterObject={filterObject} globalFilterField={globalFilterField} column={columns} actions={action} paginator={true} ></Table>

      </div>
    </div>

  )
}

const mapStateToProps = (state: IStoreState) => ({
  users: state.manageUser.userList.users,
  roles: state.userRole.roles.data,
  currentUser: state.manageUser.currentUser,
  editingUser: state.manageUser.editingUser,
  addUserStatus: state.manageUser.addUserStatus,
  editUSerStatus: state.manageUser.editUserStatus,
  deleteUserStatus: state.manageUser.deleteUserStatus
})


const mapDispatchToProps = (dispatch: any) => ({
  updateBreadCrumb: (data: any) => {
    dispatch(updateBreadCrumb(data));
  },
  clearBreadCrumb: () => {
    dispatch(clearBreadCrumb())
  },
  getUserList: () => {
    dispatch(getUserList())
  },
  getRoleList: () => {
    dispatch(getRoleList())
  },
  selectAddUser: () => {
    dispatch(selectAddUser())
  },
  selectEditUser: (data: IUser) => {
    dispatch(selectEditUser(data))
  },
  updateUserInfo: (data: any) => {
    dispatch(updateUserInfo(data))
  },
  addUser: (payload: IUserAddPayload) => {
    dispatch(addUserList(payload))
  },
  editUser: (payload: IUserEditPayload) => {
    dispatch(editUser(payload))
  },
  deleteUser: (payload: IDeleteUserPayload) => {
    dispatch(deleteUser(payload))
  },
  clearSelectedUser: () => {
    dispatch(clearSelectedUser())
  },
  showNotification: (notification: INotification) => {
    dispatch(showNotification(notification))
  },
  updateUserList: (data: any) => {
    dispatch(updateUserList(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);