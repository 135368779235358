import { ITreeNode } from '../../../types/SiteMap';
import { Link } from "react-router-dom";
import './SiteMap.scss'
import { chartNodeList, getNodeList } from "src/constants/ChartNodeList";
import { connect } from 'react-redux'
import { IStoreState } from '../../../types/StoreState';
import { getKpiReportList } from '../../../actions/reportListAction';
import { getCategoryReports } from '../../../actions/CaReportAction';
import { useEffect, useState } from 'react';
import { IKpi } from '../../../types/reportList';
import { ICategoryReports } from '../../../types/CaReport';
import { rolesList } from 'src/constants/RoleList';

interface IProps {
    userRoles: any,
    getCategoryReports: () => void,
    getKpiReportList: (all: boolean) => void,
    reportsList: {
        loading: boolean,
        error: boolean,
        success: boolean,
        data: IKpi[]
    },
    allCategoryReports: {
        loading: boolean,
        success: boolean,
        error: boolean,
        data: ICategoryReports[]
    }
}

const SiteMap = ({ userRoles, getCategoryReports, getKpiReportList, reportsList, allCategoryReports }: IProps) => {


    useEffect(() => {
        if (userRoles && !userRoles.loading && userRoles.success) {
            if (reportsList && !reportsList.loading && !reportsList.success) {
                let all = userRoles.some((item: number) => item === rolesList.QA || item === rolesList.REPORT_DEVELOPER)

                getKpiReportList(all);
            }
            if (allCategoryReports && !allCategoryReports.loading && !allCategoryReports.success) {
                getCategoryReports()
            }
        }
    }, [userRoles])

    // useEffect(() => {
    //     if (reportsList && reportsList.length > 0) {
    //         let node = nodes.;
    //     }
    // }, [reportsList])


    const generateNode = (item: ITreeNode, index: number) => {
        if ((!item.role || userRoles && userRoles.roles.some((role: number) => role === item.role))) {
            return (
                <div className={`d-flex l2-font-size ${index > 0 && 'mt-3'}`}>
                    {!item.isTop && <div className='horizontal-line'></div>}
                    {item.isExternal ? <a href={item.url} target='_blank'><div className='node'>{item.label}</div></a> :
                        <Link to={item.url}><div className='node'>{item.label}</div></Link>}
                    {item.children && item.children.length > 0 && <>
                        <div className='horizontal-line'></div>
                        <div className='vertical-line'></div>
                        <div className='d-flex flex-column'>
                            {item.children.map((child, index) => generateNode(child, index))}
                        </div>
                    </>}
                </div>)
        } else {
            return null
        }
    }


    return (
        <div className='mx-3'>
            <h3 className='p-2 border-bottom text-start fw-bold  my-3'>
                Application Site-Map
            </h3>
            <div className='sitemap-container'>
                {getNodeList(reportsList.data, allCategoryReports.data).map((i, index) => generateNode(i, index))}
            </div>
        </div>)
}

const mapStateToProps = (state: IStoreState) => ({
    userRoles: state.login.userRoles,
    reportsList: state.reportSearch.nonFilteredReports,
    allCategoryReports: state.caReport.allCategoryReports
})

const mapDispatchToProps = (dispatch: any) => ({
    getKpiReportList: (all: boolean) => {
        dispatch(getKpiReportList(all))
    },
    getCategoryReports: () => {
        dispatch(getCategoryReports())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap);