import actionTypes from './reportRequestActionTypes'
import { ReportRequest } from '../api/reportRequestApi';
const api = new ReportRequest()

export const submitRequest = (payload: any) => {
    return api.submitReportRequest(payload, _submitRequest, submitRequestSuccess, submitRequestFailure)
}

const _submitRequest = () => {
    return {
        type: actionTypes.SUBMIT_REQUEST,
    }
}

export const submitRequestSuccess = (data: any) => {
    return {
        type: actionTypes.SUBMIT_REQUEST_SUCCESS,
        payload: data
    }
}

export const submitRequestFailure = (data: any) => {
    return {
        type: actionTypes.SUBMIT_REQUEST_ERROR,
        payload: data
    }
}

export const checkAccess = (payload: any) => {
    return api.checkAccess(payload, _checkAccess, checkAccessSuccess, checkAccessFailure)
}

const _checkAccess = () => {
    return {
        type: actionTypes.CHECK_HAS_ACCESS,
    }
}

export const checkAccessSuccess = (data: any) => {
    return {
        type: actionTypes.CHECK_HAS_ACCESS_SUCCUESS,
        payload: data.hasAccess
    }
}

export const checkAccessFailure = (data: any) => {
    return {
        type: actionTypes.CHECK_HAS_ACCESS_ERROR,
        payload: data
    }
}

export const checkRequestStatus = () => {
    return api.isPIRequestPending({}, _checkRequestStatus, checkRequestStatusSuccess, checkRequestStatusFailure)
}

const _checkRequestStatus = () => {
    return {
        type: actionTypes.CHECK_REQUEST_STATUS,
    }
}

export const checkRequestStatusSuccess = (data: any) => {
    return {
        type: actionTypes.CHECK_REQUEST_STATUS_SUCCESS,
        payload: data.isSuccess
    }
}

export const checkRequestStatusFailure = (data: any) => {
    return {
        type: actionTypes.CHECK_REQUEST_STATUS_ERROR,
        payload: data
    }
}

export const clearHasAccess = () => {
    return {
        type: actionTypes.CLEAR_HAS_ACCESS
    }
}

export const updateRequestData = (data: any) => {
    return {
        type: actionTypes.UPDATE_REQUEST_DATA,
        payload: data
    }
}

export const clearRequestData = () => {
    return {
        type: actionTypes.CLEAR_DATA
    }
}