import { actionTypes } from "./ManageDeActionType";
import { ManageDeApi } from "../api/manageDeApi";
import store from '../store/store'
import { IAllDeHistoricRequest, ICompany, IDe, IDeHistoricRequest, IDeList, IDeUserRequest, IGetDeHistoryPayload,IGetCurrentDeCompaniesPayload } from "../types/ManageDe";
import moment from "moment";

const api = new ManageDeApi();

export const getAllDeList = () => {
    return api.getAllDeList({}, _getAllDeList, getAllDeListSuccess, getAllDeListFailure);
}

export const getDeRequestHistory = (payload: IGetDeHistoryPayload) => {
    return api.getDeRequestHistory(payload, _getDeRequestHistory, getDeRequestHistorySuccess, getDeRequestHistoryFailure)
}

export const getCompanyList = (param: any) => {
    return api.getCompanyList(param, _getCompanyList, getCompanyListSuccess, getCompanyListFailure);
}

export const getCurrentDeCompanyList = (param:IGetCurrentDeCompaniesPayload) => {
    return api.getCurrentDECompanies(param, _getCurrentDeCompanyList, getCurrentDeCompanyListSuccess, getCurrentDeCompanyListFailure);
}

export const getDeUserList = (param: any) => {
    return api.getDeUserList(param, _getDeUserList, getDeUserListSuccess, getDeUserListFailure)
}

export const addDe = (payload: any) => {
    return api.addDe({}, payload, _addDe, addDeSuccess, addDeFailure);
}

export const editDe = (payload: any) => {
    return api.editDe({}, payload, _editDe, editDeSuccess, editDeFailure);
}

export const deleteDe = (payload: any) => {
    return api.deleteDe({}, payload, _deleteDe, deleteDeSuccess, deleteDefailure)
}

export const updateDeInfo = (data: any) => {
    return {
        type: actionTypes.UPDATE_DE_INFO,
        payload: data
    }
}

export const selectAddDe = () => {
    return {
        type: actionTypes.SELECT_ADD_DE
    }
}

export const selectEditDe = (data: any) => {
    return {
        type: actionTypes.SELECT_EDIT_DE,
        payload: data
    }
}

export const clearSelectedDe = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_DE
    }
}

const _getAllDeList = () => {
    return {
        type: actionTypes.GET_ALL_DE_LIST
    }
}

const getAllDeListSuccess = (data: any) => {
    const payload = data.data.map((item: any) => {
        item.creationDate = moment(item.creationDate).format('YYYY-MM-DD')
        item.updationDate = moment(item.updationDate).format('YYYY-MM-DD')
        return item
    })
    return {
        type: actionTypes.GET_ALL_DE_LIST_SUCCESS,
        payload: payload
    }
}

const getAllDeListFailure = (data: any) => {
    return {
        type: actionTypes.GET_ALL_DE_LIST_FAILURE,
        payload: data
    }
}

const _getDeRequestHistory = () => {
    return {
        type: actionTypes.GET_DE_REQUEST_HISTORY
    }
}

const getStatusOfRequest = (status: any) => {
    switch (status) {
        case 'pending': {
            return 'Company Access Enabled'
        }
        case 'inactive': {
            return 'Deleted'
        }
        case 'active': {
            return 'Pending'
        }
        case 'created': {
            return 'Completed'
        }
    }
}
const getDeRequestHistorySuccess = (data: any) => {
    let _payload: any = {};
    _payload = data.data.reduce((r: any, a: any) => {
        let key = a.companyId + "_" + moment(a.creationDate).format('YYYY-MM-DD') + "_" + a.createdBy
        r[key] = r[key] || []
        r[key].push(a)
        return r;
    }, Object.create(null))
    let payload: IDeHistoricRequest[] = [];
    for (let [key, value] of Object.entries<any>(_payload)) {
        let temp = value[0];
        let _userRequests: IDeUserRequest[] = value.map((item: any) => {
            return { userId: item.userId, processDate: moment(item.updationDate).format('YYYY-MM-DD'), status: getStatusOfRequest(item.status) }
        })
        let _data: IDeHistoricRequest = {
            requestedBy: temp.createdBy,
            requestDate: moment(temp.creationDate).format('YYYY-MM-DD'),
            companyName: temp.companyName,
            key:key,
            userRequests: _userRequests
        }
        payload.push(_data)
    }
    return {
        type: actionTypes.GET_DE_REQUEST_HISTORY_SUCCESS,
        payload: payload
    }
}

const getDeRequestHistoryFailure = (data: any) => {
    return {
        type: actionTypes.GET_DE_REQUEST_HISTORY_FAILURE,
        payload: data
    }
}


const _getCompanyList = () => {
    return {
        type: actionTypes.GET_COMPANY_LIST
    }
}

const getCompanyListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_COMPANY_LIST_SUCCESS,
        payload: data.data
    }
}

const getCompanyListFailure = (data: any) => {
    return {
        type: actionTypes.GET_COMPANY_LIST_FAILURE,
        payload: data
    }
}

const _getCurrentDeCompanyList = () => {
    return {
        type: actionTypes.GET_CURRENT_DE_COMPANY_LIST
    }
}

const getCurrentDeCompanyListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_CURRENT_DE_COMPANY_LIST_SUCCESS,
        payload: data.data
    }
}

const getCurrentDeCompanyListFailure = (data: any) => {
    return {
        type: actionTypes.GET_CURRENT_DE_COMPANY_LIST_FAILURE,
        payload: data
    }
}


const _getDeUserList = () => {
    return {
        type: actionTypes.GET_USER_LIST
    }
}

const getDeUserListSuccess = (data: any) => {
    return {
        type: actionTypes.GET_USER_LIST_SUCCESS,
        payload: data.data
    }
}

const getDeUserListFailure = (data: any) => {
    return {
        type: actionTypes.GET_USER_LIST_FAILURE,
        payload: data
    }
}

const _addDe = () => {
    return {
        type: actionTypes.ADD_DE
    }
}

const addDeSuccess = (data: any) => {
    return {
        type: actionTypes.ADD_DE_SUCCESS,
        payload: data
    }
}

const addDeFailure = (data: any) => {
    return {
        type: actionTypes.ADD_DE_FAILURE,
        payload: data
    }
}

const _editDe = () => {
    return {
        type: actionTypes.EDIT_DE
    }
}

const editDeSuccess = (data: any) => {
    return {
        type: actionTypes.EDIT_DE_SUCCESS,
        payload: data
    }
}

const editDeFailure = (data: any) => {
    return {
        type: actionTypes.EDIT_DE_FAILURE,
        payload: data
    }
}

const _deleteDe = () => {
    return {
        type: actionTypes.DELETE_DE
    }
}

const deleteDeSuccess = (data: any) => {
    return {
        type: actionTypes.DELETE_DE_SUCCESS,
        payload: data
    }
}

const deleteDefailure = (data: any) => {
    return {
        type: actionTypes.DELETE_DE_FAILURE,
        payload: data
    }
}

export const filterCompany = (searchKey: any) => {
    const state = store.getState();
    let count = 0;
    const filteredCompany = [];
    for (let item of state.manageDe.companyList.data) {
        if (count > 100) {
            break
        }

        if (item && item.label.toLowerCase().includes(searchKey.toLowerCase())) {
            filteredCompany.push(item)
            count++
        }
    }

    if (state.manageDe.currentDe && state.manageDe.currentDe.companyId) {
        const companyId = state.manageDe.currentDe.companyId;
        const index = filteredCompany.findIndex(i => i && i.value === companyId)
        if (index === -1) {
            const newUser = state.manageDe.companyList.data.find(user => user.value == companyId)
            filteredCompany.push(newUser)
        }
    }

    return {
        type: actionTypes.FILTER_COMPANY,
        payload: filteredCompany
    }
}

export const filterUser = (searchKey: any) => {
    const state = store.getState();
    let count = 0;
    const filteredUser = [];
    for (let item of state.manageDe.deUserList.data) {
        if (count > 100) {
            break
        }

        if (item && item.label.toLowerCase().includes(searchKey.toLowerCase())) {
            filteredUser.push(item)
            count++
        }
    }

    if (state.manageDe.currentDe && state.manageDe.currentDe.userIds && state.manageDe.currentDe.userIds.length > 0) {
        for (let item of state.manageDe.currentDe.userIds) {
            const index = filteredUser.findIndex(i => i && i.value === item)
            if (index === -1) {
                const newUser = state.manageDe.deUserList.data.find(user => user.value == item)
                filteredUser.push(newUser)
            }
        }
    }


    return {
        type: actionTypes.FILTER_USER,
        payload: filteredUser
    }
}

export const updateDeList = (data: IDeList[]) => {
    return {
        type: actionTypes.UPDATE_DE_LIST,
        payload: data
    }
}

export const triggerJob = () => {
    return api.triggerJob({}, _triggerJob, triggerJobSuccess, triggerJobFailure)
}

export const _triggerJob = () => {
    return {
        type: actionTypes.PROCESS_JOB
    }
}

export const triggerJobSuccess = (data: any) => {
    return {
        type: actionTypes.PROCESS_JOB_SUCCESS,
        payload: data
    }
}

export const triggerJobFailure = (data: any) => {
    return {
        type: actionTypes.PROCESS_JOB_FAILURE,
        payload: data
    }
}

export const clearDe = () => {
    return {
        type: actionTypes.CLEAR_DE_REQUEST
    }
}

