import { BaseApi } from "./baseApi";
import { config } from "./config";
const api = new BaseApi();

export class ManageDeApi {

   getAllDeList(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.get(config.apiUrl.getAllDeList, parameter, requestAction, requestSuccessAction, errorAction)
   }

   getCompanyList(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.get(config.apiUrl.getCompanyList, parameter, requestAction, requestSuccessAction, errorAction)
   }

   getCurrentDECompanies(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.get(config.apiUrl.getCurrentDECompanies, parameter, requestAction, requestSuccessAction, errorAction)
   }

   getDeUserList(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.get(config.apiUrl.getDeUserList, parameter, requestAction, requestSuccessAction, errorAction)
   }

   addDe(parameter: any, payload: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.post(config.apiUrl.addDe, parameter, payload, requestAction, requestSuccessAction, errorAction);
   }

   editDe(parameter: any, payload: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.put(config.apiUrl.editDe, parameter, payload, requestAction, requestSuccessAction, errorAction);
   }
   deleteDe(parameter: any, payload: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.delete(config.apiUrl.deleteDe, parameter, payload, requestAction, requestSuccessAction, errorAction)
   }

   triggerJob(payload: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.post(config.apiUrl.triggerDeJob, {}, payload, requestAction, requestSuccessAction, errorAction);
   }

   getDeRequestHistory(parameter: any, requestAction: any, requestSuccessAction: any, errorAction: any) {
      return api.get(config.apiUrl.getDeRequestHistory, parameter, requestAction, requestSuccessAction, errorAction)
   }

}