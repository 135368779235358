import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class ManageFactApi {

    getFactList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getAllFactList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    getAllGeneratedFactList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getAllGeneratedFactList, { ...payload, noLoader: true }, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewFact(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addFact, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    editFact(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.put(config.apiUrl.editFact, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    deleteFact(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.delete(config.apiUrl.deleteFact, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}