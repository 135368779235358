import { IProcess } from "./ProcessFlowType";

export const processList:IProcess[] = [
       {
          processName:'PI Data Access',
          processDesc:'To see PI data, you need to raise a PI request. You can raise requests either from the request tile on the SHL-Analytics home page or while accessing the reports on SMART. The workflow for your PI request is shown below.',
          steps:[
            {
                title:'Request',
                desc:'A PI request has been submitted by the user.'
            },
            {
                title:'Pending',
                desc:'The request is currently in the "pending" stage. Waiting for DPO approval.'
            },
            {
                title:'Approve',
                desc:'The DPO will analyse the request and approve or reject it accordingly. If Rejected you will not get PI access.'
            },
            {
                title:'Access Granted',
                desc:'Access to PI data will be granted if the DPO has approved the request.'
            }
          ]
       },{
          processName:'Tableau Access',
          processDesc:'To see Tableau dashboards, you need to raise a Tableau-access request. You can raise requests either from the request tile on SHL Analytics or from the individual report page.',
          steps:[
            {
                title:'Request',
                desc:'The user has raised a Tableau-request.'
            },
            {
                title:'Pending',
                desc:'The request is currently in the "pending" stage. Waiting for Tableau Admin approval.'
            },
            {
                title:'Approve',
                desc:'Tableau Admin will analyse the request and approve or reject it accordingly. If rejected user will not get tableau access.'
            },
            {
                title:'Access Granted',
                desc:'Access to Tableau dashboards will be granted if Tableau Admin has approved the request.'
            }
          ]
       },
       {
        processName:'A&IM JIRA Requests',
        processDesc:'The user needs to raise a BAU ticket from Jira.',
        steps:[
          {
              title:'Waiting for support ',
              desc:'A ticket has been created but not yet assigned to anyone.'
          },
          {
              title:'In Progress',
              desc:'A ticket has been assigned, and the assignee has started working on it.'
          },
          {
              title:'Pending',
              desc:'When sample data has been shared with the requester or more information is required from the requester.'
          },
          {
              title:'Waiting for customer',
              desc:'When the required extract has been shared with the requester and confirmation is pending.'
          },
          {
            title:'Resolved',
            desc:'When the requester is satisfied with the shared data, the ticket can be resolved.'
          }
        ]
     },{
        processName:'CA Data Entitlement Request',
        processDesc:'To enable data entitlement for a particular company. You can raise a DE request from the Data Entitlement tile on SMART.',
        steps:[
              {
                title:'Request',
                desc:'The CSIT team has raised a request.'
              },
              {
                title:'Pending',
                desc:'Waiting for the scheduler to process requests'
              },
              {
                title:'Processed',
                desc:'The request has been processed successfully, and all the users are now added to that company.'
              } 
              
        ]
     },
     {
        processName:'Custom Score Group',
        processDesc:'Aside from the default score group, users can create their own custom score group. The user can add a custom score group from the Manage Score Group tile on SMART.',
        steps:[
              {
                title:'Create',
                desc:'The user needs to create a custom score group for that company.'
              },
              {
                title:'Added on Dashboard',
                desc:'Custom score groups are available on the dashboard.'
              }
              
        ]
     },{
      
      processName:'SMART Company Access Request',
      processDesc:'To enable data entitlement for a particular company. You can raise a company access request from the "Company Access Request" tile on SMART.',
      steps:[],
      chartFlow:true,
      chartPath:'asset/smart_de_flow.png'
   }
]
