const actionType = {
    FILTER_REPORTS : 'FILTER_REPORTS',
    CLEAR_FILTER: 'CLEAR_REPORT_FILTER',
    FETCH_REPORTS : 'FETCH_REPORTS',
    UPDATE_SUGGESTION_LIST:'UPDATE_SUGGESTION_LIST',
    FETCH_REPORTS_SUCCESS : 'FETCH_REPORTS_SUCCESS',
    FETCH_REPORTS_FAILURE : 'FETCH_REPORTS_FAILURE',
    UPDATE_CURRENT_REPORT: 'UPDATE_CURRENT_REPORT',
    UPDATE_CURRENT_KPI: 'UPDATE_CURRENT_KPI',
    GET_FAVOURITE_REPORT:'GET_FAVOURITE_REPORT',
    GET_FAVOURITE_REPORT_SUCCESS:'GET_FAVOURITE_REPORT_SUCCESS',
    GET_FAVOURITE_REPORT_FAILURE:'GET_FAVOURITE_REPORT_FAILURE',
    SET_FAVOURITE_REPORT:'SET_FAVOURITE_REPORT',
    SET_FAVOURITE_REPORT_SUCCESS:'SET_FAVOURITE_REPORT_SUCCESS',
    SET_FAVOURITE_REPORT_FAILURE:'SET_FAVOURITE_REPORT_FAILURE',
    REMOVE_FROM_FAVOURTIE:'REMOVE_FROM_FAVOURTIE',
    REMOVE_FROM_FAVOURTIE_SUCCESS:'REMOVE_FROM_FAVOURTIE_SUCCESS',
    REMOVE_FROM_FAVOURTIE_FAILURE:'REMOVE_FROM_FAVOURTIE_FAILURE',
    GET_RECENT_REPORTS:'GET_RECENT_REPORTS',
    GET_RECENT_REPORTS_SUCCESS:'GET_RECENT_REPORTS_SUCCESS',
    GET_RECENT_REPORTS_FAILURE:'GET_RECENT_REPORTS_FAILURE',
    GET_HOME_KPI_DATA:'GET_HOME_KPI_DATA',
    GET_HOME_KPI_DATA_SUCCESS:'GET_HOME_KPI_DATA_SUCCESS',
    GET_HOME_KPI_DATA_FAILURE:'GET_HOME_KPI_DATA_FAILURE'
    
}

export default actionType;