const actionTypes = {
    UPDATE_FILTER: 'UPDATE_REPORT_FILTER',
    UPDATE_EXTRACT_FILTER: 'UPDATE_EXTRACT_FILTER',
    CLEAR_FILTER: 'CLEAR_FILTER',
    CLEAR_EXTRCAT_FILTER: 'CLEAR_EXTRACT_FILTER',
    FETCH_OPTION: 'FETCH_OPTION',
    FETCH_OPTION_SUCCESS: 'FETCH_OPTION_SUCCESS',
    FETCH_OPTION_ERROR: 'FETCH_OPTION_ERROR',
    GET_FILTER_LIST_FOR_REPORT: 'GET_FILTER_LIST_FOR_REPORT',
    GET_FILTER_LIST_FOR_REPORT_SUCCESS: 'GET_FILTER_LIST_FOR_REPORT_SUCCESS',
    GET_FILTER_LIST_FOR_REPORT_ERROR: 'GET_FILTER_LIST_FOR_REPORT_ERROR',
    GET_FILTER_LIST_FOR_EXTRACT: 'GET_FILTER_LIST_FOR_EXTRACT',
    GET_FILTER_LIST_FOR_EXTRACT_SUCCESS: 'GET_FILTER_LIST_FOR_EXTRACT_SUCCESS',
    GET_FILTER_LIST_FOR_EXTRACT_ERROR: 'GET_FILTER_LIST_FOR_EXTRACT_ERROR',
    UPDATE_CURRENT_UPDATED_FILTER_NAME: 'UPDATE_CURRENT_UPDATED_FILTER_NAME'
}

export default actionTypes