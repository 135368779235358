import { ComponentType, lazy } from "react";

export const safeLazy = <T>(importFunction: () => Promise<{ default: ComponentType<T> }>) => {
    // ...tryImport function
    let retries = 0;
    const tryImport: any = async () => {
        try {
          return await importFunction();
        } catch (error) {
          // Retry 3 times max
          if (retries < 3) {
            retries++;
            return tryImport();
          }
          throw error;
        }
      };
  
    return lazy(async () => {
      try {
        const component = await tryImport();
  
        // Clear the sessionStorage when the component is loaded successfully
        sessionStorage.removeItem('refreshed');
  
        return component;
      } catch (error) {
        if (!sessionStorage.getItem('refreshed')) {
          sessionStorage.setItem('refreshed', 'true');
          window.location.reload();
          return { default: () => null };
        }
  
        // Throw the error if the component cannot be loaded after a refresh
        throw error;
      }
    });
  };