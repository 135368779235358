import { ITableauReportState } from "../types/tableauReport";
import actionType from '../actions/tableauReportActionTypes'

const initialState: ITableauReportState = {
    deleteTokenStatus: {
        loading: false,
        success: false,
        error: false
    },
    token: null,
    logHistory: {
        loading: false,
        success: false,
        error: false,
        reportViewAuditId: ''
    },
    logFilterStatus: {
        loading: false,
        success: false,
        error: false,
    },
    downloadReportStatus: {
        loading: false,
        success: false,
        error: false
    },
    secureDownloadReportStatus: {
        loading: false,
        success: false,
        error: false
    }

}

const reducer = (state = initialState, action: any): ITableauReportState => {
    switch (action.type) {
        case actionType.UPDATE_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }

        case actionType.DELETE_TOKEN: {
            return {
                ...state,
                deleteTokenStatus: {
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.DELETE_TOKEN_SUCCESS: {
            return {
                ...state,
                deleteTokenStatus: {
                    success: true,
                    error: false,
                    loading: false
                },
                token: null
            }
        }
        case actionType.DELETE_TOKEN_ERROR: {
            return {
                ...state,
                deleteTokenStatus: {
                    success: false,
                    error: true,
                    loading: false
                }
            }
        }

        case actionType.LOG_VIEW_HOSTORY: {
            return {
                ...state,
                logHistory: {
                    success: false,
                    error: false,
                    loading: true,
                    reportViewAuditId: ''
                }
            }
        }
        case actionType.LOG_VIEW_HOSTORY_SUCCESS: {
            return {
                ...state,
                logHistory: {
                    success: true,
                    error: false,
                    loading: false,
                    reportViewAuditId: action.payload
                }
            }
        }
        case actionType.LOG_VIEW_HOSTORY_ERROR: {
            return {
                ...state,
                logHistory: {
                    success: false,
                    error: true,
                    loading: false,
                    reportViewAuditId: ''
                }
            }
        }

        case actionType.DOWNLOAD_REPORT: {
            return {
                ...state,
                downloadReportStatus: {
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.DOWNLOAD_REPORT_SUCCESS: {
            return {
                ...state,
                downloadReportStatus: {
                    success: true,
                    error: false,
                    loading: false
                }
            }
        }
        case actionType.DOWNLOAD_REPORT_ERROR: {
            return {
                ...state,
                downloadReportStatus: {
                    success: false,
                    error: true,
                    loading: false
                }
            }
        }

        case actionType.CLEAR_DOWNLOAD_STATUS: {
            return {
                ...state,
                downloadReportStatus: {
                    success: false,
                    error: false,
                    loading: false
                }
            }
        }

        case actionType.LOG_APPLIED_FILTER: {
            return {
                ...state,
                logFilterStatus: {
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.LOG_APPLIED_FILTER_SUCCESS: {
            return {
                ...state,
                logFilterStatus: {
                    success: true,
                    error: false,
                    loading: false
                }
            }
        }
        case actionType.LOG_APPLIED_FILTER_ERROR: {
            return {
                ...state,
                logFilterStatus: {
                    success: false,
                    error: true,
                    loading: false
                }
            }
        }

        case actionType.CLEAR_LOG_VIEW_HISTORY: {
            return {
                ...state,
                logHistory: {
                    success: false,
                    error: false,
                    loading: false,
                    reportViewAuditId: ''
                }
            }
        }

        case actionType.SECURE_DOWNLOAD_REQUEST: {
            return {
                ...state,
                secureDownloadReportStatus: {
                    success: false,
                    error: false,
                    loading: true
                }
            }
        }
        case actionType.SECURE_DOWNLOAD_REQUEST_SUCCESS: {
            return {
                ...state,
                secureDownloadReportStatus: {
                    success: true,
                    error: false,
                    loading: false
                }
            }
        }
        case actionType.SECURE_DOWNLOAD_REQUEST_ERROR: {
            return {
                ...state,
                secureDownloadReportStatus: {
                    success: false,
                    error: true,
                    loading: false
                }
            }
        }

        case actionType.CLEAR_SECURE_DOWNLOAD_REQUEST: {
            return {
                ...state,
                secureDownloadReportStatus: {
                    success: false,
                    error: false,
                    loading: false
                }
            }
        }

        default:
            return state
    }
}

export default reducer