const actionTypes = {
        UPDATE_CURRENT_CATEGORY:'UPDATE_CURRENT_CATEGORY',
        UPDATE_CURRENT_CA_REPORT:'UPDATE_CURRENT_CA_REPORT',
        GET_CATEGORY_REPORTS:'GET_CATEGORY_REPORTS',
        GET_CATEGORY_REPORTS_SUCCESS:'GET_CATEGORY_REPORTS_SUCCESS',
        GET_CATEGORY_REPORTS_FAILURE:'GET_CATEGORY_REPORTS_FAILURE',
        CLEAR_CA_REPORT_STATE:'CLEAR_CA_REPORT_STATE'
        
}

export default actionTypes