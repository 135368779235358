import { ManageSolutionMappingApi } from "../api/manageSolutionMappingApi";
import store from "../store/store";
import actionType from "./ManageSolutionMappingActionType";
const api = new ManageSolutionMappingApi();


export const getAllCompany = (payload: any) => {
    return api.getAllSutCompany(payload, _getAllCompany, getAllCompanySuccess, getAllCompanyFailure)
}


const _getAllCompany = () => {
    return {
        type: actionType.GET_ALL_COMPANY
    }
}

const getAllCompanySuccess = (data: any) => {

    return {
        type: actionType.GET_ALL_COMPANY_SUCCESS,
        payload: data
    }
}

const getAllCompanyFailure = (data: any) => {
    return {
        type: actionType.GET_ALL_COMPANY_FAILURE,
        payload: data
    }
}

export const getAllMappings = (payload: any) => {
    return api.getAllMappings(payload, _getAllMappings, getAllMappingsSuccess, getAllMappingsFailure)
}

const _getAllMappings = () => {
    return {
        type: actionType.GET_ALL_MAPPINGS
    }
}

const getAllMappingsSuccess = (data: any) => {
    return {
        type: actionType.GET_ALL_MAPPINGS_SUCCESS,
        payload: data
    }
}

const getAllMappingsFailure = (data: any) => {
    return {
        type: actionType.GET_ALL_MAPPINGS_FAILURE,
        payload: data
    }
}


export const getAllSolutionMapping = (payload: any) => {
    return api.getAllSolutionMapping(payload, _getAllSolutionMapping, getAllSolutionMappingSuccess, getAllSolutionMappingFailure)
}

const _getAllSolutionMapping = () => {
    return {
        type: actionType.GET_ALL_SULUTION_MAPPING
    }
}

const getAllSolutionMappingSuccess = (data: any) => {
    return {
        type: actionType.GET_ALL_SULUTION_MAPPING_SUCCESS,
        payload: data
    }
}

const getAllSolutionMappingFailure = (data: any) => {
    return {
        type: actionType.GET_ALL_SULUTION_MAPPING_FAILURE,
        payload: data
    }
}

export const updateSolutionMappings = (data: any) => {
    return {
        type: actionType.UPDATE_SOLUTION_MAPPINGS,
        payload: data
    }
}

export const addSolutionMappings = (payload: any) => {
    return api.addSolutionMappings(payload, _addSolutionMappings, addSolutionMappingsSuccess, addSolutionMappingsFailure)
}

const _addSolutionMappings = () => {
    return {
        type: actionType.ADD_SOLUTION_MAPPINGS
    }
}

const addSolutionMappingsSuccess = (data: any) => {
    return {
        type: actionType.ADD_SOLUTION_MAPPINGS_SUCCESS,
        payload: data
    }
}

const addSolutionMappingsFailure = (data: any) => {
    return {
        type: actionType.ADD_SOLUTION_MAPPINGS_FAILURE,
        payload: data
    }
}

export const filterSutCompany = (searchKey: any, companyId: any) => {
    const state = store.getState();
    let count = 0;
    const filteredCompany = [];
    for (let item of state.manageSolutionMapping.allCompanyList.data) {
        if (count > 100) {
            break
        }

        if (item && item.label.toLowerCase().includes(searchKey.toLowerCase())) {
            filteredCompany.push(item)
            count++
        }
    }
    if (companyId) {
        let currentCompany = state.manageSolutionMapping.filteredSutCompanyList.find(item => item.value === companyId)
        filteredCompany.push(currentCompany)
    }

    return {
        type: actionType.FILTER_SUT_COMPANY,
        payload: filteredCompany
    }
}

export const resetAllSolutionMappings = () => {
    return {
        type: actionType.RESET_ALL_SOLUTION_MAPPINGS
    }
}

export const clear = () => {
    return {
        type: actionType.CLEAR_SOLUTION_MAPPING_STATE
    }
}

export const addMoreCompany = () => {
    const state = store.getState();
    const filteredCompany = [...state.manageSolutionMapping.filteredSutCompanyList, ...state.manageSolutionMapping.allCompanyList.data.slice(0, 100)];

    return {
        type: actionType.FILTER_SUT_COMPANY,
        payload: filteredCompany
    }
}

export const clearFilterCompany = () => {
    return {
        type: actionType.CLEAR_FILTER_SUT_COMPANY
    }
}