import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'primereact/chart';
import { connect } from 'react-redux'
import './BiHomePage.scss';
import { getKpiBiHomeData } from '../../../actions/BiHomePageAction';
import { IStoreState } from '../../../types/StoreState';
import { IBarRaceChartData, IKpiBiHomeData, ISeriesData } from '../../../types/BiHomePage';
import BiLoader from '../../shared/BiLoader/BiLoader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

import { BarChart } from 'echarts/charts'
import { TooltipComponent, TitleComponent, DatasetComponent, GridComponent, LegendComponent, PolarComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';

import TopFactBanner from './TopFactBanner/TopFactBanner';
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent, PolarComponent]
);
interface IProps {
    showLoader: number
}

const BiHomePage = ({ showLoader }: IProps) => {
    return (

        <div className='bi-home-container  d-flex justify-content-center position-relative'>
            {showLoader ? <BiLoader></BiLoader> : null}
            <div className='py-3 main-container-wrapper'>
                <TopFactBanner />

                <div className='shadow-sm home-content d-flex justify-content-between mt-3 mb-4'>
                    <div className='h-100 pt-5 px-4 text-container l2-font-size'>
                        <h2 className=' fw-bold'>Welcome To SHL Analytics Portal</h2>
                        <p>
                            <b>SHL Analytics</b> is the new BI reporting platform that serves as a one stop solution for SHL <b>internal teams</b> to get easy access to performance and usage data of Talent Acquisition/Management programs of our clients.
                            <br />
                            This empowers our internal teams to serve regular and ad-hoc data/insights need of clients by sharing relevant metrics with them about the performance of assessment programs.
                        </p>
                        <p>
                            <b>Explore the features -</b>
                        </p>
                        <ol>
                            <li><b>SMART</b> (SHL Metrics & Analytics Reporting Terminal) can be used to access and analyze data related to the recruitment funnel, score distribution, subscription usage, program performance, adverse impact, and more.</li>
                            <li><b>Raise a Request</b> - To get Tableau access or client PII Data for SMART reports.</li>
                        </ol>


                    </div>
                    <div>
                        <img className='home-image' alt="home page" src={process.env.PUBLIC_URL + '/asset/homepage_analytics.png'}></img>
                    </div>
                </div>

            </div>

        </div >)
}

const mapStateToProps = (state: IStoreState) => ({
    showLoader: state.notification.loader.show
})

const mapDispatchToProps = (dispatch: any) => ({
    getKpiBiHomeData: () => {
        return dispatch(getKpiBiHomeData())
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(BiHomePage);