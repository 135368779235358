import { BaseApi } from './baseApi';
import { config } from './config'

const baseApi = new BaseApi();

export class UserRoleApi {

    getRoleList(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.get(config.apiUrl.getRoleList, payload, requestAction, requestSuccessAction, requestErrorAction);
    }

    addNewRole(payload: any, requestAction: any, requestSuccessAction: any, requestErrorAction: any) {
        return baseApi.post(config.apiUrl.addNewRole, {}, payload, requestAction, requestSuccessAction, requestErrorAction);
    }
}