import { rolesList } from "./RoleList"

export interface IApplicationList {
  appName: string,
  url: string,
  role?: number
}

export const ApplicationList = [
  {
    appName: 'SMART',
    url: '/smart'
  },
  {
    appName: 'Client Analytics',
    url: '/clientAnalytics',
    role: rolesList.CAUSER
  },
  {
    appName: 'Custom Dashboards',
    url: '/customdashboard',
    role: rolesList.CUSTOMDASHBOARDUSER
  },
  {
    appName: 'Create Your Own Dashboard',
    url: '/createDashboard',
    role: rolesList.CREATEYOUROWNDASHBOARDUSER
  },
  {
    appName: 'Raise a Request',
    url: '/requests'
  }
]


export const otherLinks = [
  {
    appName: 'Help',
    url: '/help'
  },
  {
    appName: 'Site Map',
    url: '/site-map'
  }, {
    appName: 'Process Flow',
    url: '/processFlow'
  }

]